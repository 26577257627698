/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { CURRENT_USER } from '../../auth/redux/AuthCRUD'
import { ClacRecommendedConfig, DAY_HEBREW_TO_NUMBER, ITableRow, LoadPerHour } from '../interfaces/ITableRow'
import { getGraphConfig } from '../services/cashiersData'
import { CalcModal } from './calcModal'
import { GraphModal } from './graphModal'
import { ManualStandard } from './LoadPerStandard'
import LoadPrecentConfig from './loadPrecentConfig'
import StandartRow from './RowStandart'
import { UpdateStatusModal } from './updateStatusModal'
interface props {
  tableRows: ITableRow[],
  onTitleClick: Function,
  selectedClacRecommendedConfig: ClacRecommendedConfig,
  displayCalculationModal: boolean,
  displayUpdateStatusModal: boolean,
  getListData: Function,
  displayLoadRanges: boolean,
  historyMode: boolean,
  manualStandards: ManualStandard[],
  onManualStandardChange: (standardId: number, newValue: number, previousValue?: number) => any,
}
let design = 'min-w-70px fs-5 text-hover-primary fw-bolder mb-0 border border-secondary bg-light';
export default function StandartRowsList(props: props) {
  const cashiers = useMemo(() => props.tableRows || [], [props.tableRows]);
  function onClick(title: 'branch' | 'self_checkout' | 'day' | 'evening' | 'morning') {
    props.onTitleClick(title);
  }
  const [fisrtLoad, setFisrtLoad] = useState<boolean>(true);

  const [selectedItem, setSelectedItem] = useState<number>(0);

  useEffect(() => {
    if (props.tableRows.length > 0) {
      let currentIndex = selectedItem;
      if (!fisrtLoad) {
        let day = new Date().getDay() + 1;
        let findIndex = props.tableRows.findIndex(t => t.dayInNumber === day);
        if (findIndex === -1) findIndex = 0;
        setSelectedItem(findIndex);
        setFisrtLoad(false);
        currentIndex = findIndex;
      }
      setMorningCashierShift(props.tableRows[currentIndex].morningSimulate || 0);
      setEveningCashierShift(props.tableRows[currentIndex].eveningSimulate || 0);
      setHasIndependedPOS(props.tableRows[currentIndex].hasIndependedPOS);
      setLoadByMinutes(props.tableRows[currentIndex].LoadPerHour);
      setCurrentStatus(props.tableRows[0].progressingStandardStatus);
      setStandardHeaderId(props.tableRows[0].standardHeaderId);
      getConfigParams();
    }
  }, [props.tableRows, fisrtLoad, selectedItem]);

  const [hasIndependedPOS, setHasIndependedPOS] = useState<boolean>(false);
  const [morningCashierShift, setMorningCashierShift] = useState<number>(0);
  const [eveningCashierShift, setEveningCashierShift] = useState<number>(0);
  const [minutesPerCashier, setMinutesPerCashier] = useState<number>(0);
  const [morningShiftLastHour, setMorningShiftLastHour] = useState<number>(0);
  const [loadByMinutes, setLoadByMinutes] = useState<LoadPerHour[]>([]);
  const [currentStatus, setCurrentStatus] = useState<number>(0);
  const [standardHeaderId, setStandardHeaderId] = useState<number>(0);


  const onSelectRow = (index: number, morningSimulte: number, eveningSimulte: number, hasIndependedPOS: boolean) => {
    setSelectedItem(index);
    setMorningCashierShift(morningSimulte);
    setEveningCashierShift(eveningSimulte);
    setLoadByMinutes(props.tableRows[index].LoadPerHour);
    setHasIndependedPOS(hasIndependedPOS);
  }

  const getConfigParams = async () => {
    const list: any = await getGraphConfig();
    if (list) {
      setMinutesPerCashier(list.minutesPerCashier);
      setMorningShiftLastHour(list.morningShiftLastHour);
    }
    else {
      setMinutesPerCashier(0);
      setMorningShiftLastHour(0);
    }
  }

  const onMorningChange = (morningSimulte: number, previousValue?: number) => {
    setMorningCashierShift(morningSimulte);
    props.onManualStandardChange(props.tableRows[selectedItem].morningStandardId, morningSimulte, previousValue);
  }

  const onEveningChange = (eveningSimulte: number, previousValue?: number) => {
    setEveningCashierShift(eveningSimulte);
    props.onManualStandardChange(props.tableRows[selectedItem].eveningStandardId, eveningSimulte, previousValue);
  }

  const onInputMorningPrecent = (percent: number) => {
    let maxLoad = Math.max(...loadByMinutes.filter(l => l.hour <= morningShiftLastHour).map(m => m.loadByMinutes));
    const morningCashierCount = Math.round(maxLoad / (percent / 100) / minutesPerCashier);
    const previousValue = props.tableRows[selectedItem].morningSimulate;
    props.tableRows[selectedItem].morningSimulate = morningCashierCount;
    onMorningChange(morningCashierCount, previousValue);
  }

  const onInputEveningPrecent = (percent: number) => {
    let maxLoad = Math.max(...loadByMinutes.filter(l => l.hour > morningShiftLastHour).map(m => m.loadByMinutes));
    const eveningCashierCount = Math.round(maxLoad / (percent / 100) / minutesPerCashier);
    const previousValue = props.tableRows[selectedItem].eveningSimulate;
    props.tableRows[selectedItem].eveningSimulate = eveningCashierCount;
    onEveningChange(eveningCashierCount, previousValue);
  }

  const listRender = (
    cashiers
      .filter(item => !(item.deskNumber > 50 && CURRENT_USER !== "admin"))
      .map((item, index) => (
        <StandartRow
          key={index}
          historyMode={props.historyMode}
          cashier={item}
          onSelectRow={onSelectRow}
          index={index}
          selectedRow={selectedItem}
          manualStandards={props.manualStandards.find(s => s.day === DAY_HEBREW_TO_NUMBER[item.day])}
          onMorningChange={onMorningChange}
          onEveningChange={onEveningChange}
          ClacRecommendedConfig={props.selectedClacRecommendedConfig} />
      )))
  return (
    <div>
      <div className={`card`}>
        <div className='card-body py-3'>
          <div className='table-responsive LoadtableFixHead'>
            <table className='table-hover table align-middle table-row-bordered gs-4' style={{ textAlign: "center" }}>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th colSpan={1} style={{ cursor: 'pointer', backgroundClip: "padding-box" }} className={'text-center fs-5 border border-secondary bg-light'}></th>
                  <th colSpan={1} style={{ cursor: 'pointer', backgroundClip: "padding-box" }} className={'text-center fs-5 border border-secondary bg-light'}></th>
                  <th colSpan={1} style={{ cursor: 'pointer', backgroundClip: "padding-box" }} className={'text-center fs-5 border border-secondary bg-light'}></th>
                  <th colSpan={2} style={{ cursor: 'pointer', backgroundClip: "padding-box" }} className={'text-center fs-5 border border-secondary bg-light'}><FormattedMessage id="RECOMMENDATIONS_SCREEN.CURRENT_STANDARD" /></th>
                  <th colSpan={2} style={{ cursor: 'pointer', backgroundClip: "padding-box" }} className={'text-center fs-5 border border-secondary bg-light'}><FormattedMessage id="RECOMMENDATIONS_SCREEN.ACTUAL_ASSIGNMENT" /></th>
                  <th colSpan={2} style={{ cursor: 'pointer', backgroundClip: "padding-box" }} className={'text-center fs-5 border border-secondary bg-light'}><FormattedMessage id="RECOMMENDATIONS_SCREEN.TOTAL_OPEN_POS" /></th>
                  <th colSpan={2} style={{ cursor: 'pointer', backgroundClip: "padding-box" }} className={'text-center fs-5 border border-secondary bg-light'}><FormattedMessage id="RECOMMENDATIONS_SCREEN.RECOMMENDAD_STANDARD" /></th>
                  <th colSpan={2} style={{ cursor: 'pointer', backgroundClip: "padding-box" }} className={'text-center fs-5 border border-secondary bg-light'}><FormattedMessage id="RECOMMENDATIONS_SCREEN.LOAD_SIMULATION" /></th>
                  <th colSpan={2} style={{ cursor: 'pointer', backgroundClip: "padding-box" }} className={'text-center fs-5 border border-secondary bg-light'}><FormattedMessage id="RECOMMENDATIONS_SCREEN.RECOMMENDED_STANDARD_BRUTO" /></th>
                </tr>
                <tr className='fw-bolder text-muted bg-light'>
                  {/* <th className='ps-4 min-w-325px rounded-start'>id</th> */}
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('branch')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.BRANCH" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('self_checkout')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.SELF_CHECKOUT" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('day')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.DAY" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('morning')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.MORNING" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('evening')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.EVENING" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('morning')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.MORNING" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('evening')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.EVENING" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('morning')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.MORNING" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('evening')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.EVENING" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('morning')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.MORNING" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('evening')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.EVENING" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('morning')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.MORNING" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('evening')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.EVENING" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('morning')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.MORNING" /></th>
                  <th style={{ cursor: 'pointer', backgroundClip: "padding-box" }} onClick={() => onClick('evening')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.EVENING" /></th>
                </tr>
              </thead>
              <tbody>
                {listRender}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br />
      <div>
        <GraphModal className={''} morningCashierShift={morningCashierShift} eveningCashierShift={eveningCashierShift}
          loadByMinutes={loadByMinutes}
          minutesPerCashier={minutesPerCashier}
          morningShiftLastHour={morningShiftLastHour}
          hasIndependedPOS={hasIndependedPOS}
        />
      </div>
      <div>
        {props.displayUpdateStatusModal &&
          <UpdateStatusModal currentStatus={currentStatus} standardHeaderId={standardHeaderId} onChange={props.getListData} />
        }
      </div>
      <br />
      {props.displayCalculationModal &&
        <CalcModal data={props.selectedClacRecommendedConfig}
          onChange={props.getListData}
        />
      }
      {!props.historyMode && props.displayLoadRanges &&
        <LoadPrecentConfig
          onInputMorningPrecent={onInputMorningPrecent}
          onInputEveningPrecent={onInputEveningPrecent}
          morningCashiers={morningCashierShift}
          eveningCashiers={eveningCashierShift}
          minutePerCahsier={minutesPerCashier}
          loadByMinutes={loadByMinutes}
          morningShiftLastHour={morningShiftLastHour}
        />
      }
      <br />
      <br />
      <br />
    </div>
  )
}
