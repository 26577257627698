import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

type Props = {
  userName: string;
  currentWeekActions: number
  previousWeekActions: number
  todayActions: number;
  yesterdayActions: number;
  lastAction: string;
  status: number;
  color?: string
}

const CardUsageLevel: FC<Props> = ({
  userName,
  currentWeekActions,
  previousWeekActions,
  todayActions,
  yesterdayActions,
  lastAction,
  color = '',
}) => {
  return (
    <div className='retailin-card'
      style={{
        borderTop: `8px solid ${color}`,

      }}
    >
      <div className='card-body d-flex flex-center flex-column p-3'>
        {/* {warrning} */}

        <Link className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0 testclass'
          to={{ pathname: '/usageLog', state: userName }}
        >{userName}</Link>
        <br />
        {/* <a href='/cashiers' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0 testclass'>
        {name}
        </a> */}
        {/* <div className='fw-bold text-gray-400 mb-6'>
          {contactName}({phone})
        </div> */}
        <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded  mx-2 px-2 mb-2'>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}>
                <FormattedMessage id="USAGE_LEVELS_SCREEN.ACTIONS_LAST_N_DAYS" values={{ n: 7 }} />:
              </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {currentWeekActions}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}>
                <FormattedMessage id="USAGE_LEVELS_SCREEN.ACTIONS_TODAY" />:
              </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {todayActions}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}>
                <FormattedMessage id="USAGE_LEVELS_SCREEN.LAST_ACTIONS" />:
              </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {lastAction}
              </span>
            </div>
          </div>
          <div className='border border-gray-300 border-dashed rounded   mx-2 px-2 mb-2'>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}>
                <FormattedMessage id="USAGE_LEVELS_SCREEN.PREVIOUS_N_DAYS" values={{ n: 7 }} />:
              </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {previousWeekActions}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}>
                <FormattedMessage id="USAGE_LEVELS_SCREEN.ACTIONS_PREVIOUS_DAY" />:
              </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {yesterdayActions}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {/* {cashierDeskCountIndependentIsPosActive} */}
              </span>
              <span style={{ fontSize: '1rem', margin: '1px' }}>  </span>
            </div>
            {/* <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {cashierShift}
              </span>
              <span style={{ fontSize: '1rem', margin: '1px' }}> עובדים משובצים </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {activeCartPOSCount}
              </span>
              <span style={{ fontSize: '1rem', margin: '1px' }}> עגלות פעילות </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {independentDeskAvgLoad}%
              </span>
              <span style={{ fontSize: '1rem', margin: '1px' }}> עומס שירות עצמי </span>
            </div> */}
          </div>

        </div>
      </div>
    </div >
  )
}

export { CardUsageLevel }
//,display: (CURRENT_USER === "admin" ? 'block' : 'none')
