import React from 'react'
import { CardStore } from './CardStore'
import { STATUS_TYPE } from '../../auth/redux/AuthCRUD'
import { IStore } from '../interfaces/IStore'

interface Props {
  store: IStore
}

const listColor = [
  { status: 1, color: '#50cd89', icon: 'fa-smile-beam' },
  { status: 2, color: '#ffc700', icon: 'fas fa-exclamation-circle' },
  { status: 3, color: '#f1416c', icon: 'fa-times-circle' },
  { status: 4, color: '#6f42c1', icon: 'fa-times-circle' },
]

const Store = (props: Props) => {
  const store: IStore = props.store
  let colorStyle;
  switch (STATUS_TYPE) {
    case 'independentDeskStatus':
      colorStyle = listColor.find((item) => item.status === props.store.independentDeskStatus)?.color
      break;
    case 'activeDeskStatus':
      colorStyle = listColor.find((item) => item.status === props.store.activeDeskStatus)?.color
      break;
    case 'attendanceStatus':
      colorStyle = listColor.find((item) => item.status === props.store.attendanceStatus)?.color
      break;
    case 'shiftStatus':
      colorStyle = listColor.find((item) => item.status === props.store.shiftStatus)?.color
      break;
    case 'cashiersDeviationStatus':
      colorStyle = listColor.find((item) => item.status === props.store.cashiersDeviationStatus)?.color
      break;
    case 'revenueStatus':
      colorStyle = listColor.find((item) => item.status === props.store.revenueStatus)?.color
      break;
    case 'loadStatus':
      colorStyle = listColor.find((item) => item.status === props.store.loadStatus)?.color
      break;
  }

  // const colorStyle = listColor.find((item) => item.status === props.store.status)?.color

  return (
    <div style={{ width: "fit-content" }}>
      <CardStore
        store={store}
        color={colorStyle}
      />
    </div>
  )
}
export default Store
