/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import UserList, { Column } from './UsageUserList'
import { getUserList } from '../services/userData'
import { IUserList } from '../interfaces/IUsageUser'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Moment from 'react-moment';
import SearchUser from './SearchUser'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { IS_SORT_SIDE, removeUser, setIsSortSide } from '../../auth/redux/AuthCRUD'
import { FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import { sendlog, logEnum } from 'app/modules/logs/sendLog'

export default function UserListPage() {
  const [listUsageUser, setListUsageUser] = useState<IUserList[]>([])
  const [searchUser, setSearchUser] = useState<string>('')
  let branchSelected: any = useLocation().state || "";

  const [lastDateUpdate, setLastDateUpdate] = useState<Date | null>()
  const [errorString, setErrorString] = useState<string>('')


  const deleteItem = async (id: number) => {
    try {
      await removeUser(id)
      getListUsageUser()
    }
    catch (e) {
      setErrorString("FAILED_DELETE")
      new Promise(() => setTimeout(() => setErrorString(''), 100000))
    }
  }
  const history = useHistory();

  const editItem = (id: number) => {
    history.push({
      pathname: '/update_user',
      state: { id: id },
    });
  }

  const getUserToRender = () => {
    if (!listUsageUser) return []
    return listUsageUser.filter(item => item.userName.includes(searchUser))
  };

  const getListUsageUser = async () => {
    try {
      const list: any = await getUserList('')
      if (list) {
        setListUsageUser(list)
        setLastDateUpdate(new Date())
      }
    } catch (err: any) {

    }
  }
  useEffect(() => {
    // sendlog(logEnum.usageUserScreen);
    onSearchUser(branchSelected);
    getListUsageUser()
  }, [branchSelected])

  const onTitleClick = (titleSelected: Column) => {
    let list = [...listUsageUser];

    if (IS_SORT_SIDE) {
      if (titleSelected === 'userName') list.sort((a, b) => a.userName.localeCompare(b.userName));
      else if (titleSelected === 'email') list.sort((a, b) => a.email.localeCompare(b.email));
      else if (titleSelected === 'storeName') list.sort((a, b) => a.storeId - b.storeId);
      else if (titleSelected === 'userLevel') list.sort((a, b) => a.userLevel.localeCompare(b.userLevel));
    } else {
      if (titleSelected === 'userName') list.sort((b, a) => a.userName.localeCompare(b.userName));
      else if (titleSelected === 'email') list.sort((b, a) => a.email.localeCompare(b.email));
      else if (titleSelected === 'storeName') list.sort((b, a) => a.storeId - b.storeId);
      else if (titleSelected === 'userLevel') list.sort((b, a) => a.userLevel.localeCompare(b.userLevel));
    }
    setIsSortSide();
    setListUsageUser(list);
  }
  const onSearchUser = (search: string) => {
    setSearchUser(search)
  }

  useEffect(() => {
    sendlog(logEnum.userListScreen)
  }, [])

  return (
    <div>
      <nav className='navbar navbar-light bg-light row'>
        <div className='col-4' style={{ textAlign: "start" }}>
          <span>
            <FormattedMessage
              id={"CASHIERS_ALERTS_SCREEN.LAST_UPDATE"}
              values={{ lastUpdate: lastDateUpdate && <Moment format='DD-MM-YYYY HH:mm'>{lastDateUpdate}</Moment> }} />
          </span>
        </div>
        <h1 className='breadcrumb-item text-muted col-4 text-center'>
          <FormattedMessage id="SCREENS.USER_LIST" />
        </h1>
        <span className='col-4' style={{ textAlign: "end" }}>
          <a className='navbar-brand' href='https://yochananof.co.il/'>
            &nbsp;&nbsp;
            <img
              src={toAbsoluteUrl('/media/logos/retail_logo.png')}
              width='100'
              height='30'
              className='d-inline-block '
              alt=''
              loading='lazy'
            />
          </a>
        </span>
      </nav>
      <br />
      <br />
      <div className='row '>
        <div className='col-3'>
          <div className='d-flex flex-row'>
            <div className='col-6'>
              <SearchUser onSearch={onSearchUser} user={searchUser}></SearchUser>
            </div>
            <div className='col-10 font-bold text-lg' >
              <Link to={"/registration"}>
                <Button variant="primary" style={{ fontWeight: 'bolder' }} >
                  <FormattedMessage id={"USER_LIST_SCREEN.ADD_USER"} />
                </Button></Link>
            </div>
            <div className={`col-10 alert alert-danger  ${errorString.length > 0 ? "" : "invisible"}`}>
              <div className='alert-text text-lg  font-weight-bold'>
                <FormattedMessage id={`USER_LIST_SCREEN.${errorString}`} /></div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <UserList onTitleClick={onTitleClick} userArr={getUserToRender()} deleteItem={deleteItem} editItem={editItem}
      />
    </div>
  )
}
