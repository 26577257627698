/* eslint-disable jsx-a11y/anchor-is-valid */
import { IStore } from '../interfaces/IStore'
import { FC } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

type Props = {
  store: IStore
  color?: string
}

const CardStore: FC<Props> = ({
  store,
  color = '',
}) => {

  const warnings = store.orangeWarningMessages
  const errors = store.redWarningMessages

  const formatTimeParams = (timeParams: Record<string, Date>) => {
    return Object.entries(timeParams)
      .map(([key, value]) => ({ [key]: new Date(value).toLocaleString(), }))
      .reduce((obj, current) => Object.assign(obj, current), {});
  }

  const WarningsPopover = (warnings: IStore['orangeWarningMessages']) => (
    <Popover id="popover-basic" style={{ width: "25%", maxWidth: "25%", left: "50%" }}>
      {<Popover.Header><FormattedMessage id="CASHIERS_ALERTS_SCREEN.ATTENTION" /></Popover.Header>}
      {<Popover.Body>
        <div className='RetailToolTip'>
          {warnings.map((message, i) => (
            <p key={i}>
              <FormattedMessage
                id={`STORE_WARNINGS.${message.messageId}`}
                values={{ ...message.params, ...(message.timeParams !== undefined ? formatTimeParams(message.timeParams) : {}) }}
              />
            </p>
          ))}
        </div>
      </Popover.Body>}
    </Popover>
  )

  let warrning1;
  let warrning2;

  if (store.orangeWarningStatus > 0) {
    warrning1 = <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={WarningsPopover(warnings)}>
      <div className={"retail-warning retail-warning" + store.orangeWarningStatus.toString() + " fas fa-exclamation-triangle"}></div>
    </OverlayTrigger>
  }
  else { warrning1 = ''; }
  if (store.redWarningStatus > 0 && errors.length > 0) {
    warrning2 = <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={WarningsPopover(errors)}>
      <div className={"retail-warning retail-warning2 fas fa-exclamation-triangle"}></div>
    </OverlayTrigger>
  }
  else { warrning2 = ''; }


  return (
    <div className='retailin-card'
      style={{
        borderTop: `8px solid ${color}`,

      }}
    >
      <div className='card-body d-flex flex-center flex-column p-3'>
        <div className='retail-warnings-container'>
          {warrning1}
          {warrning2}
        </div>


        <Link className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0 testclass'
          to={{ pathname: '/cashiers', state: store.name }
          } >{store.name + ' (' + store.storeNumber + ')'}</Link>
        <div className='fw-bold text-gray-400 mb-6'>
          {store.contactName} ({store.phone})
        </div>
        <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded  mx-2 px-2 mb-2'>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.STANDARD" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.storeDeskStandart}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.SIGNED_ATTENDANCE" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.cashierArrived}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.SELF_CHECKOUT_DESKS" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.cashierDeskIndependentCount}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.NO_OF_POS" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.cashierDeskFullServiceCount}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.SMART_CARTS" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.cartPOSCount}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.POS_AVAILABILITY_PERCENTAGE" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.cashierDeskAvgLoad}%
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.PREVIOUS_REVENUE" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.previousRevenue}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.MINIMUM_POS_NEEDED" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.requiredCashiers}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.CARTS_AVAILABILITY_PERCENTAGE" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.cartAvgLoad ?? 0}%
              </span>
            </div>
          </div>
          <div className='border border-gray-300 border-dashed rounded   mx-2 px-2 mb-2'>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.SIGNED_ATTENDANCE" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.cashierArrived}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.EMPLOYEE_IN_POS" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.cashierDeskCountIsPosActive}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.POS_ACTIVATED" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.cashierDeskCountIndependentIsPosActive}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.EMPLOYEES_ASSIGNED" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.cashierShift}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.ACTIVE_CARTS" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.activeCartPOSCount}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.SCO_AVAILABILITY_PERCENTAGE" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.independentDeskAvgLoad}%
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.CURRENT_REVENUE" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.CurrentRevenue}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="CASHIERS_ALERTS_SCREEN.ACTIVE_POS" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.numberOfActiveDesksWhichCollectFromTillsList}
              </span>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  )
}

export { CardStore }
