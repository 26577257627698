/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react'
import StoresList from './StoresList'
import { getStores } from '../services/storesData'
import FilterStores from './FilterStores'
import SearchStore from './SearchStore'
import { filtersStores } from '../helpers/filtersStores'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Moment from 'react-moment';
import { setPOSEeventResearchScreenStatusType, setRecommendedStandardScreenStatusType, STATUS_TYPE } from '../../auth/redux/AuthCRUD'
import { auto } from '@popperjs/core'
import { logEnum, sendlog } from '../../logs/sendLog'
import StatusBy from './StatusBy'
import SortWeek from './SortWeek'
import { FilterStatusType, RecommendationFilterContext } from 'app/routing/PrivateRoutes'
import { FormattedMessage } from 'react-intl'
import { IPOSEventResearch, IPOSEventResearchCollection } from '../interfaces/IPOSEventResearchCollection'

const sortByName = (a: any, b: any) => {
  if (a["name"] < b["name"]) {
    return -1
  }
  if (a["name"] > b["name"]) {
    return 1
  }
  return 0;
}
const sortByStatus = (a: any, b: any) => {
  if (a[STATUS_TYPE] > b[STATUS_TYPE]) {
    return -1
  }
  if (a[STATUS_TYPE] < b[STATUS_TYPE]) {
    return 1
  }
  return 0;
};

const sortConfig = [
  { field: 'name', func: sortByName },
  { field: 'status', func: sortByStatus },
]

export default function POSEventResearch() {
  const [recommendationFilter, setRecommendationFilter] = useContext(RecommendationFilterContext);

  const [searchStore, setSearchStore] = useState<string>(recommendationFilter.search);
  const [statusFilter, setStatusFilter] = useState<number>(recommendationFilter.statusValue);
  const [statusType, setStatusByType] = useState(recommendationFilter.statusType);

  useEffect(() => {
    setSearchStore(recommendationFilter.search);
    setStatusFilter(recommendationFilter.statusValue);
    setStatusByType(recommendationFilter.statusType);
  }, [recommendationFilter])

  const [listStores, setListStores] = useState<IPOSEventResearchCollection[]>([])
  const [selected, setSelected] = useState<IPOSEventResearch[]>([])
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [orderStore] = useState<string>('name')
  const [lastDateUpdate, setLastDateUpdate] = useState<Date | null>()

  const getStoreToRender = () => {
    if (!selected) return [];

    const filterRun = filtersStores.find((filter) => filter.status === statusFilter);
    if (!filterRun) {
      return selected
    }

    return selected
      .filter(filterRun.filter)
      .filter((item) => {
        if (!searchStore) return true
        return item.name.includes(searchStore)
      })
      .sort(sortStore)
  };

  const sortStore = (a: any, b: any) => {
    const funcSort = sortConfig.find(s => s.field === orderStore)?.func;
    if (funcSort) {
      return funcSort(a, b);
    }
    return 1;
  }

  const getListStores = async (listReq: IPOSEventResearchCollection[] = []) => {
    try {
      let list = listReq;
      if (list.length === 0) {
        list = await getStores();
      }
      if (list) {
        setListStores(list);
        setSelected(list[0].tills);
      }
    } catch (err) { }
  }

  useEffect(() => {
    setRecommendedStandardScreenStatusType(recommendationFilter.statusType);
    sendlog(logEnum.POSEventResearch);
    getListStores().then(() => setLastDateUpdate(new Date()));
  }, [recommendationFilter.statusType])
  const onFilter = (status: number) => {
    setRecommendationFilter({ ...recommendationFilter, statusValue: status });
  }
  const onSearch = (search: string) => {
    setRecommendationFilter({ ...recommendationFilter, search });
  }
  const onStatusBy = (statusSelected: FilterStatusType) => {
    setRecommendationFilter({ ...recommendationFilter, statusType: statusSelected });
    setPOSEeventResearchScreenStatusType(statusSelected);
  }
  const onSortWeek = (selectedValue: number) => {
    setSelectedIndex(selectedValue);
    setSelected(listStores[selectedValue].tills)
  }

  const img = <img
    src={toAbsoluteUrl('/media/logos/retail_logo.png')}
    width='100'
    height='30'
    className='d-inline-block '
    alt=''
    loading='lazy'
  />
  return (
    <div>
      <nav className='navbar navbar-light row justify-content-between'>
        <div className='col-auto'>
          <span>
            {lastDateUpdate &&
              <div>
                <span>
                  <FormattedMessage
                    id={"CASHIERS_ALERTS_SCREEN.LAST_UPDATE"}
                    values={{ lastUpdate: <Moment format='DD-MM-YYYY HH:mm'>{lastDateUpdate ? lastDateUpdate : ''}</Moment> }} />
                </span>
              </div>}
            {!lastDateUpdate && < img src={toAbsoluteUrl('/media/gifs/hourglass.gif')} width={20} alt="loading" />}
          </span>
        </div>
        <h1 className='breadcrumb-item text-muted col-auto'><FormattedMessage id="SCREENS.POS_EVENT_RESEARCH" /></h1>
        <span className='col-auto'>
          <a className='navbar-brand' href='https://yochananof.co.il/'>
            &nbsp;&nbsp;
            {img}
          </a>
        </span>
      </nav>
      <br />
      <br />
      <div className='row'>
        <div className='col-xxl-5'>
          <div className='row justify-content-between'>
            <div className='col-12 col-sm-3'>
              <SearchStore onSearch={onSearch} value={searchStore}></SearchStore>
            </div>
            <div className='col-12 col-sm-5' >
              <SortWeek onSortWeek={onSortWeek} collection={listStores} />
            </div>
            <div className='col-12 col-sm-4' >
              <StatusBy onStatusBy={onStatusBy} value={statusType} />
            </div>
          </div>
        </div>
        <div style={{ marginLeft: auto, }} className='col-sm-12 col-xxl-auto pe-6 ps-5'>
          <div className='col-12 filter-buttons'>
            <FilterStores onFilter={onFilter} listStores={selected} />
          </div>
        </div>
      </div>
      <hr />
      <StoresList stores={getStoreToRender()} weekNum={listStores.length !== 0 ? listStores[selectedIndex].weekNum : 0} collection={listStores} />
    </div >
  )
}
