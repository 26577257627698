import {useCallback, useState} from 'react'
import {MultiSelect} from '../MutliSelector'

type ActionSelectorProps = {
  className?: string
  selectedActions: ActionSelection[]
  actions: ActionSelection[]
  onSelectedActionChange: (selectedStores: ActionSelection[]) => any
  selectorName: string
  onActionListLoaded?: (stores: ActionSelection[]) => any
}

export const ActionSelector: React.VFC<ActionSelectorProps> = ({
  className,
  selectedActions,
  onSelectedActionChange,
  selectorName,
  actions,
}) => {
  const [filteredStores, setFilteredStores] = useState<ActionSelection[]>([])

  const handleActionSelectionChange = (selectedIds: string[]) => {
    const selectedSet = new Set(selectedIds.map((id) => parseInt(id)))
    onSelectedActionChange(actions.filter((store) => selectedSet.has(store.id)))
  }

  const handleActionFilter = useCallback(
    (search: string) => {
      setFilteredStores(actions.filter((store) => store.name.includes(search)))
    },
    [actions]
  )

  const handleSelectAll = () => {
    onSelectedActionChange(actions)
  }

  const handleCancelSelection = () => {
    onSelectedActionChange([])
  }

  return (
    <MultiSelect
      className={className ?? ''}
      options={filteredStores.map((s) => ({label: s.name, value: s.id.toString()}))}
      onSelectionChange={handleActionSelectionChange}
      placeholder={selectorName}
      selectedOptions={selectedActions.map((s) => ({value: s.id.toString(), label: s.name}))}
      onFilter={handleActionFilter}
      selectAll={handleSelectAll}
      cancelSelection={handleCancelSelection}
    />
  )
}

export type ActionSelection = {
  id: number
  name: string
}
