/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom'
import { IRecommendedStandard } from '../interfaces/IRecommendedStandard'

type Props = {
  store: IRecommendedStandard;
  color?: string,
  historyMode: boolean,
}

const CardRecommendedStandard: FC<Props> = ({
  store,
  color = '',
  historyMode,
}) => {
  return (
    <div className='retailin-card'
      style={{
        borderTop: `8px solid ${color}`,
      }}
    >
      <div className='card-body d-flex flex-center flex-column p-3'>
        <Link className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0 testclass'
          to={{ pathname: '/loadPerStandard', state: { storeId: store.id, historyMode } }
          } >{store.name + ' (' + store.storeNumber + ')'}</Link>
        <div className='fw-bold text-gray-400 mb-6'>
          {store.contactName}({store.phone})
        </div>
        {historyMode && <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded  mx-2 px-2 mb-2'>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}> <FormattedMessage id="RECOMMENDATIONS_SCREEN.AVERAGE_STANDARD_MORNING" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.mornungAvgStandard}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}> <FormattedMessage id="RECOMMENDATIONS_SCREEN.AVERAGE_STANDARD_EVENING" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.evningAvgStandard}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <Link className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0 testclass'
                to={{ pathname: '/ProductivityPerDay', state: store.name }
                } >
                <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="RECOMMENDATIONS_SCREEN.CHAIN_PRODUCTIVITY" />(&#8362;): </span>
                <span style={{ fontSize: '1rem', padding: '1px' }}>{store.chainAvgProductivity}</span>
              </Link>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}> <FormattedMessage id="RECOMMENDATIONS_SCREEN.TOTAL_WEEKLY_ATTENDANCE" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.weeklyAttendance ?? 0}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}> <FormattedMessage id="RECOMMENDATIONS_SCREEN.TOTAL_STANDARD_EXCESS" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.overloadStandard ?? 0}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}> <FormattedMessage id="RECOMMENDATIONS_SCREEN.TOTAL_ASSIGNMENT_EXCESS" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.overloadPosting ?? 0}
              </span>
            </div>

          </div>
          <div className='border border-gray-300 border-dashed rounded   mx-2 px-2 mb-2'>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}> <FormattedMessage id="RECOMMENDATIONS_SCREEN.MORNING_RECOMMENDATION" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.mornungRecommendedStandard}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}> <FormattedMessage id="RECOMMENDATIONS_SCREEN.EVENING_RECOMMENDATION" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.evningRecommendedStandard}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <Link className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0 testclass'
                to={{ pathname: '/ProductivityPerDay', state: store.name }
                } >
                <span style={{ fontSize: '1rem', margin: '1px' }}><FormattedMessage id="RECOMMENDATIONS_SCREEN.STORE_PRODUCTIVITY" />(&#8362;): </span>
                <span style={{ fontSize: '1rem', padding: '1px' }}>{store.branchAvgProductivity}</span>
              </Link>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}> <FormattedMessage id="RECOMMENDATIONS_SCREEN.POS_NET_WORK" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.POSWorkingNet ?? 0}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}> <FormattedMessage id="RECOMMENDATIONS_SCREEN.TOTAL_STANDARD_LACK" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.underloadStandard ?? 0}
              </span>
            </div>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}> <FormattedMessage id="RECOMMENDATIONS_SCREEN.TOTAL_ASSIGNMENT_LACK" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.underloadPosting ?? 0}
              </span>
            </div>
          </div>
        </div>}
      </div>
    </div>
  )
}
export { CardRecommendedStandard }