import { API_URL } from "app/config/config"
import { actions } from "app/modules/auth"
import { AxiosInstance, AxiosRequestConfig } from "axios"
import { Store } from "redux"

export default function setupAxios(axios: AxiosInstance, store: Store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { accessToken },
      } = store.getState();
      const normalizedUrl = config.url?.startsWith("/") ? config.url : `/${config.url}`;
      config.url = `${API_URL}${normalizedUrl}`;
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
      return config
    },
    (err) => {
      Promise.reject(err);
    }
  )

  axios.interceptors.response.use(
    response => response,
    async err => {
      const originalRequestConfig = err.config as AxiosRequestConfig & { _retry?: boolean };
      if (err.response?.status === 401) {
        if (!originalRequestConfig._retry) {
          const newTokens = await refreshToken(axios, store.getState()?.auth?.refreshToken);
          store.dispatch(actions.refresh(newTokens.accessToken, newTokens.refreshToken));
          originalRequestConfig._retry = true;
          originalRequestConfig.headers.Authorization = `Bearer ${newTokens.accessToken}`
          return axios(originalRequestConfig);
        } else { // we already retried, let's just re-login
          document.location.href = "/logout";
          return;
        }
      }
      return Promise.reject(err)
    }
  )
}

async function refreshToken(axios: AxiosInstance, refreshToken: string) {
  try {
    return (await axios.post<{ refreshToken: string, accessToken: string }>(`/login/auth/refresh`, { refreshToken }, { _retry: true } as AxiosRequestConfig)).data;
  } catch (ex) {
    document.location.href = "/logout";
    return { refreshToken: "", accessToken: "" };
  }
}
