import { RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE } from '../../auth/redux/AuthCRUD'
import { IFilterStore, IRecommendedStandard } from '../interfaces/IRecommendedStandard'

function resultByStatusType(status: number, item: IRecommendedStandard): boolean {
  switch (RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE) {
    case 'standardStatus':
      return item.standardStatus === status;
    case 'productivityStatus':
      return item.productivutyStatus === status;
    case 'attendanceAgainstWorkStatus':
      return item.attendanceAgainstWorkStatus === status;
    case 'overloadStandardStatus':
      return item.overloadStandardStatus === status;
    case 'underloadStandardStatus':
      return item.underloadStandardStatus === status;

    case 'overloadPostingStatus':
      return item.overloadPostingStatus === status;
    case 'underloadPostingStatus':
      return item.underloadPostingStatus === status;

    default: return true
  }
}

export const showAllFilter = (item: IRecommendedStandard) => {
  return true
}
export const successFilter = (item: IRecommendedStandard, index: number) => {
  return resultByStatusType(1, item);
  // return item.status === 1
}
export const oneCashierDeskInactiveFilter = (item: IRecommendedStandard, index: number) => {
  return resultByStatusType(2, item);
  // return item.status === 2
}
export const fullCashierDeskInactive = (item: IRecommendedStandard, index: number) => {
  return resultByStatusType(3, item);
  // return item.status === 3
}
export const upThan100 = (item: IRecommendedStandard, index: number) => {
  return resultByStatusType(4, item);
  // return item.status === 4
}

export const filtersStores: IFilterStore[] = [
  { status: 0, des: 'show all', filter: showAllFilter },
  { status: 1, des: 'full success', filter: successFilter },
  { status: 2, des: 'one casherDeskInactive', filter: oneCashierDeskInactiveFilter },
  { status: 3, des: 'full cashierDeskCountInactive', filter: fullCashierDeskInactive },
  { status: 4, des: 'up than 100', filter: upThan100 },
]
