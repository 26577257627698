/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

const Footer: FC = () => {
  return (
    <></>
  )
}

export { Footer }
