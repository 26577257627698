import Table from 'react-bootstrap/Table'
import * as ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'
import {Button} from 'react-bootstrap'

import excelImage from './excel.jpg'
import {ActionSelection} from 'app/common/components/selectors/ActionSelector'

export type popupTableData = {
  hour?: string
  code: string
  description?: string
  quantity?: string
  price_per_unit?: string
  bruto?: string
  supervisor_name?: string
  neto?: string
}

const popupDataToString = (data: popupTableData[]): Array<Array<string>> => {
  return data.map((data) => [
    data['hour'] ?? '',
    data['code'],
    data['description'] ?? '',
    data['quantity'] ?? '',
    data['price_per_unit'] ?? '',
    data['bruto'] ?? '',
    data['supervisor_name'] ?? '',
    data['neto'] ?? '',
  ])
}

export type IPopupTableProps = {
  ticketLine: Array<string>
  data: Array<popupTableData>
  upperColumns: Array<string>
  closeWindow: () => any
  glowX?: boolean
  actionsList: ActionSelection[]
}

export function PopupTable({
  ticketLine,
  data,
  upperColumns,
  closeWindow,
  glowX,
  actionsList,
}: IPopupTableProps) {
  if (ticketLine === undefined) return <div></div>
  const firstLine = ticketLine[1] + ', ' + ticketLine.slice(3, 5).join(', ')
  const secondLine =
    'קופה: ' +
    ticketLine[2] +
    ',פתקית: ' +
    ticketLine[5] +
    (ticketLine[9] ? ', קופאי: ' + ticketLine[9] : '') +
    (ticketLine[10] ? ', מנהל: ' + ticketLine[10] : '')

  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        top: '150px',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          padding: '3px',
          borderRadius: '2px',
          backgroundColor: 'black',
        }}
      >
        <div
          style={{
            padding: '5px',
            borderRadius: '5px',
            backgroundColor: 'white',
          }}
        >
          <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Button
                style={{borderRadius: '2px', paddingBottom: '2px', padding: '5px'}}
                onClick={closeWindow}
                variant={glowX === true ? 'warning' : 'secondary'}
              >
                X
              </Button>
              <Button
                style={{display: 'flex', alignItems: 'flex-end'}}
                variant='secondary'
                size='sm'
                onClick={() => saveExcelFile(popupDataToString(data), upperColumns, ticketLine[5])}
              >
                <img
                  src={excelImage}
                  alt='שמור כאקסל'
                  style={{width: '30px', height: '25px'}}
                ></img>
              </Button>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <p style={{fontSize: '15px', paddingRight: '5px', fontWeight: 'bold'}}>
                {' '}
                {firstLine}
              </p>
              <p style={{fontSize: '15px', fontWeight: 'bold'}}> {secondLine}</p>
              {ticketLine[8] == null && (
                <p style={{fontSize: '17px', fontWeight: 'bold', color: 'red'}}> {ticketLine[7]}</p>
              )}
            </div>
          </div>
          <ActionTable
            data={popupDataToString(data)}
            upperColumns={upperColumns}
            actionsList={actionsList}
          />
        </div>
      </div>
    </div>
  )
}

function makeUpperColumns(upperColumns: string[]) {
  return (
    <tr
      style={{
        fontWeight: 'bold',
        textAlign: 'center',
        border: '1px solid #ddd',
        color: '#0472D0',
        backgroundColor: 'lightgrey',
      }}
    >
      {upperColumns.map((col, index) => (
        <th>{col}</th>
      ))}
    </tr>
  )
}

function makeDataIntoRows(data: string[][], actionsList: ActionSelection[]) {
  data.push([])

  return data.map((row, i) => (
    <tr
      key={i}
      style={{
        border: '1px solid #cccccc',
        lineHeight: 1,
      }}
    >
      {row.map((cellValue, j) => (
        <td
          key={j}
          style={{
            textAlign: 'center',
            color: actionsList.some((action) => cellValue === action.name)
              ? 'red'
              : parseFloat(cellValue) < 0
              ? 'red'
              : 'black',
            direction: 'initial',
            backgroundColor: row[0] === 'סה"כ' && j < data[1].length - 2 ? 'lightyellow' : 'white',
            padding: '8px',
          }}
        >
          {cellValue}
        </td>
      ))}
    </tr>
  ))
}

interface ITableProps {
  data: Array<Array<string>>
  upperColumns: Array<string>
  actionsList: ActionSelection[]
}

function ActionTable({data, upperColumns, actionsList}: ITableProps) {
  return (
    <Table style={{borderBottom: '1px solid #dee2e6 !important'}}>
      <thead>{makeUpperColumns(upperColumns)}</thead>
      <tbody>{makeDataIntoRows(data, actionsList)}</tbody>
    </Table>
  )
}

export async function saveExcelFile(data: string[][], upperRow: string[], ticketNum: string) {
  // Create a new workbook and add a worksheet
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Sheet 1')
  // makeUpperHeader(worksheet, upperRow)
  makeUpperHeader(worksheet, upperRow)
  writeRows(worksheet, data)
  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  saveAs(blob, 'ticket_' + ticketNum + '.xlsx')
}

function writeRows(worksheet: ExcelJS.Worksheet, data: string[][]) {
  for (let index = 0; index < data.length; index++) {
    const row = worksheet.addRow(data[index])
    for (let jindex = 0; jindex < data[index].length; jindex++) {
      const cell = row.getCell(jindex + 1)
      // cell.fill = { type: 'pattern', pattern: 'solid', };
      cell.border = {
        top: {style: 'thin'},
        left: {style: 'thin'},
        bottom: {style: 'thin'},
        right: {style: 'thin'},
      }
    }
  }
}

function makeUpperHeader(worksheet: ExcelJS.Worksheet, row: string[]) {
  const rowLength = row.length
  const emptyRow = worksheet.addRow(Array(rowLength).fill(null))
  for (let index = row.length - 1; index >= 0; index--) {
    const cell = emptyRow.getCell(index + 1)
    cell.value = row[index]
    cell.alignment = {horizontal: 'center'}
    cell.border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      bottom: {style: 'thin'},
      right: {style: 'thin'},
    }
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'D3D3D3'},
    }
    cell.font = {bold: true}
  }
}

export default ActionTable
