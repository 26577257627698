/* eslint-disable jsx-a11y/anchor-is-valid */
import { DAY_TO_MESSAGE_ID } from 'app/modules/loadPerStandard/interfaces/ITableRow';
import React from 'react'
import { FormattedMessage } from 'react-intl';
import { IProductivityPerDay } from '../interfaces/IProductivityPerDay';

type Props = {
  productivityPerDay: IProductivityPerDay;
}

const ProductivityPerDayRaw: React.FC<Props> = ({
  productivityPerDay
}) => {
  let textColor = 'text-gray-600';

  let ColorStatus;
  if (productivityPerDay.status === 1) ColorStatus = 'success';
  else if (productivityPerDay.status === 2) ColorStatus = 'warning';
  else if (productivityPerDay.status === 3) ColorStatus = 'danger';
  else if (productivityPerDay.status === 4) ColorStatus = 'info';

  const dayMessageId = (
    productivityPerDay.dayInWeek in DAY_TO_MESSAGE_ID
      ? DAY_TO_MESSAGE_ID[productivityPerDay.dayInWeek as keyof typeof DAY_TO_MESSAGE_ID]
      : '<UNKNOWN_DAY>'
  );

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <span className={'fw-bolder mb-1 fs-6 ' + textColor} >
              {productivityPerDay.store}
            </span>
          </div>
        </div>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {productivityPerDay.date}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          <FormattedMessage id={dayMessageId} />
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {productivityPerDay.productivityPerHourForChain}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {productivityPerDay.productivityPerHourForStore}
        </span>
      </td>
      <td >
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          <span className={`badge bg-${ColorStatus} fs-7 fw-bold`}>{productivityPerDay.productivityStoreGrade}%</span>
        </span>
      </td>
    </tr>
  )
}
export { ProductivityPerDayRaw }
