import { useIntl } from 'react-intl';
import { logEnum, sendlog } from '../../logs/sendLog'
import { weekList } from '../interfaces/StoreWeekLoadResponse';

interface Props {
  collection: weekList[],
  selectedWeek: number,
  onSortWeek: (statusType: number) => void,
}

const SortWeek = (props: Props) => {
  const onChange = (event: any) => {
    switch (event.target.value) {
      case 'displayAll':
        sendlog(logEnum.recommendedDisplayAll);
        break;
      case 'withIndepended':
        sendlog(logEnum.recommendedWithIndepended);
        break;
      case 'withoutIndepended':
        sendlog(logEnum.recommendedWithoutIndepended);
        break;
    }
    props.onSortWeek(parseInt(event.target.value));
  }
  const intl = useIntl();
  return (
    <div>
      <select
        className='form-select'
        data-control='select2'
        data-hide-search='true'
        data-kt-select2='true'
        data-placeholder='Select option'
        data-allow-clear='true'
        value={props.selectedWeek}
        onChange={onChange}
      >
        {
          props.collection.map((week, i) => {
            const message = intl.formatMessage({
              id: "POS_EVENT_RESEARCH_SCREEN.WEEK_NUMBER",
            }, {
              weekNumber: week.weekNum,
              startDate: week.startDate,
            });
            return <option key={i} value={week.weekNum} >{message}</option>
          })
        }
      </select>
    </div>
  )
}
export default SortWeek