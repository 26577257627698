import axios from 'axios'

export const getStores = async () => {
  try {
    const result = await axios.get(`/store`);
    return result.data;
  } catch (err) {
    return []
  }
}
