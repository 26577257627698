import { CardRecommendedStandard } from './CardRecommendedStandard'
import { RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE } from '../../auth/redux/AuthCRUD'
import { IRecommendedStandard } from '../interfaces/IRecommendedStandard'

interface Props {
  store: IRecommendedStandard,
  historyMode: boolean,
}

const listColor = [
  { status: 1, color: '#50cd89', icon: 'fa-smile-beam' },
  { status: 2, color: '#ffc700', icon: 'fas fa-exclamation-circle' },
  { status: 3, color: '#f1416c', icon: 'fa-times-circle' },
  { status: 4, color: '#6f42c1', icon: 'fa-times-circle' },
]

const standardStatusToColor = {
  1: '#f1416c',
  2: '#ffc700',
  3: '#50cd89',
} as const;

const Store = (props: Props) => {
  const store: IRecommendedStandard = props.store
  let colorStyle;
  if (!props.historyMode) {
    colorStyle = (
      store.progressingStandardStatus in standardStatusToColor
        ? standardStatusToColor[store.progressingStandardStatus as keyof typeof standardStatusToColor]
        : standardStatusToColor[1]
    );
  } else {
    switch (RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE) {
      case 'standardStatus':
        colorStyle = listColor.find((item) => item.status === props.store.standardStatus)?.color
        break;
      case 'productivityStatus':
        colorStyle = listColor.find((item) => item.status === props.store.productivutyStatus)?.color
        break;
      case 'attendanceAgainstWorkStatus':
        colorStyle = listColor.find((item) => item.status === props.store.attendanceAgainstWorkStatus)?.color
        break;
      case 'overloadStandardStatus':
        colorStyle = listColor.find((item) => item.status === props.store.overloadStandardStatus)?.color
        break;
      case 'underloadStandardStatus':
        colorStyle = listColor.find((item) => item.status === props.store.underloadStandardStatus)?.color
        break;
      case 'overloadPostingStatus':
        colorStyle = listColor.find((item) => item.status === props.store.overloadPostingStatus)?.color
        break;
      case 'underloadPostingStatus':
        colorStyle = listColor.find((item) => item.status === props.store.underloadPostingStatus)?.color
        break;
    }
  }

  return (
    <div style={{ width: "fit-content" }}>
      <CardRecommendedStandard
        color={colorStyle}
        store={store}
        historyMode={props.historyMode}
      />
    </div>
  )
}
export default Store
