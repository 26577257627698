/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { ClacRecommendedConfig, DAY_TO_MESSAGE_ID, ITableRow } from '../interfaces/ITableRow'
import { ManualStandard } from './LoadPerStandard';

type Props = {
  tableRow: ITableRow;
  onSelectRow: Function;
  onMorningChange: Function;
  onEveningChange: Function,
  index: number;
  selectedRow: number;
  clacRecommendedConfig: ClacRecommendedConfig,
  historyMode: boolean,
  manualStandards?: ManualStandard,
}

const StandartRow: React.FC<Props> = ({
  tableRow,
  selectedRow,
  onMorningChange,
  onEveningChange,
  onSelectRow,
  index,
  clacRecommendedConfig,
  historyMode,
  manualStandards,
}) => {

  const [bgColor, setBgColor] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(true)

  useEffect(() => {
    if (index === selectedRow) {
      setBgColor('bg-light')
      setDisabled(false);
    }
    else {
      setBgColor('');
      setDisabled(true);
    }
  }, [index, selectedRow])
  const calcFinalRecommendedStandard = useCallback(() => {
    tableRow.finalRecommendedMorningStandart = tableRow.morningSimulate || 0;
    if (tableRow.finalRecommendedMorningStandart) {
      if (clacRecommendedConfig.riskRangePrecent) tableRow.finalRecommendedMorningStandart +=
        Math.round((tableRow.finalRecommendedMorningStandart / 100) * clacRecommendedConfig.riskRangePrecent);
      if (clacRecommendedConfig.cashiersForCarts) tableRow.finalRecommendedMorningStandart += clacRecommendedConfig.cashiersForCarts;
      if (clacRecommendedConfig.cashiersForIndependentArea) tableRow.finalRecommendedMorningStandart += clacRecommendedConfig.cashiersForIndependentArea;
    }

    tableRow.finalRecommendedEveningStandart = tableRow.eveningSimulate || 0;
    if (tableRow.finalRecommendedEveningStandart) {
      if (clacRecommendedConfig.riskRangePrecent) tableRow.finalRecommendedEveningStandart +=
        Math.round((tableRow.finalRecommendedEveningStandart / 100) * clacRecommendedConfig.riskRangePrecent);
      if (clacRecommendedConfig.cashiersForCarts) tableRow.finalRecommendedEveningStandart += clacRecommendedConfig.cashiersForCarts;
      if (clacRecommendedConfig.cashiersForIndependentArea) tableRow.finalRecommendedEveningStandart += clacRecommendedConfig.cashiersForIndependentArea;
    }
  }, [
    clacRecommendedConfig.cashiersForCarts,
    clacRecommendedConfig.cashiersForIndependentArea,
    clacRecommendedConfig.riskRangePrecent,
    tableRow
  ]);

  useEffect(() => {
    calcFinalRecommendedStandard();
    if (index === selectedRow) setBgColor('bg-light')
    else setBgColor('');
  }, [calcFinalRecommendedStandard, index, selectedRow])

  const onClick = () => {
    if (index === selectedRow) return;
    onSelectRow(index, tableRow.morningSimulate, tableRow.eveningSimulate, tableRow.hasIndependedPOS);
  }
  const onMorningChangeVal = (event: any) => {
    let paraerVal = parseInt(event.target.value)
    if (isNaN(paraerVal)) paraerVal = 0
    const previousValue = tableRow.morningSimulate;
    tableRow.morningSimulate = paraerVal;
    // calcFinalRecommendedStandard();
    onMorningChange(paraerVal, previousValue);
  }
  const onEveningChangeVal = (event: any) => {
    let paraerVal = parseInt(event.target.value)
    if (isNaN(paraerVal)) paraerVal = 0
    const previousValue = tableRow.eveningSimulate;
    tableRow.eveningSimulate = paraerVal;
    // calcFinalRecommendedStandard();
    onEveningChange(paraerVal, previousValue);
  }
  let textColor = 'text-gray-600';
  let tdDesign = ' border border-secondary td-padding'

  const dayMessageId = (
    tableRow.day in DAY_TO_MESSAGE_ID
      ? DAY_TO_MESSAGE_ID[tableRow.day]
      : "<UNKNOWN_DAY>"
  );

  return (
    <tr className={bgColor} style={{ cursor: 'pointer' }} onClick={onClick}>
      <td className='border border-secondary'>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <span className={'fw-bolder mb-1 fs-6 ' + textColor} >
              {tableRow.store}
            </span>
          </div>
        </div>
      </td>
      <td className={tdDesign} >
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          <FormattedMessage id={tableRow.hasIndependedPOS ? "RECOMMENDATIONS_SCREEN.YES" : "RECOMMENDATIONS_SCREEN.NO"} />
        </span>
      </td>
      <td className={tdDesign} >
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          <FormattedMessage id={dayMessageId} />
        </span>
      </td>
      <td className={tdDesign}>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {tableRow.currentMorningStandart}
        </span>
      </td>
      <td className={tdDesign}>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {tableRow.currentEveningStandart}
        </span>
      </td>
      <td className={tdDesign}>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {tableRow.morningAssignment}
        </span>
      </td>
      <td className={tdDesign}>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {tableRow.eveningAssignment}
        </span>
      </td>
      <td className={tdDesign}>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {tableRow.morningTotalOpenPOS}
        </span>
      </td>
      <td className={tdDesign}>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {tableRow.eveningTotalOpenPOS}
        </span>
      </td>
      <td className={tdDesign}>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {tableRow.recommendedMorningStandart}
        </span>
      </td>
      <td className={tdDesign}>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {tableRow.recommendedEveningStandart}

        </span>
      </td>
      <td className={'col-1' + tdDesign}>
        {!historyMode && <input
          style={{ WebkitAppearance: 'none' }}
          value={manualStandards?.morning.value || ""}
          className={clsx('col-2  form-control form-control-solid text-primary',)}
          type='number'
          min={0}
          onChange={onMorningChangeVal}
          disabled={disabled}
        />}
        {historyMode && <span className={'fw-bolder d-block mb-1 fs-3 text-gray-700'}>
          {manualStandards?.morning.value}
        </span>}
      </td>
      <td className={'col-1' + tdDesign}>
        {!historyMode && <input
          style={{ WebkitAppearance: 'none' }}
          value={manualStandards?.evening.value || ""}
          className={clsx('col-2  form-control form-control-solid text-primary',)}
          type='number'
          min={0}
          onChange={onEveningChangeVal}
          disabled={disabled}
        />}
        {historyMode && <span className={'fw-bolder d-block mb-1 fs-3 text-gray-700'}>
          {manualStandards?.evening.value}
        </span>}
      </td>
      <td className={tdDesign}>
        <span className={'fw-bolder d-block mb-1 fs-3 text-gray-700'}>
          {tableRow.finalRecommendedMorningStandart}
        </span>
      </td>
      <td className={tdDesign}>
        <span className={'fw-bolder d-block mb-1 fs-3 text-gray-700'}>
          {tableRow.finalRecommendedEveningStandart}
        </span>
      </td>
    </tr>
  )
}
export { StandartRow }
