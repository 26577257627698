export const SCREEN_NAMES_TO_MESSAGE_ID = {
    "התראות מערכת קופות": "SCREENS.CASHIER_ALERTS_SCREEN",
    "זמן פנוי קופות": "SCREENS.POS_AVAILABILITY",
    "רמות שימוש": "SCREENS.USAGE_LEVELS",
    "המלצות תקן ומדדי יעילות": "SCREENS.RECOMMENDATIONS_AND_EFFICIENCY_MEASURES",
    "תחקור אירועי קופה": "SCREENS.POS_EVENT_RESEARCH",
} as const;

export type ScreenName = keyof typeof SCREEN_NAMES_TO_MESSAGE_ID;

export const SETTING_CONTENT_TO_MESSAGE_ID = {
    "שירות עצמי": "SETTINGS_SCREEN.SETTINGS_CONTENT.SELF_CHEKOUT",
    "נוכחות / הצבה": "SETTINGS_SCREEN.SETTINGS_CONTENT.ATTENDANCE_OVER_ASSIGNMENT",
    "תקן / נוכחות": "SETTINGS_SCREEN.SETTINGS_CONTENT.STANDARD_OVER_ATTENDANCE",
    "שיבוץ / נוכחות": "SETTINGS_SCREEN.SETTINGS_CONTENT.ASSIGNMENT_OVER_ATTENDANCE",
    "חוסר / עודף קופות פתוחות": "SETTINGS_SCREEN.SETTINGS_CONTENT.ACTIVE_POS_LACK_OVER_EXCESS",
    "פדיון": "SETTINGS_SCREEN.SETTINGS_CONTENT.REVENUE",
    "מסנן צבעים": "SETTINGS_SCREEN.SETTINGS_CONTENT.COLOR_FILTER",
    "מסנן צבעים לפריון": "SETTINGS_SCREEN.SETTINGS_CONTENT.PRODUCTIVITY_COLOR_FILTER",
    "מסנן צבעים לנוכחות": "SETTINGS_SCREEN.SETTINGS_CONTENT.ATTENDACE_COLOR_FILTER",
    "מסנן צבעים לעודפי תקן": "SETTINGS_SCREEN.SETTINGS_CONTENT.STANDARD_EXCESS_COLOR_FILTER",
    "מסנן צבעים לחוסרי תקן": "SETTINGS_SCREEN.SETTINGS_CONTENT.STANDARD_LACK_COLOR_FILTER",
    "מסנן צבעים לעודפי הצבה": "SETTINGS_SCREEN.SETTINGS_CONTENT.ASSIGNMENT_EXCESS_COLOR_FILTER",
    "מסנן צבעים לחוסרי הצבה": "SETTINGS_SCREEN.SETTINGS_CONTENT.ASSIGNMENT_LACK_COLOR_FILTER",
    "אחוז זמן פנוי": "SETTINGS_SCREEN.SETTINGS_CONTENT.FREE_TIME_PERCENTAGE",
} as const;

export type SettingContent = keyof typeof SETTING_CONTENT_TO_MESSAGE_ID;

export interface ISettingRow {
    icon: string;
    screenName: ScreenName;
    settingContent: SettingContent;
    updateTime: string;
    comment: string;
    pageLink: string;
}