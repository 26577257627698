import { LogRaw } from '../../../../_metronic/partials/widgets/tables/LogRaw'
import { IUsageLog } from '../interfaces/IUsageLog'

interface Props {
  usageLog: IUsageLog
}

const UsageLogRow = (props: Props) => {
  const usageLog: IUsageLog = props.usageLog
  return (
    <LogRaw
      userName={usageLog.userName}
      date={usageLog.date}
      actionType={usageLog.actionType}
    />
  )
}
export default UsageLogRow
