import { weekList } from 'app/modules/POSEventResearchDetails/interfaces/StoreWeekLoadResponse';
import { FormLabel, FormSelect } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { logEnum, sendlog } from '../../logs/sendLog'
// import { weekList } from '../interfaces/StoreWeekLoadResponse';

interface Props {
  collection: weekList[],
  onSortWeek: (index: number) => void,
}

const SortWeek = (props: Props) => {
  const onChange = (event: any) => {
    switch (event.target.value) {
      case 'displayAll':
        sendlog(logEnum.recommendedDisplayAll);
        break;
      case 'withIndepended':
        sendlog(logEnum.recommendedWithIndepended);
        break;
      case 'withoutIndepended':
        sendlog(logEnum.recommendedWithoutIndepended);
        break;
    }
    props.onSortWeek(event.target.value);
  }
  const intl = useIntl();
  return (
    <FormLabel className="m-0">
      <FormattedMessage id="RECOMMENDATIONS_SCREEN.RECOMMENDATION_BASE" />
      <FormSelect
        onChange={onChange}
      >
        {
          props.collection.map((week, i) => {
            const message = intl.formatMessage({
              id: "POS_EVENT_RESEARCH_SCREEN.WEEK_NUMBER",
            }, {
              weekNumber: week.weekNum,
              startDate: week.startDate,
            });
            return <option key={i} value={i} >{message}</option>
          })
        }
      </FormSelect>
    </FormLabel>
  )
}
export default SortWeek