import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

type Props = {
  id: number;
  userName: string;
  email: string;
  storeName: string;
  phone: string;
  userLevel: string;
  deleteItem: (id: number) => void
  editItem: (id: number) => void
}

const UserRow: React.FC<Props> = ({
  id,
  userName,
  email,
  storeName,
  phone,
  userLevel,
  deleteItem,
  editItem
}) => {
  let textColor = 'text-gray-600';
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <span className={'fw-bolder mb-1 fs-6 ' + textColor} >
              {userName}
            </span>
          </div>
        </div>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {email}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {storeName}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {phone}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {userLevel}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-1 ' + textColor}>
          <Button variant='light' onClick={() => editItem(id)}>
            <FormattedMessage id="ALERTS_SCREEN.EDIT" />
          </Button>

          <DeleteWithConfirmationButton onDeleteAlert={() =>
            deleteItem(id)}>
            <FormattedMessage id="ALERTS_SCREEN.DELETE" />
          </DeleteWithConfirmationButton>

        </span>
      </td>
    </tr>
  )
}
export { UserRow }



const DeleteWithConfirmationButton: React.FC<{ onDeleteAlert: () => void }> = ({ children, onDeleteAlert }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleDeleteClick = () => setShowConfirm(true);
  const handleNo = () => setShowConfirm(false);
  const handleYes = () => {
    setShowConfirm(false);
    onDeleteAlert();
  }
  return (
    <>
      <Button variant="danger" onClick={handleDeleteClick}>
        {children}
      </Button>
      <Modal centered show={showConfirm} onHide={handleNo} className="w-100">
        <Modal.Header>
          <h3 className="w-100 h3 text-center">
            <FormattedMessage id="ALERTS_SCREEN.ARE_YOU_SURE_YOU_WANT_TO_DELETE" />
          </h3>
        </Modal.Header>
        <Modal.Body className="d-flex gap-5 mx-20">
          <Button className="flex-grow-1 d-block" variant="primary" onClick={handleYes}>
            <FormattedMessage id="GENERAL.YES" />
          </Button>
          <Button className="flex-grow-1" variant="secondary" onClick={handleNo}>
            <FormattedMessage id="GENERAL.NO" />
          </Button>
        </Modal.Body>
      </Modal>
    </>
  )
}

