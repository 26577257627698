import { POS_EVENT_RESEARCH_SCREEN_STATUS_TYPE } from '../../auth/redux/AuthCRUD'
import { IFilterStore, IPOSEventResearch } from '../interfaces/IPOSEventResearchCollection';

function resultByStatusType(status: number, item: IPOSEventResearch): boolean {
  switch (POS_EVENT_RESEARCH_SCREEN_STATUS_TYPE) {
    case 'overLoadCashierStatus':
      return item.overLoadCashierStatus === status;
    case 'underLoadCashierStatus':
      return item.underLoadCashierStatus === status;
    default: return true
  }
}

export const showAllFilter = (item: IPOSEventResearch) => {
  return true
}
export const successFilter = (item: IPOSEventResearch, index: number) => {
  return resultByStatusType(1, item);
}
export const oneCashierDeskInactiveFilter = (item: IPOSEventResearch, index: number) => {
  return resultByStatusType(2, item);
}
export const fullCashierDeskInactive = (item: IPOSEventResearch, index: number) => {
  return resultByStatusType(3, item);
}
export const upThan100 = (item: IPOSEventResearch, index: number) => {
  return resultByStatusType(4, item);
}

export const filtersStores: IFilterStore[] = [
  { status: 0, des: 'show all', filter: showAllFilter },
  { status: 1, des: 'full success', filter: successFilter },
  { status: 2, des: 'one casherDeskInactive', filter: oneCashierDeskInactiveFilter },
  { status: 3, des: 'full cashierDeskCountInactive', filter: fullCashierDeskInactive },
  { status: 4, des: 'up than 100', filter: upThan100 },
]
