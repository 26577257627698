import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { IRecommendedStandard } from '../interfaces/IRecommendedStandard'
import { KTSVG } from '../../../../_metronic/helpers'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'

type Props = {
    className: string,
    stores: IRecommendedStandard[],
    onExitClick: () => any
}

const GraphModal: React.FC<Props> = ({ className, stores, onExitClick }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)

    const getIndependentStatus = (stores: any[], val: string) => {
        let red = 0;
        let yellow = 0;
        let green = 0;
        let purple = 0;
        stores.forEach(store => {
            switch (store[val]) {
                case 1:
                    green++;
                    break;
                case 2:
                    yellow++;
                    break;
                case 3:
                    red++;
                    break;
                case 4:
                    purple++;
                    break;
            }
        });

        return [red, yellow, green, purple];
    }

    const intl = useIntl();

    useEffect(() => {
        if (!chartRef.current) {
            return
        }
        let standardStatus = getIndependentStatus(stores, 'standardStatus');
        let productivutyStatus = getIndependentStatus(stores, 'productivutyStatus');
        let attendanceAgainstWorkStatus = getIndependentStatus(stores, 'attendanceAgainstWorkStatus');
        let overloadStandardStatus = getIndependentStatus(stores, 'overloadStandardStatus');
        let underloadStandardStatus = getIndependentStatus(stores, 'underloadStandardStatus');
        let overloadPostingStatus = getIndependentStatus(stores, 'overloadPostingStatus');
        let underloadPostingStatus = getIndependentStatus(stores, 'underloadPostingStatus');

        const height = parseInt(getCSS(chartRef.current, 'height'))
        var redArr = [standardStatus[0], productivutyStatus[0], attendanceAgainstWorkStatus[0], overloadStandardStatus[0], underloadStandardStatus[0], overloadPostingStatus[0], underloadPostingStatus[0]];
        var yellowArr = [standardStatus[1], productivutyStatus[1], attendanceAgainstWorkStatus[1], overloadStandardStatus[1], underloadStandardStatus[1], overloadPostingStatus[1], underloadPostingStatus[1]];
        var greenArr = [standardStatus[2], productivutyStatus[2], attendanceAgainstWorkStatus[2], overloadStandardStatus[2], underloadStandardStatus[2], overloadPostingStatus[2], underloadPostingStatus[2]];
        var purpleArr = [standardStatus[3], productivutyStatus[3], attendanceAgainstWorkStatus[3], overloadStandardStatus[3], underloadStandardStatus[3], overloadPostingStatus[3], underloadPostingStatus[3]];
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, redArr, yellowArr, greenArr, purpleArr, stores.length, intl))
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    })//, [chartRef]


    return (
        <div className='row justify-content-center'>
            <div className='col-12' >
                <div className={`card ${className}`}>
                    <div className='card-header border-0 pt-5 px-15'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'><FormattedMessage id={"RECOMMENDATIONS_SCREEN.STORES_BY_COLOR"} /></span>
                        </h3>
                        <div onClick={onExitClick} className='btn btn-sm btn-icon btn-active-color-primary'>
                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>
                    <div className='card-body'>
                        <div ref={chartRef} id='kt_charts_widget_6_chart' style={{ height: '250px' }}></div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export { GraphModal }

function getChartOptions(height: number, redArr: number[], yellowArr: number[], greenArr: number[], purpleArr: number[], max: number, intl: IntlShape): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')//color of njumber at the left side
    const borderColor = getCSSVariableValue('--bs-gray-200')//color of breakers lines

    const baseLightColor = getCSSVariableValue('--bs-light-primary')//color of mounts in the background
    const red = getCSSVariableValue('--bs-danger')//Bottom part of lines
    const yellow = getCSSVariableValue('--bs-warning')//top part of lines
    const green = getCSSVariableValue('--bs-success')//third part of lines
    const purple = getCSSVariableValue('--bs-info')//third part of lines

    return {
        series: [
            {
                name: 'Expenses',
                type: 'area',
                data: [10, 30, 10, 30, 10, 30, 10],
            },
            {
                name: intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.RED" }),
                type: 'bar',
                data: redArr,
            },
            {
                name: intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.YELLOW" }),
                type: 'bar',
                data: yellowArr,
            },

            {
                name: intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.GREAN" }),
                type: 'bar',
                data: greenArr,
            },
            {
                name: intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.PURPLE" }),
                type: 'bar',
                data: purpleArr,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            stacked: true,
            height: 250,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
                columnWidth: '15%',
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: [
                intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.STANDARD_OVER_RECOMMENDED_STANDARD" }),
                intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.HOURLY_PRODUCTIVITY_CHAIN_OVER_STORE" }),
                intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.ATTENDANCE_OVER_NET" }),
                intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.STANDARD_EXCESS" }),
                intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.STANDARD_LACK" }),
                intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.ASSIGNMENT_EXCESS" }),
                intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.ASSIGNMENT_LACK" }),
            ],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: "12px"
                },
                hideOverlappingLabels: false,
                trim: true
            },
        },
        yaxis: {
            max: max,
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            enabled: true,
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: (val) => intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.N_STORES" }, { n: val })
            },
            enabledOnSeries: [1, 2, 3, 4]
        },
        colors: [baseLightColor, red, yellow, green, purple],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                top: 0,
                right: 20,
                bottom: 0,
                left: 20,
            },
        },
    }
}
