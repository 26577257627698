import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { IStore } from '../interfaces/IStore'
import { KTSVG } from '../../../../_metronic/helpers'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'

type Props = {
    className: string,
    stores: IStore[],
    onExitClick: () => any
}

const GraphModal: React.FC<Props> = ({ className, stores, onExitClick }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)

    const getIndependentStatus = (stores: any[], val: string) => {
        let red = 0;
        let yellow = 0;
        let green = 0;
        let purple = 0;
        stores.forEach(store => {
            switch (store[val]) {
                case 1:
                    green++;
                    break;
                case 2:
                    yellow++;
                    break;
                case 3:
                    red++;
                    break;
                case 4:
                    purple++;
                    break;
            }
        });

        return [red, yellow, green, purple];
    }
    const intl = useIntl();

    useEffect(() => {
        if (!chartRef.current) {
            return
        }
        let IndependentStatus = getIndependentStatus(stores, 'independentDeskStatus');
        let activeDeskStatus = getIndependentStatus(stores, 'activeDeskStatus');
        let attendanceStatus = getIndependentStatus(stores, 'attendanceStatus');
        let shiftStatus = getIndependentStatus(stores, 'shiftStatus');
        let cashiersDeviationStatus = getIndependentStatus(stores, 'cashiersDeviationStatus');
        let revenueStatus = getIndependentStatus(stores, 'revenueStatus');
        let loadStatus = getIndependentStatus(stores, 'loadStatus');


        const height = parseInt(getCSS(chartRef.current, 'height'))
        var redArr = [IndependentStatus[0], activeDeskStatus[0], attendanceStatus[0], shiftStatus[0], cashiersDeviationStatus[0], revenueStatus[0], loadStatus[0]];
        var yellowArr = [IndependentStatus[1], activeDeskStatus[1], attendanceStatus[1], shiftStatus[1], cashiersDeviationStatus[1], revenueStatus[1], loadStatus[1]];
        var greenArr = [IndependentStatus[2], activeDeskStatus[2], attendanceStatus[2], shiftStatus[2], cashiersDeviationStatus[2], revenueStatus[2], loadStatus[2]];
        var purpleArr = [IndependentStatus[3], activeDeskStatus[3], attendanceStatus[3], shiftStatus[3], cashiersDeviationStatus[3], revenueStatus[3], loadStatus[3]];
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, redArr, yellowArr, greenArr, purpleArr, stores.length, intl))
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    })//, [chartRef]


    return (
        <div className={`card ${className}`}>
            <div className='card-header border-0 pt-5 px-15'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'><FormattedMessage id={"CASHIER_ALERTS_SCREEN.STORE_BY_COLOR"} /></span>
                </h3>
                <div onClick={onExitClick} className='btn btn-sm btn-icon btn-active-color-primary'>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </div>
            </div>
            <div className='card-body'>
                <div ref={chartRef} id='kt_charts_widget_6_chart' style={{ height: '350px' }}></div>
            </div>
        </div>
    )
}

export { GraphModal }

function getChartOptions(height: number, redArr: number[], yellowArr: number[], greenArr: number[], purpleArr: number[], max: number, intl: IntlShape): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')//color of njumber at the left side
    const borderColor = getCSSVariableValue('--bs-gray-200')//color of breakers lines

    const baseLightColor = getCSSVariableValue('--bs-light-primary')//color of mounts in the background
    const red = getCSSVariableValue('--bs-danger')//Bottom part of lines
    const yellow = getCSSVariableValue('--bs-warning')//top part of lines
    const green = getCSSVariableValue('--bs-success')//third part of lines
    const purple = getCSSVariableValue('--bs-info')//third part of lines

    return {
        series: [
            {
                name: 'Expenses',
                type: 'area',
                data: [10, 30, 20, 30, 15, 30, 10],
            },
            {
                name: intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.RED" }),
                type: 'bar',
                data: redArr,
            },
            {
                name: intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.YELLOW" }),
                type: 'bar',
                data: yellowArr,
            },

            {
                name: intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.GREAN" }),
                type: 'bar',
                data: greenArr,
            },
            {
                name: intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.PURPLE" }),
                type: 'bar',
                data: purpleArr,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            stacked: true,
            height: 350,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
                columnWidth: '12%',
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: [
                intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.SCO_AVAILABILITY" }),
                intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.ATTENDANCE_OVER_ASSIGNMENT" }),
                intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.STANDARD_OVER_ATTENDANCE" }),
                intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.ASSIGNMENT_OVER_ATTENDANCE" }),
                intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.LACK_EXCESS_ACTIVE_CASHIERS" }),
                intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.REVENUE_LAST_WEEK_TODAY" }),
                intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.FREE_TIME_PERCENTAGE" }),
            ],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
                hideOverlappingLabels: false,
                trim: true,
            },
        },
        yaxis: {
            max: max,
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            enabled: true,
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: (val) => intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.N_STORES" }, { n: val })
            },
            enabledOnSeries: [1, 2, 3, 4]
        },
        colors: [baseLightColor, red, yellow, green, purple],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    }
}
