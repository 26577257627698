import { FormattedMessage } from 'react-intl';
import { CURRENT_USER } from '../../auth/redux/AuthCRUD'
import { IProductivityPerDay } from '../interfaces/IProductivityPerDay'
import ProductivityPerDay from './ProductivityPerDayObj'

export type ColumnName = 'branch' | 'date' | 'week_day' | 'hourly_chain_productivity' | 'hourly_branch_productivity' | 'branch_chain_ratio';

interface props {
  onTitleClick: (column: ColumnName) => void
  cashiers: IProductivityPerDay[]
}
let design = 'min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light';
export default function ProductivityPerDayList(props: props) {
  const cashiers = props.cashiers || []
  function onClick(title: ColumnName) {
    props.onTitleClick(title);
  }
  const listRender = (
    cashiers
      .filter(item => !(item.deskNumber > 50 && CURRENT_USER !== "admin"))
      .map((item, index) => <ProductivityPerDay key={index} cashier={item}></ProductivityPerDay>)
  )

  return (<div className={`card`}>
    <div className='card-body py-3'>
      <div className='table-responsive tableFixHead'>
        <table className='table align-middle table-row-bordered gs-4 gy-4'>
          <thead>
            <tr className='fw-bolder text-muted bg-light'>
              <th style={{ cursor: 'pointer' }} onClick={() => onClick('branch')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.BRANCH" /></th>
              <th style={{ cursor: 'pointer' }} onClick={() => onClick('date')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.DATE" /></th>
              <th style={{ cursor: 'pointer' }} onClick={() => onClick('week_day')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.WEEK_DAY" /></th>
              <th style={{ cursor: 'pointer' }} onClick={() => onClick('hourly_chain_productivity')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.CHAIN_HOURLY_PRODUCTIVITY" /></th>
              <th style={{ cursor: 'pointer' }} onClick={() => onClick('hourly_branch_productivity')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.STORE_HOURLY_PRODUCTIVITY" /></th>
              <th style={{ cursor: 'pointer' }} onClick={() => onClick('branch_chain_ratio')} className={design}><FormattedMessage id="RECOMMENDATIONS_SCREEN.STORE_TO_CHAIN_RATIO" /></th>
            </tr>
          </thead>
          <tbody>
            {listRender}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}
