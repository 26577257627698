import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";

const DATAPOINTS_SIZE_TO_MESSAGE = {
    "days": "DAYS",
    "hours": "HOURS",

} as const;

export type DaysHoursDatapoints = keyof typeof DATAPOINTS_SIZE_TO_MESSAGE;

type DaysHoursSelectorProps = {
    value: DaysHoursDatapoints,
    onClick: () => any,
};

export const DayHourSelector: React.VFC<DaysHoursSelectorProps> = ({
    value,
    onClick
}) => {

    const intl = useIntl();

    return (
        <Button variant="light" onClick={onClick} style={{backgroundColor : "white", color:"black" , borderInlineColor:"grey", borderInlineWidth:"2px"}}>
            {intl.formatMessage({ id: `PER_HOUR_PRODUCTIVITY.DATAPOINT.${DATAPOINTS_SIZE_TO_MESSAGE[value]}` })}
        </Button>

    );
}
