/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import CashiersList from './CashiersList'
import { getCashiers } from '../services/cashiersData'
import { ICashier } from '../interfaces/ICashier'
import FilterCashiers from './FilterCashiers'
import SearchCashier from './SearchCashier'
import socketIOClient from 'socket.io-client'
import { filtersCashiers } from '../helpers/filtersCashiers'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import Moment from 'react-moment';
import SearchBranch from './SearchBranch'
import { IS_SORT_SIDE, setIsSortSide } from '../../auth/redux/AuthCRUD'
import StatusBy from './StatusBy'
import { auto } from '@popperjs/core'
import { logEnum, sendlog } from '../../logs/sendLog'
import { FormattedMessage } from 'react-intl'
import { API_URL } from 'app/config/config'
import { useLocation } from 'react-router-dom'

const sortByName = (a: any, b: any) => {
  if (a["name"] < b["name"]) {
    return -1
  }
  if (a["name"] > b["name"]) {
    return 1
  }
  return 0;
}
const sortByStatus = (a: any, b: any) => {
  if (a["status"] > b["status"]) {
    return -1
  }
  if (a["status"] < b["status"]) {
    return 1
  }
  return 0;
};

const sortConfig = [
  { field: 'name', func: sortByName },
  { field: 'status', func: sortByStatus },
]

export type CashierColumn = "store"
  | "pos_number"
  | "pos_type"
  | "cashier_number"
  | "transaction_count"
  | "revenue"
  | "first_ticket_time"
  | "last_ticket_time"
  | "time_since_last_ticket"
  | "gross_work"
  | "net_work"
  | "average_free_time";

export default function Cashiers() {
  const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)

  const [listCashiers, setListCashiers] = useState<ICashier[]>([])
  const [statusFilter, setStatusFilter] = useState<number>(0)
  const [searchCashier, setSearchCashier] = useState<string | null>()
  const [searchBranch, setSearchBranch] = useState(useLocation<string>().state)

  let cashiersAll = '';
  let cashiersByShift = 'shift';
  let cashiersByLastHour = 'hour';
  let cashiersByChangeRangeTime = 'range-time';

  const [lastDateUpdate, setLastDateUpdate] = useState<Date | null>()

  const getCashierToRender = () => {
    if (!listCashiers) return []

    const filterRun = filtersCashiers.find((filter) => filter.status === statusFilter)
    if (!filterRun) {
      return listCashiers
    }

    return listCashiers
      .filter(filterRun.filter)
      .filter((item) => {
        if (!searchCashier && !searchBranch) return true;
        let result = null;
        if (searchBranch) result = item.store.includes(searchBranch);
        if ((result === null || result === true) && searchCashier) result = item.cashierNumber.toString().includes(searchCashier);
        return result;
      })
      .sort(sortCashier)
  };
  const sortCashier = (a: any, b: any) => {
    const funcSort = sortConfig.find(s => s.field === 'name')?.func;
    if (funcSort) {
      return funcSort(a, b);
    }
    return 1;
  }

  const getListCashiers = async (url: string) => {
    try {
      const list: any = await getCashiers(url)
      if (list) {
        setListCashiers(list.data)
        setLastDateUpdate(new Date())
      }
    } catch (err) { }
  }
  useEffect(() => {
    const socket = socketIOClient("/", {
      transports: ['websocket'],
      auth: {
        token: accessToken,
      },
      path: `${API_URL}/socket.io`
    });
    socket.emit('startSocketCashiers')
    socket.on('allCashiers', (list) => {
      if (list) {
        setLastDateUpdate(new Date())
        setListCashiers(list)
      }
    })
    sendlog(logEnum.cashierScreen);
    getListCashiers(cashiersByLastHour)
    return () => {
      socket.disconnect();
    }
  }, [cashiersByLastHour, accessToken])
  const onFilter = (status: number) => {
    setStatusFilter(status);
  }

  const onTitleClick = (titleSelected: CashierColumn) => {
    let list = [...listCashiers];

    if (IS_SORT_SIDE) {
      if (titleSelected === 'store') list.sort((a, b) => a.store.localeCompare(b.store));
      else if (titleSelected === 'pos_number') list.sort((a, b) => a.deskNumber - b.deskNumber);
      else if (titleSelected === 'pos_type') list.sort((a, b) => a.deskType.localeCompare(b.deskType));
      else if (titleSelected === 'cashier_number') list.sort((a, b) => a.cashierNumber - b.cashierNumber);
      else if (titleSelected === 'transaction_count') list.sort((a, b) => a.numberOfTickets - b.numberOfTickets);
      else if (titleSelected === 'revenue') list.sort((a, b) => a.revenue - b.revenue);
      else if (titleSelected === 'first_ticket_time') list.sort((a, b) => a.firstTillTime.localeCompare(b.firstTillTime));
      else if (titleSelected === 'last_ticket_time') list.sort((a, b) => a.lastTillTime.localeCompare(b.lastTillTime));
      else if (titleSelected === 'time_since_last_ticket') list.sort((a, b) => a.timeFromLastTill.localeCompare(b.timeFromLastTill));
      else if (titleSelected === 'gross_work') list.sort((a, b) => a.grossWrkSum.localeCompare(b.grossWrkSum));
      else if (titleSelected === 'net_work') list.sort((a, b) => a.netWrkSum.localeCompare(b.netWrkSum));
      else if (titleSelected === 'average_free_time') list.sort((a, b) => a.avgLoad - b.avgLoad);
    } else {
      if (titleSelected === 'store') list.sort((a, b) => b.store.localeCompare(a.store));
      else if (titleSelected === 'pos_number') list.sort((a, b) => b.deskNumber - a.deskNumber);
      else if (titleSelected === 'pos_type') list.sort((a, b) => b.deskType.localeCompare(a.deskType));
      else if (titleSelected === 'cashier_number') list.sort((a, b) => b.cashierNumber - a.cashierNumber);
      else if (titleSelected === 'transaction_count') list.sort((a, b) => b.numberOfTickets - a.numberOfTickets);
      else if (titleSelected === 'revenue') list.sort((a, b) => b.revenue - a.revenue);
      else if (titleSelected === 'first_ticket_time') list.sort((a, b) => b.firstTillTime.localeCompare(a.firstTillTime));
      else if (titleSelected === 'last_ticket_time') list.sort((a, b) => b.lastTillTime.localeCompare(a.lastTillTime));
      else if (titleSelected === 'time_since_last_ticket') list.sort((a, b) => b.timeFromLastTill.localeCompare(a.timeFromLastTill));
      else if (titleSelected === 'gross_work') list.sort((a, b) => b.grossWrkSum.localeCompare(a.grossWrkSum));
      else if (titleSelected === 'net_work') list.sort((a, b) => b.netWrkSum.localeCompare(a.netWrkSum));
      else if (titleSelected === 'average_free_time') list.sort((a, b) => b.avgLoad - a.avgLoad);
    }
    setIsSortSide();
    setListCashiers(list);
  }

  const onSearchCashier = (search: string) => {
    setSearchCashier(search)
  }

  const onSearchBranch = (search: string) => {
    setSearchBranch(search)
  }

  const onStatusBy = (statusType: string) => {
    if (statusType === 'byDay') getListCashiers(cashiersAll)
    else if (statusType === 'byLastHour') getListCashiers(cashiersByLastHour)
    else if (statusType === 'byShift') getListCashiers(cashiersByShift)
    else if (statusType === 'ByRangeTime') getListCashiers(cashiersByChangeRangeTime)
  }

  return (
    <div>
      <nav className='navbar navbar-light bg-light row justify-content-between'>
        <div className='col-auto'>
          <span>
            {lastDateUpdate &&
              <div>
                <span>
                  <FormattedMessage
                    id={"CASHIERS_ALERTS_SCREEN.LAST_UPDATE"}
                    values={{ lastUpdate: <Moment format='DD-MM-YYYY HH:mm'>{lastDateUpdate ? lastDateUpdate : ''}</Moment> }} />
                </span>
              </div>}
            {!lastDateUpdate && <img src={toAbsoluteUrl('/media/gifs/hourglass.gif')} width={20} alt="loading" />}
          </span>
        </div>
        <h1 className='breadcrumb-item text-muted col-auto'><FormattedMessage id="SCREENS.POS_AVAILABILITY" /></h1>
        <span className='col-auto'>
          <a className='navbar-brand' href='https://yochananof.co.il/'>
            &nbsp;&nbsp;
            <img
              src={toAbsoluteUrl('/media/logos/retail_logo.png')}
              width='100'
              height='30'
              className='d-inline-block '
              alt=''
              loading='lazy'
            />
          </a>
        </span>
      </nav>
      <br />
      <br />
      <div className='row '>
        <div className=' col-12 col-xxl-5'>
          <div className='row justify-content-between'>
            <div className='col-xs-12 col-sm-4'>
              <SearchBranch onSearch={onSearchBranch} branch={searchBranch}></SearchBranch>
            </div>
            <div className='col-xs-12 col-sm-4'>
              <SearchCashier onSearch={onSearchCashier}></SearchCashier>
            </div>
            <div className='col-xs-12 col-sm-4 '>
              <StatusBy onStatusBy={onStatusBy} />
            </div>
          </div>
        </div>
        <div style={{ marginLeft: auto }} className='col-xxl-auto col-xl-12 pe-6 ps-5'>
          <div className='col-md-12 col-xxl-12 filter-buttons '>
            <FilterCashiers onFilter={onFilter} listCashiers={listCashiers} />
          </div>
        </div>
      </div>
      <hr />
      <CashiersList onTitleClick={onTitleClick} cashiers={getCashierToRender()} />
    </div>
  )
}
