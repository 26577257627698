import axios from 'axios'
import { ClacRecommendedConfig, RecommendedStandardRowsResponse } from '../interfaces/ITableRow';
import { weekList } from 'app/modules/POSEventResearchDetails/interfaces/StoreWeekLoadResponse';

export const getStandartRows = async (storeId?: number, date?: Date) => {
  return (await axios.get<RecommendedStandardRowsResponse>("/recommended-standard/rows", { params: { storeId: storeId, selectedDate: date } })).data;
}

export const getGraphConfig = async () => {
  try {
    return await (await axios.get("/recommended-standard/graph-config")).data;
  } catch (err) {
    return []
  }
}

export const getCalcRecommendedConfig = async () => {
  try {
    return (await axios.get<ClacRecommendedConfig[]>("/recommended-standard/recommended-config")).data;
  } catch (err) {
    return []
  }
}

export const updateStandardStatus = async (headerId: number, newStatus: number) => {
  let data = {
    "headerId": headerId,
    "newStatus": newStatus
  }
  axios.post("/standard/update-header-status", data);
}

export const updateManualRecommendedStandard = async (standardId: number, manualRecommandedStandard: number) => {
  let data = {
    "id": standardId,
    "manualRecommandedStandard": manualRecommandedStandard
  }
  axios.post("/standard/update-manual-recommended-standard", data);
}

export const getSundaysList = async () => {
  return (await axios.get<weekList[]>("/recommended-standar/sundays-list")).data;
}


///graph-config
