import { IUsage } from '../interfaces/IStore';
import UsageLevel from './UsageLevel'

type Props = {
  stores: IUsage[]
}

export default function UsageLevelList(props: Props) {
  const stores = props.stores || []

  const listRender = stores.map((item, index) => {
    return <UsageLevel key={index} usageLevel={item}></UsageLevel>
  });
  return (
    <>
      <div style={{ justifyContent: "space-around" }} className='row g-6 g-xl-9' id="main-list">{listRender}</div>
    </>
  )
}
