import { StoreSelector } from "app/common/components/selectors/StoreSelector";
import axios from "axios";
import { useEffect, useMemo, useState } from "react"
import Chart from "react-google-charts";
import { FormattedMessage, useIntl } from "react-intl";
import Moment from "react-moment";
import { Link, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "_metronic/helpers";
import { DATAPOINT_KEY_TO_MESSAGE_ID } from "../interfaces/dataPointsKeys";
import { DAY_NUMBER_TO_MESSAGE_ID, StoreWeekLoad, StoreWeekLoadResponse, weekList } from "../interfaces/StoreWeekLoadResponse";
import SortWeek from "./SortWeek";

type DatapointKey = keyof typeof DATAPOINT_KEY_TO_MESSAGE_ID;

const DATAPOINT_TO_COLOR: { [Key in DatapointKey]: string } = {
    selfcheckoutDatapoints: "magenta",
    recommendedStandardShiftDatapoints: "blue",
    attendanceDatapoints: "orange",
    assignmentDatapoints: "lightBlue",
    currentStandardDatapoints: "green",
    activePosDatapoints: "lightGreen",
    grossRecommendationDatapoints: "pink",
    minRequriedPosDatapoints: "red",
} as const;

const defaultVisibleDatapoints = new Set<DatapointKey>(["activePosDatapoints", "minRequriedPosDatapoints", "currentStandardDatapoints"]);

const nonHiddableDatapoints = new Set<DatapointKey>(["currentStandardDatapoints"]);

export const POSEventResearchDetails: React.VFC = () => {
    const [updatedAt, setUpdatedAt] = useState<Date | null>(null);
    const [storeWeekLoads, setStoreWeekLoads] = useState<StoreWeekLoad[]>([]);
    const [storeName, setStoreName] = useState<string>();
    const { week, store, weekList } = useLocation<{ store: { id: number, name: string }, week: number, weekList: weekList[] }>().state;
    const [selectedStore, setSelectedStore] = useState(store);
    const [selectedWeek, setSelectedWeek] = useState(week ?? 0);
    const [selectedIndex, setSelectedIndex] = useState<number>();
    const [chartData, setChartData] = useState<any[][]>();
    const [columnsVisibility, setColumnsVisibility] = useState(Object.keys(DATAPOINT_KEY_TO_MESSAGE_ID).map(key => defaultVisibleDatapoints.has(key as DatapointKey)));
    const [chartLoaded, setChartLoaded] = useState(false);
    const intl = useIntl();

    const chartLineNames = useMemo(() => Object.values(DATAPOINT_KEY_TO_MESSAGE_ID).map(messageId => intl.formatMessage({ id: messageId })), [intl]);

    useEffect(() => {
        const request = axios.get<StoreWeekLoadResponse>('posevent-research/rows', { params: { store: selectedStore.id, week: selectedWeek } });
        request.then(response => {
            setStoreWeekLoads(response.data.weekLoad);
            setStoreName(response.data.storeName);
            setUpdatedAt(new Date());
        });
    }, [selectedWeek, selectedStore]);

    useEffect(() => {
        if (storeWeekLoads.length > 0) {
            let currentIndex = storeWeekLoads.findIndex(wl => wl.day === new Date().getDay());
            if (!(currentIndex in storeWeekLoads)) currentIndex = 0;
            setSelectedIndex(currentIndex);
            const data: any[][] = [];
            for (let i = 0; i < storeWeekLoads[currentIndex].dayLoadResponse.timeDatapoints.length; ++i) {
                data.push([
                    storeWeekLoads[currentIndex].dayLoadResponse.timeDatapoints[i],
                    ...(Object.keys(DATAPOINT_KEY_TO_MESSAGE_ID).map(key => storeWeekLoads[currentIndex].dayLoadResponse[key as DatapointKey][i])),
                ]);
            }
            setChartData(data);
        }
    }, [storeWeekLoads])

    const setSelectedChartData = (selectedIndex: number) => {
        const data: any[][] = [];
        for (let i = 0; i < storeWeekLoads[selectedIndex].dayLoadResponse.timeDatapoints.length; ++i) {
            data.push([
                storeWeekLoads[selectedIndex].dayLoadResponse.timeDatapoints[i],
                ...(Object.keys(DATAPOINT_KEY_TO_MESSAGE_ID).map(key => storeWeekLoads[selectedIndex].dayLoadResponse[key as DatapointKey][i])),
            ]);
        }
        setChartData(data);
    }

    const onSelectedStoreChanged = (selectedIndex: number) => {
        setSelectedIndex(selectedIndex);
        setSelectedChartData(selectedIndex)
    };

    const onColumnVisibilityChange = (index: number, value: boolean) => {
        columnsVisibility[index] = value;
        setColumnsVisibility([...columnsVisibility]);
    }


    const filteredChartDataWithColumnNames = chartData && [
        ["time", ...chartLineNames.filter((_, i) => columnsVisibility[i])],
        ...(chartData.map(dataPointValues => dataPointValues.filter((_, i) => i === 0 || columnsVisibility[i - 1])) || [])
    ];

    const onSortWeek = (weekNum: number) => {
        setSelectedWeek(weekNum);
    }


    const onSelectStore = (store: { id: number, name: string }) => {
        setSelectedStore(store);
    }

    const design = 'text-center fs-5 border border-secondary bg-light';

    const img = <img
        src={toAbsoluteUrl('/media/logos/retail_logo.png')}
        width='100'
        height='30'
        className='d-inline-block '
        alt=''
        loading='lazy'
    />
    return (
        <>
            <nav className='navbar navbar-light row justify-content-between'>
                <div className='col-auto'>
                    <span>
                        {updatedAt &&
                            <div>
                                <span>
                                    <FormattedMessage
                                        id={"CASHIERS_ALERTS_SCREEN.LAST_UPDATE"}
                                        values={{ lastUpdate: <Moment format='DD-MM-YYYY HH:mm'>{updatedAt ? updatedAt : ''}</Moment> }} />
                                </span>
                            </div>}
                        {!updatedAt && < img src={toAbsoluteUrl('/media/gifs/hourglass.gif')} width={20} alt="loading" />}
                    </span>
                </div>
                <h1 className='breadcrumb-item text-muted col-auto'><FormattedMessage id="SCREENS.POS_EVENT_RESEARCH" /></h1>
                <span className='col-auto'>
                    <Link className='navbar-brand' to='/'>
                        &nbsp;&nbsp;
                        {img}
                    </Link>
                </span>
            </nav>
            <br />
            <br />
            <div className='row'>
                <div className='col-xxl-5'>
                    <div className='row justify-content-between'>
                        <div className='col-12 col-sm-3'>
                        </div>
                        <div className='col-12 col-sm-5' >
                        </div>
                        <div className='col-12 col-sm-4' >
                        </div>
                    </div>
                </div>
                <div style={{ marginLeft: "auto", }} className='col-sm-12 col-xxl-auto pe-6 ps-5'>
                    <div className='col-12 filter-buttons'>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row mb-3">
                <div className="col-3">
                    <SortWeek collection={weekList} onSortWeek={onSortWeek} selectedWeek={selectedWeek} />
                </div>
                <div className="col-3">
                    <StoreSelector
                        allowClear={false}
                        onChange={onSelectStore}
                        value={selectedStore}
                        storesType="all-stores"
                    />
                </div>
            </div>
            <div className={`card`}>
                <div className='card-body py-3'>
                    <div className='table-responsive LoadtableFixHead'>
                        <table className='table-hover table align-middle table-row-bordered gs-4' style={{ textAlign: "center" }}>
                            <thead>
                                <tr className='fw-bolder text-muted'>
                                    <th style={{ backgroundClip: "padding-box" }} className={design}><FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.BRANCH" /></th>
                                    <th style={{ backgroundClip: "padding-box" }} className={design}><FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.DAY" /></th>
                                    <th colSpan={2} style={{ backgroundClip: "padding-box" }} className={design}><FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.MORNING" /></th>
                                    <th colSpan={2} style={{ backgroundClip: "padding-box" }} className={design}><FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.EVENING" /></th>
                                </tr>
                                <tr className='fw-bolder text-muted'>
                                    <th style={{ backgroundClip: "padding-box" }} className={design}></th>
                                    <th style={{ backgroundClip: "padding-box" }} className={design}></th>
                                    <th style={{ backgroundClip: "padding-box" }} className={design}><FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.OVERLOAD" /></th>
                                    <th style={{ backgroundClip: "padding-box" }} className={design}><FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.UNDERLOAD" /></th>
                                    <th style={{ backgroundClip: "padding-box" }} className={design}><FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.OVERLOAD" /></th>
                                    <th style={{ backgroundClip: "padding-box" }} className={design}><FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.UNDERLOAD" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {storeWeekLoads.map((swl, i) => (
                                    <tr
                                        key={i}
                                        className={"text-gray-600" + (i === selectedIndex ? " bg-light" : "")}
                                        style={{ cursor: "pointer" }}
                                        onClick={_ => onSelectedStoreChanged(i)}
                                    >
                                        <td className="border border-secondary">
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <span className={'fw-bolder mb-1 fs-6 '} >
                                                        {storeName}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="border border-secondary"><FormattedMessage id={DAY_NUMBER_TO_MESSAGE_ID[swl.day]} /></td>
                                        <td className="border border-secondary">
                                            <span className={'fw-bolder d-block mb-1 fs-6 '}>
                                                {swl.morningUnderload}
                                            </span>
                                        </td>
                                        <td className="border border-secondary">
                                            <span className={'fw-bolder d-block mb-1 fs-6 '}>

                                                {swl.morningOverload}
                                            </span>
                                        </td>
                                        <td className="border border-secondary">
                                            <span className={'fw-bolder d-block mb-1 fs-6 '}>

                                                {swl.eveningUnderload}
                                            </span>
                                        </td>
                                        <td className="border border-secondary">
                                            <span className={'fw-bolder d-block mb-1 fs-6 '}>

                                                {swl.eveningOverload}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <br />
            <div className="card text-center">
                <br />
                <h1>
                    <FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.HR_PRODUCTIVITY_BY_HOUR_ANALYSIS" />
                </h1>
                <h3>
                    <span><FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.BRANCH" />: {storeName}, </span>
                    <span>
                        <span><FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.DAY" />: </span>
                        {selectedIndex !== undefined && storeWeekLoads[selectedIndex] && <FormattedMessage id={DAY_NUMBER_TO_MESSAGE_ID[storeWeekLoads[selectedIndex].day]} />}
                        <span>{', '}</span>
                    </span>
                    <span>
                        <span><FormattedMessage id="DETAILED_USAGE_LOG_SCREEN.DATE" />: </span>
                        <span>
                            {selectedIndex !== undefined && storeWeekLoads[selectedIndex] && new Date(storeWeekLoads[selectedIndex].dayLoadResponse.date).toLocaleDateString()}
                        </span>
                    </span>
                </h3>
                {filteredChartDataWithColumnNames && <Chart
                    onLoad={() => setChartLoaded(true)}
                    chartType="LineChart"
                    height={400}
                    data={filteredChartDataWithColumnNames[0]?.length > 1 ? filteredChartDataWithColumnNames : filteredChartDataWithColumnNames.map((t, i) => [t[0], i === 0 ? "0" : 0])}
                    options={{
                        colors: Object.values(DATAPOINT_TO_COLOR).filter((_c, i) => columnsVisibility[i]),
                        legend: { position: "bottom", textStyle: { fontName: "inherit" }, },
                        series: filteredChartDataWithColumnNames[0]?.length > 1 ? null : {
                            "0": {
                                color: "transparent",
                                visibleInLegend: false,
                            }
                        },
                        vAxis: {
                            minValue: 1,
                        },
                    }}
                />}
            </div>
            <div className="card" style={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingBottom: "1rem" }}>
                {chartLoaded && columnsVisibility.map((v, i) => (
                    !nonHiddableDatapoints.has(Object.keys(DATAPOINT_KEY_TO_MESSAGE_ID)[i] as DatapointKey) && <div key={i}>
                        <label className="form-check form-check-sm form-check-custom form-check-solid me-5" htmlFor={`column_${i}_visibility`}>
                            <input className="form-check-input" checked={v} type="checkbox" id={`column_${i}_visibility`} onChange={e => onColumnVisibilityChange(i, e.target.checked)} />
                            <span className="form-check-label">{chartLineNames[i]}</span>
                        </label>
                    </div>
                ))}
            </div>
            <br />
            <br />
            <br />
        </>
    )
}
