export const DAY_TO_MESSAGE_ID = {
  'א': 'GENERAL.SUNDAY',
  'ב': 'GENERAL.MONDAY',
  'ג': 'GENERAL.THUESDAY',
  'ד': 'GENERAL.WEDNESDAY',
  'ה': 'GENERAL.THURSDAY',
  'ו': 'GENERAL.FRIDAY',
  'ז': 'GENERAL.SATURDAY',
} as const;

export const DAY_HEBREW_TO_NUMBER = {
  'א': 0,
  'ב': 1,
  'ג': 2,
  'ד': 3,
  'ה': 4,
  'ו': 5,
  'ז': 6,
} as const;

export interface ITableRow {
  store: string;
  storeId: number;
  day: keyof typeof DAY_TO_MESSAGE_ID;
  dayInNumber: number;
  currentMorningStandart: number;
  currentEveningStandart: number;
  recommendedMorningStandart: number;
  recommendedEveningStandart: number;
  morningSimulate?: number;
  eveningSimulate?: number;
  grade: number;
  status: number;
  LoadPerHour: LoadPerHour[];
  hasIndependedPOS: boolean;
  morningTotalOpenPOS: number;
  eveningTotalOpenPOS: number;
  finalRecommendedMorningStandart: number;
  finalRecommendedEveningStandart: number;
  previousRecommendedMorningStandart: number;
  previousRecommendedEveningStandart: number;
  standardHeaderId: number;
  deskNumber: number;
  progressingStandardStatus: number;
  morningStandardId: number;
  eveningStandardId: number;
  morningAssignment: number;
  eveningAssignment: number;
  selectedManualMorningStandard?: number;
  selectedManualEveningStandard?: number;
}
export interface ClacRecommendedConfig {
  id: number;
  cashiersForIndependentArea: number;
  cashiersForCarts: number;
  riskRangePrecent: number;
  indepentedDeskNumber: number;
  cartNumber: number;
  storeId: number;
}
export interface IFilterCashier {
  status: number
  description: string
  filter: (item: ITableRow, index: number) => boolean
}

export interface LoadPerHour {
  hour: number;
  loadByMinutes: number;
}

export type RecommendedStandardRowsResponse = {
  rows: ITableRow[],
} & {
    [Key in "manualStandardsFor" | "calculatedFor"]: {
      weekNumber: number,
      date: Date | string,
    }
  };

