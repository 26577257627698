import { UserRow } from '_metronic/partials/widgets/tables/UserRaw'
import { IUserList } from '../interfaces/IUsageUser'

interface Props {
  usageUser: IUserList
  deleteItem: (id: number) => void
  editItem: (id: number) => void
}

const UsageUserRow = (props: Props) => {
  const usageUser: IUserList = props.usageUser
  return (
    <UserRow
      id={usageUser.id}
      userName={usageUser.userName}
      email={usageUser.email}
      storeName={usageUser.storeName}
      phone={usageUser.phone}
      userLevel={usageUser.userLevel}
      deleteItem={props.deleteItem}
      editItem={props.editItem}
    />
  )
}
export default UsageUserRow
