import { useIntl } from 'react-intl'
import { logEnum, sendlog } from '../../logs/sendLog'

interface Props {
  onStatusBy: (statusType: string) => void
}

const StatusBy = (props: Props) => {
  const onChange = (event: any) => {
    switch (event.target.value) {
      case 'byShift':
        sendlog(logEnum.cashiersPerShift)
        break;
      case 'byLastHour':
        sendlog(logEnum.cashiersPerLastHour)
        break;
      case 'byDay':
        sendlog(logEnum.cashiersPerDay)
        break;
    }
    props.onStatusBy(event.target.value);
  }

  const intl = useIntl();

  return (
    <div>
      <select
        className='form-select'
        data-control='select2'
        data-hide-search='true'
        data-kt-select2='true'
        data-placeholder='Select option'
        data-allow-clear='true'
        onChange={onChange}
      >
        <option value='byLastHour' >{intl.formatMessage({ id: "POS_AVAILABILITY_SCREEN.FOR_LAST_HOUR" })}</option>
        <option value='byShift' >{intl.formatMessage({ id: "POS_AVAILABILITY_SCREEN.FOR_SHIFT" })}</option>
        <option value='byDay' >{intl.formatMessage({ id: "POS_AVAILABILITY_SCREEN.FOR_ALL_DAY" })}</option>
        <option value='ByRangeTime' >{intl.formatMessage({ id: "POS_AVAILABILITY_SCREEN.NEW_TIME_RANGE" })}</option>
      </select>
    </div>
  )
}
export default StatusBy

