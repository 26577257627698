import { FilterStatusType } from 'app/routing/PrivateRoutes'
import { useIntl } from 'react-intl'
import { logEnum, sendlog } from '../../logs/sendLog'

interface Props {
  value: FilterStatusType,
  onStatusBy: (statusType: FilterStatusType) => void,
}

const StatusBy = (props: Props) => {

  const onChange = (event: any) => {
    switch (event.target.value) {
      case 'standardStatus':
        sendlog(logEnum.recommendedStatusByStandard);
        break;
      case 'productivityStatus':
        sendlog(logEnum.recommendedStatusByproductivity);
        break;
    }
    props.onStatusBy(event.target.value);
  }

  const { value } = props;
  const intl = useIntl();
  return (
    <div className='mb-3'>
      <select
        className='form-select'
        data-control='select2'
        data-hide-search='true'
        data-kt-select2='true'
        data-placeholder='Select option'
        data-allow-clear='true'
        onChange={onChange}
        value={value}
      >
        <option value='productivityStatus' >{intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.HOURLY_PRODUCTIVITY_CHAIN_OVER_STORE" })}</option>
        <option value='standardStatus' >{intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.STANDARD_OVER_RECOMMENDED_STANDARD" })}</option>
        <option value='attendanceAgainstWorkStatus' >{intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.ATTENDANCE_OVER_NET" })}</option>
        <option value='overloadStandardStatus' >{intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.STANDARD_EXCESS" })}</option>
        <option value='underloadStandardStatus' >{intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.STANDARD_LACK" })}</option>
        <option value='overloadPostingStatus' >{intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.ASSIGNMENT_EXCESS" })}</option>
        <option value='underloadPostingStatus' >{intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.ASSIGNMENT_LACK" })}</option>
      </select>
    </div>
  )
}
export default StatusBy