import { IStore } from '../interfaces/IStore'
import { GraphModal } from './graphModal';
import Store from './Store'
interface props {
  stores: IStore[],
  displayGraph: boolean,
  onExitClick: () => any
}
export default function StoresList(props: props) {
  const stores = props.stores || []

  const listRender = stores.map((item: any) => {
    return <Store key={item.id} store={item}></Store>
  });
  return (
    <div className='row g-6 g-xl-9 justify-content-center' id="main-list">
      {props.displayGraph &&
        <div>
          <GraphModal className='' stores={stores} onExitClick={props.onExitClick} />
        </div>
      }
      {listRender}</div>
  )
}
