import { STATUS_TYPE } from '../../auth/redux/AuthCRUD'
import { IFilterStore, IStore } from '../interfaces/IStore'

function resultByStatusType(status: number, item: IStore): boolean {
  switch (STATUS_TYPE) {
    case 'independentDeskStatus':
      return item.independentDeskStatus === status;
    case 'activeDeskStatus':
      return item.activeDeskStatus === status;
    case 'attendanceStatus':
      return item.attendanceStatus === status;
    case 'shiftStatus':
      return item.shiftStatus === status;
    case 'cashiersDeviationStatus':
      return item.cashiersDeviationStatus === status;
    case 'revenueStatus':
      return item.revenueStatus === status;///need to change
    case 'loadStatus':
      return item.loadStatus === status;///need to change
  }
  return false;
}

export const showAllFilter = (item: IStore) => {
  return true
}
export const successFilter = (item: IStore, index: number) => {
  return resultByStatusType(1, item);
  //return item.status === 1
}
export const oneCashierDeskInactiveFilter = (item: IStore, index: number) => {
  return resultByStatusType(2, item);
  //return item.status === 2
}
export const fullCashierDeskInactive = (item: IStore, index: number) => {
  return resultByStatusType(3, item);
  //return item.status === 3
}
export const upThan100 = (item: IStore, index: number) => {
  return resultByStatusType(4, item);
  //return item.status === 3
}

export const filtersStores: IFilterStore[] = [
  { status: 0, des: 'show all', filter: showAllFilter },
  { status: 1, des: 'full success', filter: successFilter },
  { status: 2, des: 'one casherDeskInactive', filter: oneCashierDeskInactiveFilter },
  { status: 3, des: 'full cashierDeskCountInactive', filter: fullCashierDeskInactive },
  { status: 4, des: 'up than 100', filter: upThan100 },
]
