/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { useState, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useIsRTL } from '_metronic/i18n/Metronici18n'

import { KTSVG } from '../../../../_metronic/helpers' //'../../../helpers'
import { SettingModalRow } from '../interfaces/IsettingModalRow'
import { SCREEN_NAMES_TO_MESSAGE_ID } from '../interfaces/ISettingRow'

type Props = {
  data: Partial<SettingModalRow>,
  getData: Function
}

const SettingModal: React.FC<Props> = ({
  data,
  getData
}) => {
  let exitButton = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  let initialValues = {
    screenName: data.screenName,
    purpleButton: (data.rowData?.find(f => f.title.includes('purpleButton')))?.value,
    greenButton: (data.rowData?.find(f => f.title.includes('greenButton')))?.value,
    yellowButton: (data.rowData?.find(f => f.title.includes('yellowButton')))?.value,
    note: data.note,
    screenId: data.screenId,
    screenSettingTypeId: data.screenSettingTypeId,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: values => {
      setIsLoading(true)
      axios.post("/setting",
        values
      ).then((res) => {
        console.log(res.data);
        setIsLoading(false);
        getData();
        setTimeout(() => {
          exitButton.current?.click();
        }, 500);
      })
    },
  });
  const isRTL = useIsRTL();
  const intl = useIntl();
  const screenNameMessageId = formik.values?.screenName && (
    formik.values.screenName in SCREEN_NAMES_TO_MESSAGE_ID
      ? SCREEN_NAMES_TO_MESSAGE_ID[formik.values.screenName]
      : `<UNKNOWN_SCREEN>(${formik.values.screenName})`
  );


  return (
    <div dir={isRTL ? 'rtl' : 'ltr'} className='modal fade' id='setting_modal' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'><FormattedMessage id="KPI_SETTINGS.ADD_UPDATE_PARAMENERS" /></h1>
              <h3 className='pt-1'><FormattedMessage id="KPI_SETTINGS.SCREEN" />: {screenNameMessageId && <FormattedMessage id={screenNameMessageId} />}</h3>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div dir={isRTL ? 'rtl' : 'ltr'} className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'><FormattedMessage id="KPI_SETTINGS.RANGE_FOR_PERPULE_ABOVE" /></label>
                <input
                  style={{ WebkitAppearance: 'none' }}
                  value={formik.values.purpleButton}
                  className={clsx('form-control form-control-lg form-control-solid text-info',)}
                  type='number'
                  autoComplete='off'
                  id="purpleButton"
                  name="purpleButton"
                  onChange={formik.handleChange}
                  min={0}
                />
              </div>
              <div dir={isRTL ? 'rtl' : 'ltr'} className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'><FormattedMessage id="KPI_SETTINGS.RANGE_FOR_GREAN_ABOVE" /></label>
                <input
                  style={{ WebkitAppearance: 'none' }}
                  value={formik.values.greenButton}
                  className={clsx('form-control form-control-lg form-control-solid text-success',)}
                  type='number'
                  autoComplete='off'
                  id="greenButton"
                  name="greenButton"
                  onChange={formik.handleChange}
                  max={formik.values.purpleButton ? formik.values.purpleButton - 1 : 100}
                  min={0}
                />
              </div>
              <div dir={isRTL ? 'rtl' : 'ltr'} className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'><FormattedMessage id="KPI_SETTINGS.RANGE_FOR_YELLOW_ABOVE" /></label>
                <input
                  style={{ WebkitAppearance: 'none' }}
                  value={formik.values.yellowButton}
                  className={clsx('form-control form-control-lg form-control-solid text-warning',)}
                  type='number'
                  autoComplete='off'
                  id="yellowButton"
                  name="yellowButton"
                  onChange={formik.handleChange}
                  max={formik.values.greenButton ? formik.values.greenButton - 1 : 100}
                  min={0}
                />
              </div>
              <div dir={isRTL ? 'rtl' : 'ltr'} className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-muted'><FormattedMessage id="KPI_SETTINGS.RANGE_FOR_RED_ABOVE" /></label>
                <input
                  style={{ WebkitAppearance: 'none' }}
                  value={formik.values.yellowButton}
                  className={clsx('form-control form-control-lg form-control-solid text-muted',)}
                  type='number'
                  autoComplete='off'
                  //id="yellowButton"
                  //name="yellowButton"
                  //onChange={formik.handleChange}
                  disabled={true}
                />
              </div>
              <div dir={isRTL ? 'rtl' : 'ltr'}>
                <label className='form-label fs-6 fw-bolder text-dark'><FormattedMessage id="KPI_SETTINGS.ENTER_REMARK" /></label>
                <textarea
                  className='form-control form-control-solid mb-8'
                  rows={2}
                  placeholder={intl.formatMessage({ id: "KPI_SETTINGS.ENTER_REMARK" })}
                  id="note"
                  name="note"
                  value={formik.values.note}
                  onChange={formik.handleChange}
                ></textarea>
              </div>
              {isLoading && (
                <span style={{ display: 'block', zIndex: 1060 }} className='indicator-progress'>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              <div dir={isRTL ? 'rtl' : 'ltr'} className='text-center'>
                <button
                  type='submit' className='btn btn-light-primary btn-sm fs-6 mx-2'><FormattedMessage id="GENERAL.CONFIRM" /></button>
                <button ref={exitButton} id='exit' type='button' data-bs-dismiss='modal' className='btn btn-light-primary btn-sm fs-6 mx-2'><FormattedMessage id="GENERAL.CANCEL" /></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SettingModal }
