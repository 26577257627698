import axios from 'axios'
import { UserModel } from '../models/UserModel'

export const GET_USER_BY_ACCESSTOKEN_URL = "/login/auth/user";
//export const LOGIN_URL = "/login/auth/login"
export const LOGIN_URL = "/login/auth/login";
export const SEND_LOG_URL = "/logs/usageLog";
export const REGISTER_URL = "/users/register";
export const REMOVE_URL = "/users/remove";
export const REQUEST_PASSWORD_URL = "/auth/forgot-password";
export let USER_NAME = '';
export let STATUS_TYPE = 'independentDeskStatus';
export let CURRENT_USER: UserLevel;
export let IS_SORT_SIDE = true;
export let RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE = 'productivityStatus';
export let POS_EVENT_RESEARCH_SCREEN_STATUS_TYPE = 'overLoadCashierStatus';

export type UserLevel = "user" | "manager" | "admin";

export interface fullUser {
  email: string, name: string, password: string, storeId: string, whatsapp?: string, userLevel: { id: string, level: string }, phone?: string
}

export function setRecommendedStandardScreenStatusType(statusType: string) {
  RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE = statusType;
}

export function setPOSEeventResearchScreenStatusType(statusType: string) {
  POS_EVENT_RESEARCH_SCREEN_STATUS_TYPE = statusType;
}

export function setIsSortSide() {
  IS_SORT_SIDE = !IS_SORT_SIDE;
}

export function setStatusType(statusType: string) {
  STATUS_TYPE = statusType;
}
export function setUserName(userName: string) {
  USER_NAME = userName;
}
export function setCurrentUser(userLevel: UserLevel) {
  CURRENT_USER = userLevel;
}
// Server should return AuthModel
export async function login(email: string, password: string) {
  //return await onLoginRequest(email, password)

  return axios.post(LOGIN_URL, { email, password })
  //return await axios.post('LOGIN_URL', {email, password})
}
export function register(user: fullUser) {
  return axios.post(REGISTER_URL, user)
}

export async function removeUser(id: number) {
  //return await onLoginRequest(email, password)

  return axios.delete(`${REMOVE_URL}?id=${id}`)
  //return await axios.post('LOGIN_URL', {email, password})
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, { email })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  //return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
