/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import ProductivityPerDayList, { ColumnName } from './ProductivityPerDayList'
import { getProductivityPerHour as getProductivityPerDay } from '../services/productivityPerDayData'
import { IProductivityPerDay } from '../interfaces/IProductivityPerDay'
import FilterCashiers from './FilterButtons'
import { filtersCashiers } from '../helpers/filtersCashiers'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Moment from 'react-moment';
import SearchBranch from './SearchBranch'
import { useLocation } from 'react-router-dom'
import { IS_SORT_SIDE, setIsSortSide } from '../../auth/redux/AuthCRUD'
import { auto } from '@popperjs/core'
import { FormattedMessage } from 'react-intl'
import { logEnum, sendlog } from 'app/modules/logs/sendLog'

export default function ProductivityPerDay(props: any) {

  const [listproductivityPerDay, setListproductivityPerDay] = useState<IProductivityPerDay[]>([])
  const [statusFilter, setStatusFilter] = useState<number>(0)
  const [searchBranch, setSearchBranch] = useState<string>('')
  let branchSelected: any = useLocation().state || "";
  const [lastDateUpdate, setLastDateUpdate] = useState<Date | null>()

  const getListToRender = () => {
    if (!listproductivityPerDay) return []

    const filterRun = filtersCashiers.find((filter) => filter.status === statusFilter)
    if (!filterRun) {
      return listproductivityPerDay
    }

    return listproductivityPerDay
      .filter(filterRun.filter)
      .filter((item) => {
        if (!searchBranch) return true
        return item.store.includes(searchBranch);
      });
  };

  const getListProductivityPerDay = async () => {
    try {
      const list: any = await getProductivityPerDay()
      if (list) {
        setListproductivityPerDay(list)
        setLastDateUpdate(new Date())
      }
    } catch (err) { }
  }

  useEffect(() => {
    onSearchBranch(branchSelected);
    getListProductivityPerDay()
  }, [branchSelected])

  const onFilter = (status: number) => {
    setStatusFilter(status);
  }

  useEffect(() => {
    sendlog(logEnum.productivityPerDayScreen)
  }, [])

  const onTitleClick = (titleSelected: ColumnName) => {
    let list = [...listproductivityPerDay];

    if (IS_SORT_SIDE) {
      if (titleSelected === 'branch') list.sort((a, b) => a.store.localeCompare(b.store));
      else if (titleSelected === 'date') list.sort((a, b) => a.date.localeCompare(b.date));
      else if (titleSelected === 'week_day') list.sort((a, b) => a.dayInWeek.localeCompare(b.dayInWeek));
      else if (titleSelected === 'hourly_chain_productivity') list.sort((a, b) => a.productivityPerHourForChain - b.productivityPerHourForChain);
      else if (titleSelected === 'hourly_branch_productivity') list.sort((a, b) => a.productivityPerHourForStore - b.productivityPerHourForStore);
      else if (titleSelected === 'branch_chain_ratio') list.sort((a, b) => a.productivityStoreGrade - b.productivityStoreGrade);
    } else {
      if (titleSelected === 'branch') list.sort((a, b) => b.store.localeCompare(a.store));
      else if (titleSelected === 'date') list.sort((a, b) => b.date.localeCompare(a.date));
      else if (titleSelected === 'week_day') list.sort((a, b) => b.dayInWeek.localeCompare(a.dayInWeek));
      else if (titleSelected === 'hourly_chain_productivity') list.sort((a, b) => b.productivityPerHourForChain - a.productivityPerHourForChain);
      else if (titleSelected === 'hourly_branch_productivity') list.sort((a, b) => b.productivityPerHourForStore - a.productivityPerHourForStore);
      else if (titleSelected === 'branch_chain_ratio') list.sort((a, b) => b.productivityStoreGrade - a.productivityStoreGrade);
    }
    setIsSortSide();
    setListproductivityPerDay(list);
  }

  const onSearchBranch = (search: string) => {
    setSearchBranch(search)
  }

  return (
    <div>
      <nav className='navbar navbar-light bg-light row justify-content-between'>
        <div className='col-auto'>
          <span>
            {lastDateUpdate &&
              <div>
                <span>
                  <FormattedMessage
                    id={"CASHIERS_ALERTS_SCREEN.LAST_UPDATE"}
                    values={{ lastUpdate: <Moment format='DD-MM-YYYY HH:mm'>{lastDateUpdate ? lastDateUpdate : ''}</Moment> }} />
                </span>
              </div>}
            {!lastDateUpdate && <img src={toAbsoluteUrl('/media/gifs/hourglass.gif')} width={20} alt="loading" />}
          </span>
        </div>
        <h1 className='breadcrumb-item text-muted col-auto'><FormattedMessage id="MAIN_MENU.POS_PRODUCTIVITY" /></h1>
        <span className='col-auto'>
          <a className='navbar-brand' href='https://yochananof.co.il/'>
            &nbsp;&nbsp;
            <img
              src={toAbsoluteUrl('/media/logos/retail_logo.png')}
              width='100'
              height='30'
              className='d-inline-block '
              alt=''
              loading='lazy'
            />
          </a>
        </span>
      </nav>
      <br />
      <br />
      <div className='row '>
        <div className=' col-12 col-xxl-5'>
          <div className='row justify-content-between'>
            <div className='col-xs-12 col-sm-4'>
              <SearchBranch onSearch={onSearchBranch} branch={searchBranch}></SearchBranch>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: auto }} className='col-xxl-auto col-xl-12 pe-6 ps-5'>
          <div className='col-md-12 col-xxl-12 filter-buttons '>
            <FilterCashiers onFilter={onFilter} listCashiers={listproductivityPerDay} />
          </div>
        </div>
      </div>
      <hr />
      <ProductivityPerDayList onTitleClick={onTitleClick} cashiers={getListToRender()} />
    </div>
  )
}
