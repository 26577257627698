import axios from 'axios'

export const getStores = async () => {
  try {
    return await (await axios.get("/usage-level")).data;
  } catch (err) {
    return []
  }
}


