import axios from "axios";
import { useEffect, useState } from "react";
import { Selector } from "../Selector";

type Store = {
    id: number,
    name: string,
    has_carts: boolean,
};

type StoreType = "all-stores" | "with-carts-only";

type BaseSelectorProps<AllowClear extends boolean> = {
    allowClear: AllowClear,
    value: { id: number, name: string },
    onClear?: () => any,
    onChange: (selectedIndex: AllowClear extends true ? ({ id: number, name: string } | undefined) : { id: number, name: string }) => any,
    placeholder?: string,
    storesType: StoreType
}

type WithOptional<T extends object, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type StoreSelectorProps<AllowClear extends boolean = any> = (AllowClear extends true ? WithOptional<BaseSelectorProps<true>, "value"> : BaseSelectorProps<false>);

export const StoreSelector: React.VFC<StoreSelectorProps> = ({
    allowClear,
    onChange,
    value,
    placeholder,
    onClear,
    storesType,
}) => {
    const [stores, setStores] = useState<Store[]>([]);
    const [loadingStores, setLoadingStores] = useState(true);
    const [filteredStores, setFilteredStores] = useState<Store[]>([]);
    const [search, setSearch] = useState("");

    const onSelectStore = (selectedStoreId: string) => onChange({ id: parseInt(selectedStoreId), name: stores.find(store => store.id === parseInt(selectedStoreId))?.name ?? "" });

    const onStoreFilter = (search: string) => setSearch(search);

    const handleClear = () => onClear?.();

    useEffect(() => {
        const source = axios.CancelToken.source();
        new Promise(async () => {
            setLoadingStores(true);
            try {
                const response = await axios.get<Store[]>("/store/list", {
                    cancelToken: source.token,
                });
                setStores(response.data);
                setLoadingStores(false);
            } catch (e) { }
        });
        return () => source.cancel();
    }, []);

    useEffect(() => {
        const filter: Parameters<typeof stores["filter"]>[0] = (
            storesType === "all-stores"
                ? store => store.name.includes(search)
                : store => store.has_carts && store.name.includes(search)
        );
        setFilteredStores(stores.filter(filter));
    }, [stores, storesType, search])

    return <Selector
        allowClear={allowClear}
        onClear={handleClear}
        onFilter={onStoreFilter}
        onSelect={onSelectStore}
        value={value && { value: value.id.toString(), label: value.name }}
        options={filteredStores.map(store => ({ label: store.name, value: store.id.toString() }))}
        loading={loadingStores}
        placeholder={placeholder}
    />
}