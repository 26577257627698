import Table from 'react-bootstrap/Table'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

export type SortColumn = {
  [key: string]: boolean | undefined
  externalId?: boolean
  storeName?: boolean
  pos_number?: boolean
  formatted_date?: boolean
  hour_part?: boolean
  tillNum?: boolean
  amount?: boolean
  statusCode?: boolean
  action?: boolean
  employeeName?: boolean
  supervisorName?: boolean
}

export const SortColumnIndex: { [index: number]: string } = {
  0: 'externalId',
  1: 'storeName',
  2: 'pos_number',
  3: 'formatted_date',
  4: 'hour_part',
  5: 'tillNum',
  6: 'amount',
  7: 'statusCode',
  8: 'action',
  9: 'employeeName',
  10: 'supervisorName',
}

const columnArrow = (bool?: boolean) => {
  switch (bool) {
    case true:
      return ' ↑'
    case false:
      return ' ↓'
    default:
      return ''
  }
}
function makeUpperColumns(upperColumns: string[], sortColumn: SortColumn, handleColumnClick: any) {
  return (
    <tr
      style={{
        fontWeight: 'bold',
        textAlign: 'center',
        border: '1px solid #ddd',
        color: '#0472D0',
        backgroundColor: 'white',
      }}
    >
      {upperColumns.map((col, index) => (
        <th style={{ cursor: index === 3 ? "crosshair" : "default" }} key={col} onClick={() => (index === 3 ? handleColumnClick ? handleColumnClick(index) : {} : '')}>
          {[col, columnArrow(sortColumn[SortColumnIndex[index]])]}
        </th>
      ))}
    </tr>
  )
}

function makeDataIntoRows(data: string[][], handleRowClick: any, checkedRow?: number | null) {
  return data?.map((row, i) => (
    <tr
      key={i}
      style={{
        border: '1px solid #ddd',
        backgroundColor:
          row[0] === 'סה"כ' ? 'lightyellow' : i === checkedRow ? 'lightblue' : 'lightgray',
      }}
      onClick={() => (handleRowClick ? handleRowClick(i) : {})}
    >
      {row.map((cellValue, j) => (
        <td
          key={j}
          style={{
            textAlign: 'center',
            color: parseFloat(cellValue) < 0 ? 'red' : 'black',
            direction: 'initial',
          }}
        >
          {cellValue ?? " "}
        </td>
      ))}
    </tr>
  ))
}

interface ITableProps {
  data: Array<Array<string>>
  upperColumns: Array<string>
  handleRowClick?: (rowNum: number) => any
  checkedRow?: number | null
  sortColumn: SortColumn
  handleColumnClick?: (rowNum: number) => any
  // currentPage: number
}

export type ActionDataResponse = string[][]

export type ActionData = {
  externalId: string
  storeName: string
  tillNum: number
  formatted_date: string
  hour_part: string
  action: string
  subAction: string
  employeeName: string
  supervisorName: string
  barcode: number
  barcodeName: string
  amount: string
  explentation: string
  bruto: string
}

function ActionTable({
  data,
  upperColumns,
  handleRowClick,
  checkedRow,
  sortColumn,
  handleColumnClick,
}: // currentPage,
  ITableProps) {
  return (
    <>
      <div style={{ height: '500px', overflowY: 'auto' }}>
        <Table striped bordered>
          <thead>{makeUpperColumns(upperColumns, sortColumn, handleColumnClick)}</thead>
          <tbody>{makeDataIntoRows(data, handleRowClick, checkedRow)}</tbody>
        </Table>
      </div>
    </>
  )
}

export async function saveExcelFile(data: string[][], upperRow: string[]) {
  // Create a new workbook and add a worksheet
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Sheet 1')
  // makeUpperHeader(worksheet, upperRow)
  makeUpperHeader(worksheet, upperRow)
  writeRows(worksheet, data)
  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  saveAs(blob, 'action_table.xlsx')
}

function writeRows(worksheet: ExcelJS.Worksheet, data: string[][]) {
  for (let index = 0; index < data.length; index++) {
    const row = worksheet.addRow(data[index])
    for (let jindex = 0; jindex < data[index].length; jindex++) {
      const cell = row.getCell(jindex + 1)
      // cell.fill = { type: 'pattern', pattern: 'solid', };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
    }
  }
}

function makeUpperHeader(worksheet: ExcelJS.Worksheet, row: string[]) {
  const rowLength = row.length
  const emptyRow = worksheet.addRow(Array(rowLength).fill(null))
  for (let index = row.length - 1; index >= 0; index--) {
    const cell = emptyRow.getCell(index + 1)
    cell.value = row[index]
    cell.alignment = { horizontal: 'center' }
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    }
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' },
    }
    cell.font = { bold: true }
  }
}
export default ActionTable
