/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormattedMessage } from 'react-intl';
import { IUsageLog } from '../interfaces/IUsageLog'
import UsageLogRow from './UsageLogRow'

export type Column = 'user' | 'date' | 'action_type'

interface props {
  usageLogArr: IUsageLog[],
  onTitleClick: (column: Column) => void
}
let design = 'min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light';
export default function UsageLogList(props: props) {
  const usageLogArr = props.usageLogArr || []
  function onClick(title: Column) {
    props.onTitleClick(title);
  }
  const listRender = usageLogArr.map((item: any) => {
    return <UsageLogRow key={item.id} usageLog={item}></UsageLogRow>
  })

  return (<div className={`card`}>
    <div className='card-body py-3'>
      <div className='table-responsive tableFixHead'>
        <table className='table align-middle table-row-bordered gs-4 gy-4'>
          <thead>
            <tr className='fw-bolder text-muted bg-light'>
              <th style={{ cursor: 'pointer' }} onClick={() => onClick('user')} className={design}>
                <FormattedMessage id="DETAILED_USAGE_LOG_SCREEN.USER" />
              </th>
              <th style={{ cursor: 'pointer' }} onClick={() => onClick('date')} className={design}>
                <FormattedMessage id="DETAILED_USAGE_LOG_SCREEN.DATE" />
              </th>
              <th style={{ cursor: 'pointer' }} onClick={() => onClick('action_type')} className={design}>
                <FormattedMessage id="DETAILED_USAGE_LOG_SCREEN.ACTION_TYPE" />
              </th>
            </tr>
          </thead>
          <tbody>
            {listRender}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}
