import {IFilter, IProductivityPerDay} from '../interfaces/IProductivityPerDay'

export const showAllFilter = (item: IProductivityPerDay) => {
  return true
}
export const successFilter = (item: IProductivityPerDay, index: number) => {
  return item.status === 1
}
export const oneCashierDeskInactiveFilter = (item: IProductivityPerDay, index: number) => {
  return item.status === 2
}
export const fullCashierDeskInactive = (item: IProductivityPerDay, index: number) => {
  return item.status === 3
}

export const upThan95 = (item: IProductivityPerDay, index: number) => {
  return item.status === 4
}

export const filtersCashiers: IFilter[] = [
  {status: 0, description: 'show all', filter: showAllFilter},
  {status: 1, description: 'full success', filter: successFilter},
  {status: 2, description: 'one casherDeskInactive', filter: oneCashierDeskInactiveFilter},
  {status: 3, description: 'full cashierDeskCountInactive', filter: fullCashierDeskInactive},
  {status: 4, description: 'up than 95 precent', filter: upThan95},
]
