import { useEffect, useState } from 'react'
import { IProductivityPerDay } from '../interfaces/IProductivityPerDay'
import { successFilter, oneCashierDeskInactiveFilter, fullCashierDeskInactive, upThan95 } from '../helpers/filtersCashiers'
import { logEnum, sendlog } from '../../logs/sendLog'
import { IButtonContent } from '../interfaces/buttonContent'
import { getButtonsContentByName } from '../../settings/services/settingsData'
import { BtnContentEnum } from 'app/modules/settings/interfaces/btnContentEnum'
import { FormattedMessage } from 'react-intl'

interface Props {
  onFilter: (statusFilter: number) => any
  listCashiers: IProductivityPerDay[]
}
const listButtons = [
  { color: 'primary', active: true },
  { color: 'success', active: false },
  { color: 'warning', active: false },
  { color: 'danger', active: false },
  { color: 'info', active: false },
]
const FilterCashiers = (props: Props) => {
  const listCashiers = props.listCashiers || [];

  const [listButtonsRender, setListButtonsRendersRender] = useState(listButtons)

  const onClickFilter = (event: any) => {
    const status = event.currentTarget.value
    switch (status) {
      case '1':
        sendlog(logEnum.cashiersGreenBtn);
        break;
      case '2':
        sendlog(logEnum.cashiersYellowBtn);
        break;
      case '3':
        sendlog(logEnum.cashiersRedBtn);
        break;
      case '4':
        sendlog(logEnum.cashiersPurpleBtn);
        break;
      case '0':
        sendlog(logEnum.cashiersBlueBtn);
        break;
    }


    const list = [...listButtonsRender]
    list.forEach((item) => {
      item.active = false
    })
    list[status].active = true
    setListButtonsRendersRender(list)
    return props.onFilter(+status)
  }

  const [purpleButton, setPurpleButton] = useState(0)
  const [greenButton, setGreenButton] = useState(0)
  const [yellowButton, setYellowButton] = useState(0)
  const getContent = async () => {
    let arr: IButtonContent[] = [];
    arr = await getButtonsContentByName(BtnContentEnum.recommendedStandardProductivity);
    arr.forEach(btnContent => {
      if (btnContent.title === 'purpleButton') setPurpleButton(btnContent.value);
      else if (btnContent.title === 'greenButton') setGreenButton(btnContent.value);
      else if (btnContent.title === 'yellowButton') setYellowButton(btnContent.value);
    });
  }
  useEffect(() => {
    getContent();
  }, [])
  return (
    <div className='row justify-content-between'>
      <button
        style={{ marginRight: '5px' }}
        value={3}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[3].color} ${listButtonsRender[3].active ? 'active' : ''
          }`}
      >
        ({listCashiers.filter(fullCashierDeskInactive).length}) {yellowButton}% &gt;
      </button>

      <button
        style={{ marginRight: '5px' }}
        value={2}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[2].color} ${listButtonsRender[2].active ? 'active' : ''
          }`}
      >
        ({listCashiers.filter(oneCashierDeskInactiveFilter).length}) {yellowButton}% - {greenButton}%
      </button>

      <button
        id={'success-button'}
        style={{ marginRight: '5px' }}
        value={1}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[1].color} ${listButtonsRender[1].active ? 'active' : ''
          }`}
      >
        ({listCashiers.filter(successFilter).length}) {greenButton}% - {purpleButton}%
      </button>
      <button
        id={'main-button'}
        style={{ marginRight: '5px', display: 'flex', columnGap: 2 }}
        value={4}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[4].color} ${listButtonsRender[4].active ? 'active' : ''
          }`}
      >
        <span>
          ({listCashiers.filter(upThan95).length})
        </span>
        <span>
          {purpleButton}%
          &lt;
        </span>
      </button>

      <button
        id={'main-button'}
        style={{ marginRight: '5px' }}
        value={0}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[0].color} ${listButtonsRender[0].active ? 'active' : ''
          }`}
      >
        ({listCashiers.length}) <FormattedMessage id={"CASHIERS_ALERTS_SCREEN.DISPLAY_ALL"} />
      </button>
    </div>
  )

}

export default FilterCashiers
