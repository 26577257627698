export enum BtnContentEnum {
    //storeScreen
    'storeIndependentDesk' = 'storeIndependentDesk',
    'storePostingCashier' = 'storePostingCashier',
    'storeStandard' = 'storeStandard',
    'storePresentCashier' = 'storePresentCashier',
    'storeCashiersDeviation' = 'storeCashiersDeviation',
    'storeRevenue' = 'storeRevenue',
    'storeLoad' = 'storeLoad',
    //cashierScreen
    'cashierBtnContent' = 'cashierBtnContent',
    //usageLevels
    'usageLevelBtnContent' = 'usageLevelBtnContent',
    //recommendedStandard
    'recommendedStandardBtnContent' = 'recommendedStandardBtnContent',
    'recommendedStandardProductivity' = 'recommendedStandardProductivity',
    'recommendedStandardAttendance' = 'recommendedStandardAttendance',
    'recommendedStandardOverloadStandard' = 'recommendedStandardOverloadStandard',
    'recommendedStandardUnderloadStandard' = 'recommendedStandardUnderloadStandard',
    'recommendedStandardOverloadPosting' = 'recommendedStandardOverloadPosting',
    'recommendedStandardUnderloadPosting' = 'recommendedStandardUnderloadPosting',
    //POSEventResearch
    'POSEventResearchUnderLoadPosting' = 'POSEventResearchUnderLoadPosting',
    'POSEventResearchOverLoadPosting' = 'POSEventResearchOverLoadPosting'
}


// if (RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE == 'standardStatus') arr = await getButtonsContent('recommendedStandartBtnContent');
// else if (RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE == 'productivityStatus') arr = await getButtonsContent('productivityBtnContent');
// else if (RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE == 'attendanceAgainstWorkStatus') arr = await getButtonsContent('attendanceBtnContent');
// else if (RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE == 'overloadStandardStatus') arr = await getButtonsContent('overloadStandardBtnContent');
// else if (RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE == 'underloadStandardStatus') arr = await getButtonsContent('underloadStandardBtnContent');
// else if (RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE == 'overloadPostingStatus') arr = await getButtonsContent('overloadPostingBtnContent');
// else if (RECOMMENDED_STANDARD_SCREEN_STATUS_TYPE == 'underloadPostingStatus') arr = await getButtonsContent('underloadPostingBtnContent');

