import React, { Suspense, lazy, Dispatch, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { Stores } from '../pages/stores/Stores'
import { Cashiers } from '../pages/cashiers/Cashiers'
import { SettingModal } from '../modules/settings/components/settingModal'
import { TablesWidget9 } from '../modules/settings/components/TablesWidget9'//'../../_metronic/partials/widgets'
import UsageLevels from '../modules/usageLevels/components/UsageLevels'
import UsageLog from '../modules/usageLog/components/UsageLog'
import { RecommendedStandard } from '../modules/recommendedStandard/components/RecommendedStandard'
import LoadPerStandard from '../modules/loadPerStandard/components/LoadPerStandard'
import ProductivityPerDay from '../modules/productivityPerDay/components/ProductivityPerDay'
import MainPieChartScreen from 'app/modules/mainPieChartScreen/components/MainPieChartScreen'
import POSEventResearch from 'app/modules/POSEventResearch/components/POSEventResearch'
import { POSEventResearchDetails } from 'app/modules/POSEventResearchDetails/components/POSEventResearchDetails'
import { AlertsManagementPage } from 'app/pages/alerts-management/Alerts'
import { MaintenancePage } from 'app/modules/maintenance/Maintenance'
import { ProductivityStatusReportPage } from 'app/modules/productivity-status-report/ProductivityStatusReportPage'
import { TrendAnalysisPage } from 'app/modules/trend-analysis/TrendAnalysisPage'
import { TransactionCountTrendsPage } from 'app/modules/trends/transcation-count/TransactionCountTrendsPage'
import { CURRENT_USER } from 'app/modules/auth/redux/AuthCRUD';
import { PerHourProductivityPage } from 'app/modules/per-hour-productivity/PerHourProductivityPage'
import { LineActionsPage } from 'app/modules/line-actions/LineActionsPage'
import UserListPage from 'app/modules/usersList/components/usersList'
import { Registration } from 'app/modules/auth/components/Registration'
import UpdateUserPage from 'app/modules/usersList/components/updateUserPage'

type StoreFilter = {
  search: string,
  statusType: string,
  statusValue: number,
};

const defaultFilter: StoreFilter = {
  search: '',
  statusType: 'independentDeskStatus',
  statusValue: 0,
}

export const StoreFilterContext = React.createContext<
  [StoreFilter, Dispatch<React.SetStateAction<StoreFilter>>]
>([defaultFilter, _v => { }]);

export type FilterPosType = 'displayAll' | 'withIndepended' | 'withoutIndepended' | 'standardInProgress' | 'approvedStandard' | 'updatedStandard';
export type FilterStatusType = 'productivityStatus' | 'standardStatus' | 'attendanceAgainstWorkStatus' | 'overloadStandardStatus' | 'underloadStandardStatus' | 'overloadPostingStatus' | 'underloadPostingStatus';

type RecommendationFilter = {
  search: string,
  statusValue: number,
  posType: FilterPosType,
  statusType: FilterStatusType,
};

const defaultRecommendationFilter: RecommendationFilter = {
  search: '',
  statusValue: 0,
  posType: 'displayAll',
  statusType: 'productivityStatus',
};

export const RecommendationFilterContext = React.createContext<
  [RecommendationFilter, Dispatch<React.SetStateAction<RecommendationFilter>>]
>([defaultRecommendationFilter, _v => { }]);

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const [storeFilter, setStoreFilter] = useState<StoreFilter>(defaultFilter);
  const [recommendationFilter, setRecommendationFilter] = useState<RecommendationFilter>(defaultRecommendationFilter);

  return (
    <StoreFilterContext.Provider value={[storeFilter, setStoreFilter]}>
      <RecommendationFilterContext.Provider value={[recommendationFilter, setRecommendationFilter]}>
        <Suspense fallback={<FallbackView />}>
          <Switch>
            <Route path='/stores' component={Stores} />
            <Route path='/mainPieChartScreen' component={MainPieChartScreen} />
            <Route path='/cashiers' component={Cashiers} />
            <Route path='/alerts-management' component={AlertsManagementPage} />
            <Route path='/settingModal' component={SettingModal} />
            <Route path='/table1' component={TablesWidget9} />
            <Route path='/usageLevel' component={UsageLevels} />
            <Route path='/usageLog' component={UsageLog} />
            <Route path='/userList' component={UserListPage} />
            <Route path='/registration' component={Registration} />
            <Route path='/update_user' component={UpdateUserPage} />
            <Route path='/recommendedStandard'>
              <RecommendedStandard historyMode={false} />
            </Route>
            <Route path='/productivity-measures'>
              <RecommendedStandard historyMode={true} />
            </Route>
            <Route path='/productivity/status-report'>
              <ProductivityStatusReportPage />
            </Route>
            <Route path="/productivity/trend-analysis" component={TrendAnalysisPage} />
            <Route path="/productivity/per-hour-productivity" component={PerHourProductivityPage} />
            <Route path="/line-actions" component={LineActionsPage} />
            <Route path='/loadPerStandard' component={LoadPerStandard} />
            <Route path='/ProductivityPerDay' component={ProductivityPerDay} />
            <Route path='/POSEventResearchDetails' component={POSEventResearchDetails} />
            <Route path='/POSEventResearch' component={POSEventResearch} />
            <Route path='/dashboard' component={DashboardWrapper} />
            <Route path='/builder' component={BuilderPageWrapper} />
            <Route path='/crafted/pages/profile' component={ProfilePage} />
            <Route path='/crafted/pages/wizards' component={WizardsPage} />
            <Route path='/crafted/widgets' component={WidgetsPage} />
            <Route path='/crafted/account' component={AccountPage} />
            <Route path="/productivity/trends/transaction-count" component={TransactionCountTrendsPage} />
            <Route path='/apps/chat' component={ChatPage} />
            <Route path='/menu-test' component={MenuTestPage} />
            <Route path='/maintenance' component={MaintenancePage} />
            <Redirect from='/auth' to='/dashboard' />
            {CURRENT_USER === 'admin' && <>
              <Redirect exact from='/' to='/mainPieChartScreen' />
              <Redirect exact from='/index' to='/mainPieChartScreen' /></>
            }
            {CURRENT_USER !== 'admin' && <>
              <Redirect exact from='/' to='/stores' />
              <Redirect exact from='/index' to='/stores' /></>
            }
            <Redirect to='error/404' />
          </Switch>
        </Suspense>
      </RecommendationFilterContext.Provider>
    </StoreFilterContext.Provider>
  )
}
