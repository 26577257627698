import React from 'react'
import { AsideDefault } from '_metronic/layout/components/aside/AsideDefault'
import { Content } from '_metronic/layout/components/Content'
import { Footer } from '_metronic/layout/components/Footer'
import { HeaderWrapper } from '_metronic/layout/components/header/HeaderWrapper'
import { ScrollTop } from '_metronic/layout/components/ScrollTop'
import { PageDataProvider } from '_metronic/layout/core'
import { MasterInit } from '_metronic/layout/MasterInit'
import { Main } from '_metronic/partials'

const MainLayout: React.FC = ({ children }) => {
    return (
        <PageDataProvider>
            <div className='page d-flex flex-row flex-column-fluid'>
                <AsideDefault />
                <div className='d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                    <HeaderWrapper />
                    <br />
                    <div className='post d-flex flex-column-fluid' id='kt_post'>
                        <Content>{children}</Content>
                    </div>
                    <Footer />
                </div>
            </div>
            <Main />
            <MasterInit />
            <ScrollTop />
        </PageDataProvider>
    )
}

export { MainLayout }
