import { StoreSelection, StoresSelector } from "app/common/components/selectors/StoresSelector";
import { WEEKS } from "app/common/constants";
import { debounce } from "app/helpers/debounce";
import { DateRangeSelector } from "app/modules/trend-analysis/TrendAnalysisPage";
import axios, { CancelToken } from "axios";
import { useEffect, useMemo, useState } from "react";
import { Card, FormGroup, FormLabel, Spinner } from "react-bootstrap";
import Chart from "react-google-charts";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "_metronic/helpers";
import { useGoogleChartLanguage, useIsRTL } from "_metronic/i18n/Metronici18n";
import { DatapointSize, DatapointSizeSelector } from "../components/DatapointSizeSelector";

const CHART_HEIGHT = 300;

type TransactionCountTrendsData = {
    times: string[];
    storesData: {
        storeName: string;
        datapoints: number[];
    }[];
};

export const TransactionCountTrendsPage: React.VFC = () => {

    const defaults = useLocation<{ selectedStores: StoreSelection[], dateRange: { min: Date, max: Date }, datapointsSize: DatapointSize } | undefined>().state;

    const [selectedStores, setSelectedStores] = useState<StoreSelection[]>(defaults?.selectedStores ?? []);
    const [dateRange, setDateRange] = useState<{ min: Date, max: Date }>(defaults?.dateRange ?? { min: new Date(Date.now() - 1 * WEEKS), max: new Date() });
    const [datapointsSize, setDatapointsSize] = useState<DatapointSize>(defaults?.datapointsSize ?? "days");
    const [data, setData] = useState<TransactionCountTrendsData>();

    const fetchData = async (
        selectedStores: StoreSelection[],
        since: Date,
        until: Date,
        by: DatapointSize,
        cancelToken: CancelToken,
    ) => {
        try {
            const { data } = await axios.get<TransactionCountTrendsData>("/productivity/trends/transactions-count", {
                cancelToken,
                params: {
                    stores: selectedStores.map(s => s.id),
                    since,
                    until,
                    by,
                },
            });
            setData(data);
        } catch (e: any) {
            if (!e.__CANCEL__) {
                throw e;
            }
        }
    }

    const fetchDebounce = useMemo(() => debounce(fetchData, 1000), []);

    const handleSelectedStoresChange = (selectedStores: StoreSelection[]) => setSelectedStores(selectedStores);

    const handleDateRangeChange = (value: { min: Date, max: Date }) => setDateRange(value);
    const handleDatapointSizeChange = (value: DatapointSize) => setDatapointsSize(value);

    useEffect(() => {
        setData(undefined);
        const source = axios.CancelToken.source();
        fetchDebounce(selectedStores, dateRange.min, dateRange.max, datapointsSize, source.token);
        return () => source.cancel();
    }, [selectedStores, dateRange, datapointsSize, fetchDebounce]);

    const chartLanguage = useGoogleChartLanguage();
    const isRTL = useIsRTL();

    return (
        <main>
            <header className="d-flex justify-content-evenly position-relative mt-5">
                <h1>
                    <FormattedMessage id="SCREENS.PRODUCTIVITY_STATUS_REPORT" />
                </h1>
                <div className="position-absolute h-100 w-100">
                    <img src={toAbsoluteUrl('/media/logos/retail_logo.png')} className="h-100" alt="retail-innovation logo" />
                </div>
            </header>
            <div className="row my-3 d-flex align-items-end">
                <FormGroup className="col-xl-3">
                    <FormLabel htmlFor="nothing" className="w-100">
                        <FormattedMessage id="TREND_ANALYSIS_SCREEN.SELECT_STORES" />
                        <StoresSelector
                            onSelectedStoresChange={handleSelectedStoresChange}
                            selectedStores={selectedStores}
                            withCartsOnly={true}
                        />
                    </FormLabel>
                </FormGroup>
                <div className="col-auto">
                    <DateRangeSelector
                        onChange={handleDateRangeChange}
                        value={dateRange}
                    />
                </div>
                <div className="col-3">
                    <FormLabel>
                        <FormattedMessage id="TREND_ANALYSIS_SCREEN.DATAPOINT_PERIOD" />
                        <DatapointSizeSelector
                            onChange={handleDatapointSizeChange}
                            value={datapointsSize}
                        />
                    </FormLabel>
                </div>

            </div>
            <hr />
            <article className="row m-0">
                <Card className="col-12 p-0">
                    <Card.Header>
                        <Card.Title>
                            <FormattedMessage id="PRODUCTIVITY_STATUS_REPORT_SCREEN.TRANSACTIONS_COUNT_PER_STORE" />
                        </Card.Title>
                    </Card.Header>
                    <Card.Body className="p-0" style={{ minHeight: CHART_HEIGHT }}>
                        {data ? <Chart
                            className="chart-fix-rtl"
                            chartType="LineChart"
                            chartLanguage={chartLanguage}
                            height={CHART_HEIGHT}
                            options={{
                                legend: { position: "top", maxLines: 2, alignment: isRTL ? "end" : undefined },
                                focusTarget: "category",
                                tooltip: { ignoreBounds: true, isHtml: true },
                            }}
                            data={[
                                ["time", ...data.storesData.map(store => store.storeName)],
                                ...(data.times.map((time, i) => [new Date(time), ...data.storesData.map(storeData => storeData.datapoints[i])]))
                            ]}
                        /> : <div className="h-100 d-flex justify-content-center align-items-center">
                            <Spinner animation="border" />
                        </div>}
                    </Card.Body>
                </Card>
            </article>
        </main>
    );
}
