import {useEffect, useState} from 'react'
import axios from 'axios'
import {useIntl} from 'react-intl'
import {StoreSelection} from 'app/common/components/selectors/StoresSelector'

// type UserLevel = {
//   id: number,
//   level: string,
// }

export const StoreOptions = () => {
  const intl = useIntl()
  const [stores, setStores] = useState<StoreSelection[]>([])

  useEffect(() => {
    const cancel = axios.CancelToken.source()
    ;(async () => {
      const {data} = await axios.get<StoreSelection[]>('/store/list', {
        cancelToken: cancel.token,
      })
      setStores(data)
    })()
    return () => cancel.cancel()
  }, [])

  const storeOptions = () => {
    return stores.map((s) => <option value={s.id}>{s.name}</option>)
  }

  return (
    <>
      <option value={-1} selected>
        {intl.formatMessage({id: 'REGISTER_USER_SCREEN.NO_STORE'})}
      </option>
      {storeOptions()}
    </>
  )
}
