
import axios from 'axios'

export const getProductivityPerHour = async () => {
  try {
    return await (await axios.get("/recommended-standard/productivity-per-hour")).data
  } catch (err) {
    return []
  }
}
