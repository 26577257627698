export const debounce = <T extends Function>(func: T, delay: number) => {
    let lastFunc: NodeJS.Timeout | null = null;
    let lastTime: number | null = null;
    return (...args: T extends (...args: infer P) => any ? P : []) => {
        if (lastFunc) {
            clearTimeout(lastFunc);
            lastFunc = null;
        }
        if (!lastTime  || Date.now() - lastTime >= delay) {
            func.apply(func, args);
        } else {
            lastFunc = setTimeout(() => {
                func.apply(func, args);
            }, delay);
        }
        lastTime = Date.now();
    }
}
