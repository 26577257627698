import axios, { CancelToken } from 'axios'
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Button, FormGroup, Modal } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { SearchComponent } from '_metronic/assets/ts/components'
import { KTSVG, toAbsoluteUrl } from '_metronic/helpers'
import { ActionSelection, ActionSelector } from 'app/common/components/selectors/ActionSelector'
import { sendlog, logEnum } from 'app/modules/logs/sendLog'

const SCREENS = [
  'CASHIER_ALERTS',
  'POS_AVAILABILITY',
  'USAGE_LEVELS',
  'RECOMMENDATIONS_AND_EFFICIENCY_MEASURES',
  'DETAILED_USAGE_LOG',
  'KPI_SETTINGS',
  'POS_PRODUCTIVITY',
  'STANDARD_RECOMMENDATION_CERTIFICATE',
  'MAIN_MEASURES',
  'POS_EVENT_RESEARCH',
  'PER_HOUR_PRODUCTIVITY',
  'EXCEPTIONS_TICKETS',
  'EXCEPTIONS_MIN_AMOUNT_TICKETS',
] as const

type Screen = typeof SCREENS[number]

const SETTINGS = [
  'INDEPENDENT_DESK',
  'ACTIVE_DESK',
  'ATTENDANCE',
  'SHIFT',
  'CASHIERS_DEVIATION',
  'REVENUE',
  'LOAD',
  'TRIANGULAR_FOR_ALERTS',
  'PER_HOUR_PRODUCTIVITY',
  'EXCEPTIONS_TICKETS',
  'EXCEPTIONS_MIN_AMOUNT_TICKETS',
] as const

type Setting = typeof SETTINGS[number]

const SCREEN_TO_SETTINGS: { [Key in typeof SCREENS[number]]: readonly typeof SETTINGS[number][] } = {
  CASHIER_ALERTS: [
    'ACTIVE_DESK',
    'ATTENDANCE',
    'CASHIERS_DEVIATION',
    'INDEPENDENT_DESK',
    'LOAD',
    'REVENUE',
    'SHIFT',
    'TRIANGULAR_FOR_ALERTS',
    'EXCEPTIONS_MIN_AMOUNT_TICKETS'
  ],
  DETAILED_USAGE_LOG: [],
  KPI_SETTINGS: [],
  MAIN_MEASURES: [],
  POS_AVAILABILITY: [],
  POS_EVENT_RESEARCH: [],
  POS_PRODUCTIVITY: [],
  RECOMMENDATIONS_AND_EFFICIENCY_MEASURES: [],
  STANDARD_RECOMMENDATION_CERTIFICATE: [],
  USAGE_LEVELS: [],
  EXCEPTIONS_TICKETS: [],
  EXCEPTIONS_MIN_AMOUNT_TICKETS: [],
  PER_HOUR_PRODUCTIVITY: ['ACTIVE_DESK'],
} as const

const COLORS = ['PURPLE', 'GREEN', 'YELLOW', 'RED'] as const

type Color = typeof COLORS[number]

const FREQUENCIES = [
  'ONCE_A_MONTH',
  'ONCE_A_WEEK',
  'ONCE_A_DAY',
  'ONCE_AN_HOUR',
  'ONE_TIME',
  'TWICE_A_DAY',
] as const

type Frequency = typeof FREQUENCIES[number]

type AlertDefinition = {
  id: number
  screen: Screen
  setting: Setting | ActionSelection[]
  minColor: Color
  minAmount: number
  frequency: Frequency
  username: string
  whatsapp: boolean
  sms: boolean
  email: boolean
  eyedo: boolean
}

type AlertDTO = {
  screen: Screen
  setting: Setting | ActionSelection[]
  minimumColor: Color
  minimumAmount: number
  frequency: Frequency
  username: string
  email: boolean
  whatsapp: boolean
  sms: boolean
  eyedo: boolean
}

type UserSeletorProps = {
  value?: string
  onSelect?: (user: User) => void
}

const UserSelector: React.VFC<UserSeletorProps> = ({ onSelect, value }) => {
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)
  const searchElement = useRef<HTMLInputElement>(null)

  const [users, setUsers] = useState<User[]>([])
  const [search, setSearch] = useState<string>()
  const [loading, setLoading] = useState(true)

  const searchObject = useRef<SearchComponent>()

  useEffect(() => {
    const source = axios.CancelToken.source()
      ; (async () => {
        const response = await axios.get<User[]>(`/users`)
        setUsers(response.data)
        setLoading(false)
      })()
    return () => source.cancel()
  }, [])

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      console.log('Works?', event.key)
      if (event.key === 'Escape') {
        event.preventDefault()
        searchObject.current?.hide()
      }
    }
    element.current?.addEventListener('keydown', listener)

    const { current } = element

    return () => current?.removeEventListener('keydown', listener)
  })


  const processs = function () {
    setSearch(searchObject.current?.inputElement.value)
    searchObject.current?.complete()
  }

  const filteredUsers = search ? users.filter((user) => user.username.includes(search)) : users

  const clear = () => {
    setSearch(undefined)
  }

  useEffect(() => {
    // Initialize search handler
    searchObject.current = SearchComponent.createInsance('#kt_header_search')

    // Search handler
    searchObject!.current!.on('kt.search.process', processs)

    // Clear handler
    searchObject!.current!.on('kt.search.clear', clear)

    searchObject!.current!.menuObject?.on('kt.menu.dropdown.shown', () =>
      searchElement.current?.focus()
    )
  }, [])

  const onUserSelected = (index: number) => {
    onSelect?.(filteredUsers[index])
    searchObject.current?.hide()
  }

  const intl = useIntl()

  return (
    <div
      id='kt_header_search'
      className='form-control p-0'
      data-kt-search-keypress='true'
      data-kt-search-enter='enter'
      data-kt-search-layout='menu'
      data-kt-menu-trigger='auto'
      data-kt-menu-overflow='false'
      data-kt-menu-permanent='true'
      data-kt-menu-placement='bottom-end'
      ref={element}
    >
      <div
        className='d-flex align-items-center btn btn-icon btn-active-light-primary w-100 h-100 px-4 form-select'
        data-kt-search-element='toggle'
        id='kt_header_search_toggle'
      >
        <div className='flex-grow-1 text-start'>{value}</div>
      </div>

      <div
        data-kt-search-element='content'
        className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
      >
        <div ref={wrapperElement} data-kt-search-element='wrapper'>
          <form
            data-kt-search-element='form'
            className='w-100 position-relative mb-3'
            autoComplete='off'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
            />

            <input
              ref={searchElement}
              disabled={loading}
              type='text'
              className='form-control form-control-flush ps-10'
              name='search'
              placeholder={intl.formatMessage({ id: 'GENERAL.SEARCH' })}
              data-kt-search-element='input'
            />

            <span
              className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
              data-kt-search-element='spinner'
            >
              <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
            </span>

            <span
              className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none text-end'
              data-kt-search-element='clear'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon-2 svg-icon-lg-1 me-0'
              />
            </span>
          </form>
          {filteredUsers.length > 0 && (
            <select
              onChange={(e) => onUserSelected(parseInt(e.target.value))}
              className='form-select'
              size={5}
              data-kt-search-element='results'
            >
              {filteredUsers.map((user, i) => (
                <option
                  selected={user.username === value}
                  className='user-option'
                  key={i}
                  value={i}
                >
                  {user.username}
                </option>
              ))}
            </select>
          )}
          {filteredUsers.length === 0 && !loading && (
            <div ref={emptyElement} data-kt-search-element='empty' className='text-center'>
              <div className='pt-10 pb-10'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50'
                />
              </div>

              <div className='pb-15 fw-bold overflow-hidden text-break'>
                <h3 className='text-gray-600 fs-5 mb-2'>
                  <FormattedMessage id='ALERTS_SCREEN.USER_SEARCH.NO_RESULTS' />
                </h3>
                <div className='text-muted fs-7'>
                  <FormattedMessage id='ALERTS_SCREEN.USER_SEARCH.PLEASE_TRY_AGAIN_WITH_DIFFERET_QUREY' />
                </div>
              </div>
            </div>
          )}

          {loading && <div>Loading...</div>}
        </div>
      </div>
    </div>
  )
}

type User = {
  username: string
}

type AlertModalProps = {
  title: string
  confirmText: string
  onConfirm: (alert: AlertDTO) => void
  show: boolean
  onHide: () => void
  value: Partial<AlertDTO>
}

const AlertEditorModal: React.VFC<AlertModalProps> = ({
  onConfirm: onCreate,
  show,
  onHide,
  value,
  confirmText,
  title,
}) => {
  const [alert, setAlert] = useState(value)
  const [selectedActions, setSelectedActions] = useState<ActionSelection[]>(
    Array.isArray(value.setting) ? value.setting : []
  )
  const [actionsList, setActionsList] = useState<ActionSelection[]>([])

  const handleSelectedActionChange = (selectedActions: ActionSelection[]) => {
    setSelectedActions(selectedActions)
    setAlert((prevAlert) => ({
      ...prevAlert,
      setting: selectedActions,
    }))
  }

  const alertValid = (alert: Partial<AlertDTO>): alert is AlertDTO =>
    (alert.username !== undefined &&
      alert.screen !== undefined &&
      alert.setting !== undefined &&
      alert.minimumColor !== undefined) ||
    (alert.minimumAmount !== undefined &&
      alert.frequency !== undefined &&
      !!(alert.email || alert.whatsapp || alert.sms || alert.eyedo))

  const handleHide = () => {
    onHide()
    if (!value.setting) {
      setSelectedActions([])
    }
    if (Array.isArray(value.setting) && value.setting) {
      setSelectedActions(value.setting)
    }
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (alertValid(alert)) {
      onHide()
      onCreate(alert)
      if (!value.setting) {
        setSelectedActions([])
      }
    }
  }

  const cancelClicked = () => {
    if (!value.setting) {
      setSelectedActions([])
    }
    if (Array.isArray(value.setting) && value.setting) {
      setSelectedActions(value.setting)
    }
    onHide()
  }

  const alertPropertyChanged = <T extends keyof AlertDTO>(key: T, value: AlertDTO[T]) => {
    if (key === 'screen') {
      setAlert({ ...alert, screen: value as AlertDTO['screen'], setting: undefined })
    } else {
      setAlert({ ...alert, [key]: value })
    }
  }

  useEffect(() => setAlert(value), [show, value])
  useEffect(() => {
    const cancel = axios.CancelToken.source()
      ; (async () => {
        const { data } = await axios.get<ActionSelection[]>('/actions/actions-list', {
          cancelToken: cancel.token,
        })
        setActionsList(data)
      })()
    return () => cancel.cancel()
  }, [])
  const readyForSubmittion = alertValid(alert)

  const intl = useIntl()

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton={true}>
        <h1 className='mb-3'>{title}</h1>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <label className='input-group-text' htmlFor='selectScreen'>
                <FormattedMessage id='ALERTS_SCREEN.SCREEN' />
              </label>
            </div>
            <select
              className='form-select'
              id='selectScreen'
              value={alert.screen}
              onChange={(e) => alertPropertyChanged('screen', e.target.value as any)}
            >
              <option selected hidden>
                {intl.formatMessage({ id: 'ALERTS_SCREEN.SELECT_SCREEN' })}
              </option>
              {SCREENS.filter(
                (screen) => SCREEN_TO_SETTINGS[screen].length > 0 || screen === 'EXCEPTIONS_TICKETS' || screen === 'EXCEPTIONS_MIN_AMOUNT_TICKETS'
              ).map((screen, i) => (
                <option key={i} value={screen}>
                  {intl.formatMessage({ id: `ALERTS_SCREEN.SCREENS.${screen}` })}
                </option>
              ))}
            </select>
          </div>
          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <label className='input-group-text' htmlFor='selectSetting'>
                <FormattedMessage id='ALERTS_SCREEN.SETTING' />
              </label>
            </div>

            {alert.screen === 'EXCEPTIONS_TICKETS' || alert.screen === 'EXCEPTIONS_MIN_AMOUNT_TICKETS' ? (
              <div style={{ width: '89%', zIndex: 4 }}>
                <ActionSelector
                  selectedActions={selectedActions}
                  selectorName={intl.formatMessage({ id: 'GENERAL.SELECT_ACTIONS' })}
                  onSelectedActionChange={handleSelectedActionChange}
                  actions={actionsList}
                />
              </div>
            ) : (
              <select
                disabled={!alert.screen}
                className='form-select'
                id='selectSetting'
                value={alert.setting as string | undefined}
                onChange={(e) => alertPropertyChanged('setting', e.target.value as any)}
              >
                {!alert.screen && (
                  <option selected hidden>
                    {intl.formatMessage({ id: 'ALERTS_SCREEN.FIRST_SELECT_SCREEN' })}
                  </option>
                )}
                {alert.screen && (
                  <>
                    {!alert.setting && (
                      <option selected hidden>
                        {intl.formatMessage({ id: 'ALERTS_SCREEN.SELECT_SETTING' })}
                      </option>
                    )}

                    {SCREEN_TO_SETTINGS[alert.screen].map((setting, i) => (
                      <option key={i} value={setting}>
                        {intl.formatMessage({ id: `ALERTS_SCREEN.SETTINGS.${setting}` })}
                      </option>
                    ))}
                  </>
                )}
              </select>
            )}
          </div>
          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <label className='input-group-text' htmlFor='selectColor'>
                <FormattedMessage id='ALERTS_SCREEN.MINIMUM_COLOR' />
              </label>
            </div>
            <select
              className='form-select'
              id='selectColor'
              value={alert.minimumColor}
              onChange={(e) => alertPropertyChanged('minimumColor', e.target.value as any)}
              disabled={alert.screen === 'EXCEPTIONS_TICKETS' || alert.screen === 'EXCEPTIONS_MIN_AMOUNT_TICKETS'}
            >
              {!alert.minimumColor && (
                <option selected hidden>
                  {intl.formatMessage({ id: 'ALERTS_SCREEN.SELECT_COLOR' })}
                </option>
              )}
              {COLORS.map((color, i) => (
                <option key={i} value={color}>
                  {intl.formatMessage({ id: `ALERTS_SCREEN.COLORS.${color}` })}
                </option>
              ))}
            </select>
          </div>
          {(alert.screen === 'EXCEPTIONS_TICKETS' || alert.screen === 'EXCEPTIONS_MIN_AMOUNT_TICKETS') && (
            <div className='input-group mb-3'>
              <div className='input-group-prepend'>
                <label className='input-group-text' htmlFor='selectColor'>
                  <FormattedMessage id='ALERTS_SCREEN.MINIMUM_AMOUNT' />
                </label>
              </div>

              <input
                type='text'
                style={{ width: '78%' }}
                value={alert.minimumAmount}
                placeholder={alert.screen === 'EXCEPTIONS_MIN_AMOUNT_TICKETS' ? '100' : ''}
                onChange={(e) => alertPropertyChanged('minimumAmount', e.target.value as any)}
              />

            </div>
          )}
          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <label className='input-group-text' htmlFor='selectFrequency'>
                <FormattedMessage id='ALERTS_SCREEN.FREQUENCY' />
              </label>
            </div>
            <select
              className='form-select'
              id='selectFrequency'
              value={alert.frequency}
              onChange={(e) => alertPropertyChanged('frequency', e.target.value as any)}
            >
              {!alert.frequency && (
                <option selected hidden>
                  {intl.formatMessage({ id: 'ALERTS_SCREEN.SELECT_FREQUENCY' })}
                </option>
              )}
              {FREQUENCIES.map((frequency, i) => (
                <option key={i} value={frequency}>
                  {intl.formatMessage({ id: `ALERTS_SCREEN.FREQUENCIES.${frequency}` })}
                </option>
              ))}
            </select>
          </div>
          <div className='input-group mb-2'>
            <div className='input-group-prepend'>
              <div className='input-group-text'>
                <FormattedMessage id='ALERTS_SCREEN.USER' />
              </div>
            </div>
            <UserSelector
              onSelect={(user) => alertPropertyChanged('username', user.username)}
              value={alert?.username}
            />
          </div>
          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <label className='input-group-text h-100'>
                <FormattedMessage id='ALERTS_SCREEN.SEND_WITH' />
              </label>
            </div>
            <div className='form-control'>
              <div className='form-check form-switch mb-1'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='emailCheckbox'
                  onChange={(e) => alertPropertyChanged('email', e.target.checked)}
                  checked={alert.email}
                />
                <label className='form-check-label' htmlFor='emailCheckbox'>
                  <FormattedMessage id='ALERTS_SCREEN.SEND_WITH.EMAIL' />
                </label>
              </div>
              <div className='form-check form-switch mb-1'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='smsCheckbox'
                  onChange={(e) => alertPropertyChanged('sms', e.target.checked)}
                  checked={alert.sms}
                />
                <label className='form-check-label' htmlFor='smsCheckbox'>
                  <FormattedMessage id='ALERTS_SCREEN.SEND_WITH.SMS' />
                </label>
              </div>
              {/* <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='whatsappCheckbox'
                  onChange={(e) => alertPropertyChanged('whatsapp', e.target.checked)}
                  checked={alert.whatsapp}
                />
                <label className='form-check-label' htmlFor='whatsappCheckbox'>
                  <FormattedMessage id='ALERTS_SCREEN.SEND_WITH.WHATSAPP' />
                </label>
              </div> */}
              <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='eyedoCheckbox'
                  onChange={(e) => alertPropertyChanged('eyedo', e.target.checked)}
                  checked={alert.eyedo}
                  disabled={alert.screen === 'EXCEPTIONS_TICKETS' || alert.screen === 'EXCEPTIONS_MIN_AMOUNT_TICKETS'}
                />
                <label className='form-check-label' htmlFor='eyedoCheckbox'>
                  <FormattedMessage id='ALERTS_SCREEN.SEND_WITH.EYEDO' />
                </label>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center mb-2 mx-10 gap-2'>
            <Button
              className='flex-grow-1'
              variant='secondary'
              type='button'
              onClick={cancelClicked}
            >
              <FormattedMessage id='ALERTS_SCREEN.CANCEL' />
            </Button>
            <Button
              disabled={!readyForSubmittion}
              className='flex-grow-1'
              variant='primary'
              type='submit'
            >
              {confirmText}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

const DeleteWithConfirmationButton: React.FC<{ onDeleteAlert: () => void }> = ({
  children,
  onDeleteAlert,
}) => {
  const [showConfirm, setShowConfirm] = useState(false)

  const handleDeleteClick = () => setShowConfirm(true)
  const handleNo = () => setShowConfirm(false)
  const handleYes = () => {
    setShowConfirm(false)
    onDeleteAlert()
  }
  return (
    <>
      <Button variant='danger' onClick={handleDeleteClick}>
        {children}
      </Button>
      <Modal centered show={showConfirm} onHide={handleNo} className='w-100'>
        <Modal.Header>
          <h3 className='w-100 h3 text-center'>
            <FormattedMessage id='ALERTS_SCREEN.ARE_YOU_SURE_YOU_WANT_TO_DELETE' />
          </h3>
        </Modal.Header>
        <Modal.Body className='d-flex gap-5 mx-20'>
          <Button className='flex-grow-1 d-block' variant='primary' onClick={handleYes}>
            <FormattedMessage id='GENERAL.YES' />
          </Button>
          <Button className='flex-grow-1' variant='secondary' onClick={handleNo}>
            <FormattedMessage id='GENERAL.NO' />
          </Button>
        </Modal.Body>
      </Modal>
    </>
  )
}

type EditAlertProps = {
  alert: AlertDTO
  onEditAlert: (alert: AlertDTO) => void
}

const EditAlertButton: React.FC<EditAlertProps> = ({ children, alert, onEditAlert }) => {
  const [showModal, setShowModal] = useState(false)
  const onShow = () => {
    setShowModal(true)
  }
  const onHide = () => {
    setShowModal(false)
  }
  const intl = useIntl()

  return (
    <>
      <Button variant='light' onClick={onShow}>
        {children}
      </Button>
      <AlertEditorModal
        title={intl.formatMessage({ id: 'ALERTS_SCREEN.EDIT' })}
        confirmText={intl.formatMessage({ id: 'ALERTS_SCREEN.SAVE' })}
        onHide={onHide}
        onConfirm={onEditAlert}
        value={alert}
        show={showModal}
      />
    </>
  )
}

export const AlertsManagementPage: React.VFC = () => {
  const [loading, setLoading] = useState(true)
  const [alerts, setAlerts] = useState<AlertDefinition[]>([])
  const [error, setError] = useState<string>()
  const [showNewAlertModal, setShowNewAlertModal] = useState(false)
  const [filter, setFilter] = useState<{ search?: string }>({})
  const [filteredAlerts, setFilteredAlerts] = useState<AlertDefinition[]>([])

  const onNewAlertModalHide = () => {
    setShowNewAlertModal(false)
  }

  useEffect(() => {
    sendlog(logEnum.alertsManagmentScreen)
  }, [])

  const updateFilter = <T extends keyof typeof filter>(key: T, value: typeof filter[T]) => {
    setFilter({ ...filter, [key]: value })
  }

  const deleteAlert = useCallback(
    async (id: number) => {
      try {
        setLoading(true)
        await axios.delete(`/alerts/${id}`)
        const alertIndex = alerts.findIndex((alert) => alert.id === id)
        if (alertIndex > -1) {
          alerts.splice(alertIndex, 1)
          setAlerts([...alerts])
        }
        setLoading(false)
      } catch (e) {
        setError((e as any).toString())
      }
    },
    [alerts]
  )

  const loadAlerts = async (cancelToken?: CancelToken) => {
    setLoading(true)
    const response = await axios.get<AlertDefinition[]>('/alerts', { cancelToken: cancelToken })
    setAlerts(response.data)
    setLoading(false)
  }

  const createAlert = useCallback(
    async (newAlert: AlertDTO) => {
      setLoading(true)
      try {
        const response = await axios.post<AlertDefinition>('/alerts', newAlert)
        alerts.push(response.data)
        setAlerts([...alerts])
      } catch (e) {
        setError(`Error while creating alert: ${e}`)
      }
      setLoading(false)
    },
    [alerts]
  )

  const updateAlert = async (id: number, updatedAlert: AlertDTO) => {
    setLoading(true)
    try {
      const response = await axios.put<AlertDefinition>(`/alerts/${id}`, updatedAlert)
      const index = alerts.findIndex((alert) => alert.id === id)
      alerts[index] = response.data
      setAlerts([...alerts])
    } catch (e) {
      setError(`Error while updating alert: ${e}`)
    }
    setLoading(false)
  }

  useEffect(() => {
    const source = axios.CancelToken.source()
    loadAlerts(source.token)
    return () => source.cancel()
  }, [])

  const intl = useIntl()

  useEffect(() => {
    setFilteredAlerts(
      alerts &&
      alerts?.filter((alert) => {
        return (
          !filter.search ||
          alert.username.toLowerCase().includes(filter.search.toLowerCase()) ||
          intl
            .formatMessage({ id: `ALERTS_SCREEN.SCREENS.${alert.screen}` })
            .toLowerCase()
            .includes(filter.search.toLowerCase()) ||
          intl
            .formatMessage({ id: `ALERTS_SCREEN.SETTINGS.${alert?.setting}` })
            .toLowerCase()
            .includes(filter.search.toLowerCase()) ||
          intl
            .formatMessage({ id: `ALERTS_SCREEN.COLORS.${alert?.minColor}` })
            .toLowerCase()
            .includes(filter.search.toLowerCase()) ||
          intl
            .formatMessage({ id: `ALERTS_SCREEN.FREQUENCIES.${alert.frequency}` })
            .toLowerCase()
            .includes(filter.search.toLowerCase())
        )
      })
    )
  }, [alerts, filter, intl])

  return (
    <>
      <AlertEditorModal
        title={intl.formatMessage({ id: 'ALERTS_SCREEN.NEW_ALERT' })}
        confirmText={intl.formatMessage({ id: 'ALERTS_SCREEN.CREATE' })}
        value={{}}
        onConfirm={createAlert}
        show={showNewAlertModal}
        onHide={onNewAlertModalHide}
      />

      <nav className='navbar navbar-light bg-light row justify-content-between'>
        <div className='col-auto' />
        <h1 className='breadcrumb-item text-muted col-auto'>
          <FormattedMessage id='SCREENS.ALERTS' />
        </h1>
        <span className='col-auto'>
          <a className='navbar-brand' href='/'>
            <img
              src={toAbsoluteUrl('/media/logos/retail_logo.png')}
              width='100'
              height='30'
              className='d-inline-block '
              alt=''
              loading='lazy'
            />
          </a>
        </span>
      </nav>
      <hr />
      <div className='row d-flex px-3 justify-content-between mb-3'>
        <FormGroup className='col-auto'>
          <input
            value={filter.search}
            onChange={(e) => updateFilter('search', e.target.value)}
            type='search'
            className='form-control'
            placeholder={intl.formatMessage({ id: 'ALERTS_SCREEN.SEARCH' })}
          />
        </FormGroup>
        <Button className='col-auto' onClick={() => setShowNewAlertModal(true)}>
          <i className='bi bi-plus' style={{ fontSize: '1.3rem' }} />
          <FormattedMessage id='ALERTS_SCREEN.NEW_ALERT' />
        </Button>
      </div>
      {error && (
        <div className='row'>
          <Alert variant='danger' dismissible={true} onClose={() => setError(undefined)}>
            {error}
          </Alert>
        </div>
      )}
      <div className='card row position-relative'>
        <div className='card-body py-3'>
          <div className='table-responsive tableFixhead'>
            <table className='table align-middle table-row-bordered gs-4 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <th className='min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light'>
                    <FormattedMessage id='ALERTS_SCREEN.SCREEN' />
                  </th>
                  <th className='min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light'>
                    <FormattedMessage id='ALERTS_SCREEN.SETTING' />
                  </th>
                  <th className='min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light'>
                    <FormattedMessage id='ALERTS_SCREEN.MINIMUM_COLOR' />
                  </th>
                  <th className='min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light'>
                    <FormattedMessage id='ALERTS_SCREEN.MINIMUM_AMOUNT' />
                  </th>
                  <th className='min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light'>
                    <FormattedMessage id='ALERTS_SCREEN.FREQUENCY' />
                  </th>
                  <th className='min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light'>
                    <FormattedMessage id='ALERTS_SCREEN.USER' />
                  </th>
                  {/* <th className='min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light'>
                    <FormattedMessage id='ALERTS_SCREEN.WHATSAPP' />
                  </th> */}
                  <th className='min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light'>
                    <FormattedMessage id='ALERTS_SCREEN.EMAIL' />
                  </th>
                  <th className='min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light'>
                    <FormattedMessage id='ALERTS_SCREEN.SMS' />
                  </th>
                  <th className='min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light'>
                    <FormattedMessage id='ALERTS_SCREEN.EYEDO' />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {filteredAlerts &&
                  filteredAlerts?.map((alert, i) => (
                    <tr key={i}>
                      <td>
                        <FormattedMessage id={`ALERTS_SCREEN.SCREENS.${alert.screen}`} />
                      </td>
                      <td>
                        {typeof alert.setting === 'string' ? (
                          <FormattedMessage id={`ALERTS_SCREEN.SETTINGS.${alert.setting}`} />
                        ) : (
                          Array.isArray(alert.setting) &&
                          alert.setting.map((s) => s.name).join(', ')
                        )}
                      </td>
                      <td>
                        {alert.minColor ? (
                          <FormattedMessage id={`ALERTS_SCREEN.COLORS.${alert.minColor}`} />
                        ) : null}
                      </td>
                      <td>{alert.minAmount}</td>
                      <td>
                        <FormattedMessage id={`ALERTS_SCREEN.FREQUENCIES.${alert.frequency}`} />
                      </td>
                      <td>{alert.username}</td>
                      <td>
                        <label className='form-check form-check-sm form-check-custom form-check-solid justify-content-center'>
                          <input
                            className='form-check-input pe-none'
                            readOnly
                            type='checkbox'
                            checked={alert.email}
                          />
                        </label>
                      </td>
                      <td>
                        <label className='form-check form-check-sm form-check-custom form-check-solid justify-content-center'>
                          <input
                            className='form-check-input pe-none'
                            readOnly
                            type='checkbox'
                            checked={alert.sms}
                          />
                        </label>
                      </td>
                      <td>
                        <label className='form-check form-check-sm form-check-custom form-check-solid justify-content-center'>
                          <input
                            className='form-check-input pe-none'
                            readOnly
                            type='checkbox'
                            checked={alert.eyedo}
                          />
                        </label>
                      </td>
                      <td>
                        <div className='d-flex gap-2'>
                          <EditAlertButton
                            onEditAlert={(updatedAlert) => updateAlert(alert.id, updatedAlert)}
                            alert={{
                              ...alert,
                              minimumColor: alert.minColor,
                              minimumAmount: alert?.minAmount,
                            }}
                          >
                            <FormattedMessage id='ALERTS_SCREEN.EDIT' />
                          </EditAlertButton>
                          <DeleteWithConfirmationButton onDeleteAlert={() => deleteAlert(alert.id)}>
                            <FormattedMessage id='ALERTS_SCREEN.DELETE' />
                          </DeleteWithConfirmationButton>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={`d-grid justify-content-center align-items-center w-100 h-100 ${!loading && 'd-none'
            }`}
          style={{ position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
        >
          <div className='spinner-border text-light' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      </div>
    </>
  )
}
