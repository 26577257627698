import { useIntl } from 'react-intl'
import { logEnum, sendlog } from '../../logs/sendLog'

interface Props {
  value: string,
  onStatusBy: (statusType: string) => void,
  disabled: boolean
}

const StatusBy = (props: Props) => {

  const onChange = (event: any) => {
    switch (event.target.value) {
      case 'independentDeskStatus':
        sendlog(logEnum.storeStatusByIndependent);
        break;
      case 'activeDeskStatus':
        sendlog(logEnum.storeStatusByExistEqualToActivePOS);
        break;
      case 'attendanceStatus':
        sendlog(logEnum.storeStatusByStandart);
        break;
      case 'shiftStatus':
        sendlog(logEnum.storeStatusByExistEqualToSchedule);
        break;
      case 'cashiersDeviationStatus':
        sendlog(logEnum.storeStatusByLoad);
        break;
      case 'revenueStatus':
        sendlog(logEnum.storeStatusByRevenue);
        break;
    }
    props.onStatusBy(event.target.value);
  }

  const intl = useIntl();

  const messages = {
    MEASURE_BY_SELF_CHECKOUT: intl.formatMessage({ id: "CASHIER_ALERTS_SCREEN.MEASURE_BY_SELF_CHECKOUT" }),
    MEASURE_BY_ACTIVE_DESK: intl.formatMessage({ id: "CASHIER_ALERTS_SCREEN.MEASURE_BY_ACTIVE_DESK" }),
    MEASURE_BY_STANDARD: intl.formatMessage({ id: "CASHIER_ALERTS_SCREEN.MEASURE_BY_STANDARD" }),
    MEASURE_BY_ATTENDANCE_OVER_ASSIGNMENT: intl.formatMessage({ id: "CASHIER_ALERTS_SCREEN.MEASURE_BY_ATTENDANCE_OVER_ASSIGNMENT" }),
    MEASURE_BY_LACK_OVER_EXCESS_CHASHIERS: intl.formatMessage({ id: "CASHIER_ALERTS_SCREEN.MEASURE_BY_LACK_OVER_EXCESS_CHASHIERS" }),
    MEASURE_BY_REVENUE: intl.formatMessage({ id: "CASHIER_ALERTS_SCREEN.MEASURE_BY_REVENUE" }),
    MEASURE_BY_FREE_TIME_PERCENTAGE: intl.formatMessage({ id: "CASHIER_ALERTS_SCREEN.MEASURE_BY_FREE_TIME_PERCENTAGE" }),
  };

  return (
    <div>
      <select
        className='form-select'
        data-control='select2'
        data-hide-search='true'
        data-kt-select2='true'
        data-placeholder='Select option'
        data-allow-clear='true'
        onChange={onChange}
        value={props.value}
        disabled={props.disabled}
      >
        <option value='independentDeskStatus'>{messages.MEASURE_BY_SELF_CHECKOUT}</option>
        <option value='activeDeskStatus'>{messages.MEASURE_BY_ACTIVE_DESK} </option>
        <option value='attendanceStatus'>{messages.MEASURE_BY_STANDARD}</option>
        <option value='shiftStatus'>{messages.MEASURE_BY_ATTENDANCE_OVER_ASSIGNMENT}</option>
        <option value='cashiersDeviationStatus'>{messages.MEASURE_BY_LACK_OVER_EXCESS_CHASHIERS}</option>
        <option value='revenueStatus'>{messages.MEASURE_BY_REVENUE}</option>
        <option value='loadStatus'>{messages.MEASURE_BY_FREE_TIME_PERCENTAGE}</option>
      </select>
    </div>
  )
}
export default StatusBy
