import { IFilterUsageLevel, IUsage } from '../interfaces/IStore'

export const showAllFilter = () => {
  return true
}
export const successFilter = (item: IUsage, index: number) => {
 // return resultByStatusType(1, item);
  return item.status === 1
}
export const oneCashierDeskInactiveFilter = (item: IUsage, index: number) => {
 // return resultByStatusType(2, item);
  return item.status === 2
}
export const fullCashierDeskInactive = (item: IUsage, index: number) => {
  //return resultByStatusType(3, item);
  return item.status === 3
}
export const upThan100 = (item: IUsage, index: number) => {
  //return resultByStatusType(4, item);
  return item.status === 4
}

export const filtersUsageLevel: IFilterUsageLevel[] = [
  { status: 0, des: 'show all', filter: showAllFilter },
  { status: 1, des: 'full success', filter: successFilter },
  { status: 2, des: 'one casherDeskInactive', filter: oneCashierDeskInactiveFilter },
  { status: 3, des: 'full cashierDeskCountInactive', filter: fullCashierDeskInactive },
  { status: 4, des: 'up than 100', filter: upThan100 },
]
