import { LogMessage, LOG_MESSAGE_TO_MESSAGE_ID } from 'app/modules/logs/sendLog';
import React from 'react'
import { IntlShape, useIntl } from 'react-intl';

type Props = {
  userName: string;
  date: string;
  actionType: LogMessage;
}

const translateActionType = (actionType: LogMessage, intl: IntlShape) => {
  if (actionType in LOG_MESSAGE_TO_MESSAGE_ID) {
    const messageDescription = LOG_MESSAGE_TO_MESSAGE_ID[actionType];
    const values = (messageDescription.translateValues &&
      Object.keys(messageDescription.translateValues)
        .map(key => ({ [key]: intl.formatMessage({ id: messageDescription.translateValues![key] }) }))
        .reduce((p, c) => Object.assign(p, c), {})
    );
    return intl.formatMessage({ id: messageDescription.id }, values);
  } else {
    return `<UNKNOWN_ACTION> (${actionType})`;
  }
}

const LogRaw: React.FC<Props> = ({
  userName,
  date,
  actionType,
}) => {
  let textColor = 'text-gray-600';

  const intl = useIntl();

  const actionTypeTranslated = translateActionType(actionType, intl);

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <span className={'fw-bolder mb-1 fs-6 ' + textColor} >
              {userName}
            </span>
          </div>
        </div>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {date}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 ' + textColor}>
          {actionTypeTranslated}
        </span>
      </td>
    </tr>
  )
}
export { LogRaw }
