import { CardPOSEventResearch } from './CardPOSEventResearch'
import { POS_EVENT_RESEARCH_SCREEN_STATUS_TYPE } from '../../auth/redux/AuthCRUD'
import { IPOSEventResearch, IPOSEventResearchCollection } from '../interfaces/IPOSEventResearchCollection'

interface Props {
  collection: IPOSEventResearchCollection[],
  store: IPOSEventResearch,
  weekNum:number
}

const listColor = [
  { status: 1, color: '#50cd89', icon: 'fa-smile-beam' },
  { status: 2, color: '#ffc700', icon: 'fas fa-exclamation-circle' },
  { status: 3, color: '#f1416c', icon: 'fa-times-circle' },
  { status: 4, color: '#6f42c1', icon: 'fa-times-circle' },
]

const Store = (props: Props) => {
  const store: IPOSEventResearch = props.store
  const weekNum: number = props.weekNum
  let colorStyle;
  switch (POS_EVENT_RESEARCH_SCREEN_STATUS_TYPE) {
    case 'overLoadCashierStatus':
      colorStyle = listColor.find((item) => item.status === props.store.overLoadCashierStatus)?.color
      break;
    case 'underLoadCashierStatus':
      colorStyle = listColor.find((item) => item.status === props.store.underLoadCashierStatus)?.color
      break;
  }

  return (
    <div style={{ width: "fit-content" }}>
      <CardPOSEventResearch
        color={colorStyle}
        store={store}
        weekNum={weekNum}
        collection={props.collection}
      />
    </div>
  )
}
export default Store
