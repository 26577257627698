import { useEffect, useRef } from "react";
import { CloseButton, Spinner } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { SearchComponent } from "_metronic/assets/ts/components";
import { KTSVG } from "_metronic/helpers";

type SelectorProps = {
    allowClear: boolean,
    value?: { label?: string, value: string },
    options: { value: string, label: string }[],
    onSelect: (selected: string) => any,
    onFilter: (search: string) => any | Promise<any>,
    onClear?: () => any,
    loading?: boolean,
    placeholder?: string,
}

export const Selector: React.VFC<SelectorProps> = ({
    loading: loadingProp,
    value,
    options,
    onFilter,
    onSelect,
    placeholder,
    allowClear,
    onClear,
}) => {
    const loading = loadingProp ?? false;
    const element = useRef<HTMLDivElement | null>(null);
    const wrapperElement = useRef<HTMLDivElement | null>(null);
    const emptyElement = useRef<HTMLDivElement | null>(null);
    const searchElement = useRef<HTMLInputElement>(null);
    const selectElement = useRef<HTMLSelectElement>(null);

    const searchObject = useRef<SearchComponent>();

    const processs = async () => {
        await onFilter(searchObject.current?.inputElement.value || "");
        searchObject.current?.complete();
    }

    const clear = () => onFilter("");

    useEffect(() => {
        // Initialize search handler
        searchObject.current = SearchComponent.createInsance('#kt_header_search');

        // Search handler
        searchObject!.current!.on('kt.search.process', processs)

        // Clear handler
        searchObject!.current!.on('kt.search.clear', clear)

        searchObject!.current!.menuObject?.on("kt.menu.dropdown.shown", () => searchElement.current?.focus());
    });

    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                event.preventDefault();
                searchObject.current?.hide();
            }
        }
        element.current?.addEventListener("keydown", listener);

        const { current } = element;

        return () => current?.removeEventListener("keydown", listener);
    });

    const handleSelect = () => {
        const selection = selectElement.current?.value!;
        onSelect(selection);
        searchObject.current?.hide();
        if (searchElement.current) {
            searchElement.current.value = "";
            onFilter("");
        }
    };

    const intl = useIntl();

    const clearClicked = () => {
        onClear?.();
        searchObject.current?.hide();
    }

    const selectedOption = options.find(option => option.value === value?.value);

    useEffect(() => {
        if (value !== undefined && !selectedOption) {
            if (allowClear) {
                onClear?.();
            }
        }
    }, [value, selectedOption, onClear, allowClear]);


    return (
        <div
            id='kt_header_search'
            className='form-control p-0 h-100'
            data-kt-search-keypress='true'
            data-kt-search-enter='enter'
            data-kt-search-layout='menu'
            data-kt-menu-trigger='auto'
            data-kt-menu-overflow='false'
            data-kt-menu-permanent='true'
            data-kt-menu-placement='bottom-end'
            ref={element}
        >
            <div
                className='d-flex align-items-center w-100 h-100 btn btn-active-light-primary p-0'
                data-kt-search-element='toggle'
                id='kt_header_search_toggle'
            >
                <div className="flex-grow-1 form-select border-0 bg-transparent">
                    <div className="text-start">
                        {allowClear && value === undefined
                            ? <span className="text-muted">{placeholder}</span>
                            : value?.label || selectedOption?.label || (loading ? <Spinner animation="border" /> : value)}</div>
                </div>
                {allowClear && value !== undefined && <CloseButton className="me-3" onClick={clearClicked} />}
            </div>

            <div
                data-kt-search-element='content'
                className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
            >
                <div
                    ref={wrapperElement}
                    data-kt-search-element='wrapper'
                >
                    <form
                        data-kt-search-element='form'
                        className='w-100 position-relative mb-3'
                        autoComplete='off'
                        onSubmit={e => e.preventDefault()}
                    >
                        <KTSVG
                            path='/media/icons/duotune/general/gen021.svg'
                            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
                        />

                        <input
                            ref={searchElement}
                            disabled={loading}
                            type='text'
                            className='form-control form-control-flush ps-10'
                            name='search'
                            placeholder={intl.formatMessage({ id: "GENERAL.SEARCH" })}
                            data-kt-search-element='input'
                        />

                        <span
                            className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                            data-kt-search-element='spinner'
                        >
                            <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
                        </span>

                        <span
                            className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none text-end'
                            data-kt-search-element='clear'
                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon-2 svg-icon-lg-1 me-0'
                            />
                        </span>
                    </form>
                    {options.length > 0 &&
                        <select
                            ref={selectElement}
                            onChange={handleSelect}
                            value={value?.value || ""}
                            className="form-select"
                            size={5}
                            data-kt-search-element='results'
                        >
                            {options.map((option, i) => (<option className="user-option" key={i} value={option.value} >{option.label}</option>))}
                        </select>}
                    {options.length === 0 && !loading && <div ref={emptyElement} data-kt-search-element='empty' className='text-center'>
                        <div className='pt-10 pb-10'>
                            <KTSVG
                                path='/media/icons/duotune/files/fil024.svg'
                                className='svg-icon-4x opacity-50'
                            />
                        </div>

                        <div className='pb-15 fw-bold overflow-hidden text-break'>
                            <h3 className='text-gray-600 fs-5 mb-2'>
                                <FormattedMessage id="ALERTS_SCREEN.USER_SEARCH.NO_RESULTS" />
                            </h3>
                            <div className='text-muted fs-7'>
                                <FormattedMessage id="ALERTS_SCREEN.USER_SEARCH.PLEASE_TRY_AGAIN_WITH_DIFFERET_QUREY" />
                            </div>
                        </div>
                    </div>}
                    {loading && <div>Loading...</div>}
                </div>
            </div>
        </div>
    )
}
