import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl";
import { LoadPerHour } from "../interfaces/ITableRow";

interface Props {
    onInputMorningPrecent: (precent: number) => void;
    onInputEveningPrecent: (precent: number) => void;
    morningCashiers: number;
    eveningCashiers: number;
    minutePerCahsier: number;
    loadByMinutes: LoadPerHour[],
    morningShiftLastHour: number;
}


const LoadPrecentConfig: React.FC<Props> = ({
    minutePerCahsier,
    loadByMinutes,
    eveningCashiers,
    morningCashiers,
    morningShiftLastHour,
    onInputEveningPrecent,
    onInputMorningPrecent
}) => {
    const [morningRangeValue, setMorningRangeValue] = useState(0)
    const [eveningRangeValue, setEveningRangeValue] = useState(0)

    const setMorningValue = (event: any) => {
        onInputMorningPrecent(parseInt(event.target.value))
    }
    const setEveningValue = (event: any) => {
        onInputEveningPrecent(parseInt(event.target.value))
    }

    useEffect(() => {
        if (morningShiftLastHour !== 0 && loadByMinutes.length > 0) {
            let morningMaxHour = Math.max(...loadByMinutes.filter(l => l.hour <= morningShiftLastHour).map(m => m.loadByMinutes));
            let eveningMaxHour = Math.max(...loadByMinutes.filter(l => l.hour > morningShiftLastHour).map(m => m.loadByMinutes));
            let morningValue = Math.max(Math.trunc(morningMaxHour / ((minutePerCahsier * morningCashiers) / 100)), 1);
            let eveningValue = Math.max(Math.trunc(eveningMaxHour / ((minutePerCahsier * eveningCashiers) / 100)), 1);
            setMorningRangeValue(isNaN(morningValue) ? 0 : morningValue);
            setEveningRangeValue(isNaN(eveningValue) ? 0 : eveningValue);
        }
    }, [morningShiftLastHour, loadByMinutes, minutePerCahsier, morningCashiers, eveningCashiers])

    return (
        <div className='card'>
            <div className='row container'>
                <h3 className='card-title text-start flex-column py-10'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                        <FormattedMessage id="RECOMMENDATIONS_SCREEN.SET_LOAD_PERCENTAGE" />
                    </span>
                </h3>
                <div className="pb-10">
                    <div className='text-center' style={{ float: "left", width: "50%" }}>
                        <div className='col-12'>
                            <h4>
                                <span>
                                    <FormattedMessage id="RECOMMENDATIONS_SCREEN.MORNING_SHIFT" />
                                    :
                                </span>
                                &nbsp;
                                <span>
                                    {morningRangeValue}%
                                </span>
                            </h4>
                        </div>
                        <input value={morningRangeValue} type='range' min={1} className='col-8' onInput={setMorningValue} />
                    </div>
                    <div className='text-center' style={{ float: "right", width: "50%" }}>
                        <div className='col-12'>
                            <h4>
                                <span>
                                    <FormattedMessage id="RECOMMENDATIONS_SCREEN.EVENING_SHIFT" />
                                    :
                                </span>
                                &nbsp;
                                <span>
                                    {eveningRangeValue}%
                                </span>
                            </h4>
                        </div>
                        <input value={eveningRangeValue} type='range' min={1} className='col-8' onInput={setEveningValue} />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
        </div>
    )
}
export default LoadPrecentConfig