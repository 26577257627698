import axios from 'axios'
import { IMenuItem, IScreen } from '../../auth/IScreen'
import { BtnContentEnum } from '../interfaces/btnContentEnum'
import { SettingModalRow } from '../interfaces/IsettingModalRow'

export const getSettings = async () => {
  try {
    return (await axios.get<SettingModalRow[]>("/setting")).data
  } catch (err) {
    return []
  }
}

export const getButtonsContent = async (url: string) => {
  return (await axios.get(`/setting/${url}`)).data;
}

export const getButtonsContentByName = async (value: BtnContentEnum) => {

  return (await axios.get("/setting/btn-type-name", { params: { settingType: value } })).data;
}

export const getMainSettingData = async () => {
  return (await axios.get("/setting/main-screen")).data;
}

export const getScreenNames = async () => {
  return (await axios.get<IScreen[]>(`/setting/screens`)).data;
}

export const getMenuScreen = async () => {
  return (await axios.get<IMenuItem[]>(`/setting/menuScreens`)).data;
}
