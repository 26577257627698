import { STATUS_TYPE } from 'app/modules/auth/redux/AuthCRUD';
import { useIntl } from 'react-intl';
import { IStore } from '../../stores/interfaces/IStore'
import { StatusTypeEnum } from '../interfaces/statusTypeEnum';
import PieChart from './PieChart';
interface props {
  stores: IStore[],
  displayGraph: boolean,
  onExitClick: () => any
}
export default function StoresList(props: props) {
  const stores = props.stores || [];
  const intl = useIntl();
  return (
    <div className='row justify-content-center g-6' id="main-list">
      <PieChart stores={stores} statusType={StatusTypeEnum.activeDeskStatus} selectedStatus={STATUS_TYPE} title={intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.ATTENDANCE_OVER_ASSIGNMENT" })} />
      <PieChart stores={stores} statusType={StatusTypeEnum.shiftStatus} selectedStatus={STATUS_TYPE} title={intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.ASSIGNMENT_OVER_ATTENDANCE" })} />
      <PieChart stores={stores} statusType={StatusTypeEnum.attendanceStatus} selectedStatus={STATUS_TYPE} title={intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.STANDARD_OVER_ATTENDANCE" })} />
      <PieChart stores={stores} statusType={StatusTypeEnum.independentDeskStatus} selectedStatus={STATUS_TYPE} title={intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.SCO_AVAILABILITY" })} />
      <PieChart stores={stores} statusType={StatusTypeEnum.cashiersDeviationStatus} selectedStatus={STATUS_TYPE} title={intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.LACK_EXCESS_ACTIVE_CASHIERS" })} />
      <PieChart stores={stores} statusType={StatusTypeEnum.loadStatus} selectedStatus={STATUS_TYPE} title={intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.FREE_TIME_PERCENTAGE" })} />
      <PieChart stores={stores} statusType={StatusTypeEnum.revenueStatus} selectedStatus={STATUS_TYPE} title={intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.REVENUE_LAST_WEEK_TODAY" })} />
    </div>
  )
}
