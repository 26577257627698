import { useIntl } from 'react-intl';
import { useIsRTL } from '_metronic/i18n/Metronici18n';

interface Props {
  value: string,
  onSearch: (search: string) => void
}

const SearchStore = (props: Props) => {
  const onChange = (event: any) => {
    props.onSearch(event.target.value);
  }
  const value = props.value;
  const intl = useIntl();
  const isRTL = useIsRTL();
  return (
    <div className='row'>
      <div className='mb-3'>
        <input
          type='text'
          value={value}
          onChange={onChange}
          className='form-control'
          id='inputSearch'
          dir={isRTL ? 'rtl' : 'ltr'}
          placeholder={intl.formatMessage({ id: "GENERAL.SEARCH_PLACEHOLDER" })}
        />
      </div>
    </div>
  );
}
export default SearchStore
