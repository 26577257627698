import axios from "axios";
import { SEND_LOG_URL, USER_NAME } from "../auth/redux/AuthCRUD";

export const logEnum = {
    //כללי
    cashierScreen: "מסך קופאיות",
    logIn: "כניסה",
    logOut: "יציאה",
    TrendAnalysisPage: "מסך ניתוח מגמות",
    productivityScreen: "מסך פעילות קופות לפי שעה",
    lineActionsScreen: "מסך שאילתת פעילות קופות חריגות",
    statusReportScreen: "מסך דוח עגלות",
    userListScreen: "מסך ניהול משתמשים",
    alertsManagmentScreen: "מסך תזמון התראות",
    productivityPerDayScreen: "מסך פריון כ\"א קו קופות",
    POSEventResearch: "מסך גרף מדדים משולב",
    storeScreen: "מסך חנויות",
    mainPieChartScreen: "מסך מחוונים ראשי",
    settingScreen: "מסך הגדרות ניהול",
    usageLevelScreen: "מסך רמות שימוש",
    usageLogScreen: "מסך לוג שימוש",
    loadPerStandardScreen: "מסך עומס לפי תקן",
    recommendedStandardScreen: "מסך תקן מומלץ",
    MaintenancePage: "מסך תחזוקה",


    //חנויות

    storesRedBtn: "חנויות  - כפתור אדום",
    storesYellowBtn: "חנויות  - כפתור צהוב",
    storesGreenBtn: "חנויות  - כפתור ירוק",
    storesPurpleBtn: "חנויות  - כפתור סגול",
    storesBlueBtn: "חנויות  - כפתור כחול",
    openGraph: "חנויות - פתיחת גרף",
    closeGraph: "חנויות - סגירת גרף",
    sortByWarningIcon: "חנויות  - פתיחת חיווי אזהרה",
    UnSortByWarningIcon: "חנויות  - סגירת חיווי אזהרה",

    storeStatusByIndependent: "חנויות - חיווי לפי שירות עצמי",
    storeStatusByExistEqualToActivePOS: "חנויות - חיווי לפי נוכחות/הצבה",
    storeStatusByStandart: "חנויות - חיווי לפי תקן",
    storeStatusByExistEqualToSchedule: "חנויות - חיווי לפי נוכחות/שיבוץ",
    storeStatusByLoad: "חנויות - חיווי לפי עומס",
    storeStatusByRevenue: "חנויות - חיווי לפי פדיון",

    //קופאיות

    cashiersRedBtn: "קופאיות  - כפתור אדום",
    cashiersYellowBtn: "קופאיות  - כפתור צהוב",
    cashiersGreenBtn: "קופאיות  - כפתור ירוק",
    cashiersPurpleBtn: "קופאיות  - כפתור סגול",
    cashiersBlueBtn: "קופאיות  - כפתור כחול",

    cashiersPerShift: "קופאיות - למשמרת",
    cashiersPerLastHour: "קופאיות - לשעה אחרונה",
    cashiersPerDay: "קופאיות - לכל היום",

    //רמות שימוש
    usageLevelRedBtn: "רמות שימוש  - כפתור אדום",
    usageLevelYellowBtn: "רמות שימוש  - כפתור צהוב",
    usageLevelGreenBtn: "רמות שימוש  - כפתור ירוק",
    usageLevelPurpleBtn: "רמות שימוש  - כפתור סגול",
    usageLevelBlueBtn: "רמות שימוש  - כפתור כחול",

    //עומס לפי תקן
    loadPerStandardRedBtn: "עומס לפי תקן - כפתור אדום",
    loadPerStandardYellowBtn: "עומס לפי תקן - כפתור צהוב",
    loadPerStandardGreenBtn: "עומס לפי תקן - כפתור ירוק",
    loadPerStandardPurpleBtn: "עומס לפי תקן - כפתור סגול",
    loadPerStandardBlueBtn: "עומס לפי תקן - כפתור כחול",

    //תקן מומלץ
    recommendedStandardRedBtn: "תקן מומלץ - כפתור אדום",
    recommendedStandardYellowBtn: "תקן מומלץ - כפתור צהוב",
    recommendedStandardGreenBtn: "תקן מומלץ - כפתור ירוק",
    recommendedStandardPurpleBtn: "תקן מומלץ - כפתור סגול",
    recommendedStandardBlueBtn: "תקן מומלץ - כפתור כחול",
    recommendedOpenGraph: "תקן מומלץ - פתיחת גרף",
    recommendedCloseGraph: "תקן מומלץ - סגירת גרף",

    recommendedDisplayAll: "תקן מומלץ - הצג הכל",
    recommendedWithIndepended: "תקן מומלץ - עם שירות עצמי",
    recommendedWithoutIndepended: "תקן מומלץ - בלי שירות עצמי",

    recommendedStatusByStandard: "תקן מומלץ - חיווי לפי תקן",
    recommendedStatusByproductivity: "תקן מומלץ - חיווי לפי פריון",
} as const;

export type LogMessage = typeof logEnum[keyof typeof logEnum];


export const LOG_MESSAGE_TO_MESSAGE_ID: Record<LogMessage, { id: string, translateValues?: Record<string, string> }> = {
    "כניסה": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.LOGIN" },
    "יציאה": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.LOGOUT" },
    "מסך חנויות": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_SCREEN" },
    "מסך פעילות קופות לפי שעה": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.PRODUCTIVITY_SCREEN" },
    "מסך מחוונים ראשי": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.MAIN_PIE_CHART_SCREEN" },
    "מסך שאילתת פעילות קופות חריגות": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.LINE_ACTIONS" },
    "מסך תחזוקה": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.MAINTANANCE" },
    "מסך דוח עגלות": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STATUS_REPORT_SCREEN" },
    "מסך ניתוח מגמות": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.TREND_ANALYSIS" },
    "מסך ניהול משתמשים": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.USER_LIST_SCREEN" },
    "מסך תזמון התראות": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.ALERT_MANAGMENT_SCREEN" },
    "מסך קופאיות": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.CASHIER_SCREEN" },
    "מסך הגדרות ניהול": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.MANAGEMENT_SETTINGS_SCREEN" },
    "מסך רמות שימוש": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.USAGE_LEVELS_SCREEN" },
    "מסך גרף מדדים משולב": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.POS_EVENT_RESEARCH" },
    "מסך פריון כ\"א קו קופות": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.PRODUCTIVITY_PER_DAY_SCREEN" },
    "מסך לוג שימוש": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.DETAILED_USAGE_LOG_SCREEN" },
    "מסך עומס לפי תקן": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.LOAD_BY_STANDARD_SCREEN" },
    "מסך תקן מומלץ": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_SCREEN" },
    "חנויות  - כפתור אדום": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_COLOR_BUTTON", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.RED" } },
    "חנויות  - כפתור צהוב": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_COLOR_BUTTON", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.YELLOW" } },
    "חנויות  - כפתור ירוק": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_COLOR_BUTTON", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.GREEN" } },
    "חנויות  - כפתור סגול": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_COLOR_BUTTON", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.PURPLE" } },
    "חנויות  - כפתור כחול": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_COLOR_BUTTON", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.BLUE" } },
    "חנויות - פתיחת גרף": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_EXPAND_GRAPH" },
    "חנויות - סגירת גרף": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_COLLAPSE_GRAPH" },
    "חנויות  - פתיחת חיווי אזהרה": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_ALERT_MEASURE_OPEN" },
    "חנויות  - סגירת חיווי אזהרה": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_ALERT_MEASURE_CLOSE" },
    "חנויות - חיווי לפי שירות עצמי": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_MEASURE_BY_SELF_CHECKOUT" },
    "חנויות - חיווי לפי נוכחות/הצבה": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_MEASURE_BY_ATTENDANCE_OVER_ASSIGNMENT" },
    "חנויות - חיווי לפי תקן": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_MEASURE_BY_STANDARD" },
    "חנויות - חיווי לפי נוכחות/שיבוץ": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_MEASURE_BY_ATTENDANCE_OVER_SCHEDULE" },
    "חנויות - חיווי לפי עומס": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_MEASURE_BY_LOAD" },
    "חנויות - חיווי לפי פדיון": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.STORES_MEASURE_BY_REVENUE" },
    "קופאיות  - כפתור אדום": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.CASHIERS_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.RED" } },
    "קופאיות  - כפתור צהוב": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.CASHIERS_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.YELLOW" } },
    "קופאיות  - כפתור ירוק": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.CASHIERS_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.GREEN" } },
    "קופאיות  - כפתור סגול": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.CASHIERS_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.PURPLE" } },
    "קופאיות  - כפתור כחול": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.CASHIERS_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.BLUE" } },
    "קופאיות - למשמרת": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.CASHIERS_FOR_SHIFT" },
    "קופאיות - לשעה אחרונה": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.CASHIERS_FOR_LAST_HOUR" },
    "קופאיות - לכל היום": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.CASHIERS_FOR_ALL_DAY" },
    "רמות שימוש  - כפתור אדום": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.USAGE_LEVELS_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.RED" } },
    "רמות שימוש  - כפתור צהוב": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.USAGE_LEVELS_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.YELLOW" } },
    "רמות שימוש  - כפתור ירוק": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.USAGE_LEVELS_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.GREEN" } },
    "רמות שימוש  - כפתור סגול": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.USAGE_LEVELS_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.PURPLE" } },
    "רמות שימוש  - כפתור כחול": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.USAGE_LEVELS_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.BLUE" } },
    "עומס לפי תקן - כפתור אדום": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.LOAD_BY_STANDARD_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.RED" } },
    "עומס לפי תקן - כפתור צהוב": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.LOAD_BY_STANDARD_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.YELLOW" } },
    "עומס לפי תקן - כפתור ירוק": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.LOAD_BY_STANDARD_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.GREEN" } },
    "עומס לפי תקן - כפתור סגול": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.LOAD_BY_STANDARD_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.PURPLE" } },
    "עומס לפי תקן - כפתור כחול": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.LOAD_BY_STANDARD_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.BLUE" } },
    "תקן מומלץ - כפתור אדום": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.RED" } },
    "תקן מומלץ - כפתור צהוב": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.YELLOW" } },
    "תקן מומלץ - כפתור ירוק": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.GREEN" } },
    "תקן מומלץ - כפתור סגול": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.PURPLE" } },
    "תקן מומלץ - כפתור כחול": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_BUTTON_COLOR", translateValues: { color: "DETAILED_USAGE_LOG_SCREEN.COLORS.BLUE" } },
    "תקן מומלץ - פתיחת גרף": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_EXPAND_GRAPH" },
    "תקן מומלץ - סגירת גרף": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_COLLAPSE_GRAPH" },
    "תקן מומלץ - הצג הכל": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_DISPLAY_ALL" },
    "תקן מומלץ - עם שירות עצמי": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_WITH_SELF_CHEKOUT" },
    "תקן מומלץ - בלי שירות עצמי": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_WITHOUT_SELF_CHEKOUT" },
    "תקן מומלץ - חיווי לפי תקן": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_MEASURE_BY_STANDARD" },
    "תקן מומלץ - חיווי לפי פריון": { id: "DETAILED_USAGE_LOG_SCREEN.ACTION.RECOMMENDED_STANDARD_MEASURE_BY_REVENUE" },
} as const


export function sendlog(message: LogMessage) {
    let obg = {
        actionType: message,
        userName: USER_NAME

    }
    axios.post(SEND_LOG_URL, obg);
}
