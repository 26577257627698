/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedMessage} from 'react-intl'
import {ICashier} from '../interfaces/ICashier'
import Cashier from './Cashier'
import {CashierColumn} from './Cashiers'

interface Props {
  cashiers: ICashier[]
  onTitleClick: (title: CashierColumn) => void
}
let design = 'min-w-110px fs-5 text-hover-primary fw-bolder mb-0 bg-light'
export default function CashiersList(props: Props) {
  const cashiers = props.cashiers || []
  function onClick(title: CashierColumn) {
    props.onTitleClick(title)
  }
  const listRender = cashiers.map((item, index) => {
    return <Cashier key={index} cashier={item}></Cashier>
  })

  return (
    <div className={`card`}>
      <div className='card-body py-3'>
        <div className='table-responsive tableFixHead'>
          <table className='table align-middle table-row-bordered gs-4 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th style={{cursor: 'pointer'}} onClick={() => onClick('store')} className={design}>
                  <FormattedMessage id='POS_AVAILABILITY_SCREEN.STORE' />
                </th>
                <th
                  style={{cursor: 'pointer'}}
                  onClick={() => onClick('pos_number')}
                  className={design}
                >
                  <FormattedMessage id='POS_AVAILABILITY_SCREEN.POS_NUMBER' />
                </th>
                <th
                  style={{cursor: 'pointer'}}
                  onClick={() => onClick('pos_type')}
                  className={design}
                >
                  <FormattedMessage id='POS_AVAILABILITY_SCREEN.POS_TYPE' />
                </th>
                <th
                  style={{cursor: 'pointer'}}
                  onClick={() => onClick('cashier_number')}
                  className={design}
                >
                  <FormattedMessage id='POS_AVAILABILITY_SCREEN.CASHIER_NUMBER' />
                </th>
                <th
                  style={{cursor: 'pointer'}}
                  onClick={() => onClick('transaction_count')}
                  className={design}
                >
                  <FormattedMessage id='POS_AVAILABILITY_SCREEN.TRANSACTION_COUNT' />
                </th>
                <th
                  style={{cursor: 'pointer'}}
                  onClick={() => onClick('revenue')}
                  className={design}
                >
                  <FormattedMessage id='POS_AVAILABILITY_SCREEN.REVENUE' />
                </th>
                <th
                  style={{cursor: 'pointer'}}
                  onClick={() => onClick('first_ticket_time')}
                  className={design}
                >
                  <FormattedMessage id='POS_AVAILABILITY_SCREEN.DATE_OF_FIRST_TICKET' />
                </th>
                <th
                  style={{cursor: 'pointer'}}
                  onClick={() => onClick('last_ticket_time')}
                  className={design}
                >
                  <FormattedMessage id='POS_AVAILABILITY_SCREEN.DATE_OF_LAST_TICKET' />
                </th>
                <th
                  style={{cursor: 'pointer'}}
                  onClick={() => onClick('time_since_last_ticket')}
                  className={design}
                >
                  <FormattedMessage id='POS_AVAILABILITY_SCREEN.TIME_FROM_LAST_TICKET' />
                </th>
                <th
                  style={{cursor: 'pointer'}}
                  onClick={() => onClick('gross_work')}
                  className={design}
                >
                  <FormattedMessage id='POS_AVAILABILITY_SCREEN.WORK_BRUTO' />
                </th>
                <th
                  style={{cursor: 'pointer'}}
                  onClick={() => onClick('net_work')}
                  className={design}
                >
                  <FormattedMessage id='POS_AVAILABILITY_SCREEN.WORK_NETO' />
                </th>
                <th
                  style={{cursor: 'pointer'}}
                  onClick={() => onClick('average_free_time')}
                  className={design}
                >
                  <FormattedMessage id='POS_AVAILABILITY_SCREEN.AVERAGE_FREE_TIME' />
                </th>
              </tr>
            </thead>
            <tbody>{listRender}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
