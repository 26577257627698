import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react"
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl"
import { toAbsoluteUrl } from "_metronic/helpers"
import { sendlog, logEnum } from "../logs/sendLog";


const dateObjectToValue = (date: Date | null) => date !== null ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` : "";

const waitForInsertTicketsJonToFinish = async () => {
    while (true) {
        const { data } = await axios.get<{ status: "running" | "not-running", progress: number }>(`/recommended-standard/insert-tickets-job-status`);
        if (data.status === "not-running") {
            break;
        } else {
            console.log("inserts tickets progress", data.progress);
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
    }
}

const RecalculateStandardForm: React.VFC = () => {
    const now = new Date();
    const [startDate, setStartDate] = useState(new Date(dateObjectToValue(now)));
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newStartDate = new Date(e.target.value);
        console.log(e.target.value, newStartDate);
        setStartDate(newStartDate);
        if (endDate && newStartDate.getTime() > endDate.getTime()) {
            setEndDate(null);
        }
    };

    const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) => setEndDate(new Date(e.target.value));

    const handleRecalculate = async () => {
        setLoading(true);
        try {
            const { data } = await axios.post<{ status: "busy" | "started", progress?: number }>(`/recommended-standard/insert-tickets`, { since: startDate, until: endDate });
            if (data.status === "started") {
                await waitForInsertTicketsJonToFinish();
            } else {
                alert("insert tickets job is already running");
                await waitForInsertTicketsJonToFinish();
            }
        } catch (e) {
            alert(`Error while recalculating: ${e}`)
        }
        setLoading(false);
    }

    useEffect(() => {
        setValid(!loading && startDate !== null && endDate !== null && startDate.getTime() < endDate.getTime());
    }, [loading, startDate, endDate]);

    const dayAfterStart = new Date(startDate);
    dayAfterStart.setDate(dayAfterStart.getDate() + 1);

    return (
        <div className="d-flex flex-column gap-2 w-auto position-relative">
            <h3>
                <FormattedMessage id="MAINTENANCE_SCREEN.RECALCULATE_STANDARD_FOR_RANGE" />
            </h3>
            <div className="form-group">
                <label htmlFor="sinceDate">
                    <FormattedMessage id="MAINTENANCE_SCREEN.START_DATE" />
                </label>
                <input required disabled={loading} onChange={handleStartDateChange} type="date" className="form-control" id="sinceDate" value={dateObjectToValue(startDate)} />
            </div>
            <div className="form-group">
                <label htmlFor="untilDate">
                    <FormattedMessage id="MAINTENANCE_SCREEN.END_DATE" />
                </label>
                <input required disabled={loading} onChange={handleEndDateChange} type="date" className="form-control" id="untilDate" min={dateObjectToValue(dayAfterStart)} value={dateObjectToValue(endDate)} />
            </div>
            <Button variant="primary" onClick={handleRecalculate} disabled={!valid} type="button" className="btn btn-primary">
                {loading
                    ? <div className="d-flex justify-content-center gap-2 align-items-center">
                        <div className="spinner-border" role="status" />
                        <span>
                            <FormattedMessage id="MAINTENANCE_SCREEN.CALCULATING" />
                        </span>
                    </div>
                    : <><FormattedMessage id="MAINTENANCE_SCREEN.RECALCULATE" /></>
                }
            </Button>
        </div>
    )
}

const MaintenanceFormWrapper: React.FC = ({ children }) => {
    return <div className="border border-secondary rounded p-5">{children}</div>
}

export const MaintenancePage: React.VFC = () => {
    useEffect(() => {
        sendlog(logEnum.MaintenancePage);
    }, [])
    return (
        <main>
            <header className="d-flex justify-content-evenly position-relative mt-5">
                <h1>
                    <FormattedMessage id="SCREENS.MAINTENANCE" defaultMessage="Maintenance" />
                </h1>
                <div className="position-absolute h-100 w-100">
                    <img src={toAbsoluteUrl('/media/logos/retail_logo.png')} className="h-100" alt="retail-innovation logo" />
                </div>
            </header>
            <hr />
            <article>
                <MaintenanceFormWrapper>
                    <RecalculateStandardForm />
                </MaintenanceFormWrapper>
            </article>
        </main>
    )
}
