import React from 'react'
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ISettingRow, SCREEN_NAMES_TO_MESSAGE_ID, SETTING_CONTENT_TO_MESSAGE_ID } from '../interfaces/ISettingRow';

type Props = {
    settingRow: ISettingRow;
    setName: Function,
}

const SettingRaw: React.FC<Props> = ({
    settingRow,
    setName
}) => {
    const onSetName = () => {
        setName(settingRow.screenName, settingRow.settingContent);
    }

    const screenNameMessageId = (
        settingRow.screenName in SCREEN_NAMES_TO_MESSAGE_ID
            ? SCREEN_NAMES_TO_MESSAGE_ID[settingRow.screenName]
            : "<UNKOWN_SCREEN>"
    );

    const settingContentMessageId = (
        settingRow.settingContent in SETTING_CONTENT_TO_MESSAGE_ID
            ? SETTING_CONTENT_TO_MESSAGE_ID[settingRow.settingContent]
            : "<UNKOWN_SETTING>"
    );

    return (
        <tr>
            <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                </div>
            </td>
            <td>
                <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                        <img src={toAbsoluteUrl(settingRow.icon)} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                        <Link
                            className='text-dark fw-bolder text-hover-primary fs-6'
                            data-bs-toggle='modal'
                            to={{ pathname: settingRow.pageLink }}
                            onClick={onSetName}
                        >
                            <FormattedMessage id={screenNameMessageId} />
                        </Link>
                    </div>
                </div>
            </td>
            <td>
                <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                <FormattedMessage id={settingContentMessageId} />
                </span>
            </td>
            <td className='text-end'>
                <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack '>
                        <span className='text-muted me-2 fs-7 fw-bold'>{settingRow.updateTime}</span>
                    </div>
                </div>
            </td>
            <td>
                <div className='d-flex justify-content-start flex-shrink-0'>
                    {settingRow.comment}
                </div>
            </td>

        </tr>
    )
}
export { SettingRaw }
