import axios from 'axios'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {MultiSelect} from '../MutliSelector'

type StoreSelectorProps = {
  className?: string
  selectedStores: StoreSelection[]
  onSelectedStoresChange: (selectedStores: StoreSelection[]) => any
  onStoreListLoaded?: (stores: StoreSelection[]) => any
  withCartsOnly?: boolean
}

export const StoresSelector: React.VFC<StoreSelectorProps> = ({
  className,
  selectedStores,
  onSelectedStoresChange,
  onStoreListLoaded,
  withCartsOnly,
}) => {
  const [stores, setStores] = useState<StoreSelection[]>([])
  const [filteredStores, setFilteredStores] = useState<StoreSelection[]>([])

  useEffect(() => {
    const cancel = axios.CancelToken.source()
    ;(async () => {
      const {data} = await axios.get<StoreSelection[]>('/store/list', {
        cancelToken: cancel.token,
      })
      setStores(data)
      onStoreListLoaded?.(data)
    })()
    return () => cancel.cancel()
  }, [onStoreListLoaded])

  useEffect(() => {
    if (withCartsOnly) {
      setFilteredStores(stores.filter((store) => store.has_carts))
    } else {
      setFilteredStores(stores)
    }
  }, [stores, withCartsOnly])

  const handleStoreSelectionChange = (selectedIds: string[]) => {
    const selectedSet = new Set(selectedIds.map((id) => parseInt(id)))
    onSelectedStoresChange(stores.filter((store) => selectedSet.has(store.id)))
  }

  const handleStoreFilter = useCallback(
    (search: string) => {
      setFilteredStores(
        stores.filter((store) => store.name.includes(search) && (!withCartsOnly || store.has_carts))
      )
    },
    [stores, withCartsOnly]
  )

  const handleSelectAll = () => {
    onSelectedStoresChange(stores)
  }

  const handleCancelSelection = () => {
    onSelectedStoresChange([])
  }

  const intl = useIntl()

  return (
    <MultiSelect
      className={className ?? ''}
      options={filteredStores.map((s) => ({label: s.name, value: s.id.toString()}))}
      onSelectionChange={handleStoreSelectionChange}
      placeholder={intl.formatMessage({id: 'GENERAL.SELECT_STORES'})}
      selectedOptions={selectedStores.map((s) => ({value: s.id.toString(), label: s.name}))}
      onFilter={handleStoreFilter}
      selectAll={handleSelectAll}
      cancelSelection={handleCancelSelection}
    />
  )
}

export type StoreSelection = {
  id: number
  name: string
  has_carts: boolean
}
