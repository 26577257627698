import axios from 'axios'
import { fullUser } from 'app/modules/auth/redux/AuthCRUD'

export const getUserList = async (url: string) => {
  try {
    return await (await axios.get(`/users/get-list`)).data
  } catch (err) {
    return []
  }
}

export const getUser = async (url: string, id: number) => {
  try {
    return await (await axios.get(`/users/user?id=${id}`)).data
  } catch (err) {
    return []
  }
}

export const changeUser = async (url: string, userId: number, user: fullUser) => {
  console.log(userId)
  try {
    return await (await axios.put(`/users/user/`, { userId, user })).data
  } catch (err) {
    return []
  }
}
