import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { MasterInit } from '_metronic/layout/MasterInit'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import { Routes } from './routing/Routes'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <LayoutProvider>
          <AuthInit>
            <Routes />
          </AuthInit>
          <MasterInit />
        </LayoutProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
