import { IRecommendedStandard } from '../interfaces/IRecommendedStandard'
import { GraphModal } from './graphModal';
import Store from './Store'
interface props {
  stores: IRecommendedStandard[],
  displayGraph: boolean,
  onExitClick: () => any,
  historyMode: boolean,
}
export default function StoresList(props: props) {
  const stores = props.stores || []

  const listRender = stores.map((item: any) => {
    return <Store historyMode={props.historyMode} key={item.id} store={item}></Store>
  });
  return (
    <div className='row g-6 g-xl-9 justify-content-center' id="main-list">
      {props.historyMode && props.displayGraph &&
        <div>
          <GraphModal className='' stores={stores} onExitClick={props.onExitClick} />
        </div>
      }
      {listRender}
    </div>
  )
}
