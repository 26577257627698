import { useIntl } from 'react-intl'
import { useIsRTL } from '_metronic/i18n/Metronici18n'

interface Props {
  user: string
  onSearch: (search: string) => void
}

const SearchUser = (props: Props) => {
  const onChange = (event: any) => {
    props.onSearch(event.target.value)
  }
  const isRTL = useIsRTL();
  const intl = useIntl();

  return (
    <div className='row'>
      <div className='mb-3'>
        <input
          type='text'
          onChange={onChange}
          className='form-control'
          id='inputSearch'
          dir={isRTL ? 'rtl' : 'ltr'}
          placeholder={intl.formatMessage({ id: "USAGE_LEVELS_SCREEN.SEARCH_BY_USER" })}
          defaultValue={props.user}
        />
      </div>
    </div>
  )
}

export default SearchUser
