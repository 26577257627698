import {useEffect, useRef, useState} from 'react'
import {Button, FormControl, FormLabel} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type MultiSelectProps = {
  className?: string
  options: {value: string; label: string}[]
  placeholder: string
  selectedOptions: {value: string; label: string}[]
  onSelectionChange: (selected: string[]) => any
  onFilter: (search: string) => any
  selectAll?: () => void
  cancelSelection?: () => void
}

export const MultiSelect: React.VFC<MultiSelectProps> = ({
  className,
  options,
  placeholder,
  selectedOptions,
  onSelectionChange,
  onFilter,
  selectAll,
  cancelSelection,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [allSelected, setAllSelected] = useState(false)
  const selectedOptionsViewerRef = useRef<HTMLDivElement>(null)
  const scrollToBottomRef = useRef(false)

  const handleToggleClicked: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setExpanded((expanded) => !expanded)
  }

  useEffect(() => {
    const hide = () => {
      setExpanded(false)
    }

    document.addEventListener('click', hide)

    return () => document.removeEventListener('click', hide)
  })

  const handleWrapperClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
  }

  const random = Math.floor(Math.random() * 1000).toString()

  const hasValue = selectedOptions.length !== 0

  const handleCheckboxChange = (value: string, checked: boolean) => {
    if (checked) {
      scrollToBottomRef.current = true
      onSelectionChange([...selectedOptions.map((o) => o.value), value])
    } else {
      onSelectionChange(selectedOptions.filter((o) => o.value !== value).map((o) => o.value))
    }
  }

  const handleClearSelection = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    event.stopPropagation()
    onSelectionChange(selectedOptions.filter((_, i) => i !== index).map((o) => o.value))
  }

  const handleFilterChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onFilter(e.target.value ?? '')
  }

  useEffect(() => {
    if (selectedOptionsViewerRef.current && scrollToBottomRef.current) {
      selectedOptionsViewerRef.current.scrollTop = selectedOptionsViewerRef.current?.scrollHeight
    }
    scrollToBottomRef.current = false
  }, [selectedOptions])

  useEffect(() => {
    if (!expanded) {
      onFilter('')
    }
  }, [expanded, onFilter])

  const intl = useIntl()

  const handleSelectAllClick = () => {
    if (!allSelected) {
      setAllSelected(true)
      if (selectAll) {
        selectAll()
      }
    } else {
      setAllSelected(false)
      if (cancelSelection) {
        cancelSelection()
      }
    }
  }

  return (
    <div
      onClick={handleWrapperClick}
      className={`position-relative p-0 w-100${className ? ` ${className}` : ''}`}
    >
      <div
        ref={selectedOptionsViewerRef}
        onClick={handleToggleClicked}
        className='form-control form-select cursor-pointer w-5 d-flex align-items-center gap-2 flex-wrap overflow-auto'
        style={{maxHeight: '8rem'}}
      >
        {!hasValue && <div className='text-muted'>{placeholder}</div>}
        {hasValue &&
          selectedOptions.map((o, i) => (
            <div className='bg-light p-1 d-flex gap-2 fix-ps' key={i}>
              <div className='d-flex align-items-center justify-content-center'>{o.label}</div>
              <Button className='p-1' variant='close' onClick={(e) => handleClearSelection(e, i)} />
            </div>
          ))}
      </div>
      {expanded && (
        <div className='position-absolute border bg-light z-index-1 w-max-content text-nowrap'>
          <FormControl
            autoFocus
            placeholder={intl.formatMessage({id: 'GENERAL.SEARCH'})}
            onChange={handleFilterChange}
          />
          <div>
            <button
              className={`bg-transport btn btn-active`}
              style={{fontSize: '13px'}}
              onClick={handleSelectAllClick}
            >
              {allSelected
                ? intl.formatMessage({id: 'GENERAL.CLEAR_ACTIONS'})
                : intl.formatMessage({id: 'GENERAL.SELECT_ALL_ACTIONS'})}
            </button>
          </div>
          <div className='overflow-auto' style={{maxHeight: '25vh'}}>
            {options.map((o, i) => (
              <FormLabel
                key={i}
                className='w-100 btn btn-light btn-active d-flex gap-4 mb-0 rounded-0'
                htmlFor={`${random}_${i}`}
              >
                <input
                  onChange={(e) => handleCheckboxChange(o.value, e.target.checked)}
                  checked={Boolean(selectedOptions.find((so) => so.value === o.value))}
                  type='checkbox'
                  className='form-check-input cursor-pointer'
                  id={`${random}_${i}`}
                />
                <span>{o.label}</span>
              </FormLabel>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
