import { IStore } from 'app/modules/stores/interfaces/IStore';
import React, { FC, useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { Link } from 'react-router-dom';
import { StatusTypeEnum } from '../interfaces/statusTypeEnum';

interface Props {
    stores: IStore[];
    statusType: StatusTypeEnum;
    title: string;
    selectedStatus: string;
}

const PieChart: FC<Props> = ({ statusType, stores, title, selectedStatus }) => {

    const [redStoresNumber, setRedStoresNumber] = useState(0)
    const [yellowStoresNumber, setYellowStoresNumber] = useState(0)
    const [greenStoresNumber, setGreenStoresNumber] = useState(0)
    const [purpleStoresNumber, setPurpleStoresNumber] = useState(0)

    useEffect(() => {
        setRedStoresNumber(stores.filter(s => s[statusType] === 3).length);
        setYellowStoresNumber(stores.filter(s => s[statusType] === 2).length);
        setGreenStoresNumber(stores.filter(s => s[statusType] === 1).length);
        setPurpleStoresNumber(stores.filter(s => s[statusType] === 4).length);
    }, [stores, statusType]);

    let data = [
        ["color", "number"],
        ["", redStoresNumber],
        ["", yellowStoresNumber],
        ["", greenStoresNumber],
        ["", purpleStoresNumber],
    ]
    const options = {
        legend: 'none',
        is3D: true,
        colors: ['#f1416c', '#ffc700', '#50cd89', '#7239ea'],
        tooltip: { trigger: 'selection', },
        pieSliceText: 'value',
        pieSliceTextStyle: {
            bold: true
        },
        fontSize: '10px',
        slices: {
            0: {  offset: selectedStatus === statusType ? 0.2 : 0 },
        },
    };
    return (
        <div className=' col-4'>
            <div className='card card-body'>
                <Link className='text-center fs-4 text-gray-800 text-hover-primary fw-bolder mb-0 testclass'
                    to={{ pathname: '/stores', state: statusType }
                    } >{title}</Link>
                {/* <h4 className='text-center'>{title}</h4> */}
                <Chart
                    chartType="PieChart"
                    data={data}
                    options={options}
                    width={"100%"}
                    height={"250px"}

                />
            </div>
        </div>


    );
}
export default PieChart
