/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { useState, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import { ClacRecommendedConfig } from '../interfaces/ITableRow'

type Props = {
  data: ClacRecommendedConfig,
  onChange: Function
}

const CalcModal: React.FC<Props> = ({
  data,
  onChange
}) => {
  let exitButton = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  let initialValues = {
    id: data.id,
    cashiersForIndependentArea: data.cashiersForIndependentArea,
    cashiersForCarts: data.cashiersForCarts,
    riskRangePrecent: data.riskRangePrecent,
    storeId: data.storeId
  }
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: values => {
      console.log(values);
      data.cashiersForCarts = values.cashiersForCarts
      data.cashiersForIndependentArea = values.cashiersForIndependentArea
      data.riskRangePrecent = values.riskRangePrecent
      setIsLoading(true)
      axios.post("/recommended-standard/update-recommended-config",
        values
      ).then((res) => {
        console.log(res.data);
        setIsLoading(false);
        onChange();
        setTimeout(() => {
          exitButton.current?.click();
        }, 500);
      })
    },
  });
  return (
    <div className='modal fade' id='calc_modal' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-8'>
              <h1 className='mb-3'>
                <FormattedMessage id="RECOMMENDATIONS_SCREEN.BRUTO_CALCULATOR" />
              </h1>
              <br />
              <div className='row'>
                <h4 className='col-6'>
                  <FormattedMessage id="RECOMMENDATIONS_SCREEN.CATEGORY" />
                </h4>
                <h4 className='col-6'>
                  <FormattedMessage id="RECOMMENDATIONS_SCREEN.EXTRA" />
                </h4>
              </div>
              <hr></hr>
            </div>
            <form className='' onSubmit={formik.handleSubmit}>
              <div className='row justify-content-around mb-5'>
                <span className='mt-3 col-4 text-gray-600 fs-5 fw-bolder'>
                  <FormattedMessage id="RECOMMENDATIONS_SCREEN.N_SELF_CHECKOUT_POS" values={{ n: data.indepentedDeskNumber ?? 0 }} />
                </span>
                <div className='col-4'>
                  <input
                    style={{ WebkitAppearance: 'none' }}
                    value={formik.values.cashiersForIndependentArea}
                    className={clsx('form-control form-control-lg form-control-solid text-info',)}
                    type='number'
                    autoComplete='off'
                    id="cashiersForIndependentArea"
                    name="cashiersForIndependentArea"
                    onChange={formik.handleChange}
                    min={0}
                  />
                </div>
              </div>
              <div className='row justify-content-around mb-5'>
                <span className='mt-3 col-4 text-gray-600 fs-5 fw-bolder'>
                  <FormattedMessage id="RECOMMENDATIONS_SCREEN.N_SMART_CARTS" values={{ n: data.cartNumber ?? 0 }} />
                </span>
                <div className='col-4'>
                  <input
                    style={{ WebkitAppearance: 'none' }}
                    value={formik.values.cashiersForCarts}
                    className={clsx('form-control form-control-lg form-control-solid text-info',)}
                    type='number'
                    autoComplete='off'
                    id="cashiersForCarts"
                    name="cashiersForCarts"
                    onChange={formik.handleChange}
                    min={0}
                  />
                </div>
              </div>
              <div className='row justify-content-around mb-5'>
                <span className='mt-3 col-4 text-gray-600 fs-5 fw-bolder'>
                  <FormattedMessage id="RECOMMENDATIONS_SCREEN.UNPLANNED_PERCENTAGE_EXTRA" />
                </span>
                <div className='col-4'>
                  <input
                    style={{ WebkitAppearance: 'none' }}
                    value={formik.values.riskRangePrecent}
                    className={clsx('form-control form-control-lg form-control-solid text-info',)}
                    type='number'
                    autoComplete='off'
                    id="riskRangePrecent"
                    name="riskRangePrecent"
                    onChange={formik.handleChange}
                    min={0}
                  />
                </div>
              </div>
              {isLoading && (
                <span style={{ display: 'block', zIndex: 1060 }} className='indicator-progress'>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              <div className='text-center mt-10'>
                <button
                  type='submit' className='btn btn-light-primary btn-sm fs-6 mx-2'><FormattedMessage id="GENERAL.CONFIRM" /></button>
                <button ref={exitButton} id='exit' type='button' data-bs-dismiss='modal' className='btn btn-light-primary btn-sm fs-6 mx-2'><FormattedMessage id="GENERAL.CANCEL" /></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CalcModal }
