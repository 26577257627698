import { StoreSelection, StoresSelector } from "app/common/components/selectors/StoresSelector";
import { DAYS, WEEKS } from "app/common/constants";
import { debounce } from "app/helpers/debounce";
import axios, { CancelToken } from "axios";
import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { Button, Card, FormControl, FormGroup, FormLabel, InputGroup, Modal, Spinner } from "react-bootstrap";
import Chart from "react-google-charts";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "_metronic/helpers";
import { useGoogleChartLanguage } from "_metronic/i18n/Metronici18n";
import { DatapointSize, DatapointSizeSelector } from "../trends/components/DatapointSizeSelector";
import { sendlog, logEnum } from "../logs/sendLog";

export type DateRange = {
    min: Date,
    max: Date,
}

type DateRangeSelectorProps = {
    className?: string,
    value?: DateRange,
    onChange: (value: Exclude<Required<DateRangeSelectorProps["value"]>, undefined>) => any,
}

const pad0 = (value: number) => value.toString().padStart(2, "0")

const getHtmlDateString = (date: Date) => `${date.getFullYear()}-${pad0(date.getMonth() + 1)}-${pad0(date.getDate())}`;

export const DateRangeSelector: React.VFC<DateRangeSelectorProps> = ({
    className,
    value,
    onChange,
}) => {

    const { min: startDate, max: endDate } = Object.assign({ min: new Date(Date.now() - 1 * DAYS), max: new Date(), }, value ?? {});

    const handleStartChange: ChangeEventHandler<HTMLInputElement> = e => {
        const value = e.target.valueAsDate!;
        const newRange = { min: value, max: endDate };
        if (value.getTime() > endDate.getTime()) {
            newRange.max = value;
        }
        onChange(newRange);
    }

    const handleEndDateChange: ChangeEventHandler<HTMLInputElement> = e => {
        const value = e.target.valueAsDate!;
        const newRange = { min: startDate, max: value };
        if (value.getTime() < startDate.getTime()) {
            newRange.max = newRange.min;
        }
        onChange(newRange);
    }

    return (
        <InputGroup className={`gap-2${className && ` ${className}`}`}>
            <FormLabel className="m-0">
                <span>
                    <FormattedMessage id="GENERAL.RANGE_PICKER.START_TIME" />
                </span>
                <FormControl value={getHtmlDateString(startDate)} max={getHtmlDateString(new Date())} required type="date" onChange={handleStartChange} />
            </FormLabel>
            <FormLabel className="m-0">
                <span>
                    <FormattedMessage id="GENERAL.RANGE_PICKER.END_TIME" />
                </span>
                <FormControl value={getHtmlDateString(endDate)} min={getHtmlDateString(new Date(startDate.getTime()))} max={getHtmlDateString(new Date())} required type="date" onChange={handleEndDateChange} />
            </FormLabel>
        </InputGroup>
    );
};

type TrendData = {
    startTime: string | Date,
    endTime: string | Date,
    totalRevenue: number,
    averageRevenue: number,
    transactionCount: number,
    cartsCount: number,
}[];

const TrendChartWrapper: React.FC<{ title: JSX.Element }> = ({
    title,
    children,
}) => {
    const [expanded, setExpanded] = useState(false);

    const handleFullScreenClick = () => {
        setExpanded(true);
    }

    const handleModalHide = () => {
        setExpanded(false);
    }
    return (
        <>
            <Card className="col-xl min-w-25" style={{ minHeight: "25vh" }}>
                <Card.Header>
                    <Card.Title>{title}</Card.Title>
                    <Button variant="outline-secondary" style={{ width: "3rem", height: "3rem" }} className="p-0 align-self-center d-none d-xl-flex align-items-center justify-content-center" onClick={handleFullScreenClick}>
                        <KTSVG svgClassName="w-100 h-100" path={toAbsoluteUrl("/media/logos/expand.svg")} />
                    </Button>
                </Card.Header>
                <Card.Body className="p-0 h-100">
                    {children}
                </Card.Body>
            </Card>
            <Modal size="xl" centered show={expanded} onHide={handleModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </>
    )
}

export const TrendAnalysisPage: React.VFC = () => {

    const [selectedStores, setSelectedStores] = useState<StoreSelection[]>([]);
    const [dateRange, setDateRange] = useState<{ min: Date, max: Date }>({ min: new Date(Date.now() - 1 * WEEKS), max: new Date() });
    const [datapointsSize, setDatapointsSize] = useState<DatapointSize>("days");

    const handleSelectedStoresChange = (selectedStores: StoreSelection[]) => setSelectedStores(selectedStores);

    const handleDateRangeChange = (value: { min: Date, max: Date }) => setDateRange(value);

    const handleDatapointSizeChange = (value: DatapointSize) => setDatapointsSize(value);

    const [data, setData] = useState<TrendData>();

    const fetchData = async (
        selectedStores: StoreSelection[],
        dateRange: Required<NonNullable<DateRangeSelectorProps['value']>>,
        datapointsSize: DatapointSize,
        cancelToken: CancelToken,
    ) => {
        try {
            const { data } = await axios.get<TrendData>("/productivity/trends", {
                cancelToken,
                params: {
                    stores: selectedStores.map(store => store.id),
                    since: dateRange.min,
                    until: dateRange.max,
                    by: datapointsSize,
                },
            });
            setData(data);
        } catch (e: any) {
            if (!e.__CANCEL__) {
                throw e;
            } else {
                console.log("canceled")
            }
        }
    };

    const debounceFetch = useMemo(() => debounce(fetchData, 1000), []);

    useEffect(() => {
        setData(undefined);
        const source = axios.CancelToken.source();
        debounceFetch(selectedStores, dateRange, datapointsSize, source.token);
        return () => source.cancel();
    }, [selectedStores, dateRange, datapointsSize, debounceFetch]);

    const intl = useIntl();


    useEffect(() => {
        sendlog(logEnum.TrendAnalysisPage)
    }, [])

    const chartLanguage = useGoogleChartLanguage();

    return (
        <main>
            <header className="d-flex justify-content-evenly position-relative mt-5">
                <h1>
                    <FormattedMessage id="SCREENS.TREND_ANALYSIS" />
                </h1>
                <div className="position-absolute h-100 w-100">
                    <img src={toAbsoluteUrl('/media/logos/retail_logo.png')} className="h-100" alt="retail-innovation logo" />
                </div>
            </header>
            <div className="row my-3 d-flex align-items-end">
                <FormGroup className="col-xl-3">
                    <FormLabel htmlFor="nothing" className="w-100">
                        <FormattedMessage id="TREND_ANALYSIS_SCREEN.SELECT_STORES" />
                        <StoresSelector
                            onSelectedStoresChange={handleSelectedStoresChange}
                            selectedStores={selectedStores}
                            withCartsOnly={true}
                        />
                    </FormLabel>
                </FormGroup>
                <div className="col-auto">
                    <DateRangeSelector
                        onChange={handleDateRangeChange}
                        value={dateRange}
                    />
                </div>
                <div className="col-3">
                    <FormLabel>
                        <FormattedMessage id="TREND_ANALYSIS_SCREEN.DATAPOINT_PERIOD" />
                        <DatapointSizeSelector
                            onChange={handleDatapointSizeChange}
                            value={datapointsSize}
                        />
                    </FormLabel>
                </div>
            </div>
            <hr />
            <article className="row gap-2">
                <TrendChartWrapper title={<FormattedMessage id="TREND_ANALYSIS_SCREEN.REVENUE" />}>
                    {data ? <Chart
                        className="chart-fix-rtl"
                        chartType="LineChart"
                        chartLanguage={chartLanguage}
                        options={{
                            legend: { position: "none" },
                            tooltip: { isHtml: true, ignoreBounds: true, },
                        }}
                        data={[["time-range", intl.formatMessage({ id: "TREND_ANALYSIS_SCREEN.REVENUE" })], ...(data.map(datapoint => [new Date(datapoint.startTime), datapoint.totalRevenue]) || [])]}
                    /> :
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <Spinner animation="border" />
                        </div>
                    }
                </TrendChartWrapper>
                <TrendChartWrapper title={
                    <Link to={{
                        pathname: "/productivity/trends/transaction-count",
                        state: {
                            selectedStores,
                            dateRange,
                            datapointsSize,
                        }
                    }}>
                        <FormattedMessage id="TREND_ANALYSIS_SCREEN.TRANSACTION_COUNT" />
                    </Link>
                }>
                    {data ? <Chart
                        className="chart-fix-rtl"
                        chartType="LineChart"
                        chartLanguage={chartLanguage}
                        options={{
                            legend: { position: "none" },
                            tooltip: { isHtml: true, ignoreBounds: true, },
                        }}
                        data={[["time-range", intl.formatMessage({ id: "TREND_ANALYSIS_SCREEN.TRANSACTION_COUNT" })], ...(data.map(datapoint => [new Date(datapoint.startTime), datapoint.transactionCount]) || [])]}
                    /> :
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <Spinner animation="border" />
                        </div>
                    }
                </TrendChartWrapper>
                <TrendChartWrapper title={<FormattedMessage id="TREND_ANALYSIS_SCREEN.AVERAGE_REVENUE" />}>
                    {data ? <Chart
                        className="chart-fix-rtl"
                        chartType="LineChart"
                        chartLanguage={chartLanguage}
                        options={{
                            legend: { position: "none" },
                            tooltip: { isHtml: true, ignoreBounds: true, },
                        }}
                        data={[["time-range", intl.formatMessage({ id: "TREND_ANALYSIS_SCREEN.AVERAGE_REVENUE" })], ...(data.map(datapoint => [new Date(datapoint.startTime), datapoint.averageRevenue]) || [])]}
                    /> :
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <Spinner animation="border" />
                        </div>
                    }
                </TrendChartWrapper>
                <TrendChartWrapper title={<FormattedMessage id="TREND_ANALYSIS_SCREEN.CARTS_COUNT" />}>
                    {data ? <Chart
                        className="chart-fix-rtl"
                        chartType="LineChart"
                        chartLanguage={chartLanguage}
                        options={{
                            legend: { position: "none" },
                            tooltip: { isHtml: true, ignoreBounds: true, },
                        }}
                        data={[["time-range", intl.formatMessage({ id: "TREND_ANALYSIS_SCREEN.CARTS_COUNT" })], ...(data.map(datapoint => [new Date(datapoint.startTime), datapoint.cartsCount]) || [])]}
                    /> :
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <Spinner animation="border" />
                        </div>
                    }
                </TrendChartWrapper>
                <div className="col-xl" /> {/* just to fill up the row */}
                <div className="col-xl" /> {/* just to fill up the row */}
            </article>
        </main>
    )
};
