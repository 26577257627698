import { useEffect, useState } from 'react'
import { IStore } from '../../stores/interfaces/IStore'
import { successFilter, oneCashierDeskInactiveFilter, fullCashierDeskInactive, upThan100 } from '../helpers/filtersStores'
import { logEnum, sendlog } from '../../logs/sendLog'
import { STATUS_TYPE } from '../../auth/redux/AuthCRUD'
import { IButtonContent } from '../../cashiers/interfaces/buttonContent'
import { getButtonsContentByName } from '../../settings/services/settingsData'
import { BtnContentEnum } from 'app/modules/settings/interfaces/btnContentEnum'
import { FormattedMessage } from 'react-intl'

interface Props {
  onFilter: (statusFilter: number) => any
  listStores: IStore[]
}

const listButtons = [
  { color: 'primary', active: true },
  { color: 'success', active: false },
  { color: 'warning', active: false },
  { color: 'danger', active: false },
  { color: 'info', active: false },
]
const FilterStores = (props: Props) => {
  const listStores = props.listStores || [];
  const [listButtonsRender, setListButtonsRendersRender] = useState(listButtons)

  const onClickFilter = (event: any) => {
    const status = event.currentTarget.value
    switch (status) {
      case '1':
        sendlog(logEnum.storesGreenBtn);
        break;
      case '2':
        sendlog(logEnum.storesYellowBtn);
        break;
      case '3':
        sendlog(logEnum.storesRedBtn);
        break;
      case '4':
        sendlog(logEnum.storesPurpleBtn);
        break;
      case '0':
        sendlog(logEnum.storesBlueBtn);
        break;
    }

    const list = [...listButtonsRender]
    list.forEach((item) => {
      item.active = false
    });


    list[status].active = true
    setListButtonsRendersRender(list)
    return props.onFilter(+status)
  }

  const [purpleButton, setPurpleButton] = useState(0)
  const [greenButton, setGreenButton] = useState(0)
  const [yellowButton, setYellowButton] = useState(0)
  const lessThen = '<';
  const upThen = '>';
  const getContent = async () => {
    let arr: IButtonContent[] = [];
    if (STATUS_TYPE === 'independentDeskStatus') arr = await getButtonsContentByName(BtnContentEnum.storeIndependentDesk);
    else if (STATUS_TYPE === 'activeDeskStatus') arr = await getButtonsContentByName(BtnContentEnum.storePostingCashier);
    else if (STATUS_TYPE === 'attendanceStatus') arr = await getButtonsContentByName(BtnContentEnum.storeStandard);
    else if (STATUS_TYPE === 'shiftStatus') arr = await getButtonsContentByName(BtnContentEnum.storePresentCashier);
    else if (STATUS_TYPE === 'cashiersDeviationStatus') arr = await getButtonsContentByName(BtnContentEnum.storeCashiersDeviation);
    else if (STATUS_TYPE === 'revenueStatus') arr = await getButtonsContentByName(BtnContentEnum.storeRevenue);
    else if (STATUS_TYPE === 'loadStatus') arr = await getButtonsContentByName(BtnContentEnum.storeLoad);

    arr.forEach(btnContent => {
      if (btnContent.title === 'purpleButton') setPurpleButton(btnContent.value);
      else if (btnContent.title === 'greenButton') setGreenButton(btnContent.value);
      else if (btnContent.title === 'yellowButton') setYellowButton(btnContent.value);
    });
  }
  useEffect(() => {
    getContent();
  })
  return (
    <div className='row justify-content-between'>
      <button
        style={{ marginRight: '5px' }}
        value={3}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[3].color} ${listButtonsRender[3].active ? 'active' : ''
          }`}
      >
        ({listStores.filter(fullCashierDeskInactive).length}) {yellowButton}% {upThen}
      </button>

      <button
        style={{ marginRight: '5px' }}
        value={2}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[2].color} ${listButtonsRender[2].active ? 'active' : ''
          }`}
      >
        ({listStores.filter(oneCashierDeskInactiveFilter).length}) {yellowButton}% - {greenButton}%
      </button>

      <button
        id={'success-button'}
        style={{ marginRight: '5px' }}
        value={1}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[1].color} ${listButtonsRender[1].active ? 'active' : ''
          }`}
      >
        ({listStores.filter(successFilter).length}) {greenButton}% - {purpleButton}%
      </button>
      <button
        id={'main-button'}
        style={{ marginRight: '5px' }}
        value={4}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[4].color} ${listButtonsRender[4].active ? 'active' : ''
          }`}
      >
        ({listStores.filter(upThan100).length}) {purpleButton}% {lessThen}
      </button>
      <button
        id={'main-button'}
        style={{ marginRight: '5px' }}
        value={0}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[0].color} ${listButtonsRender[0].active ? 'active' : ''
          }`}
      >
        ({listStores.length}) <FormattedMessage id={"CASHIERS_ALERTS_SCREEN.DISPLAY_ALL"} />
      </button>
    </div>
  );
}
export default FilterStores
