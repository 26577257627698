import { FilterStatusType } from 'app/routing/PrivateRoutes'
import { useIntl } from 'react-intl'
import { logEnum, sendlog } from '../../logs/sendLog'

interface Props {
  value: FilterStatusType,
  onStatusBy: (statusType: FilterStatusType) => void,
}

const StatusBy = (props: Props) => {
  const onChange = (event: any) => {
    switch (event.target.value) {
      case 'standardStatus':
        sendlog(logEnum.recommendedStatusByStandard);
        break;
      case 'productivityStatus':
        sendlog(logEnum.recommendedStatusByproductivity);
        break;
    }
    props.onStatusBy(event.target.value);
  }
  
  const { value } = props;
  const intl = useIntl();
  return (
    <div className='mb-3'>
      <select
        className='form-select'
        data-control='select2'
        data-hide-search='true'
        data-kt-select2='true'
        data-placeholder='Select option'
        data-allow-clear='true'
        onChange={onChange}
        value={value}
      >
        <option value='overLoadCashierStatus' >{intl.formatMessage({ id: "POS_EVENT_RESEARCH_SCREEN.OVERLOAD_CASHIERS" })}</option>
        <option value='underLoadCashierStatus' >{intl.formatMessage({ id: "POS_EVENT_RESEARCH_SCREEN.UNDERLOAD_CASHIERS" })}</option>
      </select>
    </div>
  )
}
export default StatusBy