import React, { createContext, useContext, useState, useEffect } from 'react'
import { DefaultLayoutConfig } from './DefaultLayoutConfig'
import {
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './LayoutSetup'
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
} from './LayoutModels'
import { useIsRTL } from '_metronic/i18n/Metronici18n'
import LTRLayout from './LTRLayout'
import { useIntl } from 'react-intl'

export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  setLayout: (config: LayoutSetup) => void
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => { },
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}
/**
 * NOTE(ItzikSn):
 * We use lazy loading for conditionally including/excluding css files.
 * Idea from: https://stackoverflow.com/a/59631562
 */
const RTLLayout = React.lazy(() => import("./RTLLayout"));

const LayoutProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState(LayoutSetup.config)
  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
  const isRTL = useIsRTL();
  const intl = useIntl();
  const dir = isRTL ? "rtl" : "ltr";
  document.body.dir = dir;

  document.title = intl.formatMessage({ id: "GENERAL.TITLE.RETAILIN_DASHBOARD" });

  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, LayoutSetup.config))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    setTimeout(() => {
      disableSplashScreen()
    }, 500)
  }
  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
  }

  useEffect(() => {
    disableSplashScreen()
  }, [])

  return <LayoutContext.Provider value={value}>
    <LTRLayout />
    {isRTL && <RTLLayout />}
    <div dir={isRTL ? 'rtl' : 'ltr'}>
      {children}
    </div>
  </LayoutContext.Provider>
}

export { LayoutContext, LayoutProvider }

export function useLayout() {
  return useContext(LayoutContext)
}
