import { FormSelect } from "react-bootstrap";
import { useIntl } from "react-intl";

const RANGE_TYPE_TO_MESSAGE_KEY = {
    today: "TODAY",
    last7Days: "LAST_7_DAYS",
    previousWeek: "PREVIOUS_WEEK",
    last30Days: "LAST_30_DAYS",
    previousMonth: "PREVIOUS_MONTH",
    custom: "CUSTOM_RANGE",
} as const;

export type RangeType = keyof typeof RANGE_TYPE_TO_MESSAGE_KEY;

export const RangeSelector: React.VFC<{ value: RangeType, onChange: (range: RangeType) => any }> = ({ value, onChange }) => {
    const handleChange = (value: RangeType) => onChange(value);
    const intl = useIntl();
    return (
        <FormSelect onChange={e => handleChange(e.currentTarget.value as RangeType)} value={value}>
            {Object.entries(RANGE_TYPE_TO_MESSAGE_KEY).map(([type, messageKey]) => <option key={type} value={type}>{intl.formatMessage({ id: `PRODUCTIVITY_STATUS_REPORT_SCREEN.${messageKey}` })}</option>)}
        </FormSelect>
    )
}
