import { FC } from 'react'
import { useLang } from './Metronici18n'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import enMessages from './messages/en.json'
import heMessages from './messages/he.json'
import keysMessages from './messages/keys.json'

const allMessages = {
  he: heMessages,
  en: enMessages,
  keys: keysMessages,
} as const;

const I18nProvider: FC = ({ children }) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale !== "keys" ? locale : "he"} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
