
export interface weekList {
    date: Date;
    weekNum: number;
    startDate: string;
}

export interface StoreWeekLoadResponse {
    storeName: string,
    weekLoad: StoreWeekLoad[],
}

export interface StoreWeekLoad {
    day: keyof typeof DAY_NUMBER_TO_MESSAGE_ID,
    morningOverload: number,
    morningUnderload: number,
    eveningOverload: number,
    eveningUnderload: number,
    dayLoadResponse: DayLoadResponse
}

export interface DayLoadResponse {
    day: keyof typeof DAY_NUMBER_TO_MESSAGE_ID,
    date: Date,
    timeDatapoints: string[],
    selfcheckoutDatapoints: number[],
    recommendedStandardShiftDatapoints: number[],
    attendanceDatapoints: number[],
    assignmentDatapoints: number[],
    currentStandardDatapoints: number[],
    activePosDatapoints: number[],
    grossRecommendationDatapoints: number[],
    minRequriedPosDatapoints: number[],
}

export const DAY_NUMBER_TO_MESSAGE_ID = {
    0: 'GENERAL.SUNDAY',
    1: 'GENERAL.MONDAY',
    2: 'GENERAL.THUESDAY',
    3: 'GENERAL.WEDNESDAY',
    4: 'GENERAL.THURSDAY',
    5: 'GENERAL.FRIDAY',
    6: 'GENERAL.SATURDAY',
} as const;