import { StoreSelection, StoresSelector } from "app/common/components/selectors/StoresSelector";
import { DAYS, WEEKS } from "app/common/constants";
import { debounce } from "app/helpers/debounce";
import axios, { CancelToken } from "axios";
import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, FormControl, FormLabel, InputGroup } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { toAbsoluteUrl } from "_metronic/helpers";
import { StoreTypeSelector, StoreType } from "../trends/components/StoreTypeSelector";
import { RangeSelector, RangeType } from "../trends/components/RangeSelector";
import ProductivityTable, { ProductivityPerHourDataResponse, saveExcelFile } from "./table_component";
import { DayHourSelector, DaysHoursDatapoints } from "./components/DateHourSelector";
import excelImage from './excel.jpg';
import { sendlog, logEnum } from "../logs/sendLog";


export const PRODUCTIVIY_PER_HOUR_BY_KEYS = {
    "days": true,
    "hours": true,
} as const;

export type productivityDataPointSize = keyof typeof PRODUCTIVIY_PER_HOUR_BY_KEYS;

export type DateRange = {
    min: Date,
    max: Date,
}


type DateRangeSelectorProps = {
    className?: string,
    value?: DateRange,
    onChange: (value: Exclude<Required<DateRangeSelectorProps["value"]>, undefined>) => any,
}

const pad0 = (value: number) => value.toString().padStart(2, "0")

const getHtmlDateString = (date: Date) => `${date.getFullYear()}-${pad0(date.getMonth() + 1)}-${pad0(date.getDate())}`;

export const DateRangeSelector: React.VFC<DateRangeSelectorProps> = ({
    className,
    value,
    onChange,
}) => {

    const { min: startDate, max: endDate } = Object.assign({ min: new Date(Date.now() - 1 * DAYS), max: new Date(), }, value ?? {});

    const handleStartChange: ChangeEventHandler<HTMLInputElement> = e => {
        const value = e.target.valueAsDate!;
        const newRange = { min: value, max: endDate };
        if (value.getTime() > endDate.getTime()) {
            newRange.max = value;
        }
        onChange(newRange);
    }

    const handleEndDateChange: ChangeEventHandler<HTMLInputElement> = e => {
        const value = e.target.valueAsDate!;
        const newRange = { min: startDate, max: value };
        if (value.getTime() < startDate.getTime()) {
            newRange.max = newRange.min;
        }
        onChange(newRange);
    }

    return (
        <InputGroup className={`gap-2${className && ` ${className}`}`}>
            <FormLabel className="m-0">
                <span>
                    <FormattedMessage id="GENERAL.RANGE_PICKER.START_TIME" />
                </span>
                <FormControl value={getHtmlDateString(startDate)} max={getHtmlDateString(new Date())} required type="date" onChange={handleStartChange} />
            </FormLabel>
            <FormLabel className="m-0">
                <span>
                    <FormattedMessage id="GENERAL.RANGE_PICKER.END_TIME" />
                </span>
                <FormControl value={getHtmlDateString(endDate)} min={getHtmlDateString(new Date(startDate.getTime()))} max={getHtmlDateString(new Date())} required type="date" onChange={handleEndDateChange} />
            </FormLabel>
        </InputGroup>
    );
};


export const PerHourProductivityPage: React.VFC = () => {
    const [selectedStores, setSelectedStores] = useState<StoreSelection[]>([]);
    const [dateRange, setDateRange] = useState<{ min: Date, max: Date }>({ min: new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate()), max: new Date() });
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage] = useState<number>(20);
    const [storeType, setStoreType] = useState<StoreType>("with-carts-only");
    const [DayHourValue, setdayHourValue] = useState<DaysHoursDatapoints>("hours");
    const [range, setRange] = useState<RangeType>("today");
    const [rangeCustom, setRangeCustom] = useState<boolean>(false);
    const [firstRender, setFirstRender] = useState<boolean>(true);



    const handleRangeChange = (range: RangeType) => {
        let newRange = { min: new Date(Date.now() - 1 * WEEKS), max: new Date() }
        const currentDate = new Date()
        switch (range) {
            case "today": newRange.min = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()); break;
            case "last7Days": newRange.min = new Date(Date.now() - 1 * WEEKS); break;
            case "last30Days": newRange.min = new Date(Date.now() - 30 * DAYS); break;
            case "previousMonth": newRange = { max: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), min: new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1) }; break;
            case "previousWeek":
                const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() - 6));
                const endOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7));
                newRange = { max: endOfWeek, min: startOfWeek }; break;
            case "custom":
                setRangeCustom(true); break;
        }

        if (range !== "custom") {
            setRangeCustom(false);
            setDateRange(newRange);
        }
        setRange(range)
    }


    const handleSelectedStoresChange = (selectedStores: StoreSelection[]) => setSelectedStores(selectedStores);

    const handleDayHourChange = () => {
        if (DayHourValue === "days")
            setdayHourValue("hours")
        else
            setdayHourValue("days")
    }

    const handleDateRangeChange = (value: { min: Date, max: Date }) => setDateRange({ min: value.min, max: new Date(value.max.setHours(22)) });


    const handleStoreWithCartsChange = (storesFilterOption: StoreType) => {
        setStoreType(storesFilterOption);

    }

    const onSaveExcelClick = async () => {
        const dataToExcel = await axios.get<ProductivityPerHourDataResponse>("/productivity/per-hour-productivity", {
            params: {
                stores: selectedStores.map(store => store.id),
                since: dateRange.min,
                until: dateRange.max,
                by: DayHourValue,
                storeType: storeType,
                page: -1,
                rowsPerPage: -1
            },
        });
        saveExcelFile(dataToExcel.data)
    }
    const [data, setData] = useState<ProductivityPerHourDataResponse>([]);
    const [dataLoading, setDataLodaing] = useState<boolean>(false);
    const [isRefreshAvailable, setIsRefreshAvailable] = useState<boolean>(false);
    const [glowRefresh, setGlowRefresh] = useState<boolean>(false)


    const fetchData = async (
        selectedStores: StoreSelection[],
        dateRange: Required<NonNullable<DateRangeSelectorProps['value']>>,
        DayHourValue: productivityDataPointSize,
        cancelToken: CancelToken,
        storeType: StoreType,
        page: number,
        rowsPerPage: number,
    ) => {
        // setData(response)
        try {
            setDataLodaing(true)
            const { data } = await axios.get<ProductivityPerHourDataResponse>("/productivity/per-hour-productivity", {
                cancelToken,
                params: {
                    stores: selectedStores.map(store => store.id),
                    since: dateRange.min,
                    until: dateRange.max,
                    by: DayHourValue,
                    storeType: storeType,
                    page: page,
                    rowsPerPage: rowsPerPage
                },
            });
            setDataLodaing(false)
            console.log("send")
            setData(data);
        } catch (e: any) {
            if (!e.__CANCEL__) {
                throw e;
            } else {
                console.log("canceled")
            }
        }
    };

    const debounceFetch = useMemo(() => debounce(fetchData, 1000), []);

    useEffect(() => {
        setIsRefreshAvailable(true)
        setGlowRefresh(true)
        new Promise(() => setTimeout(() => setGlowRefresh(false), 100))
    }, [selectedStores, dateRange, DayHourValue, storeType]);


    useEffect(() => {
        sendlog(logEnum.productivityScreen)
    }, [])


    useEffect(() => {
        if (firstRender) {
            const source = axios.CancelToken.source();
            debounceFetch(selectedStores, dateRange, DayHourValue, source.token, storeType, 0, rowsPerPage);
            setFirstRender(false)
        }
    }
        , [firstRender, selectedStores, dateRange, DayHourValue, storeType, rowsPerPage, debounceFetch]);


    const onClickRefresh = () => {
        setData([]);
        setPage(0)
        const source = axios.CancelToken.source();
        debounceFetch(selectedStores, dateRange, DayHourValue, source.token, storeType, 0, rowsPerPage);
        setIsRefreshAvailable(false)
        return () => source.cancel();
    }

    const intl = useIntl();

    const getSeconderyHeaderRow = () => {

        const lowerColNames = ["BRANCH", "NAME", "DATE", "TIME"]
        for (let index = 0; index < 7; index++) {
            lowerColNames.push("REVENUE")
            lowerColNames.push("TRANSACTION_NUMBER")
            lowerColNames.push("REGISTER_NUMBER")
        }
        const lowerColNamesMap = lowerColNames.map(str => intl.formatMessage({ id: 'PER_HOUR_PRODUCTIVITY.TABLE.' + str }))
        return lowerColNamesMap
    }

    const getHeaderRow = () => {

        const colNames = ["LINE", "SELF", "SMART", "TOTAL", "LINE", "SELF", "SMART"]
        const colNamesMap = colNames.map(str => intl.formatMessage({ id: 'PER_HOUR_PRODUCTIVITY.TABLE.' + str }))
        return colNamesMap
    }

    const headerRow = getHeaderRow()
    const seconderyHeaderRow = getSeconderyHeaderRow()

    function loadingLine(): string[][] {
        const loadingMessege = intl.formatMessage({ id: 'PER_HOUR_PRODUCTIVITY.TABLE.LOADING' })
        return [[loadingMessege], [loadingMessege]]
    }

    function changePage(page: number): void {
        const source = axios.CancelToken.source();
        debounceFetch(selectedStores, dateRange, DayHourValue, source.token, storeType, page, rowsPerPage);
        setIsRefreshAvailable(false)
        setPage(page)
    }

    return (
        <main>
            <header className="d-flex justify-content-evenly position-relative mt-5">
                <h1>
                    <FormattedMessage id="PER_HOUR_PRODUCTIVITY.TITLE" />
                </h1>
                <div className="position-absolute h-100 w-100">
                    <img src={toAbsoluteUrl('/media/logos/retail_logo.png')} className="h-100" alt="retail-innovation logo" />
                </div>
            </header>

            <div className="row my-4 d-flex align-items-end">
                <div className="col-2">
                    <StoreTypeSelector
                        onChange={handleStoreWithCartsChange}
                        value={storeType}
                    />
                </div>
                <div className="col-2">
                    <StoresSelector
                        onSelectedStoresChange={handleSelectedStoresChange}
                        selectedStores={selectedStores}
                        withCartsOnly={storeType === "with-carts-only"}
                    />
                </div>

                <div className="col-auto">
                    <RangeSelector
                        value={range}
                        onChange={handleRangeChange}
                    />
                </div>

                {rangeCustom && <div className="col-auto">
                    <DateRangeSelector
                        onChange={handleDateRangeChange}
                        value={dateRange}
                    />
                </div>}
                <div className="col-auto" >
                    <DayHourSelector
                        onClick={handleDayHourChange}
                        value={DayHourValue}
                    />
                </div>
                <div className="col-auto" >
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={onSaveExcelClick}
                    >
                        <img src={excelImage} alt="שמור כאקסל" style={{ width: '30px', height: '25px' }}></img>
                    </Button>
                </div>
                <div className="col-auto" >
                    <Button
                        variant={glowRefresh === true ? 'warning' : 'secondary'}
                        size='sm'
                        onClick={onClickRefresh}
                        disabled={!isRefreshAvailable}
                    >
                        <i className='bi bi-arrow-clockwise' style={{ color: 'green', fontSize: '25px' }}></i>
                    </Button>
                </div>
            </div>
            <hr />
            <article className="row gap-2">
                <div className="d-flex align-items-center justify-content-center">
                    <ButtonGroup aria-label="Basic example">
                        <Button variant="secondary" disabled={page === 0} onClick={() => changePage(page - 1)} >→</Button>
                        <Button variant="outline-light" active>{page + 1}</Button>
                        <Button variant="secondary" disabled={data.length < rowsPerPage} onClick={() => changePage(page + 1)}>←</Button>
                    </ButtonGroup>
                </div>
                <ProductivityTable data={dataLoading ? loadingLine() : data} upperColumns={headerRow} lowerColumns={seconderyHeaderRow}></ProductivityTable>
                <div className="col-xl" /> {/* just to fill up the row */}
                <div className="col-xl" /> {/* just to fill up the row */}
            </article>
        </main>
    )
};
