/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom'
import { IPOSEventResearch, IPOSEventResearchCollection } from '../interfaces/IPOSEventResearchCollection';

type Props = {
  collection: IPOSEventResearchCollection[],
  store: IPOSEventResearch;
  color?: string;
  weekNum: number;
}

const CardPOSEventResearch: FC<Props> = ({
  store,
  color = '',
  weekNum,
  collection
}) => {
  return (
    <div className='retailin-card'
      style={{
        borderTop: `8px solid ${color}`,
      }}
    >
      <div className='card-body d-flex flex-center flex-column p-3'>
        <Link className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0 testclass'
          to={{
            pathname: '/POSEventResearchDetails', state: {
              store: { id: store.id, name: store.name },
              week: weekNum,
              weekList: collection.map(({ weekNum, startDate }) => ({ weekNum, startDate }))
            }
          }
          } >{store.name + ' (' + store.storeNumber + ')'}</Link>
        <div className='fw-bold text-gray-400 mb-6'>
          {store.contactName}({store.phone})
        </div>
        <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded  mx-2 px-2 mb-2'>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}>
                <FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.UNDERLOAD_CASHIERS" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.underLoad}
              </span>
            </div>
          </div>
          <div className='border border-gray-300 border-dashed rounded   mx-2 px-2 mb-2'>
            <div className='fs-4 fw-bolder text-gray-700'>
              <span style={{ fontSize: '1rem', margin: '1px' }}>
                <FormattedMessage id="POS_EVENT_RESEARCH_SCREEN.OVERLOAD_CASHIERS" />: </span>
              <span style={{ fontSize: '1rem', padding: '1px' }}>
                {store.overLoad}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export { CardPOSEventResearch }