/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import UsageLogList, { Column } from './UsageLogList'
import { getUsageLog } from '../services/usageLogData'
import { IUsageLog } from '../interfaces/IUsageLog'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Moment from 'react-moment';
import SearchUser from './SearchUser'
import { useLocation } from 'react-router-dom'
import { IS_SORT_SIDE, setIsSortSide } from '../../auth/redux/AuthCRUD'
import { logEnum, sendlog } from '../../logs/sendLog'
import { FormattedMessage } from 'react-intl'

export default function UsageLog() {
  const [listUsageLog, setListUsageLog] = useState<IUsageLog[]>([])
  const [searchUser, setSearchUser] = useState<string>('')
  let branchSelected: any = useLocation().state || "";

  const [lastDateUpdate, setLastDateUpdate] = useState<Date | null>()

  const getUsageLogToRender = () => {
    if (!listUsageLog) return []
    return listUsageLog.filter(item => item.userName.includes(searchUser))
  };

  const getListUsageLog = async () => {
    try {
      const list: any = await getUsageLog('')
      if (list) {
        setListUsageLog(list)
        setLastDateUpdate(new Date())
      }
    } catch (err) { }
  }
  useEffect(() => {
    sendlog(logEnum.usageLogScreen);
    onSearchUser(branchSelected);
    getListUsageLog()
  }, [branchSelected])

  const onTitleClick = (titleSelected: Column) => {
    let list = [...listUsageLog];

    if (IS_SORT_SIDE) {
      if (titleSelected === 'user') list.sort((a, b) => a.userName.localeCompare(b.userName));
      else if (titleSelected === 'date') list.sort((a, b) => a.id - b.id);
      else if (titleSelected === 'action_type') list.sort((a, b) => a.actionType.localeCompare(b.actionType));
    } else {
      if (titleSelected === 'user') list.sort((a, b) => b.userName.localeCompare(a.userName));
      else if (titleSelected === 'date') list.sort((a, b) => b.id - a.id);
      else if (titleSelected === 'action_type') list.sort((a, b) => b.actionType.localeCompare(a.actionType));
    }
    setIsSortSide();
    setListUsageLog(list);
  }
  const onSearchUser = (search: string) => {
    setSearchUser(search)
  }
  return (
    <div>
      <nav className='navbar navbar-light bg-light row'>
        <div className='col-4' style={{ textAlign: "start" }}>
          <span>
            <FormattedMessage
              id={"CASHIERS_ALERTS_SCREEN.LAST_UPDATE"}
              values={{ lastUpdate: lastDateUpdate && <Moment format='DD-MM-YYYY HH:mm'>{lastDateUpdate}</Moment> }} />
          </span>
        </div>
        <h1 className='breadcrumb-item text-muted col-4 text-center'>
          <FormattedMessage id="SCREENS.DETAILED_USAGE_LOG" />
        </h1>
        <span className='col-4' style={{ textAlign: "end" }}>
          <a className='navbar-brand' href='https://yochananof.co.il/'>
            &nbsp;&nbsp;
            <img
              src={toAbsoluteUrl('/media/logos/retail_logo.png')}
              width='100'
              height='30'
              className='d-inline-block '
              alt=''
              loading='lazy'
            />
          </a>
        </span>
      </nav>
      <br />
      <br />
      <div className='row '>
        <div className='col-3'>
          <div className='row'>
            <div className='col-6'>
              <SearchUser onSearch={onSearchUser} user={searchUser}></SearchUser>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <UsageLogList onTitleClick={onTitleClick} usageLogArr={getUsageLogToRender()} />
    </div>
  )
}
