import { StoreSelection, StoresSelector } from 'app/common/components/selectors/StoresSelector'
import { DAYS, WEEKS } from 'app/common/constants'
import { debounce } from 'app/helpers/debounce'
import axios, { CancelToken } from 'axios'
import { ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Form, FormControl, FormLabel, InputGroup, Pagination } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { toAbsoluteUrl } from '_metronic/helpers'
import { RangeSelector, RangeType } from '../trends/components/RangeSelector'
import ActionTable, {
  ActionDataResponse,
  SortColumn,
  SortColumnIndex,
  saveExcelFile,
} from './table_component'
import excelImage from './excel.jpg'
import { ActionSelection, ActionSelector } from 'app/common/components/selectors/ActionSelector'
import { PopupTable, popupTableData } from './table_popup'
import { logEnum, sendlog } from '../logs/sendLog'

export type TicketsData = {
  // allData: ActionDataResponse
  // total_count: number
  headerSkip: number,
  lineActionsSkip: number
  paginationResult: ActionDataResponse
}

export type DateRange = {
  min: Date
  max: Date
}

type DateRangeSelectorProps = {
  className?: string
  value?: DateRange
  onChange: (value: Exclude<Required<DateRangeSelectorProps['value']>, undefined>) => any
}

export type SortColumnAndStatus = {
  column?: string
  status?: boolean
}

const pad0 = (value: number) => value.toString().padStart(2, '0')

const getHtmlDateString = (date: Date) =>
  `${date.getFullYear()}-${pad0(date.getMonth() + 1)}-${pad0(date.getDate())}`

export const DateRangeSelector: React.VFC<DateRangeSelectorProps> = ({
  className,
  value,
  onChange,
}) => {
  const { min: startDate, max: endDate } = Object.assign(
    { min: new Date(Date.now() - 1 * DAYS), max: new Date() },
    value ?? {}
  )

  const handleStartChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.valueAsDate!
    const newRange = { min: value, max: endDate }
    if (value.getTime() > endDate.getTime()) {
      newRange.max = value
    }
    onChange(newRange)
  }

  const handleEndDateChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.valueAsDate!
    const newRange = { min: startDate, max: value }
    if (value.getTime() < startDate.getTime()) {
      newRange.max = newRange.min
    }
    onChange(newRange)
  }

  return (
    <InputGroup className={`gap-2${className && ` ${className}`}`}>
      <FormLabel className='m-0'>
        <span>
          <FormattedMessage id='GENERAL.RANGE_PICKER.START_TIME' />
        </span>
        <FormControl
          value={getHtmlDateString(startDate)}
          max={getHtmlDateString(new Date())}
          required
          type='date'
          onChange={handleStartChange}
        />
      </FormLabel>
      <FormLabel className='m-0'>
        <span>
          <FormattedMessage id='GENERAL.RANGE_PICKER.END_TIME' />
        </span>
        <FormControl
          value={getHtmlDateString(endDate)}
          min={getHtmlDateString(new Date(startDate.getTime()))}
          max={getHtmlDateString(new Date())}
          required
          type='date'
          onChange={handleEndDateChange}
        />
      </FormLabel>
    </InputGroup>
  )
}

type paginationDivison = {
  header: number
  lineActions: number
}

export const LineActionsPage: React.VFC = () => {
  const [pagination, setPagination] = useState<paginationDivison[]>([{ header: 0, lineActions: 0 }])
  const [isButtonEnabled, setIsButtonEnabled] = useState(true)
  const [previousValues, setPreviousValues] = useState({
    dateRange: { min: new Date(), max: new Date() },
    selectedStores: [] as StoreSelection[],
    selectedActions: [] as ActionSelection[],
    fullname: '',
    supervisor: '',
    ticketNumber: '',
    ticketByEmployeeGrouping: false,
  })
  const [paginationData, setPaginationData] = useState<string[][]>([])
  // const [totalCountPages, setTotalCountPages] = useState<number>(0)
  const [ticketData, setTicketData] = useState<popupTableData[]>([])
  const [dataLoading, setDataLodaing] = useState<boolean>(false)
  const [ticketDataLoading, setTicketDataLoading] = useState<boolean>(false)
  const [selectedStores, setSelectedStores] = useState<StoreSelection[]>([])
  const [selectedActions, setSelectedActions] = useState<ActionSelection[]>([])
  const [actionsList, setActionsList] = useState<ActionSelection[]>([])
  const [selectedSubActions] = useState<ActionSelection[]>([])
  const [ticketByEmployeeGrouping, setTicketGrouping] = useState<boolean>(false)
  const [showTicket, setShowTicket] = useState<boolean>(false)
  const [showTickets, setShowTickets] = useState<boolean>(false)
  const [showTicketsButtonClicked, setShowTicketsButtonClicked] = useState<boolean>(false)
  const [sortColumn, setSortColumn] = useState<SortColumn>({})
  const sortColumnAndStatus = useRef<SortColumnAndStatus>({})
  const [glowX, setGlowX] = useState<boolean>(false)
  const currentPage = useRef<number>(1)

  let perPage = 20

  const [dateRange, setDateRange] = useState<{ min: Date; max: Date }>({
    min: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    max: new Date(),
  })
  const [fullname, setFullname] = useState<string>('')
  const [supervisor, setSupervisor] = useState<string>('')
  const [ticketNumber, setTicketNumber] = useState<string>('')
  const [minRevenue, setMinRevenue] = useState<number | undefined>()
  const [maxRevenue, setMaxRevenue] = useState<number | undefined>()
  const [ticketNum, setTicketNum] = useState<number | null>(null)
  const [sortColumnIndex, setsortColumnIndex] = useState<number | undefined>(undefined)

  const [range, setRange] = useState<RangeType>('today')
  const [rangeCustom, setRangeCustom] = useState<boolean>(false)
  const handleRangeChange = (range: RangeType) => {
    const midnight = new Date()
    midnight.setDate(midnight.getDate() + 1)
    midnight.setHours(0, 0, 0, 0)
    const tomorrow = new Date()
    tomorrow.setHours(0, 0, 0, 0)
    tomorrow.setDate(tomorrow.getDate() + 1)
    let newRange = { min: tomorrow, max: tomorrow }
    switch (range) {
      case 'today':
        const today = new Date(tomorrow)
        today.setDate(tomorrow.getDate() - 1)
        newRange.min = today
        break
      case 'last7Days':
        newRange.min = new Date(tomorrow.getTime() - 1 * WEEKS)
        break
      case 'last30Days':
        newRange.min = new Date(tomorrow.getTime() - 30 * DAYS)
        break
      case 'previousMonth':
        newRange = {
          max: new Date(tomorrow.getFullYear(), tomorrow.getMonth(), 1),
          min: new Date(tomorrow.getFullYear(), tomorrow.getMonth() - 1, 1),
        }
        break
      case 'previousWeek':
        const startOfWeek = new Date(
          tomorrow.setDate(tomorrow.getDate() - tomorrow.getDay() - 7)
        )
        const endOfWeek = new Date(
          tomorrow.setDate(tomorrow.getDate() - tomorrow.getDay() + 7)
        )
        newRange = { max: endOfWeek, min: startOfWeek }
        break
      case 'custom':
        setRangeCustom(true)
        break
    }

    if (range !== 'custom') {
      setRangeCustom(false)
      setDateRange(newRange)
    }
    setRange(range)
    setIsButtonEnabled(true)
  }

  const handleSelectedStoresChange = (selectedStores: StoreSelection[]) => {
    setSelectedStores(selectedStores)
    setIsButtonEnabled(true)
  }
  const handleSelectedActionChange = (selectedActions: ActionSelection[]) => {
    setSelectedActions(selectedActions)
    setIsButtonEnabled(true)
  }

  const handleDateRangeChange = (value: { min: Date; max: Date }) => {
    const min = value.min
    min.setHours(0, 0, 0, 0)
    const max = value.max
    max.setHours(0, 0, 0, 0)
    max.setDate(max.getDate() + 1)
    setDateRange({ min, max })

    setIsButtonEnabled(true)
  }
  const onSaveExcelClick = async () => {
    const selectedStoresNumbers = selectedStores.map((store) => store.id)
    const actionNumbers = selectedActions.map((action) => action.id)
    const subActionNumbers = selectedSubActions.map((action) => action.id)
    const { data } = await axios.post<TicketsData>("actions/exceptions-tickets", {
      stores: selectedStoresNumbers ? selectedStoresNumbers : [],
      actions: actionNumbers ? actionNumbers : [],
      subActions: subActionNumbers ? subActionNumbers : [],
      since: dateRange.min,
      until: dateRange.max,
      fullname: fullname,
      supervisor: supervisor,
      ticketNumber: ticketNumber,
      minRevenue: minRevenue,
      maxRevenue: maxRevenue,
      ticketByEmployeeGrouping: ticketByEmployeeGrouping,
      perPage: -1,
      skipHeader: 0,
      skipLineActions: 0,
      sortColumnAndStatus,
    })
    saveExcelFile(data.paginationResult, getHeaderRow())
  }
  const handleFullnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullname(event.target.value)
    setIsButtonEnabled(true)
    // onSearch(event.target.value);
  }

  const handleSupervisorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSupervisor(event.target.value)
    setIsButtonEnabled(true)
    // onSearch(event.target.value);
  }

  const handleTicketNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTicketNumber(event.target.value)
    setIsButtonEnabled(true)
  }
  const handleMinRevenueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMinRevenue(parseInt(event.target.value, 10))
    setIsButtonEnabled(true)
  }
  const handleMaxRevenueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaxRevenue(parseInt(event.target.value, 10))
    setIsButtonEnabled(true)
  }

  useEffect(() => {
    if (ticketNumber || fullname || supervisor) {
      currentPage.current = 1
    }
  }, [ticketNumber, fullname, supervisor])

  const FetchPopupData = async (
    ticketNum: number,
    cancelToken: CancelToken,
    posNumber: number,
    storeId: number,
    date: string
  ) => {
    setTicketDataLoading(true)
    const { data } = await axios.get<popupTableData[]>('/actions/ticket', {
      cancelToken,
      params: {
        ticketNum: ticketNum,
        posNumber: posNumber,
        storeId: storeId,
        date: date,
      },
    })
    setTicketDataLoading(false)
    setTicketData(data)
  }

  const setNewPagination = useCallback((currentPage: number, skipHeader: number, skipLineActions: number) => {

    const newPaginationItem =
    {
      header: pagination[currentPage - 1].header + skipHeader,
      lineActions: pagination[currentPage - 1].lineActions + skipLineActions
    }
    if (!pagination[currentPage])
      pagination.push(newPaginationItem)
    else
      pagination[currentPage] = newPaginationItem
    setPagination(pagination)
  }, [pagination, setPagination])



  const postData = useCallback(async (
    path: string,
    selectedStores: StoreSelection[],
    selectedActions: ActionSelection[],
    selectedSubActions: ActionSelection[],
    dateRange: Required<NonNullable<DateRangeSelectorProps['value']>>,
    cancelToken: CancelToken,
    fullname: string,
    supervisor: string,
    ticketNumber: string,
    minRevenue: number | undefined,
    maxRevenue: number | undefined,
    ticketByEmployeeGrouping: boolean,
    perPage: number,
    currentPage: number,
    sortColumnAndStatus: SortColumnAndStatus
  ) => {
    try {
      setDataLodaing(true)
      const selectedStoresNumbers = selectedStores.map((store) => store.id)
      const actionNumbers = selectedActions.map((action) => action.id)
      const subActionNumbers = selectedSubActions.map((action) => action.id)
      const { data } = await axios.post<TicketsData>(path, {
        cancelToken,
        stores: selectedStoresNumbers ? selectedStoresNumbers : [],
        actions: actionNumbers ? actionNumbers : [],
        subActions: subActionNumbers ? subActionNumbers : [],
        since: dateRange.min,
        until: dateRange.max,
        fullname: fullname,
        supervisor: supervisor,
        ticketNumber: ticketNumber,
        minRevenue: minRevenue,
        maxRevenue: maxRevenue,
        ticketByEmployeeGrouping: ticketByEmployeeGrouping,
        perPage,
        skipHeader: pagination[currentPage - 1].header,
        skipLineActions: pagination[currentPage - 1].lineActions,
        sortColumnAndStatus,
      })

      setDataLodaing(false)
      setPaginationData(data.paginationResult)
      setNewPagination(currentPage, data.headerSkip, data.lineActionsSkip)
    } catch (e: any) {
      if (!e.__CANCEL__) {
        throw e
      } else {
        console.log('canceled')
      }
    }
  }, [pagination, setNewPagination])

  useEffect(() => {
    const cancel = axios.CancelToken.source()
      ; (async () => {
        const { data } = await axios.get<ActionSelection[]>('/actions/actions-list', {
          cancelToken: cancel.token,
        })
        setActionsList(data)
      })()
    return () => cancel.cancel()
  }, [])

  const debouncePost = useMemo(() =>
    debounce(postData, 1000),
    [postData])

  const debouncePopupFetch = useMemo(() => debounce(FetchPopupData, 1000), [])

  const fetchDataExceptionsTickets = async () => {
    setPaginationData([])
    const source = axios.CancelToken.source()
    debouncePost(
      'actions/exceptions-tickets',
      selectedStores,
      selectedActions,
      selectedSubActions,
      dateRange,
      source.token,
      fullname,
      supervisor,
      ticketNumber,
      minRevenue,
      maxRevenue,
      ticketByEmployeeGrouping,
      perPage,
      currentPage.current,
      sortColumnAndStatus.current
    )
    setPreviousValues({
      dateRange: { min: dateRange.min, max: dateRange.max },
      selectedStores,
      selectedActions,
      fullname,
      supervisor,
      ticketNumber,
      ticketByEmployeeGrouping,
    })
    const dataChanged =
      fullname !== previousValues.fullname ||
      dateRange.min !== previousValues.dateRange.min ||
      dateRange.max !== previousValues.dateRange.max ||
      JSON.stringify(selectedStores) !== JSON.stringify(previousValues.selectedStores) ||
      JSON.stringify(selectedActions) !== JSON.stringify(previousValues.selectedActions) ||
      fullname !== previousValues.fullname ||
      supervisor !== previousValues.supervisor ||
      ticketNumber !== previousValues.ticketNumber ||
      ticketByEmployeeGrouping !== previousValues.ticketByEmployeeGrouping
    setIsButtonEnabled(!dataChanged)
  }


  useEffect(() => {
    sendlog(logEnum.lineActionsScreen)
  }, [])

  const fetchDataTickets = async () => {
    setPaginationData([])
    try {
      const show_tickets = await axios.get<boolean>('/actions/show_tickets')
      setShowTickets(show_tickets.data)
      if (showTickets) {
        const source = axios.CancelToken.source()
        postData(
          '/actions/tickets',
          selectedStores,
          selectedActions,
          selectedSubActions,
          dateRange,
          source.token,
          fullname,
          supervisor,
          ticketNumber,
          minRevenue,
          maxRevenue,
          ticketByEmployeeGrouping,
          perPage,
          currentPage.current,
          sortColumnAndStatus.current
        )
        return []
      }
    } catch (e: any) {
      if (!e.__CANCEL__) {
        throw e
      } else {
        console.log('canceled')
      }
    }
  }

  useEffect(() => {
    if (ticketNum != null && showTicket) {
      const source = axios.CancelToken.source()
      const ticketNumber = parseInt(paginationData[ticketNum][5])
      const posNumber = parseInt(paginationData[ticketNum][2])
      const storeId = parseInt(paginationData[ticketNum][0])
      const date = paginationData[ticketNum][3]
      debouncePopupFetch(ticketNumber, source.token, posNumber, storeId, date)
      return () => source.cancel()
    }
  }, [paginationData, debouncePopupFetch, showTicket, ticketNum])

  const intl = useIntl()

  const getHeaderRow = () => {
    const colNames = !ticketByEmployeeGrouping
      ? [
        'STORE_ID',
        'STORE_NAME',
        'TILL',
        'DATE',
        'HOUR',
        'TICKET',
        'SUM',
        'ACTION',
        'DESCRIPTION',
        'EMPLOYEE_NAME',
        'SUPERVISOR_NAME',
      ]
      : ['STORE_ID', 'STORE_NAME', 'EMPLOYEE_NAME', 'ACTION', 'QUANTITY', 'SUM']
    const colNamesMap = colNames.map((str) => intl.formatMessage({ id: 'LINE_ACTIONS.TABLE.' + str }))
    return colNamesMap
  }

  const closePopup = () => {
    setShowTicket(false)
  }

  const getPopupHeaderRow = () => {
    const colNames = [
      'HOUR',
      'CODE',
      'DESCRIPTION',
      'QUANTITY',
      'PRICE_PER_UNIT',
      'BRUTO',
      'SUPERVISOR_NAME',
      'NETO',
    ]
    const colNamesMap = colNames.map((str) => intl.formatMessage({ id: 'LINE_ACTIONS.TABLE.' + str }))
    return colNamesMap
  }

  const onShowTicketClick = () => {
    setShowTicket(true)
  }

  const headerRow = getHeaderRow()
  const popupHeaderRow = getPopupHeaderRow()

  function loadingLine(): string[][] {
    const loadingMessege = intl.formatMessage({ id: 'PER_HOUR_PRODUCTIVITY.TABLE.LOADING' })
    return [[loadingMessege]]
  }
  function loadingPopupLine(): popupTableData[] {
    const loadingMessege = intl.formatMessage({ id: 'PER_HOUR_PRODUCTIVITY.TABLE.LOADING' })
    return [{ code: loadingMessege }]
  }
  const handleRowClick = (rowNum: number) => {
    setTicketNum(rowNum === ticketNum ? null : rowNum)
  }

  const handleColumnClick = (index: number) => {
    if (index !== sortColumnIndex) {
      const newSortColumn: SortColumn = {}
      const newSortColumnAnsStatus: SortColumnAndStatus = {
        column: SortColumnIndex[index],
        status: true,
      }
      newSortColumn[SortColumnIndex[index]] = true
      setSortColumn(newSortColumn)
      sortColumnAndStatus.current = newSortColumnAnsStatus
      setsortColumnIndex(index)
      setPagination([{ header: 0, lineActions: 0 }])
      paginate(1)
      fetchDataExceptionsTickets()
    } else {
      if (sortColumn[SortColumnIndex[index]]) {
        const newSortColumn: SortColumn = {}
        newSortColumn[SortColumnIndex[index]] = false
        setSortColumn(newSortColumn)

        const newSortColumnAnsStatus: SortColumnAndStatus = {
          column: SortColumnIndex[index],
          status: false,
        }
        sortColumnAndStatus.current = newSortColumnAnsStatus
        fetchDataExceptionsTickets()
      } else {
        setSortColumn({})
        sortColumnAndStatus.current = {}
        setsortColumnIndex(undefined)
        fetchDataExceptionsTickets()
      }
    }
  }

  const setAllAsDefault = () => {
    setSupervisor('')
    setTicketNumber('')
    setSortColumn({})
    setsortColumnIndex(undefined)
    setTicketNum(null)
  }

  const handleTicketGroupingChange = () => {
    setAllAsDefault()
    setTicketGrouping(!ticketByEmployeeGrouping)
    const source = axios.CancelToken.source()
    debouncePost(
      'actions/exceptions-tickets',
      selectedStores,
      selectedActions,
      selectedSubActions,
      dateRange,
      source.token,
      fullname,
      supervisor,
      ticketNumber,
      minRevenue,
      maxRevenue,
      !ticketByEmployeeGrouping,
      perPage,
      currentPage.current,
      sortColumnAndStatus.current
    )
  }
  const handleOnClickAnywhere = () => {
    if (showTicket) {
      setGlowX(true)
      new Promise(() => setTimeout(() => setGlowX(false), 200))
    }
  }

  const handleChangeShowTicketsButton = () => {
    setShowTicketsButtonClicked(!showTicketsButtonClicked)
    if (!showTicketsButtonClicked) {
      fetchDataTickets()
    } else {
      fetchDataExceptionsTickets()
    }
  }

  const paginate = async (pageNumber: number) => {
    currentPage.current = pageNumber
    if (showTicketsButtonClicked) {
      fetchDataTickets()
    } else {
      fetchDataExceptionsTickets()
    }
  }

  return (
    <main onClick={handleOnClickAnywhere}>
      <header className='d-flex justify-content-evenly position-relative mt-5'>
        <h1>
          <FormattedMessage id='LINE_ACTIONS.TITLE' />
        </h1>
        <div className='position-absolute h-100 w-100'>
          <img
            src={toAbsoluteUrl('/media/logos/retail_logo.png')}
            className='h-100'
            alt='retail-innovation logo'
          />
        </div>
      </header>

      <div className='row my-4 d-flex align-items-end'>
        <div className='col-2'>
          <StoresSelector
            onSelectedStoresChange={handleSelectedStoresChange}
            selectedStores={selectedStores}
            withCartsOnly={false}
          />
        </div>

        <div className='col-auto'>
          <RangeSelector value={range} onChange={handleRangeChange} />
        </div>

        {rangeCustom && (
          <div className='col-auto'>
            <DateRangeSelector onChange={handleDateRangeChange} value={dateRange} />
          </div>
        )}
        <div className='col-auto'>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type='text'
              placeholder={intl.formatMessage({ id: 'LINE_ACTIONS.FULLNAME' })}
              value={fullname}
              onChange={handleFullnameChange}
              style={{ width: '18ch' }}
              className=' border bg-white z-index-1 w-max-content text-nowrap '
            />
            <input
              type='text'
              placeholder={intl.formatMessage({ id: 'LINE_ACTIONS.SUPERVISOR' })}
              value={supervisor}
              onChange={handleSupervisorChange}
              style={{ width: '18ch' }}
              className=' border bg-white z-index-1 w-max-content text-nowrap '
              disabled={ticketByEmployeeGrouping}
            />
          </div>
        </div>
        <div className='col-auto'>
          <input
            type='text'
            placeholder={intl.formatMessage({ id: 'LINE_ACTIONS.TICKET_NUMBER' })}
            value={ticketNumber}
            onChange={handleTicketNumberChange}
            style={{ width: '18ch', height: '5ch' }}
            className=' border bg-white z-index-1 w-max-content text-nowrap'
            disabled={ticketByEmployeeGrouping}
          />
        </div>
        <div className='col-auto'>
          <input
            type='number'
            placeholder={intl.formatMessage({ id: 'LINE_ACTIONS.MIN_REVENUE' })}
            value={minRevenue}
            onChange={handleMinRevenueChange}
            style={{ width: '10ch', height: '5ch', textAlign: "right" }}
            className=' border bg-white z-index-1 w-max-content text-nowrap text-center'
            disabled={ticketByEmployeeGrouping}
          />
          -
          <input
            type='number'
            placeholder={intl.formatMessage({ id: 'LINE_ACTIONS.MAX_REVENUE' })}
            value={maxRevenue}
            onChange={handleMaxRevenueChange}
            style={{ width: '10ch', height: '5ch', textAlign: "right" }}
            className=' border bg-white z-index-1 w-max-content text-nowrap'
            disabled={ticketByEmployeeGrouping}
          />
        </div>
        <div className='col-auto'>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ActionSelector
              selectedActions={selectedActions}
              actions={actionsList}
              selectorName={intl.formatMessage({ id: 'GENERAL.SELECT_ACTIONS' })}
              onSelectedActionChange={handleSelectedActionChange}
            />
            {/* </div>
                <div className="col-auto"> */}

            {/* <ActionSelector
                            selectedActions={selectedSubActions}
                            selectorName={intl.formatMessage({ id: "GENERAL.SELECT_SUB_ACTIONS" })}
                            onSelectedActionChange={handleSelectedSubActionChange}
                            baseUrl="/actions/sub-actions-list"
                        /> */}
          </div>
        </div>
        <div className='col-auto'>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Form.Check
              type='checkbox'
              id='searchQueryCheckbox'
              checked={ticketByEmployeeGrouping}
              onChange={handleTicketGroupingChange}
              label={intl.formatMessage({ id: 'LINE_ACTIONS.TICKET_GROUPING' })}
              className=' form-control text-muted'
            />
          </div>
        </div>
        {showTickets && (
          <div className='col-auto'>
            <Form.Check
              type='checkbox'
              id='showTicketsCheckbox'
              checked={showTicketsButtonClicked}
              onChange={handleChangeShowTicketsButton}
              label={intl.formatMessage({ id: 'LINE_ACTIONS.SHOW_TICKETS' })}
              className=' form-control text-muted'
            />
          </div>
        )}
        {ticketNum != null ? (
          <div className='col-auto'>
            <Button variant='secondary' onClick={onShowTicketClick}>
              {intl.formatMessage({ id: 'LINE_ACTIONS.SHOW_TICKET' })}
            </Button>
          </div>
        ) : null}
        <div className='col-auto'>
          <Button variant='secondary' size='sm' onClick={onSaveExcelClick}>
            <img src={excelImage} alt='שמור כאקסל' style={{ width: '30px', height: '25px' }}></img>
          </Button>
        </div>
        <div className=' my-4 d-flex align-items-end justify-content-center'>
          <Button //refresh button
            variant='secondary'
            size='sm'
            onClick={() => {
              currentPage.current = 1
              setPagination([{ "header": 0, "lineActions": 0 }])
              fetchDataExceptionsTickets()
            }}
            disabled={!isButtonEnabled}
          >
            <i className='bi bi-arrow-clockwise' style={{ color: 'green', fontSize: '20px' }}></i>
          </Button>
        </div>
      </div>
      <hr />

      <article className='row gap-2'>
        <ActionTable
          data={dataLoading ? loadingLine() : paginationData}
          upperColumns={headerRow}
          handleRowClick={handleRowClick}
          checkedRow={ticketNum}
          sortColumn={sortColumn}
          handleColumnClick={handleColumnClick}
        />

        <Pagination size='lg'>
          {/*
          <Pagination.First onClick={() => paginate(1)} disabled={currentPage.current === 1} />
          */}
          <Pagination.Prev
            onClick={() => paginate(currentPage.current > 1 ? currentPage.current - 1 : 1)}
            disabled={currentPage.current === 1}
          />
          <Pagination.Next
            onClick={() => paginate(currentPage.current + 1)}
            disabled={paginationData.length < perPage}
          />

          {/*
          <Pagination.Last
            onClick={() => paginate(totalPages)}
            disabled={currentPage.current === totalPages}
          />*/}
        </Pagination>
      </article>
      {showTicket && (
        <PopupTable
          ticketLine={paginationData[ticketNum ? ticketNum : 0]}
          data={ticketDataLoading ? loadingPopupLine() : ticketData}
          upperColumns={popupHeaderRow}
          closeWindow={closePopup}
          glowX={glowX}
          actionsList={actionsList}
        />
      )}
    </main>
  )
}


