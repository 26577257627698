import { FormSelect } from "react-bootstrap";
import { useIntl } from "react-intl";

export type StoreType = "all-stores" | "with-carts-only";

export const StoreTypeSelector: React.VFC<{ value: StoreType, onChange: (value: StoreType) => any }> = ({
    value,
    onChange,
}) => {
    const handleChange = (value: StoreType) => onChange(value);
    const intl = useIntl();
    return (
        <FormSelect onChange={e => handleChange(e.currentTarget.value as StoreType)} value={value}>
            <option value="all-stores">{intl.formatMessage({ id: "PRODUCTIVITY_STATUS_REPORT_SCREEN.ALL_STORES" })}</option>
            <option value="with-carts-only">{intl.formatMessage({ id: "PRODUCTIVITY_STATUS_REPORT_SCREEN.STORES_WITH_CARTS_ONLY" })}</option>
        </FormSelect>
    )
}
