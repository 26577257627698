import { CardUsageLevel } from '../../../../_metronic/partials/content/cards/CardUsageLevel'
import { IUsage } from '../interfaces/IStore'

interface Props {
  usageLevel: IUsage
}

const listColor = [
  { status: 1, color: '#50cd89', icon: 'fa-smile-beam' },
  { status: 2, color: '#ffc700', icon: 'fas fa-exclamation-circle' },
  { status: 3, color: '#f1416c', icon: 'fa-times-circle' },
  { status: 4, color: '#6f42c1', icon: 'fa-times-circle' },
]

const UsageLevel = (props: Props) => {
  const usageLevel: IUsage = props.usageLevel
  let colorStyle = listColor.find((item) => item.status === usageLevel.status)?.color

  return (
    <div style={{ width: "fit-content" }}>
      <CardUsageLevel
        userName={usageLevel.userName}
        currentWeekActions={usageLevel.currentWeekActions}
        previousWeekActions={usageLevel.previousWeekActions}
        todayActions={usageLevel.todayActions}
        yesterdayActions={usageLevel.yesterdayActions}
        lastAction={usageLevel.lastAction}
        status={usageLevel.status}
        color={colorStyle}
      />
    </div>
  )
}
export default UsageLevel
