import { FormSelect } from "react-bootstrap";
import { useIntl } from "react-intl";

const DATAPOINTS_SIZE_TO_MESSAGE = {
    "days": "DAYS",
    "weeks": "WEEKS",
    "months": "MONTHS",
} as const;

export type DatapointSize = keyof typeof DATAPOINTS_SIZE_TO_MESSAGE;

type DatapointsSizeSelectorProps = {
    value: DatapointSize,
    onChange: (value: DatapointSize) => any,
};

export const DatapointSizeSelector: React.VFC<DatapointsSizeSelectorProps> = ({
    value,
    onChange
}) => {
    const intl = useIntl();

    const handleChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
        onChange(e.target.value as DatapointSize);
    }

    return (
        <FormSelect value={value} onChange={handleChange}>
            {Object.entries(DATAPOINTS_SIZE_TO_MESSAGE).map(([value, messageKey]) => (
                <option key={value} value={value}>
                    {intl.formatMessage({ id: `TREND_ANALYSIS_SCREEN.DATAPOINTS_SIZE.${messageKey}` })}
                </option>
            ))}
        </FormSelect>
    );
}
