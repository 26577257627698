import { useEffect, useState } from 'react'
import axios from 'axios';
import { useIntl } from 'react-intl';



type UserLevel = {
  id: number,
  level: string,
}



export const UserLevelOptions = () => {
  const [userLevels, setUserLevels] = useState<UserLevel[]>([]);
  const intl = useIntl()

  useEffect(() => {
    const cancel = axios.CancelToken.source();
    (async () => {
      const { data } = await axios.get("/users/levels", {
        cancelToken: cancel.token,
      });
      setUserLevels(data);
    })();
    return () => cancel.cancel();
  }, []);


  return (
    <>
      <option value="" disabled selected>{intl.formatMessage({ id: "REGISTER_USER_SCREEN.USER_LEVEL" })}</option>
      {userLevels.map(ul => <option value={ul.id}>{ul.level}</option>)}
    </>
  )
}

