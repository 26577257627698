/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import UsageLevelList from './UsageLevelList'
import { getStores as getUsageLevels } from '../services/storesData'
import { IUsage } from '../interfaces/IStore'
import FilterUsageLevel from './FilterUsageLevel'
import { filtersUsageLevel } from '../helpers/filtersStores'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Moment from 'react-moment';
import { STATUS_TYPE } from '../../auth/redux/AuthCRUD'
import { auto } from '@popperjs/core'
import SearchStore from './SearchStore'
import { logEnum, sendlog } from '../../logs/sendLog'
import { FormattedMessage } from 'react-intl'

const sortByName = (a: any, b: any) => {
  if (a["name"] < b["name"]) {
    return -1
  }
  if (a["name"] > b["name"]) {
    return 1
  }
  return 0;
}

const filterNoLastAction = (list: IUsage[]) => {
  return list.filter((item) => item.lastAction !== "")
}

const sortByStatus = (a: any, b: any) => {
  if (a[STATUS_TYPE] > b[STATUS_TYPE]) {
    return -1
  }
  if (a[STATUS_TYPE] < b[STATUS_TYPE]) {
    return 1
  }
  return 0;
};

const sortConfig = [
  { field: 'name', func: sortByName },
  { field: 'status', func: sortByStatus },
]
export default function UsageLevels() {
  const [listUsageLevels, setListUsageLevels] = useState<IUsage[]>([])
  const [statusFilter, setStatusFilter] = useState<number>(0)
  const [searchStore, setSearchStore] = useState<string | null>()
  const [orderStore] = useState<string>('name')
  const [lastDateUpdate] = useState<Date | null>(new Date())

  const getUsageLevelToRender = () => {
    if (!listUsageLevels) return [];

    const filterRun = filtersUsageLevel.find((filter) => filter.status === statusFilter);
    if (!filterRun) {
      return listUsageLevels
    }

    return listUsageLevels
      .filter(filterRun.filter)
      .filter((item) => {
        if (!searchStore) return true
        return item.userName.includes(searchStore)
      })
      .sort(sortStore)
  };

  const sortStore = (a: any, b: any) => {
    const funcSort = sortConfig.find(s => s.field === orderStore)?.func;
    if (funcSort) {
      return funcSort(a, b);
    }
    return 1;
  }

  const getListUsageLevels = async (listReq: IUsage[] = []) => {
    try {
      let list = listReq;
      if (list.length === 0) {
        list = await getUsageLevels();
      }
      if (list) {
        setListUsageLevels(filterNoLastAction(list));
      }
    } catch (err) { }
  }

  useEffect(() => {
    sendlog(logEnum.usageLevelScreen);
    getListUsageLevels();//just on the first time
  }, [])
  const onFilter = (status: number) => {
    setStatusFilter(status);

    console.log("status cliced: " + status);
  }
  const onSearch = (search: string) => {
    setSearchStore(search)
  }

  const img = <img
    src={toAbsoluteUrl('/media/logos/retail_logo.png')}
    width='100'
    height='30'
    className='d-inline-block '
    alt=''
    loading='lazy'
  />
  return (
    <div>
      <nav className='navbar navbar-light row justify-content-between'>
        <div className='col-auto'>
          <span>
            <FormattedMessage
              id={"CASHIERS_ALERTS_SCREEN.LAST_UPDATE"}
              values={{ lastUpdate: <Moment format='DD-MM-YYYY HH:mm'>{lastDateUpdate ? lastDateUpdate : ''}</Moment> }} />
          </span>
        </div>
        <h1 className='breadcrumb-item text-muted col-auto'>
          <FormattedMessage id="SCREENS.USAGE_LEVELS" />
        </h1>
        <span className='col-auto'>
          <a className='navbar-brand' href='https://yochananof.co.il/'>
            &nbsp;&nbsp;
            {img}
          </a>
        </span>
      </nav>
      <br />
      <br />
      <div className='row '>
        <div className='col-xl-2 col-md-3 col-sm-12'>
          <SearchStore onSearch={onSearch}></SearchStore>
        </div>
        <div style={{ marginLeft: auto, }} className='col-12 col-md-auto  pe-6 ps-5'>
          <div className='row'>
            <div className='col-12 filter-buttons'>
              <FilterUsageLevel onFilter={onFilter} listStores={listUsageLevels} />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <UsageLevelList stores={getUsageLevelToRender()} />
    </div>
  )
}
