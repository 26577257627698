import Table from 'react-bootstrap/Table';
import * as ExcelJS from "exceljs"
import { saveAs } from 'file-saver';

const colors = ["#FFCCCC", "#CCCCFF", "#CCFFCC", "#FFFFCC"];

function makeUpperColumns(upperColumns: string[]) {
    return (
        <tr style={{ fontWeight: "bold", textAlign: "center", border: "1px solid #ddd" }}>
            <th colSpan={4} style={{ backgroundColor: "GREY", position: "sticky", zIndex: 1 }}></th>
            {
                upperColumns.map((col, index) => (
                    <th colSpan={3} style={{ backgroundColor: colors[index % colors.length] }}>
                        {col}
                    </th>
                ))
            }
        </tr>
    )
}


function makeLowerColumns(lowerColumns: string[]) {
    return (
        <tr style={{ border: "1px solid #ddd", alignContent: "center" }}>
            {lowerColumns.map(col => <th>{col}</th>)
            }
        </tr>
    )
}

function makeDataIntoRows(data: string[][]) {

    return data.map((row, i) => (
        <tr key={i} style={{ border: "1px solid #ddd", backgroundColor: i + 1 === data.length ? "yellow" : "lightgray" }} >
            {row.map((cellValue, j) => (
                <td key={j} style={{ textAlign: "center", whiteSpace: 'nowrap' }}>{cellValue}</td>
            ))}
        </tr>
    ));
}

interface ITableProps {
    data: Array<Array<string>>;
    upperColumns: Array<string>;
    lowerColumns: Array<string>;
}


export type ProductivityPerHourDataResponse = string[][];


export type ProductivityPerHourData = {
    store_id: number,
    store_name: string,
    transactionDate: string,
    timeSlot: string,
    lineRevenue: number,
    lineTransactionCount: number,
    lineRegisterCount: number,
    selfRevenue: number,
    selfTransactionCount: number,
    selfRegisterCount: number,
    smartRevenue: number,
    smartTransactionCount: number,
    smartRegisterCount: number,
};



function ProductivityTable({ data, upperColumns, lowerColumns }: ITableProps) {

    return (
        <Table striped bordered className='prodTable' style={{ tableLayout: 'fixed', display: 'inline-block' }}>
            <thead>
                {makeUpperColumns(upperColumns)
                }
            </thead>
            <tbody>
                {
                    makeLowerColumns(lowerColumns)
                }
                {
                    makeDataIntoRows(data)
                }
            </tbody>
        </Table>
    );
}




export async function saveExcelFile(data: string[][]) {
    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    data = getHeaders.concat(data)
    makeUpperHeader(worksheet, data[0])
    colorRows(worksheet, data)
    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(blob, 'productivity.xlsx');
}


function colorRows(worksheet: ExcelJS.Worksheet, data: string[][]) {
    const colors = ["D3D3D3", "FFFFFF"] //lightgrey, white
    for (let index = 1; index < data.length - 1; index++) {
        const row = worksheet.addRow(data[index])
        for (let jindex = 0; jindex < data[index].length; jindex++) {
            const cell = row.getCell(jindex + 1)
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: colors[index % 2] } };
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        }
    }
    colorLastRow(worksheet, data[data.length - 1])
}

function colorLastRow(worksheet: ExcelJS.Worksheet, data: string[]) {
    const row = worksheet.addRow(data)
    const yellow = "FFFF00"
    for (let jindex = 0; jindex < data.length; jindex++) {
        const cell = row.getCell(jindex + 1)
        if (cell.value != null && cell.value !== '') {
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: yellow } };
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        }
    }
}


function intToExcelColumn(index: number) {
    let column = '';
    while (index > 0) {
        const remainder = (index - 1) % 26;
        column = String.fromCharCode(65 + remainder) + column;
        index = Math.floor((index - 1) / 26);
    }
    return column;
}


export const getHeaders = [
    ["", "קופות קו",
        "קופות שרות עצמי",
        "עגלות חכמות",
        "סה\"כ",
        "קופות קו",
        "קופות שרות עצמי",
        "עגלות חכמות",],
    ["מס סניף", "שם", "תאריך", "שעה",
        "פדיון", "מספר עסקאות", "מספר קופות",
        "פדיון", "מספר עסקאות", "מספר קופות",
        "פדיון", "מספר עסקאות", "מספר קופות",
        "פדיון", "מספר עסקאות", "מספר קופות",
        "פדיון", "מספר עסקאות", "מספר קופות",
        "פדיון", "מספר עסקאות", "מספר קופות",
        "פדיון", "מספר עסקאות", "מספר קופות",
    ]
]

function makeUpperHeader(worksheet: ExcelJS.Worksheet, row: string[]) {
    const colors = ["FFCCCC", "CCCCFF", "CCFFCC", "FFFFCC"];
    const rowLength = row.length * 3 + 4
    const emptyRow = worksheet.addRow(Array(rowLength).fill(null));
    for (let index = row.length - 1; index >= 1; index--) {
        const col = intToExcelColumn((index) * 3 + 2)
        const endCol = intToExcelColumn((index) * 3 + 4)
        const cell = emptyRow.getCell(index * 3 + 2)
        worksheet.mergeCells(`${col}1:${endCol}1`);
        cell.value = row[index]; // Assuming the first value spans three cells
        cell.alignment = { horizontal: 'center' }; // Optional: Center the text
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
        };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: colors[index % 4]
            }
        }

        cell.font = { bold: true }
    }
}



export default ProductivityTable;

