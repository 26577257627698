/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { register } from '../redux/AuthCRUD'
import { Link, useHistory } from 'react-router-dom'
// import { StoreSelection } from 'app/common/components/selectors/StoresSelector'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormSelect } from 'react-bootstrap'
import { UserLevelOptions } from 'app/modules/usersList/components/userLevelOptions'
import { StoreOptions } from 'app/modules/usersList/components/storeOptions'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  userLevel: '',
  storeId: '-1',
  phone: undefined,
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  userLevel: Yup.number().required('userLevel is required'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(true),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const go_back = () => {
    history.push({
      pathname: '/userList',
    })
  }

  const intl = useIntl()

  const makeStatusByType = (setStatus: (messege: string) => void, messege: string) => {
    switch (messege) {
      case 'email exists': setStatus(intl.formatMessage({ id: 'REGISTER_USER_SCREEN.EMAIL_ERROR' })); break;
      default: setStatus(intl.formatMessage({ id: 'REGISTER_USER_SCREEN.REGISTATION_FAIL' })); break;
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (!formik.isValid) return
      setLoading(true)
      setTimeout(() => {
        register({
          email: values.email,
          name: `${values.firstname} ${values.lastname}`,
          password: values.password,
          storeId: values.storeId,
          userLevel: { id: values.userLevel, level: values.userLevel },
          phone: values.phone,
        })
          .then(({ data }) => {
            setLoading(false)
            setSubmitting(false)
            if (data.messege === 'success') {
              go_back()
            } else {
              makeStatusByType(setStatus, data.messege)

            }
            // dispatch(auth.actions.login(accessToken, refreshToken, userLevel))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus(setStatus(intl.formatMessage({ id: 'REGISTER_USER_SCREEN.REGISTRATION_ERROR' })))
          })
      }, 1000)
    },
  })

  return (
    <div className='block text-center'>
      <form
        className='form w-50 mx-auto text-left inline-block fv-plugins-bootstrap5 fv-plugins-framework '
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='mb-10 text-center'>
          <h1 className='text-dark mb-3'>
            <FormattedMessage id={'REGISTER_USER_SCREEN.CREATE_ACCOUNT'} />
          </h1>
        </div>

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group Firstname */}
        <div className='row fv-row mb-7'>
          <div className='col-xl-6'>
            <label className='class="form-label fw-bolder text-dark fs-6'>
              <FormattedMessage id={'REGISTER_USER_SCREEN.FISRT_NAME'} />
            </label>
            <input
              placeholder={intl.formatMessage({ id: 'REGISTER_USER_SCREEN.FISRT_NAME' })}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('firstname')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.firstname && formik.errors.firstname,
                },
                {
                  'is-valid': formik.touched.firstname && !formik.errors.firstname,
                }
              )}
            />
            {formik.touched.firstname && formik.errors.firstname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.firstname}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xl-6'>
            {/* begin::Form group Lastname */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                <FormattedMessage id={'REGISTER_USER_SCREEN.LAST_NAME'} />
              </label>
              <input
                placeholder={intl.formatMessage({ id: 'REGISTER_USER_SCREEN.LAST_NAME' })}
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('lastname')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.lastname && formik.errors.lastname,
                  },
                  {
                    'is-valid': formik.touched.lastname && !formik.errors.lastname,
                  }
                )}
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.lastname}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Email */}
        <div className='fv-row mb-7'>
          <label className='form-label fw-bolder text-dark fs-6'>
            <FormattedMessage id={'REGISTER_USER_SCREEN.EMAIL'} />
          </label>
          <input
            placeholder={intl.formatMessage({ id: 'REGISTER_USER_SCREEN.EMAIL' })}
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Email */}
        <div className='fv-row mb-7'>
          <label className='form-label fw-bolder text-dark fs-6'>
            <FormattedMessage id={'REGISTER_USER_SCREEN.PHONE'} />
          </label>
          <input
            placeholder={intl.formatMessage({ id: 'REGISTER_USER_SCREEN.PHONE' })}
            type='phone'
            autoComplete='off'
            {...formik.getFieldProps('phone')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.phone && formik.errors.phone },
              {
                'is-valid': formik.touched.phone && !formik.errors.phone,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className='mb-10 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>
              <FormattedMessage id={'REGISTER_USER_SCREEN.PASSWORD'} />
            </label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder={intl.formatMessage({ id: 'REGISTER_USER_SCREEN.PASSWORD' })}
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>
            <FormattedMessage id={'REGISTER_USER_SCREEN.CONFIRM_PASSWORD'} />
          </label>
          <input
            type='password'
            placeholder={intl.formatMessage({ id: 'REGISTER_USER_SCREEN.CONFIRM_PASSWORD' })}
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Firstname */}

        <div className='row fv-row mb-7'>
          <div className='col-xl-6'>
            <label className='class="form-label fw-bolder text-dark fs-6'>
              <FormattedMessage id={'REGISTER_USER_SCREEN.STORE'} />{' '}
            </label>
            <FormSelect
              {...formik.getFieldProps('storeId')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.storeId && formik.errors.storeId,
                },
                {
                  'is-valid': formik.touched.storeId && !formik.errors.storeId,
                }
              )}
            >
              <StoreOptions />
            </FormSelect>

            {formik.touched.storeId && formik.errors.storeId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.storeId}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xl-6'>
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                <FormattedMessage id={'REGISTER_USER_SCREEN.USER_LEVEL'} />
              </label>
              <FormSelect
                {...formik.getFieldProps('userLevel')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.userLevel && formik.errors.userLevel,
                  },
                  {
                    'is-valid': formik.touched.userLevel && !formik.errors.userLevel,
                  }
                )}
              >
                <UserLevelOptions />
              </FormSelect>
              {formik.touched.userLevel && formik.errors.userLevel && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.userLevel}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting}
          >
            {!loading && (
              <span className='indicator-label'>
                <FormattedMessage id={'REGISTER_USER_SCREEN.SUBMIT'} />
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/userList'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              <FormattedMessage id={'REGISTER_USER_SCREEN.CANCEL'} />
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </div>
  )
}


