import { IPOSEventResearch, IPOSEventResearchCollection } from '../interfaces/IPOSEventResearchCollection';
import Store from './Store'
interface props {
  collection: IPOSEventResearchCollection[],
  stores: IPOSEventResearch[],
  weekNum:number
}
export default function StoresList(props: props) {
  const stores = props.stores || []
  const weekNum:number = props.weekNum

  const listRender = stores.map((item: any) => {
    return <Store key={item.id} store={item} weekNum={weekNum}  collection={props.collection}></Store>
  });
  return (
    <div className='row g-6 g-xl-9 justify-content-center' id="main-list">
      {listRender}
    </div>
  )
}
