/**
 * NOTE(ItzikSn):
 * This component is used with React.lazy for loading this css file conditionally.
 * Idea from: https://stackoverflow.com/a/59631562
 */
import { FC } from "react"
import "_metronic/assets/css/style.css"

type Props = {};

const LTRLayout: FC<Props> = () => <></>

export default LTRLayout;