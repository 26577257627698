/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react'
import StoresList from './StoresList'
import { getStores } from '../services/storesData'
import { IRecommendedStandard } from '../interfaces/IRecommendedStandard'
import FilterStores from './FilterStores'
import SearchStore from './SearchStore'
import { filtersStores } from '../helpers/filtersStores'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import Moment from 'react-moment';
import { setRecommendedStandardScreenStatusType, STATUS_TYPE } from '../../auth/redux/AuthCRUD'
import { logEnum, sendlog } from '../../logs/sendLog'
import StatusBy from './StatusBy'
import SortPOSType from './SortPOSType'
import { FilterPosType, FilterStatusType, RecommendationFilterContext } from 'app/routing/PrivateRoutes'
import { FormattedMessage } from 'react-intl'

const sortByName = (a: any, b: any) => {
  if (a["name"] < b["name"]) {
    return -1
  }
  if (a["name"] > b["name"]) {
    return 1
  }
  return 0;
}
const sortByStatus = (a: any, b: any) => {
  if (a[STATUS_TYPE] > b[STATUS_TYPE]) {
    return -1
  }
  if (a[STATUS_TYPE] < b[STATUS_TYPE]) {
    return 1
  }
  return 0;
};

const sortConfig = [
  { field: 'name', func: sortByName },
  { field: 'status', func: sortByStatus },
]

const standardStatusToFilterStatus = {
  1: 3,
  2: 2,
  3: 1,
};

type Props = { historyMode: boolean };

export const RecommendedStandard = ({ historyMode }: Props) => {
  const [recommendationFilter, setRecommendationFilter] = useContext(RecommendationFilterContext);

  const [searchStore, setSearchStore] = useState<string>(recommendationFilter.search);
  const [statusFilter, setStatusFilter] = useState<number>(recommendationFilter.statusValue);
  const [sortPosType, setSortPosType] = useState(recommendationFilter.posType);
  const [statusType, setStatusByType] = useState(recommendationFilter.statusType);

  useEffect(() => {
    setSearchStore(recommendationFilter.search);
    setStatusFilter(recommendationFilter.statusValue);
    setSortPosType(recommendationFilter.posType);
    setStatusByType(recommendationFilter.statusType);
  }, [recommendationFilter])

  const [listStores, setListStores] = useState<IRecommendedStandard[]>([])
  const [orderStore] = useState<string>('name')
  const [displayGraph, setDisplayGraph] = useState<boolean>(true);
  const [lastDateUpdate, setLastDateUpdate] = useState<Date | null>()

  const getStoreToRender = () => {
    if (!listStores) return [];

    let filterRun;
    if (!historyMode) {
      filterRun = {
        filter: (item: IRecommendedStandard) => statusFilter === 0 || (
          item.progressingStandardStatus in standardStatusToFilterStatus
          && standardStatusToFilterStatus[item.progressingStandardStatus as keyof typeof standardStatusToFilterStatus] === statusFilter)
      };
    } else {
      filterRun = filtersStores.find((filter) => filter.status === statusFilter);
    }
    if (!filterRun) {
      return listStores
    }

    return listStores
      .filter(filterRun.filter)
      .filter((item) => {
        if (sortPosType === 'withIndepended') {
          if (!item.hasIndependedPOS) return false;
        }
        else if (sortPosType === 'withoutIndepended') {
          if (item.hasIndependedPOS) return false;
        }
        else if (sortPosType === 'standardInProgress') {
          if (item.progressingStandardStatus !== 1) return false;
        }
        else if (sortPosType === 'approvedStandard') {
          if (item.progressingStandardStatus !== 2) return false;
        }
        else if (sortPosType === 'updatedStandard') {
          if (item.progressingStandardStatus !== 3) return false;
        }
        if (!searchStore) return true
        return item.name.includes(searchStore)
      })
      .sort(sortStore)
  };

  const sortStore = (a: any, b: any) => {
    const funcSort = sortConfig.find(s => s.field === orderStore)?.func;
    if (funcSort) {
      return funcSort(a, b);
    }
    return 1;
  }

  useEffect(() => {
    sendlog(logEnum.recommendedStandardScreen);
    getStores().then(setListStores).then(() => {
      setLastDateUpdate(new Date());
    });
  }, [])
  const onFilter = (status: number) => {
    setRecommendationFilter({ ...recommendationFilter, statusValue: status });
  }
  const onSearch = (search: string) => {
    setRecommendationFilter({ ...recommendationFilter, search });
  }
  const onStatusBy = (statusSelected: FilterStatusType) => {
    setRecommendationFilter({ ...recommendationFilter, statusType: statusSelected });
    setRecommendedStandardScreenStatusType(statusSelected);
  }
  const onSortPosType = (selectedValue: FilterPosType) => {
    setRecommendationFilter({ ...recommendationFilter, posType: selectedValue });
  }

  const setDisplayGraphChoose = () => {
    if (displayGraph) sendlog(logEnum.recommendedCloseGraph);
    else sendlog(logEnum.recommendedOpenGraph);
    setDisplayGraph(!displayGraph)
  }

  const img = <img
    src={toAbsoluteUrl('/media/logos/retail_logo.png')}
    width='100'
    height='30'
    className='d-inline-block '
    alt=''
    loading='lazy'
  />
  return (
    <div>
      <nav className='navbar navbar-light row justify-content-between'>
        <div className='col-auto'>
          <span>
            {lastDateUpdate &&
              <div>
                <span>
                  <FormattedMessage
                    id={"CASHIERS_ALERTS_SCREEN.LAST_UPDATE"}
                    values={{ lastUpdate: <Moment format='DD-MM-YYYY HH:mm'>{lastDateUpdate ? lastDateUpdate : ''}</Moment> }} />
                </span>
              </div>}
            {!lastDateUpdate && < img src={toAbsoluteUrl('/media/gifs/hourglass.gif')} width={20} alt="loading" />}
          </span>
        </div>
        <h1 className='breadcrumb-item text-muted col-auto'><FormattedMessage id="SCREENS.RECOMMENDATIONS_AND_EFFICIENCY_MEASURES" /></h1>
        <span className='col-auto'>
          <a className='navbar-brand' href='https://yochananof.co.il/'>
            &nbsp;&nbsp;
            {img}
          </a>
        </span>
      </nav>
      <br />
      <br />
      <div className='row justify-content-between'>
        <div className='col-xxl-5'>
          <div className='row'>
            <div className='col-12 col-sm-3'>
              <SearchStore onSearch={onSearch} value={searchStore}></SearchStore>
            </div>
            {historyMode && <div className='col-12 col-sm-5' >
              <StatusBy onStatusBy={onStatusBy} value={statusType} />
            </div>}
            {historyMode && <div className='col-12 col-sm-3' >
              <SortPOSType historyMode={historyMode} onSortPOSType={onSortPosType} value={sortPosType} />
            </div>}
            {historyMode && <div className='col-1 mt-2'>
              <span style={{ cursor: 'pointer' }} onClick={setDisplayGraphChoose} className='symbol-label bg-light-primary'>
                <KTSVG
                  path='/media/icons/duotune/graphs/gra001.svg'
                  className='svg-icon-2x svg-icon-primary '
                />
              </span>
            </div>}
          </div>

        </div>
        <div className='col-sm-12 col-xxl-auto pe-6 ps-5'>
          <div className='col-12 filter-buttons'>
            <FilterStores historyMode={historyMode} onFilter={onFilter} listStores={listStores} />
          </div>
        </div>
      </div>
      <hr />
      <StoresList historyMode={historyMode} stores={getStoreToRender()} displayGraph={displayGraph} onExitClick={setDisplayGraphChoose} />
    </div >
  )
}
