import { useEffect, useState } from 'react'
import { successFilter, oneCashierDeskInactiveFilter, fullCashierDeskInactive, upThan100 } from '../helpers/filtersStores'
import { logEnum, sendlog } from '../../logs/sendLog'
import { IButtonContent } from '../../cashiers/interfaces/buttonContent'
import { getButtonsContentByName } from '../../settings/services/settingsData'
import { BtnContentEnum } from 'app/modules/settings/interfaces/btnContentEnum'
import { FormattedMessage } from 'react-intl'
import { IPOSEventResearch } from '../interfaces/IPOSEventResearchCollection'
import { POS_EVENT_RESEARCH_SCREEN_STATUS_TYPE } from 'app/modules/auth/redux/AuthCRUD'

interface Props {
  onFilter: (statusFilter: number) => any
  listStores: IPOSEventResearch[]
}

const listButtons = [
  { color: 'primary', active: true },
  { color: 'success', active: false },
  { color: 'warning', active: false },
  { color: 'danger', active: false },
  { color: 'info', active: false },
]
const FilterStores = (props: Props) => {
  const listStores = props.listStores || [];
  const [listButtonsRender, setListButtonsRendersRender] = useState(listButtons)

  const onClickFilter = (event: any) => {
    const status = event.currentTarget.value
    switch (status) {
      case '1':
        sendlog(logEnum.recommendedStandardGreenBtn);
        break;
      case '2':
        sendlog(logEnum.recommendedStandardYellowBtn);
        break;
      case '3':
        sendlog(logEnum.recommendedStandardRedBtn);
        break;
      case '4':
        sendlog(logEnum.recommendedStandardPurpleBtn);
        break;
      case '0':
        sendlog(logEnum.recommendedStandardBlueBtn);
        break;
    }

    const list = [...listButtonsRender]
    list.forEach((item) => {
      item.active = false
    });


    list[status].active = true
    setListButtonsRendersRender(list)
    return props.onFilter(+status)
  }

  const [purpleButton, setPurpleButton] = useState(0)
  const [greenButton, setGreenButton] = useState(0)
  const [yellowButton, setYellowButton] = useState(0)
  const getContent = async () => {
    let arr: IButtonContent[] = [];
    if (POS_EVENT_RESEARCH_SCREEN_STATUS_TYPE === 'overLoadCashierStatus') arr = await getButtonsContentByName(BtnContentEnum.POSEventResearchOverLoadPosting);
    else if (POS_EVENT_RESEARCH_SCREEN_STATUS_TYPE === 'underLoadCashierStatus') arr = await getButtonsContentByName(BtnContentEnum.POSEventResearchUnderLoadPosting);
    
    arr.forEach(btnContent => {
      if (btnContent.title === 'purpleButton') setPurpleButton(btnContent.value);
      else if (btnContent.title === 'greenButton') setGreenButton(btnContent.value);
      else if (btnContent.title === 'yellowButton') setYellowButton(btnContent.value);
    });
  }
  useEffect(() => {
    getContent();
  })
  return (
    <div className='row justify-content-between'>
      <button
        style={{ marginRight: '5px' }}
        value={3}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[3].color} ${listButtonsRender[3].active ? 'active' : ''
          }`}
      >
        ({listStores.filter(fullCashierDeskInactive).length}) {yellowButton}% &gt;
      </button>

      <button
        style={{ marginRight: '5px' }}
        value={2}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[2].color} ${listButtonsRender[2].active ? 'active' : ''
          }`}
      >
        ({listStores.filter(oneCashierDeskInactiveFilter).length}) {yellowButton}% - {greenButton}%
      </button>

      <button
        id={'success-button'}
        style={{ marginRight: '5px' }}
        value={1}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[1].color} ${listButtonsRender[1].active ? 'active' : ''
          }`}
      >
        ({listStores.filter(successFilter).length}) {greenButton}% - {purpleButton}%
      </button>
      <button
        id={'main-button'}
        style={{ marginRight: '5px', display: 'flex', columnGap: 2 }}
        value={4}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[4].color} ${listButtonsRender[4].active ? 'active' : ''
          }`}
      >
        <span>
          ({listStores.filter(upThan100).length})
        </span>
        <span>
          {purpleButton}%
          &lt;
        </span>
      </button>
      <button
        id={'main-button'}
        style={{ marginRight: '5px' }}
        value={0}
        onClick={onClickFilter}
        className={`col-auto btn btn-light-${listButtonsRender[0].color} ${listButtonsRender[0].active ? 'active' : ''
          }`}
      >
        ({listStores.length}) <FormattedMessage id={"CASHIERS_ALERTS_SCREEN.DISPLAY_ALL"} />
      </button>
    </div>
  );
}
export default FilterStores
