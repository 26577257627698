/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { IRecommendedStandard } from '../../recommendedStandard/interfaces/IRecommendedStandard'
import { LoadPerHour } from '../interfaces/ITableRow'
import { FormattedMessage } from 'react-intl'

type Props = {
    className: string,
    stores?: IRecommendedStandard[],
    morningCashierShift: number,
    eveningCashierShift: number,
    loadByMinutes: LoadPerHour[],
    minutesPerCashier: number,
    morningShiftLastHour: number,
    hasIndependedPOS: boolean
}

const GraphModal: React.FC<Props> = ({ className, loadByMinutes, morningCashierShift, eveningCashierShift, minutesPerCashier, morningShiftLastHour }) => {
    const chartElementRef = useRef<HTMLDivElement | null>(null);
    const chartRef = useRef<ApexCharts>();
    const arr = useMemo(() => {
        const resultArr = [];
        for (let i = 7; i <= 23; i++) {
            let val = loadByMinutes.find(l => l.hour === i);
            if (!val) {
                resultArr.push(0);
            } else {
                let result;
                if (i <= morningShiftLastHour) {
                    result = val.loadByMinutes / ((minutesPerCashier * morningCashierShift) / 100);
                    if (result === Infinity || isNaN(result)) result = 0;
                }
                else result = val.loadByMinutes / ((minutesPerCashier * eveningCashierShift) / 100);
                if (result === Infinity || isNaN(result)) result = 0;
                resultArr.push(parseFloat(result.toFixed(2)));
            }
        }
        return resultArr;
    }, [eveningCashierShift, loadByMinutes, minutesPerCashier, morningCashierShift, morningShiftLastHour])


    useEffect(() => {
        if (!chartElementRef.current) {
            return
        }

        const chart = new ApexCharts(chartElementRef.current, getChartOptions());
        if (chart) {
            chart.render()
        }

        chartRef.current = chart;

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartElementRef]);

    useEffect(() => {
        chartRef.current?.updateSeries([{ data: arr, name: 'אחוז עומס' }], true);
    }, [arr]);

    return (
        <div className={`card ${className}`}>
            <div className='card-header border-0 pt-5 px-15'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'><FormattedMessage id="RECOMMENDATIONS_SCREEN.LOAD_SEGMENTED_BY_HOUR" /></span>
                </h3>
            </div>
            <div className='card-body'>
                <div ref={chartElementRef} id='kt_charts_widget_6_chart' style={{ height: '250px' }}></div>
            </div>
        </div>
    )
}

export { GraphModal }

function getChartOptions(): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')//color of njumber at the left side
    const borderColor = getCSSVariableValue('--bs-gray-200')//color of breakers lines
    const blue = getCSSVariableValue('--bs-primary')//Bottom part of lines

    return {
        series: [],
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            stacked: true,
            height: 250,
            toolbar: {
                show: false,
            },
            animations: {
                enabled: false,
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
                columnWidth: '15%',
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: [
                '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
            ],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            max: 110,
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            enabled: true,
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + '%'
                },
            },
            enabledOnSeries: [0]
        },
        colors: [blue],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 20,
            },
        },
    }
}
