/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react'
import StoresList from './StoresList'
import { getStores } from '../services/storesData'
import { IStore } from '../interfaces/IStore'
import FilterStores from './FilterStores'
import SearchStore from './SearchStore'
import socketIOClient from 'socket.io-client'
import StatusBy from './StatusBy'
import { filtersStores } from '../helpers/filtersStores'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import Moment from 'react-moment';
import { CURRENT_USER, setStatusType, STATUS_TYPE } from '../../auth/redux/AuthCRUD'
import { auto } from '@popperjs/core'
import { logEnum, sendlog } from '../../logs/sendLog'
import { StoreFilterContext } from 'app/routing/PrivateRoutes'
import { FormattedMessage } from 'react-intl'
import { API_URL } from 'app/config/config'
import { useLocation } from 'react-router-dom'

const sortByName = (a: any, b: any) => {
  if (a["name"] < b["name"]) {
    return -1
  }
  if (a["name"] > b["name"]) {
    return 1
  }
  return 0;
}
const sortByStatus = (a: any, b: any) => {
  if (a[STATUS_TYPE] > b[STATUS_TYPE]) {
    return -1
  }
  if (a[STATUS_TYPE] < b[STATUS_TYPE]) {
    return 1
  }
  return 0;
};

const sortConfig = [
  { field: 'name', func: sortByName },
  { field: 'status', func: sortByStatus },
]
export default function Stores() {
  const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)

  const [listStores, setListStores] = useState<IStore[]>([])
  const [filter, setFilter] = useContext(StoreFilterContext);

  const [searchStore, setSearchStore] = useState(filter.search);
  const [statusType, setStatusByType] = useState(filter.statusType);
  const [statusFilter, setStatusFilter] = useState(filter.statusValue)
  let selectedStatus = useLocation().state as string ?? filter.statusType ?? "independentDeskStatus";

  useEffect(() => {
    setSearchStore(filter.search);
    setStatusByType(filter.statusType);
    setStatusType(filter.statusType);
    setStatusFilter(filter.statusValue);
  }, [filter]);

  console.log(filter);

  const [warningFilter, setWarningFilter] = useState<boolean>(false);
  const [displayGraph, setDisplayGraph] = useState<boolean>(false);
  const [lastDateUpdate, setLastDateUpdate] = useState<Date | null>()
  const [warningBg, setWarningBg] = useState<string>('warning-bg-non-active');

  const getStoreToRender = () => {
    if (!listStores) return [];

    setStatusType(filter.statusType);

    const filterRun = filtersStores.find((filter) => filter.status === statusFilter);
    if (!filterRun) {
      return listStores
    }

    return listStores
      .filter(filterRun.filter)
      .filter((item) => {
        if (warningFilter && !showWarningStores(item)) return false;
        if (!searchStore) return true
        return item.name.includes(searchStore)
      })
      .sort(sortStore)
  };

  const showWarningStores = (store: IStore) => {
    return store.orangeWarningStatus > 0 || store.redWarningStatus > 0;
  }

  const sortStore = (a: any, b: any) => {
    const funcSort = sortConfig.find(s => s.field === 'name')?.func;
    if (funcSort) {
      return funcSort(a, b);
    }
    return 1;
  }

  const getListStores = async (listReq: IStore[] = []) => {
    try {
      let list = listReq;
      if (list.length === 0) {
        list = await getStores();
      }
      if (list) {
        setListStores(list);
        setLastDateUpdate(new Date());
      }
    } catch (err) { }
  }

  const onStatusBy = (statusSelected: string) => {
    setFilter({ ...filter, statusType: statusSelected });
    setStatusType(statusSelected);
  }

  useEffect(() => {
    const socket = socketIOClient("/", {
      transports: ['websocket'],
      auth: {
        token: accessToken,
      },
      path: `${API_URL}/socket.io`
    });
    socket.emit('startSocketStores')
    socket.on('allStores', (list) => {
      if (list) {
        setListStores(list);
        setLastDateUpdate(new Date())
      }
    });
    onStatusBy(selectedStatus);
    sendlog(logEnum.storeScreen);
    return () => {
      socket.disconnect();
    }
    // eslint-disable-next-line
  }, [accessToken, selectedStatus]);

  useEffect(() => {
    getListStores();
  }, []);

  const onFilter = (status: number) => {
    setFilter({ ...filter, statusValue: status })
  }
  const onSearch = (search: string) => {
    setFilter({ ...filter, search });
  }

  const manageWarningFilter = () => {
    if (warningBg === 'warning-bg-active') {
      sendlog(logEnum.UnSortByWarningIcon);
      setWarningBg('warning-bg-non-active');
    }
    else {
      sendlog(logEnum.sortByWarningIcon);
      setWarningBg('warning-bg-active');
    }
    setWarningFilter(!warningFilter);

  }

  const setDisplayGraphChoose = () => {
    if (CURRENT_USER === 'admin'){
    if (displayGraph) sendlog(logEnum.closeGraph);
    else sendlog(logEnum.openGraph);
    setDisplayGraph(!displayGraph)}

  }

  const img = <img
    src={toAbsoluteUrl('/media/logos/retail_logo.png')}
    width='100'
    height='30'
    className='d-inline-block '
    alt=''
    loading='lazy'
  />
  return (
    <div>
      <nav className='navbar navbar-light row justify-content-between'>
        <div className='col-auto'>
          <span>
            {lastDateUpdate &&
              <div>
                <span>
                  <FormattedMessage
                    id={"CASHIERS_ALERTS_SCREEN.LAST_UPDATE"}
                    values={{ lastUpdate: <Moment format='DD-MM-YYYY HH:mm'>{lastDateUpdate ? lastDateUpdate : ''}</Moment> }} />
                </span>
              </div>}
            {!lastDateUpdate && < img src={toAbsoluteUrl('/media/gifs/hourglass.gif')} width={20} alt="loading" />}
          </span>
        </div>
        <h1 className='breadcrumb-item text-muted col-auto'><FormattedMessage id="SCREENS.CASHIER_ALERTS_SCREEN" /></h1>
        <span className='col-auto'>
          <a className='navbar-brand' href='https://yochananof.co.il/'>
            &nbsp;&nbsp;
            {img}
          </a>
        </span>
      </nav>
      <br />
      <br />
      <div className='row'>
        <div className='col-xxl-5'>
          <div className='row justify-content-between'>
            <div className='col-4'>
              <SearchStore onSearch={onSearch} search={searchStore}></SearchStore>
            </div>
            <div className='col-6'>
              <StatusBy onStatusBy={onStatusBy} value={statusType} disabled={CURRENT_USER!=='admin'} />
            </div>
            <div onClick={manageWarningFilter} className={"col-auto retail-warning-filter text-hover-warning fas fs-2 mt-3 fa-exclamation-triangle " + warningBg}></div>
            <div className='col-auto mt-2 '>
              <span style={{ cursor: 'pointer' }} onClick={setDisplayGraphChoose} className='symbol-label bg-light-primary ' >
                <KTSVG
                  path='/media/icons/duotune/graphs/gra001.svg'
                  className='svg-icon-2x svg-icon-primary '
                />
              </span>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: auto, }} className='col-12 col-xxl-auto pe-6 ps-5'>
          <div className='col-12 filter-buttons'>
            <FilterStores onFilter={onFilter} listStores={listStores} />
          </div>
        </div>
      </div>
      <hr />
      <StoresList stores={getStoreToRender()} displayGraph={displayGraph} onExitClick={setDisplayGraphChoose} />
    </div>
  )
}
