import { DayLoadResponse } from "./StoreWeekLoadResponse";

export const DATAPOINT_KEY_TO_MESSAGE_ID: {[Key in keyof Omit<DayLoadResponse, 'day' | 'date' | 'timeDatapoints'>]: string} = {
    selfcheckoutDatapoints: "POS_EVENT_RESEARCH_SCREEN.SELFCHECKOUT",
    recommendedStandardShiftDatapoints: "POS_EVENT_RESEARCH_SCREEN.RECOMMENDED_STANDARD_SHIFT",
    attendanceDatapoints: "POS_EVENT_RESEARCH_SCREEN.ATTENDANCE",
    assignmentDatapoints: "POS_EVENT_RESEARCH_SCREEN.ASSIGNMENT",
    currentStandardDatapoints: "POS_EVENT_RESEARCH_SCREEN.CURRENT_STANDARD",
    activePosDatapoints: "POS_EVENT_RESEARCH_SCREEN.ACTIVE_POS",
    grossRecommendationDatapoints: "POS_EVENT_RESEARCH_SCREEN.GROSS_RECOMMENDATION",
    minRequriedPosDatapoints: "POS_EVENT_RESEARCH_SCREEN.MINIMUM_REQURIED_POS",
} as const;
