/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useIntl } from 'react-intl';

const STATUS_TO_SEVETIRY = {
  1: "success",
  2: "warning",
  3: "danger",
  4: "info",
} as const;

const DESK_TYPE_TO_NAME = {
  "קופה ראשית": "POS_AVAILABILITY_SCREEN.POS_TYPE_MAIN",
  "שירות מלא": "POS_AVAILABILITY_SCREEN.POS_TYPE_FULL_SERVICE",
  "שירות עצמי": "POS_AVAILABILITY_SCREEN.POS_TYPE_SCO",
  "עגלה": "POS_AVAILABILITY_SCREEN.POS_TYPE_CART",
} as const;

type Props = {
  className: string
  color?: string
  avatar?: string
  icon?: string
  status: number

  store: string;
  deskNumber: number;
  deskType: keyof typeof DESK_TYPE_TO_NAME;
  cashierNumber: number;
  revenue: number;
  firstTillTime: string;
  lastTillTime: string;
  timeFromLastTill: string;
  grossWrkSum: string;
  netWrkSum: string;
  avgLoad: number;
  numberOfTickets: number;
}

const CashierRaw: React.FC<Props> = ({
  status,

  store,
  deskNumber,
  deskType,
  cashierNumber,
  revenue,
  firstTillTime,
  lastTillTime,
  timeFromLastTill,
  grossWrkSum,
  netWrkSum,
  avgLoad,
  numberOfTickets
}) => {
  let textColor = 'text-gray-600';

  const intl = useIntl();
  const severity = status in STATUS_TO_SEVETIRY ? STATUS_TO_SEVETIRY[status as keyof typeof STATUS_TO_SEVETIRY] : "danger";
  const deskTypeFormatted = deskType in DESK_TYPE_TO_NAME && intl.formatMessage({ id: DESK_TYPE_TO_NAME[deskType] });
  return (

    <tr className={textColor}>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <span className={'fw-bolder mb-1 fs-6 '} >
              {store}
            </span>
          </div>
        </div>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 '}>
          {deskNumber}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 '}>
          {deskTypeFormatted}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 '}>
          {cashierNumber}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 '}>
          {numberOfTickets}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 '}>
          {revenue}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 '}>
          {firstTillTime}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 '}>
          {lastTillTime}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 '}>
          {timeFromLastTill}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 '}>
          {grossWrkSum}
        </span>
      </td>
      <td>
        <span className={'fw-bolder d-block mb-1 fs-6 '}>
          {netWrkSum}
        </span>
      </td>
      <td >
        <span className={'fw-bolder d-block mb-1 fs-6 '}>
          <span className={`badge bg-${severity} fs-7 fw-bold`}>{avgLoad}%</span>
        </span>
      </td>
    </tr>

  )
}
export { CashierRaw }
