/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIsRTL } from '_metronic/i18n/Metronici18n';
import { logEnum, sendlog } from '../../logs/sendLog';
import { SettingModalRow } from '../interfaces/IsettingModalRow';
import { ISettingRow } from '../interfaces/ISettingRow';
import { mainSettingScreenData } from '../interfaces/mainSettingScreenData';
import { getMainSettingData, getSettings } from '../services/settingsData';
import { SettingModal } from './settingModal';
import { SettingRaw } from './settingRow';

type Props = {
  className: string
}

const TablesWidget9: React.FC<Props> = ({ className }) => {

  const mainDataRef = useRef<mainSettingScreenData[]>()

  const getData = useCallback(async () => {
    mainDataRef.current = await getMainSettingData();

    const mainData = mainDataRef.current;

    if (mainData) {
      let independentDeskRowData = mainData.find(val => val.screenId === 1 && val.settingContent === 'שירות עצמי');
      let postingCashierRowData = mainData.find(val => val.screenId === 1 && val.settingContent === 'נוכחות / הצבה');
      let standardRowData = mainData.find(val => val.screenId === 1 && val.settingContent === 'תקן / נוכחות');
      let presentCashierRowData = mainData.find(val => val.screenId === 1 && val.settingContent === 'שיבוץ / נוכחות');
      let loadRowData = mainData.find(val => val.screenId === 1 && val.settingContent === 'אחוז זמן פנוי');
      let revenueRowData = mainData.find(val => val.screenId === 1 && val.settingContent === 'פדיון');
      let cashiersDeviationRowData = mainData.find(val => val.screenId === 1 && val.settingContent === 'חוסר / עודף קופות פתוחות');

      let cashierRowData = mainData.find(val => val.screenId === 2);
      let usageLevelRowData = mainData.find(val => val.screenId === 3);
      let recommendedStandartRowData = mainData.find(val => val.screenId === 4 && val.settingContent === 'מסנן צבעים');
      let productivityRowData = mainData.find(val => val.screenId === 4 && val.settingContent === 'מסנן צבעים לפריון');
      let attendanceRowData = mainData.find(val => val.screenId === 4 && val.settingContent === 'מסנן צבעים לנוכחות');
      let overloadStandardRowData = mainData.find(val => val.screenId === 4 && val.settingContent === 'מסנן צבעים לעודפי תקן');
      let underloadStandardRowData = mainData.find(val => val.screenId === 4 && val.settingContent === 'מסנן צבעים לחוסרי תקן');
      let overloadPostingRowData = mainData.find(val => val.screenId === 4 && val.settingContent === 'מסנן צבעים לעודפי הצבה');
      let underloadPostingRowData = mainData.find(val => val.screenId === 4 && val.settingContent === 'מסנן צבעים לחוסרי הצבה');


      let underloadPostingRowDataPOSEventResearch = mainData.find(val => val.screenId === 10 && val.settingContent === 'מסנן צבעים לחוסרי הצבה');
      let overloadPostingRowDataPOSEventResearch = mainData.find(val => val.screenId === 10 && val.settingContent === 'מסנן צבעים לעודפי הצבה');

      if (independentDeskRowData) {
        independentDeskStoreScreenSetting.screenName = independentDeskRowData.screenName;
        independentDeskStoreScreenSetting.comment = independentDeskRowData.note;
        independentDeskStoreScreenSetting.updateTime = independentDeskRowData.lastUpdate;
        independentDeskStoreScreenSetting.settingContent = independentDeskRowData.settingContent;
      }
      if (postingCashierRowData) {
        postingStoreScreenSetting.screenName = postingCashierRowData.screenName;
        postingStoreScreenSetting.comment = postingCashierRowData.note;
        postingStoreScreenSetting.updateTime = postingCashierRowData.lastUpdate;
        postingStoreScreenSetting.settingContent = postingCashierRowData.settingContent;
      }
      if (standardRowData) {
        standardStoreScreenSetting.screenName = standardRowData.screenName;
        standardStoreScreenSetting.comment = standardRowData.note;
        standardStoreScreenSetting.updateTime = standardRowData.lastUpdate;
        standardStoreScreenSetting.settingContent = standardRowData.settingContent;
      }
      if (presentCashierRowData) {
        presentStoreScreenSetting.screenName = presentCashierRowData.screenName;
        presentStoreScreenSetting.comment = presentCashierRowData.note;
        presentStoreScreenSetting.updateTime = presentCashierRowData.lastUpdate;
        presentStoreScreenSetting.settingContent = presentCashierRowData.settingContent;
      }
      if (loadRowData) {
        loadStoreScreenSetting.screenName = loadRowData.screenName;
        loadStoreScreenSetting.comment = loadRowData.note;
        loadStoreScreenSetting.updateTime = loadRowData.lastUpdate;
        loadStoreScreenSetting.settingContent = loadRowData.settingContent;
      }
      if (cashiersDeviationRowData) {
        cashiersDeviationStoreScreenSetting.screenName = cashiersDeviationRowData.screenName;
        cashiersDeviationStoreScreenSetting.comment = cashiersDeviationRowData.note;
        cashiersDeviationStoreScreenSetting.updateTime = cashiersDeviationRowData.lastUpdate;
        cashiersDeviationStoreScreenSetting.settingContent = cashiersDeviationRowData.settingContent;
      }


      if (revenueRowData) {
        storeScreenRevenueSetting.screenName = revenueRowData.screenName;
        storeScreenRevenueSetting.comment = revenueRowData.note;
        storeScreenRevenueSetting.updateTime = revenueRowData.lastUpdate;
        storeScreenRevenueSetting.settingContent = revenueRowData.settingContent;
      }
      if (cashierRowData) {
        cashierScreenSetting.screenName = cashierRowData.screenName;
        cashierScreenSetting.comment = cashierRowData.note;
        cashierScreenSetting.updateTime = cashierRowData.lastUpdate;
        cashierScreenSetting.settingContent = cashierRowData.settingContent;
      }

      if (usageLevelRowData) {
        usageLevel.screenName = usageLevelRowData.screenName;
        usageLevel.comment = usageLevelRowData.note;
        usageLevel.updateTime = usageLevelRowData.lastUpdate;
        usageLevel.settingContent = usageLevelRowData.settingContent;
      }
      if (recommendedStandartRowData) {
        recommendedStandartScreenSetting.screenName = recommendedStandartRowData.screenName;
        recommendedStandartScreenSetting.comment = recommendedStandartRowData.note;
        recommendedStandartScreenSetting.updateTime = recommendedStandartRowData.lastUpdate;
        recommendedStandartScreenSetting.settingContent = recommendedStandartRowData.settingContent;
      }

      if (productivityRowData) {
        recommendedStandartScreenProductivitySetting.screenName = productivityRowData.screenName;
        recommendedStandartScreenProductivitySetting.comment = productivityRowData.note;
        recommendedStandartScreenProductivitySetting.updateTime = productivityRowData.lastUpdate;
        recommendedStandartScreenProductivitySetting.settingContent = productivityRowData.settingContent;
      }

      if (attendanceRowData) {
        recommendedStandartScreenAttendanceSetting.screenName = attendanceRowData.screenName;
        recommendedStandartScreenAttendanceSetting.comment = attendanceRowData.note;
        recommendedStandartScreenAttendanceSetting.updateTime = attendanceRowData.lastUpdate;
        recommendedStandartScreenAttendanceSetting.settingContent = attendanceRowData.settingContent;
      }
      if (overloadStandardRowData) {
        recommendedStandartScreenOverloadStandardSetting.screenName = overloadStandardRowData.screenName;
        recommendedStandartScreenOverloadStandardSetting.comment = overloadStandardRowData.note;
        recommendedStandartScreenOverloadStandardSetting.updateTime = overloadStandardRowData.lastUpdate;
        recommendedStandartScreenOverloadStandardSetting.settingContent = overloadStandardRowData.settingContent;
      }
      if (underloadStandardRowData) {
        recommendedStandartScreenUnderloadStandardSetting.screenName = underloadStandardRowData.screenName;
        recommendedStandartScreenUnderloadStandardSetting.comment = underloadStandardRowData.note;
        recommendedStandartScreenUnderloadStandardSetting.updateTime = underloadStandardRowData.lastUpdate;
        recommendedStandartScreenUnderloadStandardSetting.settingContent = underloadStandardRowData.settingContent;
      }
      if (overloadPostingRowData) {
        recommendedStandartScreenOverloadPostingSetting.screenName = overloadPostingRowData.screenName;
        recommendedStandartScreenOverloadPostingSetting.comment = overloadPostingRowData.note;
        recommendedStandartScreenOverloadPostingSetting.updateTime = overloadPostingRowData.lastUpdate;
        recommendedStandartScreenOverloadPostingSetting.settingContent = overloadPostingRowData.settingContent;
      }
      if (underloadPostingRowData) {
        recommendedStandartScreenUnderloadPostingSetting.screenName = underloadPostingRowData.screenName;
        recommendedStandartScreenUnderloadPostingSetting.comment = underloadPostingRowData.note;
        recommendedStandartScreenUnderloadPostingSetting.updateTime = underloadPostingRowData.lastUpdate;
        recommendedStandartScreenUnderloadPostingSetting.settingContent = underloadPostingRowData.settingContent;
      }

      if (underloadPostingRowDataPOSEventResearch) {
        underLoadPOSEventResearchScreenSetting.screenName = underloadPostingRowDataPOSEventResearch.screenName;
        underLoadPOSEventResearchScreenSetting.comment = underloadPostingRowDataPOSEventResearch.note;
        underLoadPOSEventResearchScreenSetting.updateTime = underloadPostingRowDataPOSEventResearch.lastUpdate;
        underLoadPOSEventResearchScreenSetting.settingContent = underloadPostingRowDataPOSEventResearch.settingContent;
      }

      if (overloadPostingRowDataPOSEventResearch) {
        overloadPOSEventResearchScreenSetting.screenName = overloadPostingRowDataPOSEventResearch.screenName;
        overloadPOSEventResearchScreenSetting.comment = overloadPostingRowDataPOSEventResearch.note;
        overloadPOSEventResearchScreenSetting.updateTime = overloadPostingRowDataPOSEventResearch.lastUpdate;
        overloadPOSEventResearchScreenSetting.settingContent = overloadPostingRowDataPOSEventResearch.settingContent;
      }
    }
    let collection: Partial<ISettingRow>[] = [
      independentDeskStoreScreenSetting,
      postingStoreScreenSetting,
      standardStoreScreenSetting,
      presentStoreScreenSetting,
      cashiersDeviationStoreScreenSetting,
      storeScreenRevenueSetting,
      loadStoreScreenSetting,
      cashierScreenSetting,
      usageLevel,
      recommendedStandartScreenSetting,
      recommendedStandartScreenProductivitySetting,
      recommendedStandartScreenAttendanceSetting,
      recommendedStandartScreenOverloadStandardSetting,
      recommendedStandartScreenUnderloadStandardSetting,
      recommendedStandartScreenOverloadPostingSetting,
      recommendedStandartScreenUnderloadPostingSetting,
      underLoadPOSEventResearchScreenSetting,
      overloadPOSEventResearchScreenSetting
    ]
    setSettingRowArr(collection);
  },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    sendlog(logEnum.settingScreen);
    getData();
  }, [getData]);

  let defaultSettingObj: Partial<SettingModalRow> = {}
  let postingStoreScreenSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/ecommerce/ecm001.svg',
    pageLink: '#setting_modal'
  };
  let standardStoreScreenSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/ecommerce/ecm001.svg',
    pageLink: '#setting_modal'
  };
  let presentStoreScreenSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/ecommerce/ecm001.svg',
    pageLink: '#setting_modal'
  };
  let loadStoreScreenSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/ecommerce/ecm001.svg',
    pageLink: '#setting_modal'
  };
  let independentDeskStoreScreenSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/ecommerce/ecm001.svg',
    pageLink: '#setting_modal'
  };
  let storeScreenRevenueSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/ecommerce/ecm001.svg',
    pageLink: '#setting_modal'
  };
  let cashierScreenSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/communication/com014.svg',
    pageLink: '#setting_modal'
  };
  let usageLevel: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/graphs/gra001.svg',
    pageLink: '#setting_modal'
  };
  let recommendedStandartScreenSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/general/gen003.svg',
    pageLink: '#setting_modal'
  };
  let recommendedStandartScreenProductivitySetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/general/gen003.svg',
    pageLink: '#setting_modal'
  };
  let recommendedStandartScreenAttendanceSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/general/gen003.svg',
    pageLink: '#setting_modal'
  };
  let recommendedStandartScreenOverloadStandardSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/general/gen003.svg',
    pageLink: '#setting_modal'
  };
  let recommendedStandartScreenUnderloadStandardSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/general/gen003.svg',
    pageLink: '#setting_modal'
  };
  let recommendedStandartScreenOverloadPostingSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/general/gen003.svg',
    pageLink: '#setting_modal'
  };
  let recommendedStandartScreenUnderloadPostingSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/general/gen003.svg',
    pageLink: '#setting_modal'
  };
  let cashiersDeviationStoreScreenSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/ecommerce/ecm001.svg',
    pageLink: '#setting_modal'
  };
  let underLoadPOSEventResearchScreenSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/ecommerce/ecm001.svg',
    pageLink: '#setting_modal'
  };
  let overloadPOSEventResearchScreenSetting: Partial<ISettingRow> = {
    icon: '/media/icons/duotune/ecommerce/ecm001.svg',
    pageLink: '#setting_modal'
  };
  const [settingRowArr, setSettingRowArr] = useState<Partial<ISettingRow>[]>([])
  const [data, setData] = useState<Partial<SettingModalRow>>(defaultSettingObj)

  const onSetName = async (newScreenName: string, settingContent: string) => {
    setData(defaultSettingObj);
    let list = await getSettings();
    setData(list.find(s => s.screenName === newScreenName && s.settingContent === settingContent) || defaultSettingObj);
  }

  let tr = settingRowArr.map((item, index) => {
    return <SettingRaw key={index} settingRow={item as ISettingRow} setName={onSetName} />

  });
  const isRTL = useIsRTL();
  return (
    <div dir={isRTL ? 'rtl' : 'ltr'} className={`card ${className}`}>
      <SettingModal data={data as SettingModalRow} getData={getData} />
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            <FormattedMessage id="SCREENS.KPI_SETTINGS" />
          </span>
        </h3>
        <img src='/media/icons/duotune/general/gen021.svg' alt="gen021" />

      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'><FormattedMessage id="KPI_SETTINGS.SCREEN" /></th>
                <th className='min-w-140px'><FormattedMessage id="KPI_SETTINGS.SCREEN_SETTING" /></th>
                <th className='min-w-120px'><FormattedMessage id="KPI_SETTINGS.UPDATE_TIME" /></th>
                <th className='min-w-100px'><FormattedMessage id="KPI_SETTINGS.NOTE" /></th>
              </tr>
            </thead>
            <tbody>
              {tr}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )
}

export { TablesWidget9 }
