import { FilterPosType } from 'app/routing/PrivateRoutes'
import { useIntl } from 'react-intl';
import { logEnum, sendlog } from '../../logs/sendLog'

interface Props {
  value: FilterPosType,
  onSortPOSType: (statusType: FilterPosType) => void,
  historyMode: boolean,
}

const SortPOSType = (props: Props) => {

  const onChange = (event: any) => {
    switch (event.target.value) {
      case 'displayAll':
        sendlog(logEnum.recommendedDisplayAll);
        break;
      case 'withIndepended':
        sendlog(logEnum.recommendedWithIndepended);
        break;
      case 'withoutIndepended':
        sendlog(logEnum.recommendedWithoutIndepended);
        break;
    }
    props.onSortPOSType(event.target.value);
  }
  const { value } = props;
  const intl = useIntl();
  return (
    <div className='mb-3'>
      <select
        className='form-select'
        data-control='select2'
        data-hide-search='true'
        data-kt-select2='true'
        data-placeholder='Select option'
        data-allow-clear='true'
        onChange={onChange}
        value={value}
      >
        <option value='displayAll' >{intl.formatMessage({ id: "CASHIERS_ALERTS_SCREEN.DISPLAY_ALL" })}</option>
        {!props.historyMode && <option value='withIndepended' >{intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.WITH_SELF_SERVICE" })}</option>}
        {!props.historyMode && <option value='withoutIndepended' >{intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.WITHOUT_SERLF_SERVICE" })}</option>}
        <option value='standardInProgress' >{intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.STANDARD_IN_PROCESS" })}</option>
        <option value='approvedStandard' >{intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.STANDARD_APPROVED" })}</option>
        <option value='updatedStandard' >{intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.STANDARD_UPDATED" })}</option>

      </select>
    </div>
  )
}
export default SortPOSType