import { StoreSelection, StoresSelector } from "app/common/components/selectors/StoresSelector";
import { WEEKS } from "app/common/constants";
import { debounce } from "app/helpers/debounce";
import axios, { CancelToken } from "axios";
import { useEffect, useMemo, useState } from "react";
import { Card, Spinner } from "react-bootstrap-v5";
import Chart, { ChartWrapperOptions, ReactGoogleChartProps } from "react-google-charts";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "_metronic/helpers";
import { DateRange, DateRangeSelector } from "../trend-analysis/TrendAnalysisPage";
import { RangeSelector, RangeType } from "../trends/components/RangeSelector";
import { StoreType, StoreTypeSelector } from "../trends/components/StoreTypeSelector";
import { sendlog, logEnum } from "../logs/sendLog";

type ChartWrapperProps = {
    title: JSX.Element,
    chartType: "bar" | "pie",
    className: string,
    data?: DataByCashierType,
    label: string,
};

type DataByCashierType = {
    pos: number,
    selfCheckout: number,
    carts: number,
}

const CASHIER_TYPE_TO_MESSAGE_ID: { [Key in keyof DataByCashierType]: string } = {
    carts: "CARTS",
    pos: "FULL_SERVICE",
    selfCheckout: "SELF_CHECKOUT",
} as const;

type ChartType = ChartWrapperProps['chartType'];

const CHART_TYPE_TO_GOOGLE_CHART_TYPE: { [key in ChartType]: ReactGoogleChartProps['chartType'] } = {
    bar: "BarChart",
    pie: "PieChart",
} as const;

const CHART_TYPE_TO_POSITION: { [key in ChartType]: ChartWrapperOptions['legend'] } = {
    bar: { position: "none", },
    pie: { position: "top", maxLines: 3 },
}

const ChartWrapper: React.VFC<ChartWrapperProps> = ({
    className,
    title,
    data,
    chartType,
    label,
}) => {

    const intl = useIntl();

    return (
        <Card className={className}>
            <Card.Header>
                <Card.Title>
                    {title}
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {data
                    ? <Chart
                        chartType={CHART_TYPE_TO_GOOGLE_CHART_TYPE[chartType]}
                        data={[["label", label, { role: "annotation" }], ...(Object.entries(data).map(([key, value]) => (
                            [
                                intl.formatMessage({
                                    id: `PRODUCTIVITY_STATUS_REPORT_SCREEN.POS_TYPES.${CASHIER_TYPE_TO_MESSAGE_ID[key as keyof DataByCashierType]}`
                                }),
                                value,
                                value,
                            ]
                        )))]}
                        options={{
                            tooltip: { trigger: 'selection', ignoreBounds: true, isHtml: true, text: "both" },
                            pieSliceTextStyle: { bold: true, },
                            pieResidueSliceLabel: intl.formatMessage({ id: "PRODUCTIVITY_STATUS_REPORT_SCREEN.POS_TYPES.OTHER" }),
                            fontSize: 12,
                            bars: "vertical",
                            legend: CHART_TYPE_TO_POSITION[chartType],
                            sliceVisibilityThreshold: 0,
                        }}
                    />
                    : <Card.Subtitle className="text-center"><Spinner animation="border" /></Card.Subtitle>}
            </Card.Body>
        </Card>
    );
}

type StatusReportData = {
    revenue: DataByCashierType,
    transactions: DataByCashierType,
    averageTransactionDuration: DataByCashierType,
}

export const ProductivityStatusReportPage: React.VFC = () => {
    const [selectedStores, setSelectedStores] = useState<StoreSelection[]>([]);
    const [storeType, setStoreType] = useState<StoreType>("with-carts-only");
    const [range, setRange] = useState<RangeType>("last30Days");
    const [statusReportData, setStatusReportData] = useState<StatusReportData>();
    const [dateRange, setDateRange] = useState<DateRange>({ min: new Date(Date.now() - 1 * WEEKS), max: new Date() });

    useEffect(() => {
        sendlog(logEnum.statusReportScreen)
    }, [])

    const fetchData = async (
        range: RangeType,
        customSince: Date,
        customUntil: Date,
        storesType: StoreType,
        selectedStores: StoreSelection[],
        cancelToken: CancelToken,
    ) => {
        try {
            const { data } = await axios.get<StatusReportData>("/productivity/status-report", {
                cancelToken,
                params: {
                    range,
                    customSince,
                    customUntil,
                    storesType,
                    storeIds: selectedStores.map(store => store.id),
                },
            });
            setStatusReportData(data);
        } catch (e) { }
    };

    const debounceFetch = useMemo(() => debounce(fetchData, 1000), []);

    useEffect(() => {
        const source = axios.CancelToken.source();
        setStatusReportData(undefined);
        debounceFetch(range, dateRange.min, dateRange.max, storeType, selectedStores, source.token);
        return () => source.cancel();
    }, [range, storeType, selectedStores, debounceFetch, dateRange.min, dateRange.max]);

    const handleStoreSelectionChange = (selectedStores: StoreSelection[]) => setSelectedStores(selectedStores);

    const handleRangeChange = (range: RangeType) => setRange(range);

    const handleStoreWithCartsChange = (storesFilterOption: StoreType) => {
        setStoreType(storesFilterOption);
        if (storesFilterOption === "with-carts-only") {
            setSelectedStores(selectedStores => selectedStores.filter(store => store.has_carts));
        }
    }

    const handleDateRangeChange = (dateRange: DateRange) => setDateRange(dateRange);

    const intl = useIntl();

    return (
        <main>
            <header className="d-flex justify-content-evenly position-relative mt-5">
                <h1>
                    <FormattedMessage id="SCREENS.PRODUCTIVITY_STATUS_REPORT" />
                </h1>
                <div className="position-absolute h-100 w-100">
                    <img src={toAbsoluteUrl('/media/logos/retail_logo.png')} className="h-100" alt="retail-innovation logo" />
                </div>
            </header>
            <div className="row my-3 d-flex align-items-end gy-2">
                <div className="col-auto">
                    <StoreTypeSelector
                        onChange={handleStoreWithCartsChange}
                        value={storeType}
                    />
                </div>
                <div className="col-3">
                    <StoresSelector
                        onSelectedStoresChange={handleStoreSelectionChange}
                        selectedStores={selectedStores}
                        withCartsOnly={storeType === "with-carts-only"}
                    />
                </div>
                <div className="col-auto">
                    <RangeSelector
                        value={range}
                        onChange={handleRangeChange}
                    />
                </div>
                {range === "custom" &&
                    <DateRangeSelector
                        className="col-auto mb-0"
                        onChange={handleDateRangeChange}
                        value={dateRange}
                    />
                }
            </div>
            <hr />
            <article className="row gap-4 justify-content-center">
                <ChartWrapper
                    chartType="pie"
                    className="col-xxl-3 col-lg-4"
                    title={<FormattedMessage id="PRODUCTIVITY_STATUS_REPORT_SCREEN.REVENUE" />}
                    label={intl.formatMessage({ id: "PRODUCTIVITY_STATUS_REPORT_SCREEN.REVENUE" })}
                    data={statusReportData?.revenue}
                />
                <ChartWrapper
                    chartType="pie"
                    className="col-xxl-3 col-lg-4"
                    title={
                        <Link
                            to={{
                                pathname: "/productivity/trends/transaction-count",
                                state: {
                                    storeType: storeType,
                                    selectedStores,
                                    range,
                                },
                            }}
                        >
                            <FormattedMessage id="PRODUCTIVITY_STATUS_REPORT_SCREEN.TRANSACTIONS_COUNT" />
                        </Link>

                    }
                    label={intl.formatMessage({ id: "PRODUCTIVITY_STATUS_REPORT_SCREEN.TRANSACTIONS_COUNT" })}
                    data={statusReportData?.transactions}
                />
                <ChartWrapper
                    chartType="bar"
                    className="col-xxl-3 col-lg-4"
                    title={<FormattedMessage id="PRODUCTIVITY_STATUS_REPORT_SCREEN.AVERAGE_TRANSACTION_AMOUNT" />}
                    label={intl.formatMessage({ id: "PRODUCTIVITY_STATUS_REPORT_SCREEN.AVERAGE_TRANSACTION_AMOUNT" })}
                    data={statusReportData?.averageTransactionDuration}
                />
            </article>
        </main>
    )
};
