import { IProductivityPerDay } from '../interfaces/IProductivityPerDay'
import { ProductivityPerDayRaw } from './ProductivityPerDayRaw'

interface Props {
  cashier: IProductivityPerDay
}

const ProductivityPerDay = (props: Props) => {
  const cashier: IProductivityPerDay = props.cashier
  return (
    <ProductivityPerDayRaw
      productivityPerDay={cashier}
    />
  )
}
export default ProductivityPerDay
