import { CashierRaw } from '../../../../_metronic/partials/widgets/tables/CashierRaw'
import { ICashier } from '../interfaces/ICashier'

interface Props {
  cashier: ICashier
}

const listColor = [
  { status: 1, color: '#50cd89', icon: 'fa-smile-beam' },
  { status: 2, color: '#ffc700', icon: 'fas fa-exclamation-circle' },
  { status: 3, color: '#f1416c', icon: 'fa-times-circle' },
]

const Cashier = (props: Props) => {
  const cashier: ICashier = props.cashier
  const avatar = Math.floor(Math.random() * 10)
  const colorStyle = listColor.find((item) => item.status === props.cashier.status)?.color
  const iconStyle = listColor.find((item) => item.status === props.cashier.status)?.icon

  return (
    <CashierRaw
      color={colorStyle}
      icon={iconStyle}
      avatar={`/media/avatars/150-${avatar}.jpg`}
      store={cashier.store}

      deskNumber={cashier.deskNumber}
      deskType={cashier.deskType}
      cashierNumber={cashier.cashierNumber}
      revenue={cashier.revenue}
      firstTillTime={cashier.firstTillTime}
      lastTillTime={cashier.lastTillTime}
      timeFromLastTill={cashier.timeFromLastTill}
      grossWrkSum={cashier.grossWrkSum}
      netWrkSum={cashier.netWrkSum}
      avgLoad={cashier.avgLoad}
      status={cashier.status}
      numberOfTickets={cashier.numberOfTickets}
      className={''}
    />
  )
}
export default Cashier
