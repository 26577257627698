/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormattedMessage } from 'react-intl';
import { IUserList } from '../interfaces/IUsageUser'
import UserRow from './UsageUserRow'

export type Column =
  'userName' |
  'email' |
  'storeName' |
  'phone' |
  'userLevel'
const columnArr = [
  'userName',
  'email',
  'storeName',
  'phone',
  'userLevel',
  'delete']


interface props {
  userArr: IUserList[],
  onTitleClick: (column: Column) => void
  deleteItem: (id: number) => void
  editItem: (id: number) => void
}
let design = 'min-w-90px fs-5 text-hover-primary fw-bolder mb-0 bg-light';
export default function UserList(props: props) {
  const userArr = props.userArr || []
  function onClick(title: Column) {
    props.onTitleClick(title);
  }

  const listRender = userArr.map((item: any) => {
    return <UserRow key={item.id} usageUser={item}
      deleteItem={props.deleteItem} editItem={props.editItem}
    ></UserRow>
  })
  const headerRender = () => {
    const hdrs: JSX.Element[] = []
    columnArr.forEach((col, i) => {
      hdrs.push(<th key={i}
        style={{ cursor: 'pointer' }} onClick={() => onClick(col as Column)} className={design}>
        <FormattedMessage id={"USER_LIST_SCREEN." + col.toUpperCase()} />
      </th>)
    })
    return hdrs
  }

  return (<div className={`card`}>
    <div className='card-body py-3'>
      <div className='table-responsive tableFixHead'>
        <table className='table align-middle table-row-bordered gs-4 gy-4'>
          <thead>
            <tr className='fw-bolder text-muted bg-light'>
              {headerRender()}
            </tr>
          </thead>
          <tbody>
            {listRender}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}
