import { StandartRow } from './StandartRow'
import { ClacRecommendedConfig, ITableRow } from '../interfaces/ITableRow'
import { ManualStandard } from './LoadPerStandard'

interface Props {
  cashier: ITableRow,
  onSelectRow: Function,
  onMorningChange: Function,
  onEveningChange: Function,
  index: number,
  selectedRow: number,
  ClacRecommendedConfig: ClacRecommendedConfig,
  historyMode: boolean,
  manualStandards?: ManualStandard,
}

const RowStandart = (props: Props) => {
  const onSelectRow = (index: number, morningSimulte: number, eveningSimulte: number, hasIndependedPOS: boolean) => {
    props.onSelectRow(index, morningSimulte, eveningSimulte, hasIndependedPOS);
  }
  const tableRow: ITableRow = props.cashier;
  calcFinalRecommendedStandard();
  function calcFinalRecommendedStandard() {
    tableRow.finalRecommendedMorningStandart = props.manualStandards?.morning.value || 0;
    if (tableRow.finalRecommendedMorningStandart) {
      if (props.ClacRecommendedConfig.riskRangePrecent)
        tableRow.finalRecommendedMorningStandart +=
          Math.round((tableRow.finalRecommendedMorningStandart / 100) * props.ClacRecommendedConfig.riskRangePrecent)
      if (props.ClacRecommendedConfig.cashiersForCarts)
        tableRow.finalRecommendedMorningStandart += props.ClacRecommendedConfig.cashiersForCarts
      if (props.ClacRecommendedConfig.cashiersForIndependentArea)
        tableRow.finalRecommendedMorningStandart += props.ClacRecommendedConfig.cashiersForIndependentArea
    }

    tableRow.finalRecommendedEveningStandart = props.manualStandards?.evening.value || 0;
    if (tableRow.finalRecommendedEveningStandart) {
      if (props.ClacRecommendedConfig.riskRangePrecent)
        tableRow.finalRecommendedEveningStandart +=
          Math.round((tableRow.finalRecommendedEveningStandart / 100) * props.ClacRecommendedConfig.riskRangePrecent)
      if (props.ClacRecommendedConfig.cashiersForCarts)
        tableRow.finalRecommendedEveningStandart += props.ClacRecommendedConfig.cashiersForCarts
      if (props.ClacRecommendedConfig.cashiersForIndependentArea)
        tableRow.finalRecommendedEveningStandart += props.ClacRecommendedConfig.cashiersForIndependentArea
    }
  }
  return (
    <StandartRow
      historyMode={props.historyMode}
      onSelectRow={onSelectRow}
      index={props.index}
      selectedRow={props.selectedRow}
      onMorningChange={props.onMorningChange}
      onEveningChange={props.onEveningChange}
      manualStandards={props.manualStandards}
      tableRow={tableRow}
      clacRecommendedConfig={props.ClacRecommendedConfig} />
  )
}
export default RowStandart
