/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useRef, useState } from 'react'
import StandartRowsList from './StandartRowsList'
import { getCalcRecommendedConfig, getStandartRows, getSundaysList } from '../services/cashiersData'
import { ClacRecommendedConfig, DAY_HEBREW_TO_NUMBER, ITableRow, RecommendedStandardRowsResponse } from '../interfaces/ITableRow'
import { filtersCashiers } from '../helpers/filtersCashiers'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Moment from 'react-moment';
import { Link, useLocation } from 'react-router-dom'
import { logEnum, sendlog } from '../../logs/sendLog'
import { FormattedMessage, useIntl } from 'react-intl'
import { setIsSortSide } from 'app/modules/auth/redux/AuthCRUD'
import { weekList } from 'app/modules/POSEventResearchDetails/interfaces/StoreWeekLoadResponse'
import SortWeek from './SortWeek'
import { Button, FormLabel, FormSelect } from 'react-bootstrap'
import axios from 'axios'

const sortByName = (a: any, b: any) => {
  if (a["name"] < b["name"]) {
    return -1
  }
  if (a["name"] > b["name"]) {
    return 1
  }
  return 0;
}
const sortByStatus = (a: any, b: any) => {
  if (a["status"] > b["status"]) {
    return -1
  }
  if (a["status"] < b["status"]) {
    return 1
  }
  return 0;
};

export type ManualStandard = ({ [Shift in "morning" | "evening"]: { id: number, value?: number } } & { day: number });

const sortConfig = [
  { field: 'name', func: sortByName },
  { field: 'status', func: sortByStatus },
]
export default function LoadPerStandard(props: any) {
  let defalutVal: ClacRecommendedConfig = {
    id: 0,
    cashiersForIndependentArea: -1,
    cashiersForCarts: -1,
    riskRangePrecent: -20,
    storeId: 0,
    indepentedDeskNumber: -1,
    cartNumber: -1
  }

  const [listStandartRows, setListStandartRows] = useState<ITableRow[]>([])
  const [selectedClacRecommendedConfig, setSelectedClacRecommendedConfig] = useState<ClacRecommendedConfig>(defalutVal)
  const [statusFilter] = useState<number>(0)
  // const [searchBranch, setSearchBranch] = useState<string>('')
  const [displayCalculationModal, setDisplayCalculationModal] = useState<boolean>(true)
  const [displayUpdateStatusModal, setDisplayUpdateStatusModal] = useState<boolean>(true)
  const { storeId: branchSelected, historyMode } = useLocation<{ storeId: number, historyMode: boolean }>().state || { storeId: "", historyMode: true };
  const [sundaysList, setSundaysList] = useState<weekList[]>([])
  const [lastDateUpdate, setLastDateUpdate] = useState<Date | null>()
  const manualStandardChanges = useRef<{ id: number, value: number, initialValue?: number }[]>([]);
  const [manualStandards, setManualStandards] = useState<ManualStandard[]>([]);
  const [calculatedFor, setCalculatedFor] = useState<RecommendedStandardRowsResponse['calculatedFor']>();

  const [hasManualStandardChanges, setHasManualStandardChanges] = useState(false);

  const getStandartRowsToRender = () => {
    if (!listStandartRows) return []

    const filterRun = filtersCashiers.find((filter) => filter.status === statusFilter)
    if (!filterRun) {
      return listStandartRows
    }

    return listStandartRows
      .filter(filterRun.filter)
      // .filter((item) => searchBranch === item.store)
      .sort(sortCashier)
  };
  const sortCashier = (a: any, b: any) => {
    const funcSort = sortConfig.find(s => s.field === 'order')?.func;
    if (funcSort) {
      return funcSort(a, b);
    }
    return 1;
  }

  const getSundayList = async () => {
    let data = await getSundaysList();
    setSundaysList(data)
  }

  const getListData = useCallback(async (date?: Date) => {
    try {
      const data = await getStandartRows(branchSelected, date);
      const configList: ClacRecommendedConfig[] = await getCalcRecommendedConfig();
      if (data.rows) {
        setListStandartRows(data.rows)
        setLastDateUpdate(new Date())
      }
      if (historyMode) {
        setCalculatedFor(data.calculatedFor);
      } else {
        setCalculatedFor(data.manualStandardsFor);
      }
      if (configList) {
        let selected = data.rows.find(s => s.storeId === branchSelected);
        let selectedval = configList.find(r => r.storeId === selected?.storeId);
        if (selectedval) {
          setSelectedClacRecommendedConfig(selectedval);
        }
      }
    } catch (err) { }
  }, [branchSelected, historyMode]);
  useEffect(() => {
    // onSearchBranch(branchSelected);
    sendlog(logEnum.loadPerStandardScreen);
    getListData();
    getSundayList();
  }, [branchSelected, getListData])

  const manualStandardIsEmpty = manualStandards.length === 0;
  useEffect(() => {
    if (manualStandardIsEmpty || historyMode) {
      setManualStandards(listStandartRows.map(row => ({
        day: DAY_HEBREW_TO_NUMBER[row.day],
        evening: {
          id: row.eveningStandardId,
          value: historyMode ? row.selectedManualEveningStandard : row.eveningSimulate,
        },
        morning: {
          id: row.morningStandardId,
          value: historyMode ? row.selectedManualMorningStandard : row.morningSimulate,
        }
      })));
    }
  }, [listStandartRows, manualStandardIsEmpty, historyMode]);

  const onTitleClick = (_titleSelected: string) => {
    let list = [...listStandartRows];


    setIsSortSide();
    setListStandartRows(list);
  }

  const displayCalcModal = () => {
    setDisplayCalculationModal(true);
  }

  const displayUpdatingStatusModal = () => {
    setDisplayUpdateStatusModal(true);
  }

  const onSortWeek = (index: number) => {
    let val = sundaysList[index].date
    getListData(val);
  }

  const handleSaveManualStandardChanges = async () => {
    try {
      await axios.post(`/standard/update-manual-recommended-standard/batch`, manualStandardChanges.current);
      setHasManualStandardChanges(false);
      manualStandardChanges.current = [];
    } catch (e: any) {
      alert(`error during save changes: ${e.toString()}`);
    }
  }

  const handleManualStandardChange = (standardId: number, newValue: number, previousValue?: number) => {
    const previousChangeIndex = manualStandardChanges.current.findIndex(change => change.id === standardId);
    if (previousChangeIndex !== -1) {
      const previousChange = manualStandardChanges.current[previousChangeIndex];
      if (previousChange.initialValue === newValue) {
        manualStandardChanges.current.splice(previousChangeIndex, 1);
        setHasManualStandardChanges(manualStandardChanges.current.length !== 0);
      } else {
        previousChange.value = newValue;
        setHasManualStandardChanges(true);
      }
    } else {
      setHasManualStandardChanges(true);
      manualStandardChanges.current.push({ id: standardId, value: newValue, initialValue: previousValue });
    }

    for (const manualStandard of manualStandards) {
      if (manualStandard.evening.id === standardId) {
        manualStandard.evening.value = newValue;
        break;
      } else if (manualStandard.morning.id === standardId) {
        manualStandard.morning.value = newValue;
        break;
      }
    }
    setManualStandards([...manualStandards]);
  }

  const resetSimulateValues = () => {
    for (const row of listStandartRows) {
      const manual = manualStandards.find(ms => ms.day === DAY_HEBREW_TO_NUMBER[row.day]);
      if (manual) {
        if ((row.recommendedMorningStandart ?? 0) !== (manual.morning.value ?? 0)) {
          console.log("morning change", row.recommendedMorningStandart, manual.morning.value)
          handleManualStandardChange(manual.morning.id, row.recommendedMorningStandart, manual.morning.value);
        }
        if ((row.recommendedEveningStandart ?? 0) !== (manual.evening.value ?? 0)) {
          console.log("evening change", row.recommendedEveningStandart, manual.evening.value)
          handleManualStandardChange(manual.evening.id, row.recommendedEveningStandart, manual.evening.value);
        }
      }
    }

    let tempListStandard = [...listStandartRows];
    for (const row of tempListStandard) {
      if (row.eveningSimulate !== row.recommendedEveningStandart) row.eveningSimulate = row.recommendedEveningStandart
      if (row.morningSimulate !== row.recommendedMorningStandart) row.morningSimulate = row.recommendedMorningStandart
    }
    setListStandartRows(tempListStandard);
  }

  const intl = useIntl();

  const calculatedForText = (
    calculatedFor
      ? intl.formatMessage(
        { id: "POS_EVENT_RESEARCH_SCREEN.WEEK_NUMBER" },
        {
          weekNumber: calculatedFor.weekNumber,
          startDate: new Date(calculatedFor.date).toLocaleDateString(),
        }
      )
      : intl.formatMessage({ id: "RECOMMENDATIONS_SCREEN.LOADING_RECOMMENDATION_FOR_WEEK" })
  );

  return (
    <div>
      <nav className='navbar navbar-light bg-light row justify-content-between'>
        <div className='col-auto'>
          <span>
            {lastDateUpdate &&
              <div>
                <span>
                  <FormattedMessage
                    id={"CASHIERS_ALERTS_SCREEN.LAST_UPDATE"}
                    values={{ lastUpdate: <Moment format='DD-MM-YYYY HH:mm'>{lastDateUpdate ? lastDateUpdate : ''}</Moment> }} />
                </span>
              </div>}
            {!lastDateUpdate && <img src={toAbsoluteUrl('/media/gifs/hourglass.gif')} width={20} alt="loading" />}
          </span>
        </div>
        <h1 className='breadcrumb-item text-muted col-auto'><FormattedMessage id="RECOMMENDATIONS_SCREEN.STANDARD_RECOMMENDATION_DOC" /></h1>
        <span className='col-auto'>
          <a className='navbar-brand' href='/'>
            &nbsp;&nbsp;
            <img
              src={toAbsoluteUrl('/media/logos/retail_logo.png')}
              width='100'
              height='30'
              className='d-inline-block '
              alt=''
              loading='lazy'
            />
          </a>
        </span>
      </nav>
      <br />
      <br />
      <div className='row'>
        <div className='col-12 col-xxl-10 d-flex gap-2 flex-wrap align-items-end'>
          <FormLabel className="m-0">
            <FormattedMessage id="RECOMMENDATIONS_SCREEN.RECOMMENDATION_FOR_WEEK" />
            <FormSelect aria-readonly disabled value={calculatedForText}>
              <option>{calculatedForText}</option>
            </FormSelect>
          </FormLabel>
          <SortWeek collection={sundaysList} onSortWeek={onSortWeek} />
          {!historyMode && <Link
            className='btn btn-primary d-flex align-items-center'
            data-bs-toggle='modal'
            to={{ pathname: '#calc_modal' }}
            onClick={displayCalcModal}
          >
            <FormattedMessage id="RECOMMENDATIONS_SCREEN.BRUTO_CALCULATOR" />
          </Link>}
          {!historyMode && <Link
            className='btn btn-primary d-flex align-items-center'
            data-bs-toggle='modal'
            to={{ pathname: '#status_modal' }}
            onClick={displayUpdatingStatusModal}
          >
            <FormattedMessage id="RECOMMENDATIONS_SCREEN.UPDATE_STATUS" />
          </Link>}
          {!historyMode && <button onClick={resetSimulateValues} className='btn btn-primary'>
            <FormattedMessage id="RECOMMENDATIONS_SCREEN.RESET_STANDARD" />
          </button>}
          {!historyMode && <Button variant="primary" disabled={!hasManualStandardChanges} onClick={handleSaveManualStandardChanges}>
            {!hasManualStandardChanges
              ? <FormattedMessage id="RECOMMENDATIONS_SCREEN.NO_CHANGES" />
              : <FormattedMessage id="RECOMMENDATIONS_SCREEN.SAVE_CHANGES" />}
          </Button>}
        </div>
      </div>
      <hr />
      <StandartRowsList
        onManualStandardChange={handleManualStandardChange}
        manualStandards={manualStandards}
        historyMode={historyMode}
        onTitleClick={onTitleClick}
        tableRows={getStandartRowsToRender()}
        selectedClacRecommendedConfig={selectedClacRecommendedConfig}
        displayCalculationModal={displayCalculationModal}
        displayUpdateStatusModal={displayUpdateStatusModal}
        getListData={getListData}
        displayLoadRanges={true}
      />
    </div>
  )
}
