/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { CURRENT_USER, setCurrentUser, setUserName, UserLevel } from '../../../../app/modules/auth/redux/AuthCRUD';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { setLanguage, useLang } from '_metronic/i18n/Metronici18n';
import { useCallback, useEffect, useState } from 'react';
import { getMenuScreen } from 'app/modules/settings/services/settingsData';

const UserLevelTransalte = {
  "admin": 3,
  "manager": 2,
  "user": 1
}

type screens = {
  [key: string]: number;
};


export function AsideMenuMain() {
  const intl = useIntl()
  const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)
  const userName = useSelector<RootState, string | undefined>(({ auth }) => auth.user?.email) ?? '';
  const userLevel = useSelector<RootState, UserLevel | undefined>(({ auth }) => auth.userLevel) ?? 'user';
  setCurrentUser(userLevel);
  setUserName(userName);

  const lang = useLang();
  const [menuItemsDictionary, setMenuItemsDictionary] = useState<screens>({});

  const getScreens = useCallback(async () => {
    const screens = await getMenuScreen()
    const dictionary: screens = screens.reduce((acc: screens, curr) => {
      acc[curr.title] = curr.userMinLevel;
      return acc;
    }, {});

    setMenuItemsDictionary(dictionary)
  }, [])

  useEffect(() => {
    getScreens()
  }, [getScreens])

  const isShowMenuItem = (name: string) => {
    console.log(name, !menuItemsDictionary[name], menuItemsDictionary[name] <= UserLevelTransalte[CURRENT_USER], menuItemsDictionary[name], UserLevelTransalte[CURRENT_USER])
    return !menuItemsDictionary[name] || menuItemsDictionary[name] <= UserLevelTransalte[CURRENT_USER]
  }


  return (
    <>
      {isShowMenuItem("MAIN_MENU.ONLINE_ALERTS") && <AsideMenuItemWithSub
        icon='/media/icons/duotune/general/gen022.svg'
        to={''}
        title={intl.formatMessage({ id: "MAIN_MENU.ONLINE_ALERTS" })}>
        {isShowMenuItem("SCREENS.PIE_CHART") && <AsideMenuItem
          to='/mainPieChartScreen'
          hasBullet={true}
          title={intl.formatMessage({ id: "SCREENS.PIE_CHART" })}
          fontIcon='bi-app-indicator'
        />}

        {isShowMenuItem("SCREENS.CASHIER_ALERTS_SCREEN") && <AsideMenuItem
          to='/stores'
          hasBullet={true}
          title={intl.formatMessage({ id: "SCREENS.CASHIER_ALERTS_SCREEN" })}
          fontIcon='bi-app-indicator'
        />}
      </AsideMenuItemWithSub>}

      {isShowMenuItem("MAIN_MENU.INFO_RECOMMENDATION_SIMULATION") &&
        <AsideMenuItemWithSub
          icon='/media/icons/duotune/general/gen003.svg'
          to={''}
          title={intl.formatMessage({ id: "MAIN_MENU.INFO_RECOMMENDATION_SIMULATION" })}>

          {isShowMenuItem("SCREENS.RECOMMENDATIONS_AND_EFFICIENCY_MEASURES") && <AsideMenuItem
            to='/recommendedStandard'
            hasBullet={true}
            title={intl.formatMessage({ id: "SCREENS.RECOMMENDATIONS_AND_EFFICIENCY_MEASURES" })}
            fontIcon='bi-app-indicator'
          />}
        </AsideMenuItemWithSub>}

      {isShowMenuItem("MAIN_MENU.HISTORICAL_INFORMTAION") &&
        <AsideMenuItemWithSub
          title={intl.formatMessage({ id: "MAIN_MENU.HISTORICAL_INFORMTAION" })}
          to=''
          icon='/media/icons/duotune/general/gen004.svg'
        >
          {isShowMenuItem("SCREENS.POS_EVENT_RESEARCH") &&
            <AsideMenuItem
              to='/POSEventResearch'
              hasBullet={true}
              title={intl.formatMessage({ id: "SCREENS.POS_EVENT_RESEARCH" })}
              fontIcon='bi-app-indicator'
            />
          }

          {isShowMenuItem("MAIN_MENU.POS_PRODUCTIVITY") && <AsideMenuItem
            to='/ProductivityPerDay'
            hasBullet={true}
            title={intl.formatMessage({ id: "MAIN_MENU.POS_PRODUCTIVITY" })}
            fontIcon='bi-app-indicator'
          />}

          {isShowMenuItem("SCREENS.PRODUCTIVITY_MEASURES") && <AsideMenuItem
            title={intl.formatMessage({ id: "SCREENS.PRODUCTIVITY_MEASURES" })}
            to='/productivity-measures'
            hasBullet={true}
          />}

          {isShowMenuItem("SCREENS.LINE_ACTIONS") && <AsideMenuItem
            title={intl.formatMessage({ id: "SCREENS.LINE_ACTIONS" })}
            to='/line-actions'
            hasBullet
          />}

        </AsideMenuItemWithSub>}

      {isShowMenuItem("MAIN_MENU.PRODUCTIVITY_ANALYSIS") &&
        <AsideMenuItemWithSub
          title={intl.formatMessage({ id: "MAIN_MENU.PRODUCTIVITY_ANALYSIS" })}
          to=''
          icon='/media/icons/duotune/general/gen004.svg'
        >
          {isShowMenuItem("SCREENS.PRODUCTIVITY_STATUS_REPORT") && <AsideMenuItem
            title={intl.formatMessage({ id: "SCREENS.PRODUCTIVITY_STATUS_REPORT" })}
            to='/productivity/status-report'
            hasBullet
          />}
          {isShowMenuItem("SCREENS.TREND_ANALYSIS") && <AsideMenuItem
            title={intl.formatMessage({ id: "SCREENS.TREND_ANALYSIS" })}
            to='/productivity/trend-analysis'
            hasBullet
          />}
          {isShowMenuItem("SCREENS.PER_HOUR_PRODUCTIVITY") && <AsideMenuItem
            title={intl.formatMessage({ id: "SCREENS.PER_HOUR_PRODUCTIVITY" })}
            to='/productivity/per-hour-productivity'
            hasBullet
          />}


        </AsideMenuItemWithSub>}

      {isShowMenuItem("MAIN_MENU.OPERATION") && (
        <AsideMenuItemWithSub
          icon='/media/icons/duotune/coding/cod001.svg'
          to={''}
          title={intl.formatMessage({ id: "MAIN_MENU.OPERATION" })}>

          {isShowMenuItem("SCREENS.KPI_SETTINGS") && <AsideMenuItem
            to='/table1'
            hasBullet={true}
            title={intl.formatMessage({ id: "SCREENS.KPI_SETTINGS" })}
            fontIcon='bi-app-indicator'
          />}
          {isShowMenuItem("SCREENS.ALERTS") && <AsideMenuItem
            to='/alerts-management'
            hasBullet={true}
            title={intl.formatMessage({ id: "SCREENS.ALERTS" })}
            fontIcon='bi-alarm-fill'
          />}
          {isShowMenuItem("SCREENS.USAGE_LEVELS") && <AsideMenuItem
            to='/usageLevel'
            hasBullet={true}
            title={intl.formatMessage({ id: "SCREENS.USAGE_LEVELS" })}
            fontIcon='bi-app-indicator'
          />}
          {isShowMenuItem("SCREENS.USER_LIST") && <AsideMenuItem
            to='/userList'
            hasBullet={true}
            title={intl.formatMessage({ id: "SCREENS.USER_LIST" })}
            fontIcon='bi-app-indicator'
          />}

          {isShowMenuItem("SCREENS.DETAILED_USAGE_LOG") && <AsideMenuItem
            to='/usageLog'
            hasBullet={true}
            title={intl.formatMessage({ id: "SCREENS.DETAILED_USAGE_LOG" })}
            fontIcon='bi-app-indicator'
          />}
          {isShowMenuItem("SCREENS.MAINTENANCE") && <AsideMenuItem
            to='/maintenance'
            hasBullet
            title={intl.formatMessage({ id: "SCREENS.MAINTENANCE", defaultMessage: "Maintenance" })}
          />}
        </AsideMenuItemWithSub>
      )}

      <br />
      <AsideMenuItem
        to='/logout'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: "MAIN_MENU.LOGOUT" })}
        fontIcon='bi-app-indicator'
      />
      {!accessToken &&
        <AsideMenuItem
          to='/auth'
          icon='/media/icons/duotune/art/art002.svg'
          title='כניסה'
          fontIcon='bi-app-indicator'
        />}

      <div className='menu-item'>
        <div className='menu-content'>
          <div style={{ margin: 10, zIndex: 1 }} className="language-picker">
            <select
              value={lang}
              onChange={e => setLanguage(e.target.value)}
              className='form-select'
              data-hide-search='true'
              data-kt-select2='true'
            >
              <option value={'he'}>עברית</option>
              <option value={'en'}>English</option>
              {CURRENT_USER === "admin" && <option value={'keys'}>Translation Keys</option>}
            </select>
          </div>

          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
      </div>
    </>
  )
}
