/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { changeUser, getUser } from '../services/userData'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { fullUser } from 'app/modules/auth/redux/AuthCRUD'
import clsx from 'clsx'
import * as Yup from 'yup'
import { useHistory, useLocation } from 'react-router-dom'
import { FormSelect } from 'react-bootstrap'
import { UserLevelOptions } from './userLevelOptions'
import { StoreOptions } from './storeOptions'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  userLevel: Yup.number().required('userLevel is required'),
  storeId: Yup.number().required('store is required'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(true),
})

interface LocationState {
  id: number
}

export default function UpdateUserPage() {
  const location = useLocation<LocationState>()
  const { id } = location.state || {}
  const [User, setUser] = useState<fullUser>()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const go_back = () => {
    history.push({
      pathname: '/userList',
    })
  }

  useEffect(() => {
    getUser('', typeof location.state.id == 'number' ? location.state.id : 0).then((gotUser) => {
      try {
        setLoading(true)
        console.log(gotUser)
        if (gotUser) {
          setLoading(false)
          setUser(gotUser)
        }
      } catch (err) { }
    })
  }, [location.state.id])



  const initialValues = {
    name: User?.name ?? '',
    email: User?.email ?? '',
    password: User?.password ?? '',
    changepassword: User?.password ?? '',
    userLevel: User?.userLevel?.level ?? '3',
    storeId: User?.storeId ?? '',
    phone: User?.phone ?? '',
  }

  const intl = useIntl()
  const makeStatusByType = (setStatus: (messege: string) => void, messege: string) => {
    switch (messege) {
      case 'email exists': setStatus(intl.formatMessage({ id: 'UPDATE_USER_SCREEN.EMAIL_ERROR' })); break;
      default: setStatus(intl.formatMessage({ id: 'UPDATE_USER_SCREEN.REGISTATION_FAIL' })); break;
    }
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      setLoading(true)
      changeUser('', id, {
        email: values.email,
        name: values.name,
        password: values.password,
        storeId: values.storeId,
        userLevel: { id: values.userLevel, level: values.userLevel },
        phone: values.phone,
      })
        .then((res) => {
          setLoading(false)
          console.log(res)
          if (res.messege === 'success')
            setTimeout(() => {
              go_back()
            }, 500)
          else {
            makeStatusByType(setStatus, res.messege)
          }
        })
        .catch(() => {
          setSubmitting(false)
          setLoading(false)
          setStatus(setStatus(intl.formatMessage({ id: 'UPDATE_USER_SCREEN.UPDATE_ERROR' })))
        })
    },
  })
  return (
    <div>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>
                <FormattedMessage id='UPDATE_USER.HEADLINE' />
              </h1>
            </div>
            <form onSubmit={formik.handleSubmit}>
              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  <FormattedMessage id='UPDATE_USER.NAME' />
                </label>
                <input
                  style={{ WebkitAppearance: 'none' }}
                  value={formik.values.name}
                  disabled={loading}
                  className={clsx(
                    'form-control form-control-lg form-control-solid text-info',
                    {
                      'is-invalid': formik.touched.name && formik.errors.name,
                    },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  type='string'
                  autoComplete='off'
                  id='name'
                  name='name'
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  <FormattedMessage id='UPDATE_USER.PASSWORD' />
                </label>
                <input
                  style={{ WebkitAppearance: 'none' }}
                  value={formik.values.password}
                  disabled={loading}
                  className={clsx(
                    'form-control form-control-lg form-control-solid text-info',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                  type='password'
                  autoComplete='off'
                  id='password'
                  name='password'
                  onChange={formik.handleChange}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  <FormattedMessage id='UPDATE_USER.CHANGEPASSWORD' />
                </label>
                <input
                  style={{ WebkitAppearance: 'none' }}
                  value={formik.values.changepassword}
                  disabled={loading}
                  className={clsx(
                    'form-control form-control-lg form-control-solid text-info',
                    {
                      'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                    },
                    {
                      'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                    }
                  )}
                  type='password'
                  autoComplete='off'
                  id='changepassword'
                  name='changepassword'
                  onChange={formik.handleChange}
                />
                {formik.touched.changepassword && formik.errors.changepassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.changepassword}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  <FormattedMessage id='UPDATE_USER.EMAIL' />
                </label>
                <input
                  style={{ WebkitAppearance: 'none' }}
                  value={formik.values.email}
                  disabled={loading}
                  className={clsx(
                    'form-control form-control-lg form-control-solid text-info',
                    {
                      'is-invalid': formik.touched.email && formik.errors.email,
                    },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='string'
                  autoComplete='off'
                  id='email'
                  name='email'
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  <FormattedMessage id='UPDATE_USER.PHONE' />
                </label>
                <input
                  style={{ WebkitAppearance: 'none' }}
                  value={formik.values.phone}
                  disabled={loading}
                  className={clsx(
                    'form-control form-control-lg form-control-solid text-info',
                    {
                      'is-invalid': formik.touched.phone && formik.errors.phone,
                    },
                    {
                      'is-valid': formik.touched.phone && !formik.errors.phone,
                    }
                  )}
                  type='string'
                  autoComplete='off'
                  id='phone'
                  name='phone'
                  onChange={formik.handleChange}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.phone}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='row fv-row mb-7'>
                <div className='col-xl-6'>
                  <label className='class="form-label fw-bolder text-dark fs-6'>
                    <FormattedMessage id={'REGISTER_USER_SCREEN.STORE'} />{' '}
                  </label>
                  <FormSelect
                    placeholder='store'
                    {...formik.getFieldProps('storeId')}
                    disabled={loading}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.storeId && formik.errors.storeId,
                      },
                      {
                        'is-valid': formik.touched.storeId && !formik.errors.storeId,
                      }
                    )}
                  >
                    <StoreOptions />
                  </FormSelect>

                  {formik.touched.storeId && formik.errors.storeId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.storeId}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-xl-6'>
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      <FormattedMessage id={'REGISTER_USER_SCREEN.USER_LEVEL'} />
                    </label>
                    <FormSelect
                      placeholder='user level'
                      {...formik.getFieldProps('userLevel')}
                      disabled={loading}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.userLevel && formik.errors.userLevel,
                        },
                        {
                          'is-valid': formik.touched.userLevel && !formik.errors.userLevel,
                        }
                      )}
                    >
                      <UserLevelOptions />
                    </FormSelect>
                    {formik.touched.userLevel && formik.errors.userLevel && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.userLevel}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <button
                  disabled={loading}
                  type='submit'
                  className='btn btn-light-primary btn-sm fs-6 mx-2'
                >
                  <FormattedMessage id='GENERAL.CONFIRM' />
                </button>
                <button
                  onClick={go_back}
                  id='exit'
                  type='button'
                  data-bs-dismiss='modal'
                  className='btn btn-light-primary btn-sm fs-6 mx-2'
                >
                  <FormattedMessage id='GENERAL.CANCEL' />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
