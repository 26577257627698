/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useIsRTL } from '_metronic/i18n/Metronici18n'
import { KTSVG } from '../../../../_metronic/helpers'
import { updateStandardStatus } from '../services/cashiersData'

type Props = {
  standardHeaderId: number,
  currentStatus: number,
  onChange: Function
}

const UpdateStatusModal: React.FC<Props> = ({
  standardHeaderId = 0,
  currentStatus,
  onChange
}) => {
  let exitButton = useRef<HTMLButtonElement>(null);
  const [selected, setSelected] = useState<number>(0);

  useEffect(() => {
    if (selected === 0) {
      setSelected(currentStatus);
    }
  }, [selected, currentStatus])

  const onCheck = (event: any) => {
    if (event.target.id === 'inProgress') setSelected(1)
    else if (event.target.id === 'approved') setSelected(2)
    else if (event.target.id === 'updated') setSelected(3)
  }

  const postStatus = async () => {
    updateStandardStatus(standardHeaderId, selected);
    setTimeout(async () => {
      await onChange();
      exitButton.current?.click();
    }, 400);
  }

  const exitClicked = () => {
    setSelected(0);
    exitButton.current?.click();
  }
  const isRTL = useIsRTL();
  return (
    <div dir={isRTL ? 'rtl' : 'ltr'} className='modal fade' id='status_modal' aria-hidden='true'>
      <div className='modal-dialog mw-450px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-8'>
              <h1 className='mb-3'>
                <FormattedMessage id="RECOMMENDATIONS_SCREEN.RECOMMENDATION_STANDARD_DOC_UPDATE" />
              </h1>
              <br />
            </div>
            <div className='text-start'>
              <div className=" form-check">
                <span className='mt-3 col-4 text-gray-600 fs-5 fw-bolder'>
                  <FormattedMessage id="RECOMMENDATIONS_SCREEN.IN_PROCESS" />
                </span>
                <input checked={selected === 1} onChange={onCheck} className="form-check-input" type="radio" name="flexRadioDefault" id="inProgress" />
              </div>
              <br />
              <div className="form-check">
                <span className='mt-3 col-4 text-gray-600 fs-5 fw-bolder'>
                  <FormattedMessage id="RECOMMENDATIONS_SCREEN.CONFIRMED" />
                </span>
                <input checked={selected === 2} onChange={onCheck} className="form-check-input" type='radio' name="flexRadioDefault" id="approved" />
              </div>
              <br />
              <div className="form-check">
                <span className='mt-3 col-4 text-gray-600 fs-5 fw-bolder'>
                  <FormattedMessage id="RECOMMENDATIONS_SCREEN.UPDATED" />
                </span>
                <input checked={selected === 3} onChange={onCheck} className="form-check-input" type='radio' name="flexRadioDefault" id="updated" />
              </div>
            </div>
            <div dir={isRTL ? 'rtl' : 'ltr'} className='text-center mt-10'>
              <button onClick={postStatus} className='btn btn-light-primary btn-sm fs-6 mx-2'><FormattedMessage id="GENERAL.CONFIRM" /></button>
              <button ref={exitButton} onClick={exitClicked} id='exit' type='button' data-bs-dismiss='modal' className='btn btn-light-primary btn-sm fs-6 mx-2'><FormattedMessage id="GENERAL.CANCEL" /></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { UpdateStatusModal }
